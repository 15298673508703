import { CellStyleFunction, ITableField } from '../frontendInterfaces';
import { i18n } from '@/vue';
import { isNil } from 'lodash';

export enum TableClass {
  ALIGN_MIDDLE = 'align-middle',
  BREAK_ALL_WORDS = 'break-all-words',
  TEXT_CENTER = 'text-center',
}
export type FieldOptions<T> = {
  translationKey?: string;
  translationCounter?: number;
  simpleLabel?: string;
  sortable?: boolean;
  thClass?: TableClass[];
  tdClass?: (TableClass | string)[];
  tdClassFunction?: CellStyleFunction<T>;
  index?: number;
  width?: string;
};
type TableFieldsBuilderOptions<T> = {
  translationKeyPrefix: string;
  defaults: FieldOptions<T>;
};
export type TableFieldsBuilderOptionsInput<T> = Omit<
  TableFieldsBuilderOptions<T>,
  'defaults'
> & { defaults?: FieldOptions<T> };

export class TableFieldsBuilder<T> {
  private options: TableFieldsBuilderOptions<T>;
  private fields: Required<ITableField<T>>[] = [];

  constructor(options: TableFieldsBuilderOptionsInput<T>) {
    this.options = {
      ...options,
      defaults: options.defaults ?? {},
    };
  }

  public addField(key: keyof T, fieldOptions?: FieldOptions<T>): this {
    fieldOptions = {
      ...this.options.defaults,
      ...fieldOptions,
    };

    const field = {
      key: key,
      label: this.getLabel(key.toString(), fieldOptions),
      sortable:
        fieldOptions.sortable ?? this.options.defaults.sortable ?? false,
      thClass: [
        ...(fieldOptions.thClass ?? this.options.defaults.thClass ?? []),
      ],
      tdClass: this.getTdClassList(fieldOptions),

      thStyle: fieldOptions.width ? { width: fieldOptions.width } : undefined,
    };

    if (fieldOptions.index !== undefined) {
      this.fields.splice(fieldOptions.index, 0, field);
      return this;
    } else this.fields.push(field);

    return this;
  }

  public addWidths(widths: number[], isActionIcon: boolean = false): this {
    if (widths.length !== this.fields.length) {
      throw new Error('Widths length must be equal to fields length');
    }
    const widthsSum =
      widths.reduce((acc, width) => acc + width, 0) + (isActionIcon ? 2 : 0);
    if (widthsSum !== 100) {
      throw new Error(
        `Widths sum must be equal to 100. Current sum: ${widthsSum}`,
      );
    }

    widths.forEach((width, index) => {
      const widthClass = `w-${width}`;
      const fieldTdClass = this.fields[index].tdClass;
      if (Array.isArray(fieldTdClass)) {
        fieldTdClass.push(widthClass);
      } else {
        throw new Error(
          `Cannot use ${this.addField.name} method with tdClassFunction.`,
        );
      }
    });

    return this;
  }

  private getLabel(key: string, options: FieldOptions<T>): string {
    switch (true) {
      case !isNil(options.simpleLabel):
        return options.simpleLabel!;
      case !!options.translationKey:
        return i18n
          .t(`${this.options.translationKeyPrefix}.${options.translationKey}`)
          .toString();
      default:
        return i18n.t(`${this.options.translationKeyPrefix}.${key}`).toString();
    }
  }

  private getTdClassList(
    fieldOptions: FieldOptions<T>,
  ): string[] | CellStyleFunction<T> {
    const classes = [
      ...(fieldOptions.tdClass ?? this.options.defaults.tdClass ?? []),
    ];
    const cellStyleFunction =
      fieldOptions.tdClassFunction ?? this.options.defaults.tdClassFunction;

    if (cellStyleFunction) {
      return function (value: T[keyof T], key: keyof T, item: T) {
        const style = cellStyleFunction(value, key, item);
        return [...classes, ...style];
      };
    } else return classes;
  }

  public build(): Required<ITableField<T>>[] {
    return this.fields;
  }
}
