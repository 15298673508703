import DigitalizationProjectCheckBotManagement from '@/components/DigitalizationProjectCheckBotManagement/DigitalizationProjectCheckBotManagement.vue';
import DigitalizationProjectContentTypes from '@/components/DigitalizationProjectContentTypes/DigitalizationProjectContentTypes.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'digitalization-project-settings',

  components: {
    DigitalizationProjectCheckBotManagement,
    DigitalizationProjectContentTypes,
  },

  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
  },
});
