import { GlobalUtils } from '@/mixins/globalUtils';
import { ContentTypeConfigurationMode } from '@/views/ContentTypeConfiguration/ContentTypeConfiguration.script';
import digitalizationProjectTabNumbersMixin, {
  DigitalizationProjectDetailViewTab,
  DigitalizationProjectTabNumbersMixin,
} from '@/views/DigitalizationProjectDetails/digitalizationProjectTabNumbersMixin';
import projectTabNumbersMixin from '@/views/ProjectDetails/projectTabNumbersMixin';
import axios, { AxiosResponse } from 'axios';
import { cloneDeep, set } from 'lodash';
import {
  AssignmentType,
  ContentTypeFieldType,
  ICheckBotMapping,
  IContentTypeField,
  ICreateDigitalizationProjectContentTypeMappingDto,
  IDigitalizationProjectContentTypeFieldMappingDto,
  IFieldMapping,
  IGetContentTypeAndCheckBotMappingDto,
  Serialized,
  TermsPerTermSet,
  UniversalMappingField,
} from 'types';
import { reverseKeyValuePairs } from 'utils';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import MainTable from '../../components/MainTable/MainTable.vue';
import endpointsList from '../../helpers/endpointsList';
import { router } from '../../vue';
import universalMappingFields from './universalMappingFields';

interface IContentTypeMappingTable {
  checkBotMapping: IReversedCheckBotMapping;
  fieldsWithValidationError: string[];
  mappingFields?: IFieldMapping[] | IContentTypeField[];
  possibleTaxonomyFieldValues?: TermsPerTermSet;
  selectedAssignments: ISelectedAssignments;
  validationError: TranslateResult | false;
}

interface ISelectedAssignments {
  [field: string]: ISelectedAssignment;
}
interface ISelectedAssignment {
  type: AssignmentType;
  value: string;
}

interface IReversedCheckBotMapping {
  [field: string]: keyof ICheckBotMapping;
}

export default Vue.extend({
  name: 'content-type-mapping-table',
  components: { MainTable },
  mixins: [digitalizationProjectTabNumbersMixin, projectTabNumbersMixin],
  props: {
    contentTypeId: {
      type: String,
      required: true,
    },
    /** Table headers from the CSV file */
    headers: {
      type: Array,
      required: true,
    },
    mode: {
      type: String as () => ContentTypeConfigurationMode,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
  },
  data(): IContentTypeMappingTable {
    return {
      checkBotMapping: {},
      fieldsWithValidationError: [],
      mappingFields: undefined,
      possibleTaxonomyFieldValues: undefined,
      selectedAssignments: {},
      validationError: false,
    };
  },

  methods: {
    async getContentTypeMapping() {
      try {
        const response = await axios.get<IFieldMapping[]>(
          endpointsList.contentTypeMappings.getFieldsMapping(
            this.contentTypeId,
            this.projectId,
            this.supplierId,
          ),
        );

        /**
         * If the mapping wasn't set up previously, than it will just load the
         * fields without mapping
         */
        if (!response.data.length) {
          this.getFields();
          return;
        }

        this.mappingFields = response.data;
        this.initializeContentTypeMappingForm();
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('contentTypeConfiguration.errors.errorGettingMapping'),
          'danger',
        );
      }
    },

    initializeContentTypeMappingForm() {
      if (!this.mappingFields) return;

      for (const field of this.mappingFields as IFieldMapping[]) {
        set(this.selectedAssignments, `${field.field}.value`, field.value);
        set(
          this.selectedAssignments,
          `${field.field}.type`,
          field.assignmentType,
        );
      }
    },

    async getCheckBotMapping() {
      try {
        const response: AxiosResponse<ICheckBotMapping> = await axios.get(
          endpointsList.contentTypeMappings.getCheckBotMapping(
            this.contentTypeId,
          ),
        );

        this.checkBotMapping = reverseKeyValuePairs(
          response.data as unknown as Record<string, string>,
        ) as IReversedCheckBotMapping;
      } catch (err) {
        //
      }
    },

    async getFields() {
      try {
        const response: AxiosResponse<IContentTypeField[]> = await axios.get(
          endpointsList.contentTypeMappings.getContentTypeById(
            this.contentTypeId,
          ),
        );
        const mappingFields = response.data;

        if (
          !mappingFields.some((field) => field.field.toLowerCase() === 'kks')
        ) {
          mappingFields.unshift({
            field: 'KKS',
            fieldDisplayName: 'KKS',
            dataType: ContentTypeFieldType.TEXT,
            required: true,
          });
        }

        if (
          mappingFields.find(
            (field) => field.field.toLowerCase() === 'kks' && !field.required,
          )
        ) {
          const kksField = mappingFields.find(
            (field) => field.field.toLowerCase() === 'kks',
          );
          kksField!.required = true;
        }

        for (const field of universalMappingFields) {
          mappingFields.unshift(field);
        }

        this.mappingFields = mappingFields;

        for (const field of mappingFields) {
          set(this.selectedAssignments, `${field.field}.value`, '');
          set(this.selectedAssignments, `${field.field}.type`, 'csvField');
        }
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }
    },

    validateMapping() {
      let isValidated = true;

      this.fieldsWithValidationError = [];

      this.validationError = false;

      const requiredFields: string[] = [];

      for (const field of this.mappingFields ?? []) {
        if (field.required) {
          requiredFields.push(field.field);
        }
      }

      for (const field of requiredFields) {
        if (
          this.selectedAssignments[field].value === '' &&
          this.selectedAssignments[field].type !== 'currentDate'
        ) {
          this.fieldsWithValidationError.push(field);
          isValidated = false;

          this.validationError = this.$t(
            'contentTypeConfiguration.selectForAllFields',
          );
        }
      }

      return isValidated;
    },

    rowColor(item: IFieldMapping | IContentTypeField, type: string) {
      if (!item || type !== 'row') {
        return;
      }
      if (this.fieldsWithValidationError.includes(item.field)) {
        return 'table-danger';
      }
    },

    async submitFieldsMapping() {
      const mappedFields: IFieldMapping[] = [];

      for (const field of this.mappingFields ?? []) {
        const mappedField = {
          field: field.field,
          fieldDisplayName: field.fieldDisplayName,
          dataType: field.dataType,
          value: this.selectedAssignments[field.field].value,
          assignmentType: this.selectedAssignments[field.field].type,
          required: field.required,
        };

        mappedFields.push(mappedField);
      }

      const patchObject = {
        fieldsMapping: mappedFields,
      };

      try {
        const response = await axios.patch(
          endpointsList.contentTypeMappings.addFieldsMapping(
            this.contentTypeId,
            this.projectId,
            this.supplierId,
          ),
          patchObject,
        );

        if (response.status === 200) {
          (this as unknown as GlobalUtils).createToast(
            this.$t('contentTypeConfiguration.mappingSaved'),
            this.$t('contentTypeConfiguration.mappingSaved'),
            'success',
          );
        }

        setTimeout(() => {
          this.goToProjectsSupplierDetailView();
        }, 3000);
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }
    },

    handleCancelButtonClick(): void {
      if (this.mode === ContentTypeConfigurationMode.PROJECT) {
        this.goToProjectsSupplierDetailView();
      } else if (
        this.mode === ContentTypeConfigurationMode.DIGITALIZATION_PROJECT
      ) {
        this.goToDigitalizationProjectSettings();
      }
    },

    goToProjectsSupplierDetailView() {
      router.push({
        name: 'projectsSupplierDetailView',
        params: {
          supplierId: this.supplierId,
          projectId: this.projectId,
          tabNumber: '1',
        },
      });
    },

    goToDigitalizationProjectSettings() {
      const tabNumber = (
        this as unknown as DigitalizationProjectTabNumbersMixin
      ).getDigitalizationProjectDetailViewTabNumber(
        DigitalizationProjectDetailViewTab.SETTINGS,
      );

      router.push({
        name: 'digitalizationProjectDetails',
        params: {
          id: this.projectId,
          tabNumber,
        },
      });
    },

    async submitMapping() {
      if (!this.validateMapping()) {
        return;
      }

      if (this.mode === ContentTypeConfigurationMode.PROJECT) {
        await this.submitFieldsMapping();
      } else if (
        this.mode === ContentTypeConfigurationMode.DIGITALIZATION_PROJECT
      ) {
        this.submitDigitalizationContentType();
      }
    },

    setInputType(field: string) {
      this.selectedAssignments[field].value = '';

      this.selectedAssignments = cloneDeep(this.selectedAssignments);
    },

    contentTypeField(mapping: IFieldMapping) {
      let fieldName = mapping.fieldDisplayName;

      if (fieldName === UniversalMappingField.DOCUMENT_ID) {
        fieldName = 'DocumentID';
      }

      if (mapping.required) {
        fieldName = `${fieldName}*`;
      }
      if (this.checkBotMapping[mapping.field]) {
        fieldName = `${fieldName} (${this.checkBotMapping[mapping.field]})`;
      }

      return fieldName;
    },

    async getPossibleTaxonomyFieldValues() {
      try {
        const response = await axios.get(
          endpointsList.contentTypeMappings.getPossibleValuesForContentTypesTaxonomyFields(
            this.contentTypeId,
          ),
        );

        this.possibleTaxonomyFieldValues = response.data;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t(
            'contentTypeConfiguration.errors.couldNotGetPossibleTaxonomyValues',
          ),
          'danger',
        );
      }
    },

    async getDigitalizationContentTypeAndCheckBotMapping() {
      try {
        const response: AxiosResponse<IGetContentTypeAndCheckBotMappingDto> =
          await axios.get(
            endpointsList.contentTypeMappings.getDigitalizationContentTypeAndCheckBotMapping(
              this.contentTypeId,
              this.projectId,
            ),
          );

        this.mappingFields = response.data.fieldMappings;
        this.initializeContentTypeMappingForm();
        this.checkBotMapping = reverseKeyValuePairs(
          response.data.checkBotMapping,
        );
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('contentTypeConfiguration.errors.errorGettingMapping'),
          'danger',
        );
      }
    },

    async submitDigitalizationContentType() {
      const fieldMappings: IDigitalizationProjectContentTypeFieldMappingDto[] =
        Object.entries(this.selectedAssignments).map(([field, assignment]) => {
          const checkBotMapping = Object.entries(this.checkBotMapping).find(
            ([contentTypeField]) => contentTypeField === field,
          )?.[1];

          return {
            field,
            value: assignment.value,
            assignmentType: assignment.type,
            checkBotMapping,
          };
        });

      try {
        const body: Serialized<ICreateDigitalizationProjectContentTypeMappingDto> =
          {
            contentTypeId: this.contentTypeId,
            digitalizationProjectId: this.projectId,
            fieldMappings,
          };

        await axios.post(
          endpointsList.contentTypeMappings
            .createDigitalizationProjectContentTypeMapping,
          body,
        );

        (this as unknown as GlobalUtils).createToast(
          this.$t('contentTypeConfiguration.mappingSaved'),
          this.$t('contentTypeConfiguration.mappingSaved'),
          'success',
        );

        setTimeout(() => {
          this.goToDigitalizationProjectSettings();
        }, 3000);
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('contentTypeConfiguration.errors.errorSavingMapping'),
          'danger',
        );
      }
    },
  },

  mounted() {
    if (
      [this.projectId, this.contentTypeId].some((prop) => prop === undefined)
    ) {
      router.push({ name: 'projectsList' });
      return;
    }

    if (this.mode === ContentTypeConfigurationMode.PROJECT) {
      this.getContentTypeMapping();
      this.getCheckBotMapping();
    } else if (
      this.mode === ContentTypeConfigurationMode.DIGITALIZATION_PROJECT
    ) {
      this.getDigitalizationContentTypeAndCheckBotMapping();
    }

    this.getPossibleTaxonomyFieldValues();
  },

  computed: {
    dependenciesFetched(): boolean {
      return !!(this.mappingFields && this.possibleTaxonomyFieldValues);
    },

    tableFields() {
      return [
        {
          key: 'fieldDisplayName',
          label: this.$t('contentTypeConfiguration.contentTypeField'),
          sortable: true,
          tdClass: ['align-middle', 'w-20'],
        },
        {
          key: 'dataType',
          label: this.$t('contentTypeConfiguration.dataType'),
          sortable: true,
          tdClass: ['align-middle', 'w-20'],
        },
        {
          key: 'importValueType',
          label: this.$t('contentTypeConfiguration.importFieldType'),
          sortable: false,
          tdClass: ['align-middle', 'w-30'],
        },
        {
          key: 'importValue',
          label: this.$t('contentTypeConfiguration.importField'),
          sortable: false,
          tdClass: ['align-middle', 'w-30'],
        },
      ];
    },

    importValueTypeSelectOptions(): { value: string; text: TranslateResult }[] {
      return [
        {
          value: 'csvField',
          text: this.$i18n.t('contentTypeConfiguration.csvField'),
        },
        {
          value: 'constantAssignment',
          text: this.$i18n.t('contentTypeConfiguration.constantValue'),
        },
        {
          value: 'currentDate',
          text: this.$i18n.t('contentTypeConfiguration.currentDate'),
        },
      ];
    },
  },
});
