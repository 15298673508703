import { router } from '../../vue';
import Vue from 'vue';
import defaultActionIcon from '../../assets/icons/mask.svg';
import { ITableField } from '../../frontendInterfaces';
import { cloneDeep } from 'lodash';

export default Vue.extend({
  name: 'main-table',
  components: {},
  props: {
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array as () => ITableField[],
      required: true,
    },
    perPage: {
      type: Number,
      required: false,
    },
    currentPage: {
      type: Number,
      required: false,
    },
    detailsClickTarget: {
      type: String,
      required: false,
    },
    detailsParam: {
      type: String,
      required: false,
    },
    customActionIcon: {
      type: String,
      required: false,
    },
    customFilterFunction: {
      type: Function,
      required: false,
    },
    isEmitOnClick: {
      type: Boolean,
      required: false,
    },
    rowClassFunction: {
      type: Function,
      required: false,
    },
    noActionIcon: {
      type: Boolean,
      required: false,
    },
    noLocalSorting: {
      type: Boolean,
      required: false,
      default: false,
    },
    responsive: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionIconTooltipText: {
      type: String,
      required: false,
      default: '',
    },
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialSortBy: {
      type: String,
      required: false,
    },
    initialSortDesc: {
      type: Boolean,
      required: false,
    },
    customSortFunction: {
      type: Function,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      sortDesc: this.initialSortDesc ?? false,
      sortBy:
        this.initialSortBy ??
        (this.customSortFunction ? undefined : this.fields[0].key),
      defaultActionIcon: defaultActionIcon,
    };
  },

  computed: {
    fieldsWithAcionField() {
      if (this.noActionIcon) {
        return this.fields;
      }

      return [
        ...(this.fields as any[]),
        {
          key: 'action',
          label: '',
          tdClass: ['w-2', 'text-right', 'align-middle', 'action-button'],
        },
      ];
    },
    trClass(): string | Function {
      if (!this.rowClassFunction) {
        if (this.noActionIcon) {
          return 'row-class';
        }
        return 'row-class-clickable';
      }
      return this.rowClassFunction;
    },
  },
  methods: {
    preprocessItems() {
      let items = cloneDeep(this.items);
      if (this.customSortFunction) {
        items = this.customSortFunction(items);
      }
      if (this.customFilterFunction) {
        items = items.filter(this.customFilterFunction as any);
      }

      return items;
    },

    handleDetailsClick(id: string) {
      router.push({ name: this.detailsClickTarget, params: { id } });
    },

    handleRowClicked(item: Record<string, any>) {
      if (this.isEmitOnClick) {
        this.emitEvent({ item });
        return;
      }
      if (this.noActionIcon) {
        return;
      }
      this.handleDetailsClick(item[this.detailsParam ?? '_id']);
    },

    emitEvent(payload: any) {
      this.$emit('action-event', payload);
    },
  },

  watch: {
    initialSortBy: {
      handler() {
        this.sortBy = this.initialSortBy;
      },
      immediate: true,
    },

    initialSortDesc: {
      handler() {
        this.sortDesc = this.initialSortDesc;
      },
      immediate: true,
    },

    sortDesc: {
      handler() {
        this.$emit('sorting-change', this.sortDesc);
      },
      immediate: true,
    },

    sortBy: {
      handler() {
        this.$emit('sort-by-change', this.sortBy);
      },
      immediate: true,
    },
  },
});
