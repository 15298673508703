import { ToastMixin } from '../../mixins/globalUtils';
import Papa from 'papaparse';
import Vue from 'vue';

interface ICsvReader {
  headers: string[] | null;
  createToast?: ToastMixin;
}

export default Vue.extend({
  name: 'csv-reader',
  data(): ICsvReader {
    return {
      headers: null,
    };
  },

  methods: {
    triggerFileUpload() {
      (this.$refs.file as any).click();
    },

    uploadFile() {
      const inputRef: HTMLInputElement = this.$refs.file as HTMLInputElement;
      const files = inputRef.files;
      if (!files) {
        return;
      }

      const file = files[0];

      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        // Load only the first row, because only headers are needed
        preview: 1,
        encoding: 'iso-8859-1',
        skipEmptyLines: true,
        complete: (results) => {
          if (results.errors.length) {
            this.createToast!(
              this.$t('errors.error'),
              this.$t('errors.error'),
              'danger',
            );

            return;
          }

          if (!results) {
            return;
          }

          this.headers = Object.keys(
            results.data[0] as { [key: string]: string | number | null },
          );
        },
      });
    },
  },

  watch: {
    headers() {
      this.$emit('headers', this.headers);
    },
  },

  mounted() {
    (this.$refs.firstElementForFocus as HTMLElement).focus();
  },
});
