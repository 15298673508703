import ProjectForm from '../../components/ProjectForm/ProjectForm.vue';
import InspectorsList from '../../components/InspectorsList/InspectorsList.vue';
import Vue from 'vue';
import ProjectsDocuManagers from '../ProjectsDocuManagers/ProjectsDocuManagers.vue';
import { ProjectFormMode } from '../ProjectForm/ProjectForm.script';

export default Vue.extend({
  name: 'project-informations',
  components: { ProjectForm, InspectorsList, ProjectsDocuManagers },
  props: {
    projectData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ProjectFormMode,
    };
  },
  methods: {
    handleUpdateProjectDataEvent() {
      this.$emit('update-project-data');
    },
  },
});
