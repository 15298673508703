export function deduplicateObjectsByKey<T extends object>(
  array: T[],
  key: keyof T,
): T[] {
  const seen = new Set();
  return array.filter((item) => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }
    seen.add(keyValue);
    return true;
  });
}
