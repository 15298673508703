<template>
  <b-modal
    id="missing-files-modal"
    :title="$t('missingFilesModal.title')"
    centered
    @ok="handleModalOK"
    :ok-title="$t('missingFilesModal.submit')"
    :cancel-title="$t('missingFilesModal.cancel')"
  >
    <p v-if="missingFiles">{{ $t('missingFilesModal.missingFiles') }}</p>
    <ul>
      <li v-for="id in missingFiles" :key="id">{{ id }}</li>
    </ul>

    <p v-if="missingFilesWithChangedMetadata.length">
      {{ $t('missingFilesModal.missingFilesWithChangedMetadata') }}
    </p>
    <ul>
      <li v-for="id in missingFilesWithChangedMetadata" :key="id">{{ id }}</li>
    </ul>

    <p v-if="emptyFiles.length">
      {{ $t('missingFilesModal.emptyFiles') }}
    </p>
    <ul>
      <li v-for="id in emptyFiles" :key="id">{{ id }}</li>
    </ul>
  </b-modal>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'missing-files-modal',
    components: {},
    props: {
      missingFiles: {
        type: Array,
        required: true,
      },
      missingFilesWithChangedMetadata: {
        type: Array,
        required: true,
      },
      emptyFiles: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {};
    },
    methods: {
      handleModalOK() {
        this.$emit('ignoreMissingFiles');
      },
    },
    mounted() {},
  });
</script>

<style scoped lang="scss"></style>
