import Vue from 'vue';
import StyledModal from '../StyledModal/StyledModal.vue';

export default Vue.extend({
  name: 'confirmation-modal',

  components: { StyledModal },

  props: {
    title: {
      type: String,
      required: false,
    },
    message: {
      type: String,
      required: true,
    },
  },
});
