import StyledDatePicker from '@/components/StyledDatePicker/StyledDatePicker.vue';
import StyledModal from '@/components/StyledModal/StyledModal.vue';
import StyledMultiselect from '@/components/StyledMultiselect/StyledMultiselect.vue';
import { formatDateForDisplay, isCustomErrorCodeException } from '@/helpers';
import { GlobalUtils } from '@/mixins/globalUtils';
import { PatchNotesService } from '@/services';
import { ErrorCode, IGetPatchNotesResponseDto, PatchNoteVersion } from 'types';
import { extractIsoDateString } from 'utils';
import Vue from 'vue';

type PatchNotesEditor = {
  allVersions: PatchNoteVersion[];
  content: string;
  currentAppVersion: string;
  fetchedUnmodifiedPatchNote: IGetPatchNotesResponseDto | null;
  isExistingVersionHintVisible: boolean;
  isFetchingCurrentAppVersion: boolean;
  isFetchingPatchNote: boolean;
  isFetchingVersions: boolean;
  isPatchNoteForCurrentVersionMissing: boolean;
  isSaving: boolean;
  releaseDate: Date | null;
  selectedExistingVersion: PatchNoteVersion | null;
  selectedVersionObject: {
    major: number;
    minor: number;
    patch: number;
  };
  wasModalOpened: boolean;
};

export enum PatchNotesEditorMode {
  EDIT = 'edit',
  VIEW = 'view',
}

export default Vue.extend({
  name: 'patch-notes-editor',

  components: {
    StyledDatePicker,
    StyledModal,
    StyledMultiselect,
  },

  props: {
    mode: {
      type: String as () => PatchNotesEditorMode | undefined,
      required: false,
      validator: (value: string) => {
        return Object.values(PatchNotesEditorMode).includes(
          value as PatchNotesEditorMode,
        );
      },
      default: PatchNotesEditorMode.VIEW,
    },
    modalId: {
      type: String,
      required: false,
      default: 'patch-notes-editor-modal',
    },
  },

  data(): PatchNotesEditor {
    return {
      allVersions: [],
      content: '',
      currentAppVersion: '',
      fetchedUnmodifiedPatchNote: null,
      isExistingVersionHintVisible: false,
      isFetchingCurrentAppVersion: false,
      isFetchingPatchNote: false,
      isFetchingVersions: false,
      isPatchNoteForCurrentVersionMissing: false,
      isSaving: false,
      releaseDate: null,
      selectedExistingVersion: null,
      selectedVersionObject: {
        major: 0,
        minor: 0,
        patch: 0,
      },
      wasModalOpened: false,
    };
  },

  computed: {
    saveButtonLabel(): string {
      return this.selectedExistingVersion
        ? this.$t('patchNotesEditor.saveChangesForVersion', {
            version: this.selectedVersionString,
          }).toString()
        : this.$t('generic.save').toString();
    },

    selectedVersionString(): string {
      return PatchNotesService.constructVersionString(
        this.selectedVersionObject,
      );
    },

    isFormValid(): boolean {
      return !!(
        this.releaseDate &&
        this.content &&
        this.selectedVersionString !== '0.0.0'
      );
    },

    isFormDirty(): boolean {
      const isReleaseDateDirty =
        !!this.releaseDate &&
        this.releaseDate !== this.fetchedUnmodifiedPatchNote?.releaseDate;
      const isContentDirty =
        !!this.content &&
        this.content !== this.fetchedUnmodifiedPatchNote?.content;

      return this.isEditingMode && (isReleaseDateDirty || isContentDirty);
    },

    isEditingMode(): boolean {
      return this.mode === PatchNotesEditorMode.EDIT;
    },

    isViewingMode(): boolean {
      return this.mode === PatchNotesEditorMode.VIEW;
    },

    isSaveButtonDisabled(): boolean {
      return !this.isFormValid || !this.isFormDirty;
    },

    markdownEditorMode(): 'preview' | 'editable' {
      switch (this.mode) {
        case PatchNotesEditorMode.EDIT:
          return 'editable';
        case PatchNotesEditorMode.VIEW:
          return 'preview';
        default:
          throw new Error('Invalid mode', this.mode);
      }
    },
  },

  methods: {
    handleDateUpdated(date: Date): void {
      this.releaseDate = date;
    },

    /** Clamps the value to be between 0 and 9 */
    clampVersionValue(value: number): number {
      if (value < 0) return 0;
      if (value > 9) return parseInt(value.toString().substring(0, 1));
      return value;
    },

    handleExistingVersionSelected(version: PatchNoteVersion): void {
      this.isPatchNoteForCurrentVersionMissing = false;
      this.fetchAndSetPatchNote(version.id);
    },

    async fetchAndSetPatchNote(id: string): Promise<void> {
      try {
        this.isFetchingPatchNote = true;
        const patchNote = await PatchNotesService.getPatchNoteById(id);
        this.fetchedUnmodifiedPatchNote = patchNote;
        this.populatePatchNote(patchNote);
      } catch {
        (this as unknown as GlobalUtils).createErrorToast(
          this.$t('patchNotesEditor.errors.errorGettingPatchNote'),
        );
      } finally {
        this.isFetchingPatchNote = false;
      }
    },

    clearLoadedVersion(): void {
      this.selectedExistingVersion = null;
      this.fetchedUnmodifiedPatchNote = null;
      this.releaseDate = null;
      this.content = '';
    },

    populatePatchNote(patchNote: IGetPatchNotesResponseDto): void {
      this.content = patchNote.content;
      this.releaseDate = patchNote.releaseDate;
      this.selectedVersionObject = PatchNotesService.splitVersionString(
        patchNote.version,
      );
    },

    handleVersionInput(): void {
      const existingVersion = this.allVersions.find(
        (version) =>
          version.version ===
          PatchNotesService.constructVersionString(this.selectedVersionObject),
      );
      if (existingVersion) {
        if (this.isFormDirty) {
          this.isExistingVersionHintVisible = true;
        } else {
          this.fetchAndSetPatchNote(existingVersion.id);
        }
        this.selectedExistingVersion = existingVersion;
      } else {
        this.isExistingVersionHintVisible = false;
        if (!this.isFormDirty) this.clearLoadedVersion();
      }
    },

    handleExistingVersionHintConfirmation(): void {
      if (!this.selectedExistingVersion) {
        throw new Error('No existing version selected');
      }
      this.isExistingVersionHintVisible = false;
      this.fetchAndSetPatchNote(this.selectedExistingVersion.id);
    },

    handleExistingVersionHintDismiss(): void {
      this.isExistingVersionHintVisible = false;
    },

    async fetchPatchNoteVersions(): Promise<void> {
      try {
        this.isFetchingVersions = true;
        this.allVersions = await PatchNotesService.getAllPatchNoteVersions();
      } catch {
        (this as unknown as GlobalUtils).createErrorToast(
          this.$t('patchNotesEditor.errors.errorGettingPatchNoteVersions'),
        );
      } finally {
        this.isFetchingVersions = false;
      }
    },

    async fetchCurrentAppVersion(): Promise<void> {
      try {
        this.isFetchingCurrentAppVersion = true;
        this.currentAppVersion = await PatchNotesService.getCurrentAppVersion();
      } catch {
        (this as unknown as GlobalUtils).createErrorToast(
          this.$t('patchNotesEditor.errors.errorGettingCurrentAppVersion'),
        );
      } finally {
        this.isFetchingCurrentAppVersion = false;
      }
    },

    async fetchPatchnoteForCurrentVersion(): Promise<void> {
      try {
        this.isFetchingPatchNote = true;
        const patchNote =
          await PatchNotesService.getPatchNoteForCurrentVersion();
        this.selectedExistingVersion = patchNote;
        this.populatePatchNote(patchNote);
      } catch (e) {
        if (
          isCustomErrorCodeException(e) &&
          e.response?.data.customErrorCode === ErrorCode.VERSION_NOT_FOUND
        ) {
          this.isPatchNoteForCurrentVersionMissing = true;
        } else {
          (this as unknown as GlobalUtils).createErrorToast(
            this.$t('patchNotesEditor.errors.errorGettingPatchNote'),
          );
        }
      } finally {
        this.isFetchingPatchNote = false;
      }
    },

    versionSelectLabel(version: PatchNoteVersion): string {
      return `${version?.version} - ${formatDateForDisplay(version?.releaseDate)}`;
    },

    async savePatchNote(): Promise<void> {
      try {
        this.isSaving = true;

        /** Modifying existing patch note */
        if (this.selectedExistingVersion) {
          await PatchNotesService.updatePatchNote(
            this.selectedExistingVersion.id,
            this.selectedVersionString,
            this.releaseDate!,
            this.content,
          );
        } else {
          /** New patch note */
          await PatchNotesService.createPatchNote(
            this.selectedVersionString,
            this.releaseDate!,
            this.content,
          );
        }

        this.fetchPatchNoteVersions();
        this.selectedExistingVersion =
          await PatchNotesService.getPatchNoteForVersion(
            this.selectedVersionString,
          );
        this.fetchAndSetPatchNote(this.selectedExistingVersion.id);

        (this as unknown as GlobalUtils).createSuccessToast(
          this.$t('patchNotesEditor.patchNoteSuccessfullySaved'),
        );
      } catch (e) {
        if (
          isCustomErrorCodeException(e) &&
          e.response?.data.customErrorCode ===
            ErrorCode.PATCH_NOTE_RELEASE_DATE_CONFLICT
        ) {
          const conflictingDate = new Date(
            extractIsoDateString(e.response?.data.message!)!,
          );
          (this as unknown as GlobalUtils).createErrorToast(
            this.$t('patchNotesEditor.errors.releaseDatesConflict', {
              conflictingDate: formatDateForDisplay(conflictingDate),
            }),
            10 * 1000,
          );
        } else {
          (this as unknown as GlobalUtils).createErrorToast(
            this.$t('patchNotesEditor.errors.errorSaving'),
          );
        }
      } finally {
        this.isSaving = false;
      }
    },

    handleModalOpened(): void {
      /** Fetch data only once when the modal is opened */
      if (!this.wasModalOpened) {
        this.fetchPatchNoteVersions();
        if (this.isViewingMode) {
          this.fetchPatchnoteForCurrentVersion();
        }
      }
      this.wasModalOpened = true;
    },
  },

  mounted(): void {
    this.fetchCurrentAppVersion();
  },
});
