import { render, staticRenderFns } from "./DocumentAndMetadata.vue?vue&type=template&id=485d78ba&scoped=true"
import script from "./DocumentAndMetadata.script.ts?vue&type=script&lang=ts&external"
export * from "./DocumentAndMetadata.script.ts?vue&type=script&lang=ts&external"
import style0 from "./DocumentAndMetadata.styles.scss?vue&type=style&index=0&id=485d78ba&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "485d78ba",
  null
  
)

export default component.exports