import VfButton from '@/components/VfButton/VfButton.vue';
import Vue from 'vue';
import { ButtonSize, ButtonVariant } from '../VfButton/VfButton.script';

export enum TitlePanelVariant {
  SMALL = 'small',
}

export default Vue.extend({
  name: 'title-panel',

  components: { VfButton },

  props: {
    buttonLabel: {
      type: String,
      required: false,
    },
    count: {
      type: Number,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    noCounter: {
      type: Boolean,
      required: false,
      default: false,
    },
    onButtonClick: {
      type: Function,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    variant: {
      type: String as () => TitlePanelVariant,
      required: false,
    },
  },

  data() {
    return {
      buttonSize: ButtonSize.LARGE,
      buttonVariant: ButtonVariant.SECONDARY,
    };
  },
});
