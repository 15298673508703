import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import actionIcon from '../../assets/icons/mask.svg';
import endpointsList from '../../helpers/endpointsList';
import { IProject, IProjectsSupplierSummary, ISupplier, Role } from 'types';
import { GlobalUtils } from '../../mixins/globalUtils';
import { router } from '../../vue';
import AddSupplierModal from '../AddSupplierModal/AddSupplierModal.vue';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import FilterableTable from '../FilterableTable/FilterableTable.vue';

interface IProjectsSuppliers {
  suppliers?: IProjectsSupplierSummary[];
  tableBusy: boolean;
  sortBy: keyof ISupplier;
  sortDesc: boolean;
  tableFilterConfiguration: IFilterConfiguration;
  tableFilterGridConfiguration: string[];
  actionIcon: string;
  removeSupplierAllowedRoles: [Role.Admin, Role.DocuManager];
}

export default Vue.extend({
  name: 'projects-suppliers-list',
  components: {
    FilterableTable,
    AddSupplierModal,
  },
  props: {
    projectData: {
      type: Object as () => IProject,
      required: true,
    },
  },

  data(): IProjectsSuppliers {
    return {
      suppliers: undefined,
      tableBusy: false,
      sortBy: 'name',
      sortDesc: false,
      tableFilterConfiguration: {
        supplierName: {
          type: FilterType.INPUT,
          width: '208px',
          column: 1,
        },
        assignedContentTypeNames: {
          type: FilterType.INPUT,
          width: '208px',
          column: 2,
        },
      },

      tableFilterGridConfiguration: ['28%', '30%', '30%', '10%', '2%'],
      actionIcon,
      removeSupplierAllowedRoles: [Role.Admin, Role.DocuManager],
    };
  },

  computed: {
    fieldsSuppliersTable(): {
      key: string;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
    }[] {
      return [
        {
          key: 'supplierName',
          label: this.$i18n.t('projectsSuppliers.supplierName'),
          sortable: true,
          tdClass: ['w-28'],
        },
        {
          key: 'assignedContentTypeNames',
          label: this.$i18n.t('projectsSuppliers.contentTypes'),
          sortable: true,
          tdClass: ['w-30'],
        },
        {
          key: 'assignedCoworkerNames',
          label: this.$i18n.t('projectsSuppliers.coworkers'),
          sortable: true,
          tdClass: ['w-30'],
        },
        {
          key: 'uploadCount',
          label: this.$i18n.t('projectsSuppliers.deliveries'),
          sortable: true,
          tdClass: ['w-10'],
        },
        {
          key: 'actionIcon',
          label: '',
          sortable: false,
          tdClass: ['w-2', 'action-button'],
        },
      ];
    },
  },

  methods: {
    async fetchSuppliers() {
      try {
        this.tableBusy = true;

        const response: AxiosResponse<IProjectsSupplierSummary[]> =
          await axios.get(
            endpointsList.projectsSuppliers.getProjectsSuppliersSummary(
              this.projectData._id?.toString(),
            ),
          );

        this.suppliers = response.data;
      } catch (error) {
        (this as unknown as GlobalUtils).createToast!(
          this.$t('errors.error'),
          this.$t('projectsSuppliers.errors.couldNotLoadSuppliers'),
          'danger',
        );
      } finally {
        this.tableBusy = false;
      }
    },

    contentTypesForSupplier(supplierId: string): string {
      if (!this.suppliers) {
        return '';
      }

      const supplier = this.suppliers.find(
        (supplier) => supplier.supplierId === supplierId,
      );

      const contentTypeNames = supplier?.assignedContentTypeNames;
      return contentTypeNames?.join(', ') ?? '';
    },

    coworkersForSupplier(supplierId: string): string {
      if (!this.suppliers) {
        return '';
      }

      const supplier = this.suppliers.find(
        (supplier) => supplier.supplierId === supplierId,
      );

      const assignedCoworkerNames = supplier?.assignedCoworkerNames;
      return assignedCoworkerNames?.join(', ') ?? '';
    },

    handleAddSupplierClick() {
      router.push({
        name: 'addSupplierToProject',
        params: {
          id: this.projectData._id.toString(),
        },
      });
    },

    rowColors(item?: IProjectsSupplierSummary) {
      if (item?.isDeleted) {
        return 'inactive-row';
      }
      return 'row-class';
    },

    handleRowClick(row: { item: IProjectsSupplierSummary }) {
      router.push({
        name: 'projectsSupplierDetailView',
        params: {
          projectId: this.projectData._id.toString(),
          supplierId: row.item.supplierId,
        },
      });
    },

    async handleRemoveSupplierIconClick(supplier: IProjectsSupplierSummary) {
      try {
        this.tableBusy = true;

        await axios.delete(
          endpointsList.projectsSuppliers.removeSupplierFromProject(
            this.projectData._id.toString(),
            supplier.supplierId,
          ),
        );
        (this as unknown as GlobalUtils).createToast(
          '',
          this.$t('projectsSuppliers.supplierSuccessfullyDeleted'),
          'success',
        );
      } catch (err) {
        if (
          err instanceof AxiosError &&
          err.response?.status === HttpStatusCode.Conflict
        ) {
          (this as unknown as GlobalUtils).createToast(
            this.$t('errors.error'),
            this.$t('projectsSuppliers.errors.cannotRemoveSupplierWithUploads'),
            'danger',
          );
        } else {
          (this as unknown as GlobalUtils).createToast(
            this.$t('errors.error'),
            this.$t('projectsSuppliers.errors.errorRemovingSupplier'),
            'danger',
          );
        }
      } finally {
        this.tableBusy = false;
        this.fetchSuppliers();
      }
    },
  },

  watch: {
    projectData: {
      handler() {
        this.fetchSuppliers();
      },
      deep: true,
    },
  },

  created() {
    this.fetchSuppliers();
  },
});
