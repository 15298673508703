"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormalControlStatusHelper = void 0;
const types_1 = require("types");
class FormalControlStatusHelper {
    constructor(formalControlStatus) {
        this.formalControlStatus = formalControlStatus;
    }
    isPositive() {
        return types_1.positiveFormalControlStatuses.includes(this.formalControlStatus);
    }
    isNegative() {
        return types_1.negativeFormalControlStatuses.includes(this.formalControlStatus);
    }
    isWaiting() {
        return types_1.WAITING_FORMAL_CONTROL_STATUSES.includes(this.formalControlStatus);
    }
    isActionable() {
        return types_1.actionableFormalControlStatuses.includes(this.formalControlStatus);
    }
}
exports.FormalControlStatusHelper = FormalControlStatusHelper;
