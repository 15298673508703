import CheckBotEstablishedKksPanel from '@/components/CheckBotEstablishedKksPanel/CheckBotEstablishedKksPanel.vue';
import DocumentMetadataCalendarInput from '@/components/DocumentMetadataCalendarInput/DocumentMetadataCalendarInput.vue';
import DocumentMetadataFreeTextInput from '@/components/DocumentMetadataFreeTextInput/DocumentMetadataFreeTextInput.vue';
import DocumentMetadataSelect from '@/components/DocumentMetadataSelect/DocumentMetadataSelect.vue';
import DocumentMetadataTagInput from '@/components/DocumentMetadataTagInput/DocumentMetadataTagInput.vue';
import MainTable from '@/components/MainTable/MainTable.vue';
import { endpointsList } from '@/helpers';
import { ITableRow } from '@/interfaces/frontend-types';
import kksValidator, { KksValidator } from '@/mixins/kksValidator';
import { IMetadataEntry } from '@/views/DocumentDetailView/DocumentDetailView.script';
import axios, { AxiosResponse } from 'axios';
import {
  AUTOMATICALLY_SET_CONTENT_TYPE_FIELDS,
  CHECK_BOT_ESTABLISHED_KKS,
  ContentTypeFieldType,
  DEFAULT_METADATA_VALUES_SEPARATOR,
  DocumentDetailViewMode,
  EDITABLE_METADATA_FIELDS,
  IDigitalizationDocument,
  IDocumentMetadataWithFieldMetadataDto,
  IFileMetadata,
  IFileMetadataWithWithFieldsMetadataDto,
  IFilesListFile,
  IMetadataAndFieldMetadata,
  KKS,
  KKS_METADATA_FIELD,
  Role,
} from 'types';
import { removeEmptyStrings } from 'utils';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { ITagClasses } from '../StyledTagsInput/StyledTagsInput.script';

interface IDocumentsMetadata {
  checkBotEstablishedKksNumbersField: keyof IFileMetadata;
  documentMetadataAndFieldInfos?:
    | IFileMetadataWithWithFieldsMetadataDto /** For supplier projects */
    | IDocumentMetadataWithFieldMetadataDto /** For digitalization projects */;
  EDITABLE_METADATA_FIELDS: typeof EDITABLE_METADATA_FIELDS;
  EDITING_METADATA_ALLOWED_ROLES: [
    Role.Admin,
    Role.DocuManager,
    Role.DocuResponsible,
    Role.DigitalizationCoworker,
  ];
  isCopyingKks: boolean;
  isFetchingMetadataWithFieldInfos: boolean;
  isShowingKksProposals: boolean;
  isShowingOnlyEditableFields: boolean;
  KKS_METADATA_FIELD: typeof KKS_METADATA_FIELD;
  metadata?: IMetadataEntry[];
  modes: {
    project: DocumentDetailViewMode.PROJECT;
    digitalizationProject: DocumentDetailViewMode.DIGITALIZATION_PROJECT;
  };
  sortBy: string;
}

export default Vue.extend({
  name: 'documents-metadata',

  components: {
    CheckBotEstablishedKksPanel,
    DocumentMetadataCalendarInput,
    DocumentMetadataFreeTextInput,
    DocumentMetadataSelect,
    DocumentMetadataTagInput,
    MainTable,
  },

  mixins: [kksValidator],

  props: {
    digitalizationDocument: {
      type: Object as () => IDigitalizationDocument,
      required: false,
    },
    documentId: {
      type: String,
      required: true,
    },
    file: {
      type: Object as () => IFilesListFile,
      required: false,
    },
    mode: {
      type: String as () => DocumentDetailViewMode,
      required: true,
    },
    uploadId: {
      type: String,
      required: true,
    },
  },

  data(): IDocumentsMetadata {
    return {
      checkBotEstablishedKksNumbersField: 'checkBotEstablishedKKS',
      documentMetadataAndFieldInfos: undefined,
      EDITABLE_METADATA_FIELDS,
      EDITING_METADATA_ALLOWED_ROLES: [
        Role.Admin,
        Role.DocuManager,
        Role.DocuResponsible,
        Role.DigitalizationCoworker,
      ],
      isCopyingKks: false,
      isFetchingMetadataWithFieldInfos: false,
      isShowingKksProposals: false,
      KKS_METADATA_FIELD,
      metadata: undefined,
      modes: {
        project: DocumentDetailViewMode.PROJECT,
        digitalizationProject: DocumentDetailViewMode.DIGITALIZATION_PROJECT,
      },
      isShowingOnlyEditableFields: true,
      sortBy: 'field',
    };
  },

  computed: {
    tableFields(): {
      key: string;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
    }[] {
      return [
        {
          key: 'field',
          label: this.$i18n.t('documentUpload.field'),
          sortable: false,
          tdClass: ['w-30'],
        },
        {
          key: 'value',
          label: this.$i18n.t('documentUpload.value'),
          sortable: false,
          tdClass: ['w-70'],
        },
      ];
    },
  },

  methods: {
    async getMetadataAndFieldInfos() {
      try {
        this.isFetchingMetadataWithFieldInfos = true;

        let response:
          | AxiosResponse<
              | IFileMetadataWithWithFieldsMetadataDto[]
              | IDocumentMetadataWithFieldMetadataDto[]
            >
          | undefined = undefined;

        if (this.mode === DocumentDetailViewMode.PROJECT) {
          response = await axios.post(
            endpointsList.documentsUploads.getFileMetadataByIds(
              this.file.projectId.toString(),
              true,
            ),
            [this.file._id],
          );
        } else if (
          this.mode === DocumentDetailViewMode.DIGITALIZATION_PROJECT
        ) {
          response = await axios.post(
            endpointsList.digitalizationUploads.getDocumentMetadataByIds(
              this.digitalizationDocument.digitalizationProjectId.toString(),
              true,
            ),
            [this.digitalizationDocument._id],
          );
        }
        if (!response) return;

        this.documentMetadataAndFieldInfos = response.data[0];

        this.metadata = response.data[0].metadata.map((data) => ({
          field: data.fieldName,
          value: data.fieldValue ?? '',
        }));
      } finally {
        this.isFetchingMetadataWithFieldInfos = false;
      }
    },

    metadataFiltering(entry: IMetadataEntry): boolean {
      return this.isFieldEditable(entry.field);
    },

    toggleIsShowingOnlyEditableFields(): void {
      this.isShowingOnlyEditableFields = !this.isShowingOnlyEditableFields;
    },

    sortMetadata(metadata: IMetadataEntry[]): IMetadataEntry[] {
      const predefinedOrder = [
        'FileLeafRef',
        'Title',
        'Revision',
        KKS as string,
        CHECK_BOT_ESTABLISHED_KKS as string,
        'FolderNumber',
        'DocumentClass',
        'DocumentClassContent',
        'CreateDate',
        'ReleaseDate',
        'DocLanguage',
        'ConfidentialClass',
        'DocumentCreator',
        'CreatorDocumentNumber',
        'DocStatus',
      ];

      return metadata.sort((a, b) => {
        const indexA = predefinedOrder.indexOf(a.field);
        const indexB = predefinedOrder.indexOf(b.field);

        /**
         * If both fields are in the predefined order, sort by their index in
         * the order array
         */
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        /**
         * If only one field is in the predefined order, that one comes first
         */
        if (indexA !== -1) return -1;
        if (indexB !== -1) return 1;

        /**
         * If neither field is in the predefined order, keep their original
         * order
         */
        return 0;
      });
    },

    getFieldInfo(fieldName: string): IMetadataAndFieldMetadata | undefined {
      return this.documentMetadataAndFieldInfos?.metadata.find(
        (fieldInfo) => fieldInfo.fieldName === fieldName,
      );
    },

    getPossibleValuesForField(fieldName: string): string[] {
      return this.getFieldInfo(fieldName)?.possibleValues ?? [];
    },

    isTaxonomyField(fieldName: string): boolean {
      return (
        this.getFieldInfo(fieldName)?.fieldType ===
        ContentTypeFieldType.TAXONOMY_FIELD_TYPE
      );
    },

    isTaxonomyMultiField(fieldName: string): boolean {
      return (
        this.getFieldInfo(fieldName)?.fieldType ===
        ContentTypeFieldType.TAXONOMY_FIELD_TYPE_MULTI
      );
    },

    isDateField(fieldName: string): boolean {
      return (
        this.getFieldInfo(fieldName)?.fieldType ===
        ContentTypeFieldType.DATE_TIME
      );
    },

    areCheckBotEstablishedKksAvailable(): boolean {
      return !!this.file?.metadata.checkBotEstablishedKKS?.length ?? false;
    },

    validateMetadataForField(fieldName: string): (value: string) => boolean {
      const fieldInfo = this.documentMetadataAndFieldInfos?.metadata.find(
        (field) => field.fieldName === fieldName,
      );
      const isFieldRequired = fieldInfo?.required;

      if (fieldInfo === undefined) {
        throw Error(`Could not get field info for metadata field ${fieldName}`);
      }

      return (value: string) => !isFieldRequired || Boolean(value);
    },

    isFieldEditable(fieldName: string): boolean {
      const field = this.getFieldInfo(fieldName);

      return (
        !field?.readonly &&
        !AUTOMATICALLY_SET_CONTENT_TYPE_FIELDS.includes(fieldName) &&
        fieldName !== 'documentId' &&
        fieldName !== 'Filepath'
      );
    },

    async updateDocumentData(): Promise<void> {
      this.$emit('document-data-changed');
      await this.getMetadataAndFieldInfos();
    },

    getKksAndCheckBotKks(): string[] {
      const checkBotEstablishedKks = this.getCheckBotKks();
      const acceptedKks = (
        this.metadata?.find((entry) => entry.field === KKS_METADATA_FIELD)
          ?.value as string
      )?.split(DEFAULT_METADATA_VALUES_SEPARATOR);

      return removeEmptyStrings([
        ...(acceptedKks ?? []),
        ...(checkBotEstablishedKks ?? []),
      ]);
    },

    async validateKksAndCheckBotKks(): Promise<void> {
      const kks = this.getKksAndCheckBotKks();
      if (!kks?.length) return;

      await (this as unknown as KksValidator).validateKks(kks);
    },

    getKksTagsClasses(row: ITableRow<IMetadataEntry>): ITagClasses {
      if (
        ![KKS_METADATA_FIELD, this.checkBotEstablishedKksNumbersField].includes(
          row.item.field,
        )
      ) {
        return {};
      }

      return (this as unknown as KksValidator).getKksTagClasses();
    },

    toggleIsShowingKksProposals() {
      this.isShowingKksProposals = !this.isShowingKksProposals;
    },

    getCheckBotKks() {
      return (this.file?.metadata ?? this.digitalizationDocument?.fileMetadata)
        ?.checkBotEstablishedKKS;
    },

    getKksProposalsButtonLabel(): string {
      return this.isShowingKksProposals
        ? this.$t('documentDetailView.hideKksProposals').toString()
        : this.$t('documentDetailView.showKksProposals').toString();
    },
  },

  mounted() {
    this.validateKksAndCheckBotKks();
    this.getMetadataAndFieldInfos();
  },

  watch: {
    metadata: {
      async handler() {
        this.validateKksAndCheckBotKks();
      },
      deep: true,
    },
  },
});
