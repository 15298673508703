import { router } from '../../vue';
import axios, { AxiosResponse } from 'axios';
import endpointsList from '../../helpers/endpointsList';
import { IUploadCountForContentTypeName } from 'types';
import Vue from 'vue';
import { ToastMixin } from '../../mixins/globalUtils';
import { TranslateResult } from 'vue-i18n';
import FilterableTable from '../FilterableTable/FilterableTable.vue';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import ContentTypeModal from '../../components/ContentTypeModal/ContentTypeModal.vue';
import { ContentTypeConfigurationMode } from '@/views/ContentTypeConfiguration/ContentTypeConfiguration.script';
import { ContentTypeModalMode } from '../ContentTypeModal/ContentTypeModal.script';

interface IContentTypeManagementTable {
  contentTypes?: IUploadCountForContentTypeName[];
  sortBy: keyof IUploadCountForContentTypeName;
  sortDesc: boolean;
  filterConfiguration: IFilterConfiguration;
  filterGridConfiguration: string[];
  createToast?: ToastMixin;
  contentTypeModalMode: ContentTypeModalMode.PROJECT;
}

export default Vue.extend({
  name: 'content-type-management-table',
  components: { FilterableTable, ContentTypeModal },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: true,
    },
  },

  data(): IContentTypeManagementTable {
    return {
      contentTypes: undefined,
      sortBy: 'contentTypeName',
      sortDesc: true,
      filterConfiguration: {
        contentTypeName: {
          width: '208px',
          column: 1,
          type: FilterType.INPUT,
        },
        supplierName: {
          width: '400px',
          column: 2,
          type: FilterType.INPUT,
        },
      },
      filterGridConfiguration: ['50%', '50%'],
      contentTypeModalMode: ContentTypeModalMode.PROJECT,
    };
  },

  computed: {
    tableFields(): Array<{
      key: keyof IUploadCountForContentTypeName;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
    }> {
      return [
        {
          key: 'contentTypeName',
          label: this.$i18n.t('projectDetails.contentTypes.contentTypeName'),
          sortable: true,
          tdClass: ['w-50'],
        },
        {
          key: 'uploadCount',
          label: this.$i18n.t('projectDetails.contentTypes.uploadCount'),
          sortable: true,
          tdClass: ['w-50'],
        },
      ];
    },
  },

  methods: {
    async getContentTypes() {
      try {
        const response: AxiosResponse<IUploadCountForContentTypeName[]> =
          await axios.get(
            endpointsList.projectsSuppliers.getContentTypesListForSupplierInProject(
              this.projectId,
              this.supplierId,
            ),
          );

        this.contentTypes = response.data;
      } catch {
        //
      }
    },

    handleContentTypeEditIconClick(row: any) {
      router.push({
        name: 'contentTypeConfiguration',
        params: {
          contentTypeId: row.item.contentTypeId,
          projectId: this.projectId,
          mode: ContentTypeConfigurationMode.PROJECT,
          supplierId: this.supplierId,
        },
      });
    },
  },

  mounted() {
    this.getContentTypes();

    (this.$refs.firstElementForFocus as HTMLElement).focus();
  },
});
