import { ITagClasses } from '@/components/StyledTagsInput/StyledTagsInput.script';
import StyledTagsInput from '@/components/StyledTagsInput/StyledTagsInput.vue';
import kksValidator, { KksValidator } from '@/mixins/kksValidator';
import {
  DEFAULT_METADATA_VALUES_SEPARATOR,
  IDocumentMetadataWithFieldMetadataDto,
  IMetadataAndFieldMetadata,
  KKS,
} from 'types';
import Vue from 'vue';
import { MetadataGridEditorProvider } from '../../MetadataGridEditor.script';

const inject: (keyof MetadataGridEditorProvider)[] = [
  'fieldInfo',
  'kksValidation',
];

interface ITagEditor {
  componentStyle: {
    position: 'fixed';
    top: string;
    left: string;
    width?: string;
  };
  currentInput: string[];
  tagClasses: ITagClasses;
  valuesSeparator: string;
}

export default Vue.extend({
  components: { StyledTagsInput },
  mixins: [kksValidator],
  props: {
    close: {
      type: Function,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    prop: {
      type: String,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  inject,
  data(): ITagEditor {
    return {
      componentStyle: {
        position: 'fixed',
        top: '0px',
        left: '0px',
      },
      currentInput: [],
      tagClasses: {},
      valuesSeparator: DEFAULT_METADATA_VALUES_SEPARATOR,
    };
  },
  methods: {
    setCurrentTags(): void {
      const tags: string = this.model[this.prop];
      /**
       * If after editing the cell no value is assigned, the editor will by
       * default assign an empty string as the value. In this case this empty
       * string must be ignored.
       */
      if (!tags) {
        this.currentInput = [];
        return;
      }

      this.currentInput = tags.split(DEFAULT_METADATA_VALUES_SEPARATOR);
    },

    async handleTagsChanged(input: string[]): Promise<void> {
      this.currentInput = input;
      await this.setTagClasses();
    },

    saveInput(): void {
      this.save(this.currentInput.join(this.valuesSeparator));
    },

    getFieldInfoByFieldName(
      fieldName: string,
    ): IMetadataAndFieldMetadata | undefined {
      return (
        (this as unknown as MetadataGridEditorProvider).fieldInfo() as
          | IDocumentMetadataWithFieldMetadataDto[]
          | undefined
      )?.[0].metadata.find((field) => field.fieldName === fieldName);
    },

    getPossibleFieldValues(): string[] | undefined {
      const fieldInfo = this.getFieldInfoByFieldName(this.prop);
      return fieldInfo?.possibleValues ?? [];
    },

    addOnlyFromAutocomplete(): boolean {
      return !!this.getPossibleFieldValues()?.length;
    },

    positionTagInput(): void {
      this.$nextTick(() => {
        const element = this.$refs.styledTagsInput;

        const containingCellWidth = (
          (element as HTMLElement)?.parentNode?.parentNode as HTMLElement
        )?.getBoundingClientRect().width;

        const rect = (element as HTMLElement)?.getBoundingClientRect();

        /**
         * Opening tag input a little higher if there are many tags so that the
         * end of the input always fits on the screen.
         */
        const topOffset = `${rect.top - (this.model[this.prop]?.length > 40 ? 100 : 0)}px`;
        const leftOffset = `${rect.left}px`;
        const tagInputWidth = `${containingCellWidth}px`;

        this.componentStyle.top = topOffset;
        this.componentStyle.left = leftOffset;
        this.componentStyle.width = tagInputWidth;
      });
    },

    async setTagClasses(): Promise<void> {
      if (this.prop !== KKS) {
        this.tagClasses = {};
        return;
      }

      await (this as unknown as KksValidator).validateKks(this.currentInput);
      this.tagClasses = (this as unknown as KksValidator).getKksTagClasses(
        (this as unknown as KksValidator).kksValidationList,
      );
    },
  },
  mounted() {
    this.setCurrentTags();
    this.setTagClasses();
    this.positionTagInput();
  },
});
