<template>
  <div>
    <div v-if="version" class="version-string" @click="openPatchNotesModal">
      v{{ version }}
    </div>
    <patch-notes-editor modalId="patch-notes-navbar" />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import PatchNotesEditor from '@/components/PatchNotesEditor/PatchNotesEditor.vue';
  import { PatchNotesService } from '@/services';

  interface ICurrentVersion {
    version: string | null;
  }

  export default Vue.extend({
    name: 'current-version',
    components: { PatchNotesEditor },
    data(): ICurrentVersion {
      return {
        version: null,
      };
    },
    methods: {
      async getCurrentVersion(): Promise<void> {
        this.version = await PatchNotesService.getCurrentAppVersion();
      },
      async openPatchNotesModal(): Promise<void> {
        this.$bvModal.show('patch-notes-navbar');
      },
    },
    mounted(): void {
      this.getCurrentVersion();
    },
  });
</script>

<style lang="scss" scoped>
  .version-string {
    cursor: pointer;
  }
</style>
