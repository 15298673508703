<template>
  <styled-multiselect
    v-model="selectedVgbk"
    :label="getLabel"
    :loading="isFetchingVgbks"
    :options="vgbks"
    :placeholder="$t('vgbkSelect.selectVgbk')"
  ></styled-multiselect>
</template>

<script lang="ts">
  import Vue from 'vue';
  import StyledMultiselect from '@/components/StyledMultiselect/StyledMultiselect.vue';
  import { IVgbkListElementDto } from 'types';
  import { VgbkService } from '@/services';
  import { GlobalUtils } from '@/mixins/globalUtils';

  export default Vue.extend({
    name: 'vgbk-select',
    components: { StyledMultiselect },
    props: {
      projectId: {
        type: String,
        required: true,
      },
      value: {
        type: Object as () => IVgbkListElementDto | null,
        default: null,
      },
    },
    data(): {
      isFetchingVgbks: boolean;
      selectedVgbk: IVgbkListElementDto | null;
      vgbks: IVgbkListElementDto[];
    } {
      return {
        isFetchingVgbks: false,
        selectedVgbk: null,
        vgbks: [],
      };
    },
    methods: {
      async fetchVgbks() {
        try {
          this.isFetchingVgbks = true;
          this.vgbks = await VgbkService.getVgbkListForProject(this.projectId);
        } catch {
          (this as unknown as GlobalUtils).createErrorToast(
            this.$t('vgbkSelect.errors.errorGettingVgbk'),
          );
        } finally {
          this.isFetchingVgbks = false;
        }
      },
      getLabel(vgbkObject: IVgbkListElementDto): string {
        return `${vgbkObject.designation} - ${vgbkObject.class} (${this.$t('generic.count')} ${vgbkObject.kksCount})`;
      },
    },
    watch: {
      value(newValue: IVgbkListElementDto | null) {
        this.selectedVgbk = newValue;
      },
      selectedVgbk(newValue: IVgbkListElementDto | null) {
        this.$emit('input', newValue);
      },
    },
    mounted() {
      this.fetchVgbks();
    },
  });
</script>

<style lang="scss" scoped>
  .multiselect {
    width: 100%;
  }
</style>
