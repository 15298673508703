"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormalControlStatus = void 0;
var FormalControlStatus;
(function (FormalControlStatus) {
    FormalControlStatus["ACCEPTED"] = "accepted";
    FormalControlStatus["REJECTED"] = "rejected";
    FormalControlStatus["ACCEPTED_WITH_REMARKS"] = "acceptedWithRemarks";
    FormalControlStatus["WAITING"] = "waiting";
})(FormalControlStatus || (exports.FormalControlStatus = FormalControlStatus = {}));
