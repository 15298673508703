import Multiselect from 'vue-multiselect';
import axios, { AxiosResponse } from 'axios';
import endpointsList from '../../helpers/endpointsList';
import Vue from 'vue';
import { IAdUserInternalFormat, IInspector, UserGroup } from 'types';
import { IsUserRoleMixin, ToastMixin } from '../../mixins/globalUtils';
import StyledModal from '../../components/StyledModal/StyledModal.vue';
import StyledMultiselect from '../../components/StyledMultiselect/StyledMultiselect.vue';

interface ISpGroupModal {
  selectedUser: boolean;
  spGroupUsers: IAdUserInternalFormat[];
  isLoadingUsers: boolean;
  isNoSelectedUserError: boolean;
  createToast?: ToastMixin;
  isUserRole?: IsUserRoleMixin;
}

export default Vue.extend({
  name: 'sp-group-modal',
  components: { Multiselect, StyledModal, StyledMultiselect },
  props: {
    group: {
      type: String as () => UserGroup,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    infoText: {
      type: String,
      required: false,
    },
    modalId: {
      type: String,
      required: false,
    },
  },
  data(): ISpGroupModal {
    return {
      selectedUser: false,
      spGroupUsers: [],
      isLoadingUsers: false,
      isNoSelectedUserError: false,
    };
  },

  methods: {
    async getSpGroupMembers() {
      const groupEndpoint = this.getEndpointForGroup(this.group);

      try {
        const response: AxiosResponse<IAdUserInternalFormat[]> =
          await axios.get(groupEndpoint);

        this.spGroupUsers = response.data;
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('spGroupModal.couldNotGetUsers'),
          'danger',
        );
      }
    },

    getEndpointForGroup(group: UserGroup) {
      switch (group) {
        case UserGroup.inspectors:
          return endpointsList.inspectors.getAllInspectors;
        case UserGroup.coworkers:
          return endpointsList.sharePointIntegration.getAllCoworkers;
        case UserGroup.docuManagers:
          return endpointsList.docuManagers.getAllDocuManagers;
        case UserGroup.docuResponsibles:
          return endpointsList.docuResponsibles.getAllDocuResponsibles;
        default:
          throw new Error('Wrong group name passed to SpGroupModal');
      }
    },

    nameAndEmailLabel({ firstName, lastName, email }: IInspector) {
      if (firstName === null && lastName === null) {
        firstName = this.$i18n.t('spGroupModal.noData') as string;
        lastName = '';
      } else if (firstName === null) {
        firstName = this.$i18n.t('spGroupModal.noData') as string;
      } else if (lastName === null) {
        lastName = this.$i18n.t('spGroupModal.noData') as string;
      }

      if (email) {
        return `${firstName} ${lastName} — [${email}]`;
      } else {
        return `${firstName} ${lastName}`;
      }
    },

    cleanModal() {
      this.selectedUser = false;
      this.isLoadingUsers = false;
      this.isNoSelectedUserError = false;
    },

    handleModalOK(event: Event) {
      event.preventDefault();

      if (this.selectedUser) {
        this.isNoSelectedUserError = false;
        this.$emit('selected-user', this.selectedUser);

        this.$nextTick(() => {
          this.$bvModal.hide(this.modalId ?? 'styled-modal');
        });
        this.cleanModal();
      } else {
        this.isNoSelectedUserError = true;
      }
    },
  },

  watch: {
    selectedUser() {
      if (this.selectedUser) {
        this.isNoSelectedUserError = false;
      }
    },
  },
});
