import axios, { AxiosError, AxiosResponse } from 'axios';
import { Types } from 'mongoose';
import Vue from 'vue';
import Vuex from 'vuex';
import endpointsList from './helpers/endpointsList';
import { ICurrentUserInformation, IUser, Role } from 'types';
import { router } from './vue';
import { FilesOverviewTableConfig } from './components/FilesOverviewTable/FilesOverviewTable.script';
import { DigitalizationDocumentsOverviewTableConfig } from './components/DigitalizationDocumentsOverview/DigitalizationDocumentsOverview.script';

Vue.use(Vuex);

interface IVueexStore {
  state: IVueexState;
  mutations: {
    [key: string]: (...args: any[]) => void;
  };
}

export interface IVueexState {
  language: string;
  userRoles?: Role[];
  supplierId?: string;
  userData?: IUser;
  currentProjectId?: string;
  projectDetailViewTabNumber: number;
  currentDigitalizationProjectId?: string;
  digitalizationProjectDetailViewTabNumber: number;
  filesOverviewTableConfig?: FilesOverviewTableConfig;
  digitalizationDocumentsOverviewTableConfig?: DigitalizationDocumentsOverviewTableConfig;
  isCurrentPatchNotePending: boolean;
}

const store: IVueexStore = {
  state: {
    language: 'de',
    userRoles: [],
    supplierId: undefined,
    userData: undefined,
    currentProjectId: undefined,
    projectDetailViewTabNumber: 0,
    currentDigitalizationProjectId: undefined,
    digitalizationProjectDetailViewTabNumber: 0,
    filesOverviewTableConfig: undefined,
    digitalizationDocumentsOverviewTableConfig: undefined,
    isCurrentPatchNotePending: false,
  },
  mutations: {
    setLang(state: IVueexState, payload: string) {
      state.language = payload;
    },

    async setCurrentUserInfo(state: IVueexState) {
      try {
        const response: AxiosResponse<ICurrentUserInformation> =
          await axios.get(endpointsList.general.userData);

        state.userData = response.data.userData;
        state.supplierId = response.data.supplier?.toString();
        state.userRoles = response.data.roles;
        state.isCurrentPatchNotePending =
          response.data.isCurrentPatchNotePending;
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === 403) {
            router.push({
              name: 'forbiddenView',
            });
          }
        }

        throw err;
      }
    },

    setCurrentProjectId(state: IVueexState, id: Types.ObjectId) {
      state.currentProjectId = id.toString();
    },

    setProjectDetailViewTabNumber(state: IVueexState, tabNumber: number) {
      state.projectDetailViewTabNumber = tabNumber;
    },

    setCurrentDigitalizationProjectId(state: IVueexState, id: Types.ObjectId) {
      state.currentDigitalizationProjectId = id.toString();
    },

    setDigitalizationProjectDetailViewTabNumber(
      state: IVueexState,
      tabNumber: number,
    ) {
      state.digitalizationProjectDetailViewTabNumber = tabNumber;
    },

    setFilesOverviewTableConfig(
      state: IVueexState,
      config: FilesOverviewTableConfig,
    ) {
      state.filesOverviewTableConfig = config;
    },

    setDigitalizationDocumentsOverviewTableConfig(
      state: IVueexState,
      config: DigitalizationDocumentsOverviewTableConfig,
    ) {
      state.digitalizationDocumentsOverviewTableConfig = config;
    },

    setIsCurrentPatchNotePending(state: IVueexState, isPending: boolean) {
      state.isCurrentPatchNotePending = isPending;
    },
  },
};

export default new Vuex.Store(store);
