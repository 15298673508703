import axios from 'axios';
import {
  IFilesListFile,
  INSPECTION_DONE_STATUSES,
  InspectorStatusEnum,
  MetadataCheckStatus,
  Role,
} from 'types';
import Vue from 'vue';
import endpointsList from '../../helpers/endpointsList';
import { ToastMixin } from '../../mixins/globalUtils';
import {
  IRemarksModalEventPayload,
  RemarksModalMode,
} from '../RemarksModal/RemarksModal.script';
import RemarksModal from '../RemarksModal/RemarksModal.vue';

interface IInspectorPanel {
  createToast?: ToastMixin;
  remarks: string;
  remarksModalMode?: RemarksModalMode;
  inspectionDoneStatuses: InspectorStatusEnum[];
  isChangingStatus: boolean;
  selectedStatus?: InspectorStatusEnum;

  metadataCheckAcceptedStatus: MetadataCheckStatus;
  acceptedInspectorStatus: InspectorStatusEnum.accepted;
  rejectedInspectorStatus: InspectorStatusEnum.rejected;
  acceptedWithRemarksInspectorStatus: InspectorStatusEnum.acceptedWithRemarks;
  allowedRoles: [Role.Admin, Role.Inspector];
}

export default Vue.extend({
  name: 'inspector-panel',

  components: { RemarksModal },

  props: {
    file: {
      type: Object as () => IFilesListFile,
      required: true,
    },
  },

  data(): IInspectorPanel {
    return {
      remarks: '',
      remarksModalMode: undefined,
      inspectionDoneStatuses: INSPECTION_DONE_STATUSES,
      isChangingStatus: false,
      selectedStatus: undefined,

      metadataCheckAcceptedStatus: MetadataCheckStatus.ACCEPTED,
      acceptedInspectorStatus: InspectorStatusEnum.accepted,
      rejectedInspectorStatus: InspectorStatusEnum.rejected,
      acceptedWithRemarksInspectorStatus:
        InspectorStatusEnum.acceptedWithRemarks,
      allowedRoles: [Role.Admin, Role.Inspector],
    };
  },

  methods: {
    async handleStatusChange(
      statusChageType: InspectorStatusEnum,
      remarks?: string,
    ) {
      this.isChangingStatus = true;
      this.selectedStatus = statusChageType;
      try {
        await axios.patch(
          endpointsList.documentsUploads.assignInspectorStatus,
          {
            uploadId: this.file.uploadId,
            documentId: this.file.documentId,
            inspectorStatus: statusChageType,
            inspectorRemarks: remarks ?? '',
          },
        );

        this.createToast!(
          this.$t('documentDetailView.inspectorStatusChanged'),
          this.$t('documentDetailView.inspectorStatusChanged'),
          'success',
        );

        this.remarks = '';
      } catch (err) {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }

      this.isChangingStatus = false;
      this.$emit('status-updated');
    },

    handleRemarksModalSubmit(eventPayload: IRemarksModalEventPayload) {
      this.handleStatusChange(eventPayload.status, eventPayload.remarks);
    },

    isInspectionDone(): boolean {
      return (
        this.file &&
        this.inspectionDoneStatuses.includes(this.file?.inspectorStatus)
      );
    },

    isInspectionPossible(): boolean {
      return [
        InspectorStatusEnum.waiting,
        InspectorStatusEnum.acceptedWithRemarks,
      ].includes(this.file?.inspectorStatus);
    },
  },

  created() {
    this.remarks = this.file?.inspectorRemarks;
  },
});
