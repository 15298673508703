import { ISingleUploadRecord } from '@/components/SingleUploadForm/SingleUploadForm.script';
import EventEmitter from 'events';
import { IUploadRecord } from '../../DocumentUpload/DocumentUpload.script';

export default abstract class DocumentUploadHelperAbstract extends EventEmitter {
  isUploadToSPFinished: boolean = false;
  isUploadToDBFinished: boolean = false;

  constructor(
    readonly projectName: string,
    readonly projectId: string,
    readonly projectNumber: string,
    readonly referenceNumber: string,
    readonly contentTypeId: string,
    readonly uploadRecords: IUploadRecord[] | ISingleUploadRecord[],
  ) {
    super();
  }

  static listRecordsWithMissingFile(uploadRecords: IUploadRecord[]) {
    const [
      IdsOfRecordsWithMissingFile,
      IdsOfRecordsWithChangedMetadata,
      IdsOfRecordsWithEmptyFiles,
    ] = [
      (record: IUploadRecord) => !record.file && !record.isChangedMetadata,
      (record: IUploadRecord) =>
        Boolean(!record.file && record.isChangedMetadata),
      (record: IUploadRecord) => record.isEmptyFile,
    ].map((condition) => this._getIdsOfFaultyRecords(uploadRecords, condition));

    return {
      IdsOfRecordsWithMissingFile,
      IdsOfRecordsWithChangedMetadata,
      IdsOfRecordsWithEmptyFiles,
    };
  }

  private static _getIdsOfFaultyRecords(
    uploadRecords: IUploadRecord[],
    condition: (uploadRecord: IUploadRecord) => boolean,
  ): string[] {
    const faultyRecords = uploadRecords.filter(condition);
    return faultyRecords.map((record) => record.metadata!.documentId);
  }

  async finalizeUploadToSharepoint() {
    await this.createSharepointFolder();

    await this.saveFilesInSharepoint();
  }

  abstract createSharepointFolder(): Promise<void>;

  abstract saveFilesInSharepoint(): Promise<void>;

  abstract postDocumentsUploadsToDB(): Promise<void>;
}
