/**
 * This component is based on the KKSPicker component from the
 * vdp-cit-heatberlin-ecm-grid-sales-web repository.
 */
import { endpointsList } from '@/helpers';
import {
  faWarning,
  faCircleCheck,
  faCopy,
} from '@fortawesome/free-solid-svg-icons';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import axios, { AxiosResponse } from 'axios';
import {
  DEFAULT_METADATA_VALUES_SEPARATOR,
  IKksTreeRequestDto,
  IKksTreeResponseDto,
  IPreselectedNodesRequestDto,
  KksState,
} from 'types';
import Vue, { PropType } from 'vue';
import kksValidator, { KksValidator } from '@/mixins/kksValidator';

type KksPicker = {
  treeselectKks: number | null;
  selectedValue: IKksTreeResponseDto | null;
  presetPlaceholder: string | null;
  iconWarning: typeof faWarning;
  iconCheck: typeof faCircleCheck;
  iconCopy: typeof faCopy;
  wildcard: boolean;
  selectedMapped: SelectedMapped | SelectedMapped[] | false;
  filterStatesSelected: KksState[];
  filterStates: [
    { id: 'geplant'; label: 'geplant' },
    { id: 'dms-vorgeschlagen'; label: 'dms-vorgeschlagen' },
    { id: 'dms-bestaetigt'; label: 'dms-bestaetigt' },
    { id: 'dms-abgelehnt'; label: 'dms-abgelehnt' },
    { id: 'sap-pm'; label: 'sap-pm' },
    { id: 'undefiniert'; label: 'undefiniert' },
  ];
  isBusy: boolean;
};

type SelectedMapped = {
  normalizedContext: string;
  kks: string;
  wildcard: boolean;
};

type KksWithChildren = Omit<IKksTreeResponseDto, 'children'> & {
  children: null | IKksTreeResponseDto[];
};

type LoadOptions = {
  action: string;
  parentNode: KksWithChildren;
  searchQuery: string;
  callback: (
    parentNode?: KksWithChildren | null,
    children?: KksWithChildren[] | null,
  ) => void;
};

export default Vue.extend({
  name: 'KksPicker',
  components: {
    Treeselect,
  },
  mixins: [kksValidator],
  props: {
    maxHeight: {
      required: false,
      type: Number,
      default: 600,
    },
    displayWarnings: {
      required: false,
      type: Boolean,
      default: false,
    },
    flat: {
      required: false,
      type: Boolean,
      default: true,
    },
    disabled: {
      required: false,
      default: false,
    },
    preSelected: {
      required: false,
      default: null,
    },
    locationScope: {
      required: true,
      type: String,
      default: null,
    },
    multiSelect: {
      required: false,
      type: Boolean,
      default: true,
    },
    showWildcardSelector: {
      required: false,
      type: Boolean,
      default: true,
    },
    showCopyButton: {
      required: false,
      type: Boolean,
      default: false,
    },
    showFilterDropdown: {
      required: false,
      type: Boolean,
      default: false,
    },
    canRemoveLastValue: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data(): KksPicker {
    return {
      treeselectKks: null,
      selectedValue: null,
      presetPlaceholder: null,
      iconWarning: faWarning,
      iconCheck: faCircleCheck,
      iconCopy: faCopy,
      wildcard: false,
      selectedMapped: false,
      filterStatesSelected: [KksState.SAP_PM],
      filterStates: [
        { id: 'geplant', label: 'geplant' },
        { id: 'dms-vorgeschlagen', label: 'dms-vorgeschlagen' },
        { id: 'dms-bestaetigt', label: 'dms-bestaetigt' },
        { id: 'dms-abgelehnt', label: 'dms-abgelehnt' },
        { id: 'sap-pm', label: 'sap-pm' },
        { id: 'undefiniert', label: 'undefiniert' },
      ],
      isBusy: false,
    };
  },
  watch: {
    async filterStatesSelected() {
      this.selectedValue = null;
      this.treeselectKks = Date.now();
    },
    wildcard() {
      if (this.selectedMapped) {
        this.emitSelect(this.selectedMapped);
      }
    },
    async selectedValue(newVal: SelectedMapped[]) {
      const kks = newVal.map((v) => v.kks);
      (this as unknown as KksValidator).validateKks(
        kks,
        this.locationScope,
        this.filterStatesSelected,
      );

      if (this.multiSelect) {
        this.$emit(
          'input',
          newVal.map((x) => x.kks),
        );
      }
    },
  },
  async mounted() {
    if (this.preSelected) {
      this.parsePreSelected();
    }
  },
  methods: {
    getVarian(state: KksState) {
      switch (state) {
        case 'geplant':
          return 'info';
        case 'dms-vorgeschlagen':
          return 'primary';
        case 'dms-bestaetigt':
          return 'success';
        case 'dms-abgelehnt':
          return 'danger';
        default:
          return 'light';
      }
    },
    copyContext() {
      let copyVal = this.selectedValue && this.selectedValue.kks;
      if (!copyVal) {
        const match = (this.presetPlaceholder || '').match(/[^-]*$/);
        if (match) {
          copyVal = match[0] || this.presetPlaceholder;
        }
      }
      navigator.clipboard.writeText(copyVal || '');
    },
    async parsePreSelected() {
      let cloned: string | string[] = JSON.parse(
        JSON.stringify(this.preSelected),
      );
      if (typeof cloned === 'string') {
        cloned = cloned.split(DEFAULT_METADATA_VALUES_SEPARATOR);
      }
      const validatioResult = await (
        this as unknown as KksValidator
      ).validateKks(cloned, this.locationScope, this.filterStatesSelected);
      const invalid = cloned.filter((kks) => !validatioResult?.[kks]);

      const kksForRequest = cloned.filter((kks) => validatioResult?.[kks]);
      try {
        this.isBusy = true;
        const preselected = await axios.post<
          any,
          AxiosResponse<any, any>,
          IPreselectedNodesRequestDto
        >(endpointsList.prodisApiIntegration.getPreselectedNodes(), {
          nodes: kksForRequest,
          location: this.locationScope,
          states: this.filterStatesSelected,
        });

        const preselectedNotValid = invalid.map((v) => ({
          id: v,
          kks: v,
          normalizedContext: v,
        }));

        (this.selectedValue as any) = [
          ...preselected.data,
          ...preselectedNotValid,
        ];
      } finally {
        this.isBusy = false;
      }
    },
    async loadOptions({
      action,
      parentNode,
      searchQuery,
      callback,
    }: LoadOptions) {
      if (action === 'ASYNC_SEARCH') {
        const r = await this.fetchVals(null, searchQuery);
        if (searchQuery) {
          r.push({
            id: searchQuery,
            kks: searchQuery,
          });
        }
        callback(null, r);
      } else if (action === 'LOAD_CHILDREN_OPTIONS') {
        parentNode.children = await this.fetchVals(parentNode.id, null);
        callback();
      } else {
        parentNode.children = [];
        callback();
      }
    },
    async fetchVals(branchId: string | null, searchTerm: string | null) {
      const body: IKksTreeRequestDto = {
        locationScope: this.locationScope,
        branchId: branchId ? branchId : undefined,
        searchTerm: searchTerm ? searchTerm : undefined,
        state: this.filterStatesSelected,
      };
      const treeData = await axios.post(
        endpointsList.prodisApiIntegration.getKksTree(),
        body,
      );
      return treeData.data || [];
    },
    emitSelect(val: SelectedMapped[] | SelectedMapped) {
      if (this.multiSelect) {
        return;
      }
      this.presetPlaceholder = null;
      if (Array.isArray(val)) {
        this.selectedMapped = val.map((v) => ({
          normalizedContext: v.normalizedContext,
          kks: v.kks,
          wildcard: this.wildcard,
        }));
        this.$emit('input', this.selectedMapped);
      } else {
        this.selectedMapped = {
          normalizedContext: val.normalizedContext,
          kks: val.kks,
          wildcard: this.wildcard,
        };
        this.$emit('input', this.selectedMapped);
      }
    },
    setFixedValue(val: string) {
      this.presetPlaceholder = val;
    },
  },
});
