"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.arraysHaveSameValues = arraysHaveSameValues;
/**
 * Checks if two arrays contain the same values, regardless of their order.
 *
 * This method compares the values in the arrays without considering their
 * indexes. It returns `true` if both arrays have the same values and lengths,
 * and `false` otherwise.
 *
 * @param {any[]} arr1 - The first array to compare.
 * @param {any[]} arr2 - The second array to compare.
 * @returns {boolean} `true` if the arrays have the same values, otherwise
 * `false`.
 *
 * @example
 * arraysHaveSameValues([1, 2, 3], [3, 2, 1]); // true
 * arraysHaveSameValues([1, 2, 3], [4, 5, 6]); // false
 */
function arraysHaveSameValues(arr1, arr2) {
    return (arr1.length === arr2.length && arr1.every((item) => arr2.includes(item)));
}
