@mixin noScroll {
  /* Hide scrollbar for IE and Edge */
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
  }
}

.rowHeaders {
  z-index: 2;
  font-size: 10px;
  display: flex;
  height: 100%;

  revogr-data .rgCell {
    text-align: center;
  }

  .rgCell {
    padding: 0 1em !important;
    min-width: 100%;
  }
}

revogr-viewport-scroll {
  @include noScroll;

  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  z-index: 1;
  height: 100%;

  &.colPinStart,
  &.colPinEnd {
    z-index: 2;
  }

  // make sure it would work
  &.colPinEnd:has(.active) {
    overflow: visible;
  }

  &.rgCol {
    flex-grow: 1;
  }

  .content-wrapper {
    overflow: hidden;
  }

  .inner-content-table {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 100%;
    min-width: 100%;
    position: relative;
    z-index: 0;
  }

  .vertical-inner {
    overflow-y: auto;
    position: relative;
    width: 100%;
    flex-grow: 1;
    @include noScroll;

    revogr-data,
    revogr-overlay-selection {
      height: 100%;
    }
  }
}
