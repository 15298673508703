"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FINAL_DOCUMENT_STATUSES = exports.FAILED_DIGITALIZATION_FORMAL_CONTROL_STATUSES = exports.POSITIVE_DIGITALIZATION_FORMAL_CONTROL_STATUSES = exports.WAITING_DIGITALIZATION_CHECK_BOT_STATUSES = exports.REJECTED_QUALITY_CHECK_STATUSES = exports.WAITING_QUALITY_CHECK_STATUSES = exports.POSITIVE_QUALITY_CHECK_STATUSES = exports.WAITING_METADATA_INSPECTION_STATUSES = void 0;
const digitalization_check_bot_status_enum_1 = require("./digitalization-check-bot-status.enum");
const digitalization_formal_control_status_enum_1 = require("./digitalization-formal-control-status.enum");
const document_status_type_1 = require("./document-status.type");
const metadata_inspection_status_enum_1 = require("./metadata-inspection-status.enum");
const quality_check_status_enum_1 = require("./quality-check-status.enum");
exports.WAITING_METADATA_INSPECTION_STATUSES = [metadata_inspection_status_enum_1.MetadataInspectionStatus.WAITING];
exports.POSITIVE_QUALITY_CHECK_STATUSES = [
    quality_check_status_enum_1.QualityCheckStatus.ACCEPTED,
];
exports.WAITING_QUALITY_CHECK_STATUSES = [
    quality_check_status_enum_1.QualityCheckStatus.WAITING,
    quality_check_status_enum_1.QualityCheckStatus.WAITING_FOR_FORMAL_CONTROL,
];
exports.REJECTED_QUALITY_CHECK_STATUSES = [
    quality_check_status_enum_1.QualityCheckStatus.REJECTED,
];
exports.WAITING_DIGITALIZATION_CHECK_BOT_STATUSES = [
    digitalization_check_bot_status_enum_1.DigitalizationCheckBotStatus.WAITING_FOR_METADATA_INFERENCE,
    digitalization_check_bot_status_enum_1.DigitalizationCheckBotStatus.WAITING,
];
exports.POSITIVE_DIGITALIZATION_FORMAL_CONTROL_STATUSES = [
    digitalization_formal_control_status_enum_1.DigitalizationFormalControlStatus.ACCEPTED,
    digitalization_formal_control_status_enum_1.DigitalizationFormalControlStatus.ACCEPTED_WITH_REMARKS,
];
exports.FAILED_DIGITALIZATION_FORMAL_CONTROL_STATUSES = [digitalization_formal_control_status_enum_1.DigitalizationFormalControlStatus.REJECTED];
exports.FINAL_DOCUMENT_STATUSES = [
    document_status_type_1.DocumentStatus.RECORD_LIBRARY_TRANSFER_IN_PROGRESS,
    document_status_type_1.DocumentStatus.RECORD_LIBRARY_TRANSFER_FAILED,
    document_status_type_1.DocumentStatus.RECORD_LIBRARY_TRANSFER_SUCCESSFULL,
];
