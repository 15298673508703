import {
  FilterType,
  IFilterConfiguration,
  Mode,
  SelectOptions,
} from '@/components/FilterableTable/FilterableTable.script';
import FilterableTable from '@/components/FilterableTable/FilterableTable.vue';
import filterableTableHelpersMixin, {
  IFilterableTableHelpersMixin,
} from '@/components/FilterableTable/filterableTableHelpersMixin';
import { ButtonVariant } from '@/components/VfButton/VfButton.script';
import VfButton from '@/components/VfButton/VfButton.vue';
import { ITableField } from '@/frontendInterfaces';
import { debounce } from '@/helpers/debounce';
import endpointsList from '@/helpers/endpointsList';
import {
  DigitalizationProjectStatus,
  IDigitalizationProject,
  IDigitalizationProjectsPageOptionsDto,
  IDigitalizationProjectSummaryDto,
  IPageDto,
  Order,
  Role,
} from 'types';
import axios, { AxiosResponse } from 'axios';
import Vue from 'vue';

type DigitalizationProjectsFiltering = Pick<
  IDigitalizationProjectsPageOptionsDto,
  'title' | 'number' | 'status'
>;

interface IDigitalizationProjectsList {
  addProjectButtonVariant: ButtonVariant.SECONDARY;
  createDigitalizationProjectAllowedRoles: [Role.Admin];
  filtering?: DigitalizationProjectsFiltering;
  isLoadingDigitalizationProjects: boolean;
  items?: IDigitalizationProjectSummaryDto[];
  tableFilterConfiguration: IFilterConfiguration;
  tableFilterGridConfiguration: string[];
  tableMode: Mode.OUTSOURCED;
  tableRows?: number;
  page: number;
  perPage: number;
  selectOptions?: SelectOptions<IDigitalizationProjectSummaryDto>;
  sortBy: keyof IDigitalizationProjectSummaryDto;
  sortDesc: boolean;
}

export default Vue.extend({
  name: 'digitalization-projects-list',
  components: { FilterableTable, VfButton },
  mixins: [filterableTableHelpersMixin],

  data(): IDigitalizationProjectsList {
    return {
      addProjectButtonVariant: ButtonVariant.SECONDARY,
      createDigitalizationProjectAllowedRoles: [Role.Admin],
      filtering: undefined,
      isLoadingDigitalizationProjects: false,
      items: undefined,
      tableFilterConfiguration: {
        title: {
          type: FilterType.INPUT,
          width: '208px',
          column: 1,
        },
        number: {
          type: FilterType.INPUT,
          width: '208px',
          column: 2,
        },
        status: {
          type: FilterType.SELECT,
          width: '120px',
          column: 3,
          translateOptions: true,
        },
      },
      tableFilterGridConfiguration: ['50%', '35%', '10%'],
      tableMode: Mode.OUTSOURCED,
      tableRows: 0,
      page: 1,
      perPage: 10,
      selectOptions: undefined,
      sortBy: 'title',
      sortDesc: false,
    };
  },

  computed: {
    tableFields(): ITableField<IDigitalizationProject>[] {
      return [
        {
          key: 'title',
          label: this.$t('digitalizationProjectsList.title'),
          sortable: true,
          tdClass: ['w-50'],
        },
        {
          key: 'number',
          label: this.$t('digitalizationProjectsList.number'),
          sortable: true,
          tdClass: ['w-35'],
        },
        {
          key: 'status',
          label: this.$t('digitalizationProjectsList.status'),
          sortable: true,
          tdClass: ['w-10'],
        },
      ];
    },
  },

  methods: {
    async getDigitalizationProjects(): Promise<void> {
      try {
        this.isLoadingDigitalizationProjects = true;

        const filtering = Object.fromEntries(
          Object.entries(this.filtering ?? {}).filter(([_key, value]) =>
            Boolean(value),
          ),
        );

        const response: AxiosResponse<
          IPageDto<IDigitalizationProjectSummaryDto>
        > = await axios.get(
          endpointsList.digitalizationProjects.getDigitalizationProjectsList({
            order: this.sortDesc ? Order.DESC : Order.ASC,
            page: this.page,
            sortBy: this.sortBy,
            take: this.perPage,
            ...filtering,
          }),
        );

        const pageDto = response.data;

        this.items = pageDto.data;
        this.tableRows = pageDto.meta.itemCount;
      } catch {
        (this as any).createToast!(
          this.$t('errors.error'),
          this.$t(
            'digitalizationProjectsList.errors.errorGettingDigitalizationProjects.long',
          ),
          'danger',
        );
      } finally {
        this.isLoadingDigitalizationProjects = false;
      }
    },

    handleCreateDigitalizationProjectClick(): void {
      this.$router.push({ name: 'createDigitalizationProject' });
    },

    debouncedGetDigitalizationProjects: debounce(function (this: any) {
      this.getDigitalizationProjects();
    }, 200),

    handlePerPageChange(perPage: number): void {
      this.perPage = perPage;
      this.getDigitalizationProjects();
    },

    handlePageNumberChange(pageNumber: number): void {
      this.page = pageNumber;
      this.getDigitalizationProjects();
    },

    handleFilteringChange(filtering: DigitalizationProjectsFiltering): void {
      this.filtering = filtering;
      this.debouncedGetDigitalizationProjects();
    },

    handleSortDescChanged(sortDesc: boolean): void {
      this.sortDesc = sortDesc;
      this.getDigitalizationProjects();
    },

    handleSortByChanged(sortBy: keyof IDigitalizationProjectSummaryDto): void {
      this.sortBy = sortBy;
      this.getDigitalizationProjects();
    },

    getSelectOptions(): void {
      const statusOptions = (
        this as unknown as IFilterableTableHelpersMixin
      ).getSelectOptionsFromEnum(
        DigitalizationProjectStatus,
        'digitalizationProjectsList.statuses',
      );

      this.selectOptions = {
        status: statusOptions,
      };
    },
  },

  created() {
    this.getDigitalizationProjects();
    this.getSelectOptions();
  },
});
