import { IFilesQueryRequest } from 'types';
import { Filtering } from '../FilesOverviewTable.script';

export function getFileQueryItem(
  field: keyof Filtering,
): keyof IFilesQueryRequest {
  const filteringToQuery: {
    [key in keyof Filtering]: keyof IFilesQueryRequest;
  } = {
    documentId: 'documentId',
    revision: 'revision',
    referenceNumber: 'referenceNumber',
    supplierName: 'supplierName',
    contentType: 'contentTypeName',
    uploadDate: 'uploadDate',
    inspectionDueDate: 'inspectionDueDate',
    coworker: 'coworker',
    formalControlStatus: 'formalControlStatus',
    inspectorStatus: 'inspectorStatus',
    recordLibraryTransferStatus: 'recordLibraryTransferStatus',
  };

  // TODO: Fix non null assertion
  return filteringToQuery[field as keyof Filtering]!;
}
