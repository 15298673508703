import { IProject, IInspectorStatusesForSupplier, Role } from 'types';
import { IsUserRoleMixin, ToastMixin } from '../../mixins/globalUtils';
import axios from 'axios';
import Vue from 'vue';
import endpointsList from '../../helpers/endpointsList';
import { router } from '../../vue';
import FilterableTable from '../../components/FilterableTable/FilterableTable.vue';
import {
  FilterType,
  IFilterConfiguration,
} from '../../components/FilterableTable/FilterableTable.script';

interface IProjectsList {
  projects: IProject[] | null;
  inspectorStatuses: IInspectorStatusesForSupplier | null;
  sortBy: string;
  sortDesc: boolean;
  createProjectAllowedRoles: Role[];
  createToast?: ToastMixin;
  isUserRole?: IsUserRoleMixin;
  tableFilterConfiguration: IFilterConfiguration;
  tableFilterGridConfiguration: string[];
}

export default Vue.extend({
  name: 'projects-list',
  components: {
    FilterableTable,
  },
  data(): IProjectsList {
    return {
      projects: null,
      inspectorStatuses: null,
      sortBy: 'projectTitle',
      sortDesc: false,

      createProjectAllowedRoles: [Role.Admin],

      tableFilterConfiguration: {
        projectTitle: {
          type: FilterType.INPUT,
          width: '208px',
          column: 1,
        },
        projectNumber: {
          type: FilterType.INPUT,
          width: '208px',
          column: 2,
        },
        projectStatus: {
          type: FilterType.SELECT,
          width: '120px',
          column: 3,
          translateOptions: true,
        },
      },
      tableFilterGridConfiguration: ['50%', '35%', '10%'],
    };
  },

  computed: {
    tableFields() {
      const tableFields = [
        {
          key: 'projectTitle',
          label: this.$i18n.t('projectsList.title'),
          sortable: true,
          tdClass: ['w-50'],
        },
        {
          key: 'projectNumber',
          label: this.$i18n.t('projectsList.number'),
          sortable: true,
          tdClass: ['w-35'],
        },
        {
          key: 'projectStatus',
          label: this.$i18n.t('projectsList.status'),
          sortable: true,
          tdClass: ['w-10'],
        },
      ];

      return tableFields;
    },
  },

  methods: {
    async getProjects() {
      try {
        const response = await axios.get(endpointsList.projects.getAllProjects);

        this.projects = response.data;
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('projectsList.errors.couldNotLoadProjects'),
          'danger',
        );
      }
    },

    handleNewProjectClick() {
      router.push({
        name: 'createProject',
        params: { mode: 'create-project' },
      });
    },
  },

  mounted() {
    this.getProjects();
  },
});
