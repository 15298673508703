import ChangeInspectionDueDateModal from '@/components/ChangeInspectionDueDateModal/ChangeInspectionDueDateModal.vue';
import filterableTableHelpersMixin, {
  IFilterableTableHelpersMixin,
} from '@/components/FilterableTable/filterableTableHelpersMixin';
import axios, { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import {
  CheckbotStatusEnum,
  DocumentDetailViewMode,
  FormalControlStatus,
  IFilesListFile,
  IFilesQueryRequest,
  IFileTableData,
  IInspectorStatusAssignmentResponseDto,
  InspectorStatusEnum,
  IPaginatedFileTableDataResponse,
  ISetMultipleInspectorStatusesDto,
  RecordLibraryTransferStatus,
  Role,
  Serialized,
  SharePointUploadStatus,
} from 'types';
import { isDefined } from 'utils';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import FilterableTable from '../../components/FilterableTable/FilterableTable.vue';
import endpointsList from '../../helpers/endpointsList';
import { GlobalUtils, IsUserRoleMixin } from '../../mixins/globalUtils';
import { router } from '../../vue';
import {
  FilterType,
  IFilterConfiguration,
  IFiltering,
  Mode,
  SelectOptions,
} from '../FilterableTable/FilterableTable.script';
import InspectorConfirmationModal from '../InspectorConfirmationModal/InspectorConfirmationModal.vue';
import MetadataModal from '../MetadataModal/MetadataModal.vue';
import { getFileQuery } from './helpers/getFileQuery';
import { getFileQueryItem } from './helpers/getFileQueryItem';

interface IFilesOverviewTable {
  files: IFileTableData[] | null;
  selectedFiles: { [documentId: string]: boolean };
  statusChanges: IStatusChanges | Record<string, never>;
  isChangingInspectorStatus: boolean;
  isUserRole?: IsUserRoleMixin;
  sortBy: keyof IFilesQueryRequest;
  sortDesc: boolean;
  page: number;
  perPage: number;
  inspectionAllowedRoles: [Role.Admin, Role.Inspector];
  changeInspectionDueDateAllowedRoles: [
    Role.Admin,
    Role.Inspector,
    Role.DocuManager,
  ];
  waitingInspectorStatus: InspectorStatusEnum.waiting;
  waitingCheckBotStatus: CheckbotStatusEnum;
  tableMode: Mode.OUTSOURCED;
  tableRows?: number;
  filtering?: Partial<IFilesQueryRequest>;
  isLoadingFiles: boolean;
  selectOptions?: SelectOptions<IFileTableData>;
  referenceNumberPrefiltering?: IFiltering;
  inspectorStatusChangeResults?: IInspectorStatusAssignmentResponseDto;
}

interface IStatusChanges {
  files: IStatusChangeFile[];
  statusChangeType: InspectorStatusEnum;
}

interface IStatusChangeFile {
  _id: string;
  documentId: string;
  supplierId: string;
  uploadId: string;
}

export type Filtering = {
  [key in keyof IFileTableData]?: any;
};

export default Vue.extend({
  name: 'files-overview-table',
  components: {
    MetadataModal,
    InspectorConfirmationModal,
    FilterableTable,
    ChangeInspectionDueDateModal,
  },
  mixins: [filterableTableHelpersMixin],
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data(): IFilesOverviewTable {
    return {
      files: null,
      sortBy: 'documentId',
      sortDesc: false,
      page: 1,
      perPage: 10,
      selectedFiles: {},
      statusChanges: {},
      isChangingInspectorStatus: false,
      inspectionAllowedRoles: [Role.Admin, Role.Inspector],
      changeInspectionDueDateAllowedRoles: [
        Role.Admin,
        Role.Inspector,
        Role.DocuManager,
      ],
      waitingInspectorStatus: InspectorStatusEnum.waiting,
      waitingCheckBotStatus: CheckbotStatusEnum.waiting,
      tableMode: Mode.OUTSOURCED,
      tableRows: undefined,
      filtering: undefined,
      isLoadingFiles: false,
      selectOptions: undefined,
      referenceNumberPrefiltering: undefined,
      inspectorStatusChangeResults: undefined,
    };
  },

  computed: {
    tableFields(): {
      key: string;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
      thClass?: string[];
    }[] {
      const tableFields = [
        {
          key: 'documentId',
          label: '#',
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
        {
          key: 'referenceNumber',
          label: this.$t('projectDetails.documents.referenceNumber'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
        {
          key: 'revision',
          label: this.$t('projectDetails.documents.revision'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
        {
          key: 'contentType',
          label: this.$t('projectDetails.documents.contentType'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
        {
          key: 'uploadDate',
          label: this.$t('projectDetails.documents.uploadDate'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
        {
          key: 'inspectionDueDate',
          label: this.$t('projectDetails.documents.inspectionDueDate'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
        {
          key: 'coworker',
          label: this.$t('projectDetails.documents.coworker'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
        {
          key: 'formalControlStatus',
          label: this.$t('projectDetails.documents.checkBotStatus'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
        {
          key: 'inspectorStatus',
          label: this.$t('projectDetails.documents.inspectorStatus'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
        {
          key: 'recordLibraryTransferStatus',
          label: this.$t(
            'projectDetails.documents.recordLibraryTransferStatus',
          ),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        },
      ];

      if (this.isUserRole!([Role.Admin, Role.Inspector, Role.DocuManager])) {
        tableFields.splice(3, 0, {
          key: 'supplierName',
          label: this.$tc('projectDetails.documents.supplierName', 1),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words'],
        });

        tableFields.unshift({
          key: 'select',
          label: '',
          sortable: false,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        });

        // Admin/Docu Manager/Inspector variant
        [
          'w-2',
          'w-9',
          'w-5',
          'w-5',
          'w-13',
          'w-12',
          'w-11',
          'w-9',
          'w-8',
          'w-8',
          'w-8',
          'w-8',
        ].forEach((widthClass, index) => {
          tableFields[index].tdClass.push(widthClass);
        });

        return tableFields;
      }

      // Coworker variant
      [
        'w-18',
        'w-5',
        'w-5',
        'w-12',
        'w-11',
        'w-10',
        'w-10',
        'w-9',
        'w-9',
        'w-9',
      ].forEach((widthClass, index) => {
        tableFields[index].tdClass.push(widthClass);
      });

      return tableFields;
    },

    tableFilterConfiguration(): IFilterConfiguration {
      if (this.isUserRole!([Role.Admin, Role.Inspector, Role.DocuManager])) {
        return {
          documentId: {
            column: 1,
            width: '130px',
            type: FilterType.INPUT,
            simpleLabel: true,
          },
          referenceNumber: {
            column: 2,
            width: '70px',
            type: FilterType.INPUT,
            simpleLabel: true,
          },
          revision: {
            column: 3,
            width: '70px',
            type: FilterType.INPUT,
            simpleLabel: true,
          },
          supplierName: {
            column: 4,
            width: '180px',
            type: FilterType.INPUT,
            customLabel: this.$tc('projectDetails.documents.supplierName', 1),
          },
          contentType: {
            column: 5,
            width: '140px',
            type: FilterType.SELECT,
            simpleLabel: true,
          },
          coworker: {
            column: 8,
            width: '120px',
            type: FilterType.INPUT,
            simpleLabel: true,
          },
          formalControlStatus: {
            column: 9,
            width: '110px',
            type: FilterType.SELECT,
            simpleLabel: true,
            translateOptions: true,
          },
          inspectorStatus: {
            column: 10,
            width: '110px',
            type: FilterType.SELECT,
            simpleLabel: true,
            translateOptions: true,
          },
          recordLibraryTransferStatus: {
            column: 11,
            width: '110px',
            type: FilterType.SELECT,
            simpleLabel: true,
            translateOptions: true,
          },
        };
      }
      return {
        documentId: {
          column: 1,
          width: '130px',
          type: FilterType.INPUT,
          simpleLabel: true,
        },
        referenceNumber: {
          column: 2,
          width: '70px',
          type: FilterType.INPUT,
          simpleLabel: true,
        },
        revision: {
          column: 3,
          width: '70px',
          type: FilterType.INPUT,
          simpleLabel: true,
        },
        contentType: {
          column: 4,
          width: '140px',
          type: FilterType.SELECT,
          simpleLabel: true,
        },
        coworker: {
          column: 7,
          width: '120px',
          type: FilterType.INPUT,
          simpleLabel: true,
        },
        formalControlStatus: {
          column: 8,
          width: '110px',
          type: FilterType.SELECT,
          simpleLabel: true,
          translateOptions: true,
        },
        inspectorStatus: {
          column: 9,
          width: '110px',
          type: FilterType.SELECT,
          simpleLabel: true,
          translateOptions: true,
        },
        transferToRecordLibrary: {
          column: 10,
          width: '110px',
          type: FilterType.SELECT,
          simpleLabel: true,
          translateOptions: true,
        },
      };
    },

    tableFilterGridConfiguration() {
      if (this.isUserRole!([Role.Admin, Role.DocuManager, Role.Inspector])) {
        return [
          '9%',
          '5%',
          '5%',
          '13%',
          '12%',
          '11%',
          '9%',
          '8%',
          '8%',
          '8%',
          '8%',
          '2%',
        ];
      }

      return ['18%', '5%', '5%', '12%', '11%', '10%', '10%', '9%', '9%', '9%'];
    },
  },

  methods: {
    async getFiles() {
      this.isLoadingFiles = true;
      try {
        const response: AxiosResponse<IPaginatedFileTableDataResponse> =
          await axios.get(
            endpointsList.documentsUploads.getFilesForProject(
              this.projectId,
              this.page,
              this.perPage,
              this.sortBy,
              this.sortDesc ? -1 : 1,
              this.filtering,
            ),
          );

        const filesAndCount = response.data;

        this.files = filesAndCount.files;
        this.tableRows = filesAndCount.resultsCount;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('projectDetails.documents.errors.couldNotLoadDocuments'),
          'danger',
        );
      }
      this.isLoadingFiles = false;
    },

    // Adding empty function to the Vue instance in order
    // to later assign them in the created hook preserving
    // the this context
    debouncedGetFiles: new Function(),

    async handlePerPageChange(perPage: number) {
      this.perPage = perPage;
      this.debouncedGetFiles();
    },

    async handlePageNumberChange(pageNumber: number) {
      this.page = pageNumber;
      this.debouncedGetFiles();
      /** Deselecting all documents on page change */
      this.toggleAllDocumentsSelection(false);
    },

    async handleFilteringChange(filtering: Filtering) {
      const transformedFiltering = getFileQuery(filtering);

      this.filtering = transformedFiltering;
      this.debouncedGetFiles();
    },

    async handleSortDescChanged(sortDesc: boolean) {
      this.sortDesc = sortDesc;
      this.debouncedGetFiles();
    },

    async handleSortByChanged(sortBy: keyof IFileTableData) {
      const sortByQueryFormat = getFileQueryItem(sortBy);
      this.sortBy = sortByQueryFormat;
      this.debouncedGetFiles();
    },

    getFormalControlStatusOfFile(file: IFilesListFile): string {
      if (file.sharePointUploadStatus === SharePointUploadStatus.FAILED) {
        return '-';
      }
      if (file.formalControlStatus) {
        return this.$t(
          `formalControlStatus.${file.formalControlStatus}`,
        ).toString();
      }
      return this.$t('formalControlStatus.waiting').toString();
    },

    goToDocumentDetailView(row: any) {
      const { documentId, supplierId, uploadId } = row.item;
      router.push({
        name: 'documentDetailView',
        params: {
          mode: DocumentDetailViewMode.PROJECT,
          documentId,
          projectId: this.projectId,
          supplierId,
          uploadId,
        },
      });
    },

    async getSelectOptions() {
      const contentTypeNamesResponse: AxiosResponse<string[]> = await axios.get(
        endpointsList.contentTypeMappings.getContentTypeNamesForProject(
          this.projectId,
        ),
      );
      const contentTypeNames = contentTypeNamesResponse.data;

      const selectOptions: SelectOptions = {
        formalControlStatus: (
          this as unknown as IFilterableTableHelpersMixin
        ).getSelectOptionsFromEnum(FormalControlStatus, 'formalControlStatus'),
        recordLibraryTransferStatus: (
          this as unknown as IFilterableTableHelpersMixin
        ).getSelectOptionsFromEnum(
          RecordLibraryTransferStatus,
          'recordLibraryTransferStatus',
        ),
        inspectorStatus: (
          this as unknown as IFilterableTableHelpersMixin
        ).getSelectOptionsFromEnum(InspectorStatusEnum, 'inspectorStatus'),
        contentType: (
          this as unknown as IFilterableTableHelpersMixin
        ).getSelectOptionsFromValues(contentTypeNames),
      };

      for (const column in selectOptions) {
        const columnOptions = selectOptions[column];
        columnOptions?.push({
          text: this.$i18n.t('filterableTable.all'),
          value: null,
        });
      }

      this.selectOptions = selectOptions;
    },

    setFileSelectStatus(documentId: string, status: boolean) {
      Vue.set(this.selectedFiles, documentId, status);
    },

    isFileSelected(documentId: string) {
      return !!this.selectedFiles[documentId];
    },

    handleStatusChangeConfirmation(statusChangeType: InspectorStatusEnum) {
      const files: IStatusChangeFile[] = [];

      for (const documentId in this.selectedFiles) {
        if (this.selectedFiles[documentId] && this.files) {
          const file = this.files.find(
            (file) => file.documentId === documentId,
          )!;

          const { _id, uploadId, supplierId } = file;

          files.push({
            _id: _id.toString(),
            documentId,
            uploadId: uploadId.toString(),
            supplierId: supplierId.toString(),
          });
        }
      }

      this.statusChanges = {
        statusChangeType,
        files,
      };
    },

    async addInspectorStatus() {
      try {
        this.isChangingInspectorStatus = true;
        const requestBody: Serialized<ISetMultipleInspectorStatusesDto> = {
          fileIds: this.statusChanges.files.map((file) => file._id),
          inspectorStatus: this.statusChanges.statusChangeType,
        };
        const response =
          await axios.patch<IInspectorStatusAssignmentResponseDto>(
            endpointsList.documentsUploads.assignInspectorStatusToMultipleFiles,
            requestBody,
          );

        this.inspectorStatusChangeResults = response.data;
      } catch (err) {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      } finally {
        this.isChangingInspectorStatus = false;
        this.statusChanges = {};
        this.selectedFiles = {};
        this.getFiles();
      }
    },

    clearInspectorStatusChangeResults(): void {
      this.inspectorStatusChangeResults = undefined;
    },

    toggleAllDocumentsSelection(isSelected: boolean) {
      for (const file of this.files ?? []) {
        this.setFileSelectStatus(file.documentId, isSelected);
      }
    },

    areAllDocumentsSelected(): boolean {
      if (!this.files) return false;
      else
        return this.files.every((file) => this.selectedFiles[file.documentId]);
    },

    applyPrefiltering() {
      const referenceNumberForFiltering =
        this.$store.state.currentUploadReferenceNumber;
      if (referenceNumberForFiltering === undefined) {
        return;
      }

      this.referenceNumberPrefiltering = {
        referenceNumber: referenceNumberForFiltering,
      };
    },

    getIdsOfSelectedFiles(): string[] {
      if (!this.files) return [];

      return Object.entries(this.selectedFiles)
        .filter(([_documentId, isSelected]) => isSelected)
        .map(([documentId]) =>
          (this.files ?? [])
            .find((file) => file.documentId === documentId)
            ?._id.toString(),
        )
        .filter(isDefined);
    },

    handleInspectionDueDatesUpdated() {
      this.$bvModal.hide('change-inspection-due-date-modal');
      this.selectedFiles = {};
      this.getFiles();
    },

    isInspectionForSelectedDocumentsAllowed(): boolean {
      return Object.entries(this.selectedFiles)
        .filter(([_documentId, isSelected]) => isSelected)
        .map(([documentId]) =>
          this.files?.find((file) => file.documentId === documentId),
        )
        .every((file) => file?.inspectorStatus === InspectorStatusEnum.waiting);
    },

    getRowClass(file: IFilesListFile | undefined): string {
      if (file) {
        if (file.sharePointUploadStatus === SharePointUploadStatus.FAILED) {
          return 'danger-row';
        }
        if (this.selectedFiles[file?.documentId]) {
          return 'selected-row';
        }
      }

      return 'row-class-clickable';
    },
  },

  mounted() {
    if (!this.projectId) {
      return;
    }

    this.debouncedGetFiles = debounce(this.getFiles, 200);

    this.debouncedGetFiles();
    this.getSelectOptions();
    this.applyPrefiltering();
  },
});
