@import './styles/revo-grid.mixin.scss';
@import './styles/revo-grid.default.scss';
@import './styles/revo-grid.material.scss';
@import './styles/revo-grid.dark.material.scss';
@import './styles/revo-grid.dark.compact.scss';
@import './styles/revo-grid.compact.scss';

revo-grid {
  display: block;
  height: 100%;
  font-family: Helvetica, Arial, Sans-Serif, serif;
  font-size: 14px;
  position: relative;

  @include noSelect;

  &.column-draggable.column-drag-start {
    &, * {
      &:hover {
        cursor: grabbing;
      }
    }
  }

  .footer-wrapper,
  .header-wrapper {
    width: 100%;

    revogr-data {
      z-index: 3;
    }
  }

  revo-dropdown {
    width: 100%;

    .rv-dr-root {
      max-height: 100%;
    }
    &.shrink label {
      opacity: 0;
    }
  }

  .viewports {
    max-height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
  }

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .main-viewport {
    flex-grow: 1;
    height: 0;
    display: flex;
    flex-direction: row;
  }

  .draggable {
    $s: 30px;
    position: fixed;
    height: $s;
    line-height: $s;
    background: #fff;
    border-radius: 3px;
    display: block;
    z-index: 100;
    margin-top: 5px;
    margin-right: -20px;
    box-shadow: 0 4px 20px 0 rgba(black, 0.15);
    padding-left: 20px;
    padding-right: 5px;

    &.hidden {
      display: none;
    }

    .revo-alt-icon {
      $alt-icon-color: black;
      background-color: $alt-icon-color;
      position: absolute;
      left: 5px;
      top: 10px;
    }
  }

  .draggable-wrapper {
    &.hidden {
      display: none;
    }
  }

  .drag-position {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    z-index: 2;
    background: gray;
    pointer-events: none;
  }

  .drag-position-y {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 1px;
    z-index: 2;
    background: gray;
    pointer-events: none;
  }

  .drag-auto-scroll-y {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    width: 1px;
  }

  .clipboard {
    position: absolute;
    left: 0;
    top: 0;
  }

  revogr-scroll-virtual {
    position: relative;
    &.vertical,
    &.horizontal {
      z-index: 3;
    }
  }
}
