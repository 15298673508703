import Vue from 'vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export interface IDateRange {
  startDate?: Date;
  endDate?: Date;
}

interface IStyledDateRangePicker {
  dateRange: IDateRange;
  previousRange: IDateRange;
  locale: Record<string, any>;
  rerenderKey: number;
}

export default Vue.extend({
  name: 'styled-date-range-picker',

  components: { DateRangePicker },

  props: {
    cancelOnClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    currentDate: {
      type: Date,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isValidationError: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data(): IStyledDateRangePicker {
    return {
      dateRange: {},
      previousRange: {},
      locale: {
        firstDay: 1,
        daysOfWeek: [
          this.$i18n.t('generic.days.sunday'),
          this.$i18n.t('generic.days.monday'),
          this.$i18n.t('generic.days.tuesday'),
          this.$i18n.t('generic.days.wednesday'),
          this.$i18n.t('generic.days.thursday'),
          this.$i18n.t('generic.days.friday'),
          this.$i18n.t('generic.days.saturday'),
        ],
        monthNames: [
          this.$i18n.t('generic.months.january'),
          this.$i18n.t('generic.months.february'),
          this.$i18n.t('generic.months.march'),
          this.$i18n.t('generic.months.april'),
          this.$i18n.t('generic.months.may'),
          this.$i18n.t('generic.months.june'),
          this.$i18n.t('generic.months.july'),
          this.$i18n.t('generic.months.august'),
          this.$i18n.t('generic.months.september'),
          this.$i18n.t('generic.months.october'),
          this.$i18n.t('generic.months.november'),
          this.$i18n.t('generic.months.december'),
        ],
      },
      rerenderKey: 0,
    };
  },

  methods: {
    updateDateRange(value: IDateRange) {
      this.dateRange = value;
      this.$emit('date-updated', value.startDate);
    },

    setCurrentDateRange() {
      if (!this.currentDate) return;
      this.dateRange.startDate = new Date(this.currentDate);
      this.rerenderKey++;
    },

    handleOkButtonClick() {
      (this.$refs.picker as any).togglePicker(false);
      this.$emit('ok-button-clicked', this.dateRange.startDate);
    },

    handleToggle(isOpen: boolean) {
      if (!isOpen && this.cancelOnClose) {
        this.previousRange = this.dateRange;
      }

      if (!isOpen && !this.cancelOnClose) {
        this.$emit('toggle', this.dateRange.startDate);
      }
    },
  },

  watch: {
    currentDate() {
      this.setCurrentDateRange();
    },
  },
});
