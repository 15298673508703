var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-inline"},[_c('div',{staticClass:"input-wrapper"},[(_vm.showFilterDropdown)?_c('div',[_c('Treeselect',{staticClass:"treeselect-statepicker",attrs:{"limit":2,"multiple":true,"options":_vm.filterStates,"placeholder":_vm.$t('kksPicker.selectStates')},scopedSlots:_vm._u([{key:"value-label",fn:function({ node }){return _c('div',{},[_c('span',[_vm._v(_vm._s(node.raw.label))])])}}],null,false,4050779267),model:{value:(_vm.filterStatesSelected),callback:function ($$v) {_vm.filterStatesSelected=$$v},expression:"filterStatesSelected"}})],1):_vm._e(),_c('Treeselect',{key:_vm.treeselectKks,class:{
        'treeselect-margin': _vm.showFilterDropdown,
        busy: _vm.isBusy,
      },attrs:{"appendToBody":true,"createOption":true,"disabled":_vm.disabled || _vm.isBusy,"placeholder":_vm.presetPlaceholder || '',"open-on-click":true,"clearable":false,"flat":_vm.flat,"displayWarnings":_vm.displayWarnings,"multiple":_vm.multiSelect,"async":true,"no-options-text":_vm.$t('kksPicker.noSelectOptions'),"no-results-text":_vm.$t('kksPicker.searchPrompt'),"load-options":_vm.loadOptions,"default-options":true,"max-height":_vm.maxHeight,"value-format":_vm.multiSelect ? 'object' : null},on:{"select":_vm.emitSelect},scopedSlots:_vm._u([{key:"option-label",fn:function({ node }){return _c('div',{attrs:{"title":node.raw.label || node.raw.kks}},[_c('div',[_c('span',[_vm._v(_vm._s(node.raw.kks))]),(node.raw.label && node.raw.kks)?_c('span',{staticClass:"text-black-50"},[_vm._v("("+_vm._s(node.raw.label)+")")]):_c('span',{staticClass:"text-black-50"},[_vm._v(_vm._s(node.raw.label))]),(node.raw.state !== 'sap-pm')?_c('b-badge',{attrs:{"variant":_vm.getVarian(node.raw.state)}},[_vm._v(_vm._s(node.raw.state))]):_vm._e()],1)])}},{key:"value-label",fn:function({ node }){return _c('div',{class:{
          'value-label': true,
          valid: _vm.kksValidationList[node.raw.kks],
          invalid: !_vm.kksValidationList[node.raw.kks],
        }},[_c('span',[_vm._v(_vm._s(node.raw.kks))]),(node.raw.label && node.raw.kks)?_c('span',[_vm._v("("+_vm._s(node.raw.label)+") ")]):_c('span',[_vm._v(_vm._s(node.raw.label))]),(node.raw.state !== 'sap-pm')?_c('b-badge',{attrs:{"variant":_vm.getVarian(node.raw.state)}},[_vm._v(_vm._s(node.raw.state))]):_vm._e()],1)}}]),model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}}),(_vm.showCopyButton)?_c('b-button',{staticClass:"copy-button",attrs:{"title":_vm.$t('kksPicker.copyToClipboard'),"variant":"outline-primary"},on:{"click":function($event){return _vm.copyContext()}}},[_c('font-awesome-icon',{staticClass:"copy-icon",attrs:{"icon":_vm.iconCopy}})],1):_vm._e()],1),(_vm.showWildcardSelector && _vm.selectedMapped)?_c('b-form-checkbox',{model:{value:(_vm.wildcard),callback:function ($$v) {_vm.wildcard=$$v},expression:"wildcard"}},[_vm._v(" "+_vm._s(_vm.$t('kksPicker.allowWildcardSelector'))+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }