var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.digitalizationProjectSettings)?_c('div',{attrs:{"id":"digitalization-uploads-overview"}},[_c('title-panel',{attrs:{"buttonLabel":_vm.$t('digitalizationUploadsOverview.newUpload'),"count":_vm.tableRows,"displayButton":_vm.isNewUploadAllowed(),"onButtonClick":_vm.handleNewUploadButtonClick,"title":_vm.$t('digitalizationUploadsOverview.uploads')}}),_c('new-digitalization-upload-modal',{attrs:{"id":"new-upload-modal","digitalizationProjectId":_vm.digitalizationProjectSettings._id,"digitalizationProjectNumber":_vm.digitalizationProjectSettings.number,"digitalizationProjectTitle":_vm.digitalizationProjectSettings.title}}),_c('filterable-table',{attrs:{"busy":_vm.isLoadingUploads,"fields":_vm.tableFields,"filterConfiguration":_vm.tableFilterConfiguration,"filterGridConfiguration":_vm.tableFilterGridConfiguration,"isEmitOnClick":true,"items":_vm.uploads,"mode":_vm.tableMode,"noActionIcon":true,"rowClassFunction":_vm.getRowClass,"rows":_vm.tableRows,"explicitSelectOptions":_vm.selectOptions,"initialSortBy":_vm.sortBy,"initialSortDesc":_vm.sortDesc,"componentName":"digitalizationUploadsOverview"},on:{"action-event":_vm.goToDocumentsOverview,"filtering-changed":_vm.handleFilteringChange,"page-changed":_vm.handlePageNumberChange,"per-page-changed":_vm.handlePerPageChange,"sort-desc-changed":_vm.handleSortDescChanged,"sort-by-changed":_vm.handleSortByChanged},scopedSlots:_vm._u([{key:"cell(newAndUpdatedDocumentsCount)",fn:function(row){return [_c('div',{staticClass:"upload-content"},[_vm._v(" "+_vm._s(_vm.formatUploadContentsForDisplay(row.item))+" ")])]}},{key:"cell(documentsStatuses)",fn:function(row){return [_c('div',{staticClass:"document-statuses-list"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"metadata-inspection-count",attrs:{"title":_vm.$t(
              `digitalizationDocumentStatus.${_vm.documentStatus.METADATA_INSPECTION}`,
            )}},[_vm._v(_vm._s(row.item.documentsStatuses[_vm.documentStatus.METADATA_INSPECTION]))]),_vm._v(" | "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"formal-control-count",attrs:{"title":_vm.$t(
              `digitalizationDocumentStatus.${_vm.documentStatus.FORMAL_CONTROL}`,
            )}},[_vm._v(_vm._s(row.item.documentsStatuses[_vm.documentStatus.FORMAL_CONTROL]))]),_vm._v(" | "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"quality-check-count",attrs:{"title":_vm.$t(`digitalizationDocumentStatus.${_vm.documentStatus.QUALITY_CHECK}`)}},[_vm._v(_vm._s(row.item.documentsStatuses[_vm.documentStatus.QUALITY_CHECK]))]),_vm._v(" | "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"transfer-in-progres-count",attrs:{"title":_vm.$t(
              `digitalizationDocumentStatus.${_vm.documentStatus.RECORD_LIBRARY_TRANSFER_IN_PROGRESS}`,
            )}},[_vm._v(_vm._s(row.item.documentsStatuses[ _vm.documentStatus.RECORD_LIBRARY_TRANSFER_IN_PROGRESS ]))]),_c('span',{staticClass:"status-separator"}),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"transfer-successsfull-count",attrs:{"title":_vm.$t(
              `digitalizationDocumentStatus.${_vm.documentStatus.RECORD_LIBRARY_TRANSFER_SUCCESSFULL}`,
            )}},[_vm._v(_vm._s(row.item.documentsStatuses[ _vm.documentStatus.RECORD_LIBRARY_TRANSFER_SUCCESSFULL ]))]),_vm._v(" || "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"transfer-failed-count",attrs:{"title":_vm.$t(
              `digitalizationDocumentStatus.${_vm.documentStatus.RECORD_LIBRARY_TRANSFER_FAILED}`,
            )}},[_vm._v(_vm._s(row.item.documentsStatuses[ _vm.documentStatus.RECORD_LIBRARY_TRANSFER_FAILED ]))])])]}},{key:"cell(uploadDate)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.formatDateForDisplay(new Date(row.item.uploadDate)))+" ")]}},{key:"cell(action)",fn:function(row){return [(_vm.isUploadConfirmationReady(row.item))?[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"href":_vm.getUploadConfirmationFileLink(row.item),"target":"_blank","title":_vm.$t('digitalizationUploadsOverview.downloadUploadConfirmation')}},[_c('img',{attrs:{"src":_vm.fileCheckIcon}})])]:_vm._e()]}}],null,false,2742804845)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }