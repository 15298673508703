"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectorStatusEnum = void 0;
var InspectorStatusEnum;
(function (InspectorStatusEnum) {
    InspectorStatusEnum["waitingForFormalControl"] = "waitingForFormalControl";
    InspectorStatusEnum["waiting"] = "waiting";
    InspectorStatusEnum["accepted"] = "accepted";
    InspectorStatusEnum["acceptedWithRemarks"] = "acceptedWithRemarks";
    InspectorStatusEnum["rejected"] = "rejected";
    InspectorStatusEnum["timedOut"] = "timedOut";
})(InspectorStatusEnum || (exports.InspectorStatusEnum = InspectorStatusEnum = {}));
