var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"vf-header"},[_c('div',{staticClass:"vf-header__menu-container"},[_c('ul',{staticClass:"vf-header__menu-list vf-header__menu-level-1"},[_c('router-link',{staticClass:"vf-header__logo-desktop",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("../../assets/BEW_logo.svg")}}),_c('p',[_vm._v(_vm._s(_vm.$t('navBar.header')))])]),_c('div',{staticClass:"vf-header-item__container"},[_c('div',{class:{
            'vf-header__item vf-header__item-1': true,
            active: _vm.currentAppSection === 'projects',
          },attrs:{"id":"vf_header_level_1"}},[_c('router-link',{class:{
              active: _vm.currentAppSection === 'projects',
            },attrs:{"to":{ name: 'projectsList' },"id":"vf-header__link"}},[_vm._v(_vm._s(_vm.$t('navBar.projects')))])],1),(_vm.isUserRole(_vm.digitalizationProjectsAllowedRoles))?_c('div',{class:{
            'vf-header__item vf-header__item-1': true,
            active: _vm.currentAppSection === 'digitalizationProjects',
          },attrs:{"id":"vf_header_level_1"}},[_c('router-link',{class:{
              active: _vm.currentAppSection === 'digitalizationProjects',
            },attrs:{"to":{ name: 'digitalizationProjectsList' },"id":"vf-header__link"}},[_vm._v(_vm._s(_vm.$t('navBar.digitalizationProjects')))])],1):_vm._e(),(_vm.isUserRole(_vm.suppliersManagementAllowedRoles))?_c('div',{class:{
            'vf-header__item vf-header__item-1': true,
            active: _vm.currentAppSection === 'suppliers',
          },attrs:{"id":"vf_header_level_1"}},[_c('router-link',{class:{
              active: _vm.currentAppSection === 'suppliers',
            },attrs:{"to":{ name: 'suppliersList' },"id":"vf-header__link"}},[_vm._v(_vm._s(_vm.$t('navBar.suppliers')))])],1):_vm._e()]),_c('div',{staticClass:"vf-icon-div"},[(_vm.isUserRole(_vm.adminRole))?_c('div',{class:{
            'vf-header__item vf-header__item-1': true,
            active: _vm.currentAppSection === 'administration',
          },attrs:{"id":"vf_header_level_1"}},[_c('router-link',{class:{
              active: _vm.currentAppSection === 'administration',
            },attrs:{"to":{ name: 'administration' },"id":"vf-header__link"}},[_vm._v(_vm._s(_vm.$t('navBar.administration')))])],1):_vm._e(),_c('div',{attrs:{"id":"language-selection"}},[_c('img',{attrs:{"src":require("../../assets/icons/globe.svg")}}),_c('a',{class:_vm.$store.state.language === 'de' ? 'active' : null,attrs:{"id":"de","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLang('de')}}},[_vm._v(" de ")]),_c('a',{class:_vm.$store.state.language === 'en' ? 'active' : null,attrs:{"id":"en","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.setLang('en')}}},[_vm._v(" en ")])]),_c('b-icon',{attrs:{"icon":"person-fill","id":"current-user-icon"}}),(_vm.isHelpPageForRole())?_c('a',{attrs:{"href":_vm.getHelpPageLink(),"target":"”_blank”"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'circle-info']}})],1):_vm._e(),_c('user-info'),_c('b-icon',{attrs:{"tabindex":"0","icon":"upload","id":"upload-icon"},on:{"click":_vm.logout,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.logout.apply(null, arguments)}}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }