"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractIsoDateString = extractIsoDateString;
/**
 * Extracts an ISO 8601 date string from the input string.
 *
 * @param input - The string to search for an ISO 8601 date.
 * @returns The extracted ISO 8601 date string if found, otherwise `null`.
 *
 * @example
 * extractIsoDateString("The event is on 2025-01-06T11:00:00.000Z.");
 * // Returns: "2025-01-06T11:00:00.000Z"
 *
 * extractIsoDateString("No date here.");
 * // Returns: null
 */
function extractIsoDateString(input) {
    const isoDateRegex = /\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,3})?Z\b/;
    const match = input.match(isoDateRegex);
    return match ? match[0] : null;
}
