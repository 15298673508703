revogr-scroll-virtual {
  &[auto-hide] {
    position: absolute;
    z-index: 100;

    &.vertical {
      right: 0;
      top: 0;

      &:hover {
        min-width: 20px;
      }
    }

    &.horizontal {
      bottom: 0;
      left: 0;

      &:hover {
        min-height: 20px;
      }
    }
  }

  &.vertical {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    > div {
      width: 1px;
    }
  }

  &.horizontal {
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;

    > div {
      height: 1px;
    }
  }
}
