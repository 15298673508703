import { IProjectDuration } from 'types';
import Vue from 'vue';
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import { cloneDeep } from 'lodash';

export interface IDateRange {
  startDate?: Date;
  endDate?: Date;
}

interface IStyledDateRangePicker {
  dateRange: IDateRange;
  previousRange: IDateRange;
  locale: Record<string, any>;
}

export enum CalendarOpenDirection {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
  INLINE = 'inline',
}

export default Vue.extend({
  name: 'styled-date-range-picker',

  components: { DateRangePicker },

  props: {
    currentDateRange: {
      type: Object as () => IProjectDuration,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isSmall: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    calendarOpenDirection: {
      type: String as () => CalendarOpenDirection,
      required: false,
      default: CalendarOpenDirection.CENTER,
    },
  },

  data(): IStyledDateRangePicker {
    return {
      dateRange: {},
      previousRange: {},
      locale: {
        firstDay: 1,
        daysOfWeek: [
          this.$i18n.t('generic.days.sunday'),
          this.$i18n.t('generic.days.monday'),
          this.$i18n.t('generic.days.tuesday'),
          this.$i18n.t('generic.days.wednesday'),
          this.$i18n.t('generic.days.thursday'),
          this.$i18n.t('generic.days.friday'),
          this.$i18n.t('generic.days.saturday'),
        ],
        monthNames: [
          this.$i18n.t('generic.months.january'),
          this.$i18n.t('generic.months.february'),
          this.$i18n.t('generic.months.march'),
          this.$i18n.t('generic.months.april'),
          this.$i18n.t('generic.months.may'),
          this.$i18n.t('generic.months.june'),
          this.$i18n.t('generic.months.july'),
          this.$i18n.t('generic.months.august'),
          this.$i18n.t('generic.months.september'),
          this.$i18n.t('generic.months.october'),
          this.$i18n.t('generic.months.november'),
          this.$i18n.t('generic.months.december'),
        ],
      },
    };
  },

  methods: {
    updateDateRange(value: IDateRange) {
      this.dateRange = value;
      this.$emit('date-range-updated', value);
    },

    handleCancel() {
      (this.$refs.picker as any).togglePicker(false);
      this.dateRange = this.previousRange;
      this.$emit('date-range-updated', this.dateRange);
    },

    setCurrentRange() {
      if (
        !this.currentDateRange.durationStart ||
        !this.currentDateRange.durationEnd
      ) {
        this.resetPicker();
        return;
      }

      const startDate = new Date(this.currentDateRange.durationStart);
      this.dateRange.startDate = startDate;

      const endDate = new Date(this.currentDateRange.durationEnd);
      this.dateRange.endDate = endDate;

      this.dateRange = cloneDeep(this.dateRange);
    },

    resetPicker() {
      this.dateRange = {};
    },

    isPlaceholderActive() {
      return !this.dateRange.startDate && this.placeholder;
    },
  },

  watch: {
    dateRange(value: IDateRange) {
      /**
       * Dates returned by the picker have time set to 12:00. It needs to be
       * set to midnight.
       */
      const startDate = value.startDate!;
      if (startDate) {
        startDate.setHours(0, 0, 0, 0);
      }
      const endDate = value.endDate!;
      if (endDate) {
        endDate.setHours(0, 0, 0, 0);
      }
    },

    currentDateRange() {
      this.setCurrentRange();
    },
  },
});
