"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AUTOMATICALLY_SET_CONTENT_TYPE_FIELDS = void 0;
exports.AUTOMATICALLY_SET_CONTENT_TYPE_FIELDS = [
    'CreatedBy',
    'Filepath',
    'ObjectID',
    'OriginalFilename',
    'DDP_Projekt',
    'ResponsibleArea',
    'StationNumber',
    'DocStatus',
];
