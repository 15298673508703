"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isSystemPath = isSystemPath;
/**
 * Determines if a given file path corresponds to a system-generated file.
 * System-generated files can include hidden files and OS-specific metadata
 * files such as macOS's `.DS_Store` and Windows' `Thumbs.db`. This function
 * checks against known patterns of these system files.
 *
 * @param {string} path - The file path to check.
 * @returns {boolean} True if the path is a system-generated file, false
 * otherwise.
 */
function isSystemPath(path) {
    const systemPatterns = [
        /^__MACOSX\//, // macOS metadata
        /^\./, // Hidden Unix files (starts with dot)
        /\.DS_Store$/, // Specific macOS system file
        /^Thumbs.db$/, // Windows image cache
        /^desktop.ini$/, // Windows folder settings
    ];
    return systemPatterns.some((pattern) => pattern.test(path));
}
