"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupBy = groupBy;
/**
 * Generic function to group an array of objects based on a custom key
 * generator.
 *
 * @param array - The array of objects to group.
 * @param keyGenerator - A function that generates a unique key for each
 * object.
 * @returns An object grouped by the generated keys.
 */
function groupBy(array, keyGenerator) {
    return array.reduce((groups, item) => {
        const key = keyGenerator(item);
        if (!groups[key]) {
            groups[key] = [];
        }
        groups[key].push(item);
        return groups;
    }, {});
}
