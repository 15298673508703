"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckbotStatusEnum = void 0;
var CheckbotStatusEnum;
(function (CheckbotStatusEnum) {
    CheckbotStatusEnum["ok"] = "OK";
    CheckbotStatusEnum["critical"] = "Critical";
    CheckbotStatusEnum["error"] = "Error";
    CheckbotStatusEnum["warning"] = "Warning";
    CheckbotStatusEnum["info"] = "Info";
    // internally used checkbot statutes
    CheckbotStatusEnum["timedOut"] = "timed out";
    CheckbotStatusEnum["waiting"] = "waiting";
})(CheckbotStatusEnum || (exports.CheckbotStatusEnum = CheckbotStatusEnum = {}));
