var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"kksList",staticClass:"check-bot-established-kks-list-wrapper"},[_c('ul',{class:{
      'check-bot-established-kks-list': true,
      busy: _vm.isValidatingKks,
    }},_vm._l((_vm.kksList),function(kks){return _c('li',{key:kks,class:{
        'valid-check-bot-established-kks': _vm.kksValidationList?.[kks],
        'invalid-check-bot-established-kks':
          _vm.kksValidationList?.[kks] === false,
      },on:{"click":function($event){return _vm.handleKksClick(kks)}}},[_vm._v(" "+_vm._s(kks)+" "),_c('font-awesome-icon',{attrs:{"icon":['fas', 'check']}})],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }