import endpointsList from '@/helpers/endpointsList';
import { router } from '@/vue';
import axios from 'axios';
import Multiselect from 'vue-multiselect';

export default {
  name: 'docu-manager-detail-view',
  components: { Multiselect },
  props: ['docuManagerOid'],
  data() {
    return {
      docuManagerData: false,
      projects: false,

      tableCurrentPage: 1,
      tableFields: [
        {
          key: 'projectTitle',
          label: this.$i18n.t('projectForm.projectTitle'),
          sortable: true,
        },
        {
          key: 'projectNumber',
          label: this.$i18n.t('projectForm.projectId'),
          sortable: true,
        },
        {
          key: 'projectStatus',
          label: this.$i18n.t('projectForm.projectStatus'),
          sortable: true,
        },
      ],

      selectedDocuManager: null,
      docuManagers: [],
      isLoadingDocuManagers: false,
      error: false,
    };
  },
  methods: {
    getDocuManagerData() {
      axios
        .get(
          endpointsList.docuManagers.getDocuManagerByOid(this.docuManagerOid),
        )
        .then((response) => (this.docuManagerData = response.data))
        .catch(() => {
          this.createToast(
            this.$t('errors.error'),
            this.$t('errors.error'),
            'danger',
          );
        });
    },
    getProjectsForDocuManager() {
      axios
        .get(
          endpointsList.projects.getProjectsForDocuManager(this.docuManagerOid),
        )
        .then((response) => {
          this.projects = response.data;
        })
        .catch(() => {
          this.createToast(
            this.$t('errors.error'),
            this.$t('errors.error'),
            'danger',
          );
        });
    },
    getAllDocuManagers() {
      this.isLoadingDocuManagers = true;
      axios
        .get(endpointsList.docuManagers.getAllDocuManagers)
        .then((response) => {
          const docuManagers = response.data;
          docuManagers.splice(
            docuManagers.findIndex(
              (manager) => manager.oid === this.docuManagerOid,
            ),
            1,
          );
          this.docuManagers = docuManagers;
          this.isLoadingDocuManagers = false;
        })
        .catch((error) => {
          console.log(error);
          this.createToast(
            this.$t('errors.error'),
            this.$t('errors.error'),
            'danger',
          );
        });
    },
    nameAndEmail({ firstName, lastName, email }) {
      if (email) {
        return `${firstName} ${lastName} — [${email}]`;
      } else {
        return `${firstName} ${lastName}`;
      }
    },
    handleModalOK(bvModalEvent) {
      if (!this.selectedDocuManager) {
        bvModalEvent.preventDefault();
        this.error = this.$t('docuManagerDetailView.noDocuManagerSelected');
        return;
      }
      axios
        .patch(
          endpointsList.projects.exchangeDocuManagersInProject(
            this.docuManagerOid,
            this.selectedDocuManager.oid,
          ),
        )
        .then(() => {
          this.error = false;
          this.createToast(
            this.$t('docuManagerDetailView.docuManagerExchanged'),
            this.$t('docuManagerDetailView.docuManagerExchanged'),
            'success',
          );
        })
        .catch((error) => {
          console.log(error);
          this.createToast(
            this.$t('errors.error'),
            this.$t('errors.error'),
            'danger',
          );
        });
    },
  },
  computed: {
    rows() {
      return this.projects.length;
    },
  },
  mounted() {
    if (!this.docuManagerOid) {
      router.push({ name: 'addDocuManager' });
    }
    this.getDocuManagerData();
    this.getProjectsForDocuManager();

    this.$root.$on('bv::modal::show', () => {
      this.getAllDocuManagers();
    });
  },
  watch: {
    selectedDocuManager() {
      this.error = false;
    },
  },
};
