import endpointsList from '../../helpers/endpointsList';
import { ToastMixin } from '../../mixins/globalUtils';
import axios, { AxiosResponse } from 'axios';
import Vue from 'vue';

interface IInspectionTimeConfiguration {
  timeValue: string | null;
  isSaving: boolean;
  createToast?: ToastMixin;
}

export default Vue.extend({
  name: 'inspection-time-configuration',
  components: {},
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data(): IInspectionTimeConfiguration {
    return {
      timeValue: null,
      isSaving: false,
    };
  },

  methods: {
    async setInspectionDueTime() {
      try {
        await axios.patch(
          endpointsList.projects.setInspectionDueTime(this.id),
          {
            inspectionDueTime: Number(this.timeValue),
          },
        );

        this.createToast!(
          this.$t('projectConfiguration.inspectionTimeSet'),
          this.$t('projectConfiguration.inspectionTimeSet'),
          'success',
        );
      } catch (err) {
        console.log(err);

        this.createToast!(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }
    },

    async getInspectionDueTime() {
      try {
        const response: AxiosResponse<number> = await axios.get(
          endpointsList.projects.getInspectionDueTime(this.id),
        );

        this.timeValue = response.data.toString();
      } catch (err) {
        console.log(err);

        this.createToast!(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }
    },
  },

  mounted() {
    this.getInspectionDueTime();
  },
});
