import { render, staticRenderFns } from "./DocumentMetadataFreeTextInput.vue?vue&type=template&id=40d69296&scoped=true"
import script from "./DocumentMetadataFreeTextInput.script.ts?vue&type=script&lang=ts&external"
export * from "./DocumentMetadataFreeTextInput.script.ts?vue&type=script&lang=ts&external"
import style0 from "./DocumentMetadataFreeTextInput.style.scss?vue&type=style&index=0&id=40d69296&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d69296",
  null
  
)

export default component.exports