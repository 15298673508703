import { render, staticRenderFns } from "./MetadataValidationModal.vue?vue&type=template&id=40267413&scoped=true"
import script from "./MetadataValidationModal.script.ts?vue&type=script&lang=ts&external"
export * from "./MetadataValidationModal.script.ts?vue&type=script&lang=ts&external"
import style0 from "./MetadataValidationModal.styles.scss?vue&type=style&index=0&id=40267413&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40267413",
  null
  
)

export default component.exports