import { endpointsList } from '@/helpers';
import axios, { AxiosResponse } from 'axios';
import {
  ICreatePatchNotesRequestDto,
  IGetPatchNotesResponseDto,
  IGetPatchNotesVersionsResponseDto,
  ISerializedPatchNotesResponseDto,
  PatchNoteVersion,
} from 'types';

export type VersionObject = {
  major: number;
  minor: number;
  patch: number;
};

export class PatchNotesService {
  public static async getCurrentAppVersion(): Promise<string> {
    const response = await axios.get<string>(
      endpointsList.patchNotes.getCurrentVersion(),
    );
    return response.data;
  }

  public static async getAllPatchNoteVersions(): Promise<PatchNoteVersion[]> {
    const response = await axios.get<IGetPatchNotesVersionsResponseDto>(
      endpointsList.patchNotes.getAllPatchNoteVersions(),
    );

    return response.data.versions.map(this.deserializePatchNote);
  }

  public static async getPatchNoteById(
    id: string,
  ): Promise<IGetPatchNotesResponseDto> {
    const response = await axios.get<ISerializedPatchNotesResponseDto>(
      endpointsList.patchNotes.getPatchNoteById(id),
    );

    return this.deserializePatchNote(response.data);
  }

  private static deserializePatchNote<T extends { releaseDate: string }>(
    patchNote: T,
  ): T & { releaseDate: Date } {
    return {
      ...patchNote,
      releaseDate: new Date(patchNote.releaseDate),
    };
  }

  public static createPatchNote(
    version: string,
    releaseDate: Date,
    content: string,
  ): Promise<void> {
    return axios.post<void, void, ICreatePatchNotesRequestDto>(
      endpointsList.patchNotes.createPatchNote(),
      {
        version,
        releaseDate,
        content,
      },
    );
  }

  public static async updatePatchNote(
    id: string,
    version: string,
    releaseDate: Date,
    content: string,
  ): Promise<IGetPatchNotesResponseDto> {
    const response = await axios.put<
      ISerializedPatchNotesResponseDto,
      AxiosResponse<ISerializedPatchNotesResponseDto>,
      ICreatePatchNotesRequestDto
    >(endpointsList.patchNotes.updatePatchNoteById(id), {
      version,
      releaseDate,
      content,
    });

    return this.deserializePatchNote(response.data);
  }

  public static async getPatchNoteForCurrentVersion(): Promise<IGetPatchNotesResponseDto> {
    const response = await axios.get<ISerializedPatchNotesResponseDto>(
      endpointsList.patchNotes.getCurrentPatchNote(),
    );
    return this.deserializePatchNote(response.data);
  }

  public static async getPatchNoteForVersion(
    version: string,
  ): Promise<IGetPatchNotesResponseDto> {
    const versions = await this.getAllPatchNoteVersions();
    const versionId = versions.find((v) => v.version === version)?.id;
    return this.getPatchNoteById(versionId!);
  }

  public static splitVersionString(version: string): {
    major: number;
    minor: number;
    patch: number;
  } {
    const splitVersion = version.split('.');
    return {
      major: parseInt(splitVersion[0], 10),
      minor: parseInt(splitVersion[1], 10),
      patch: parseInt(splitVersion[2], 10),
    };
  }

  public static constructVersionString(version: VersionObject): string {
    return `${version.major}.${version.minor}.${version.patch}`;
  }
}
