import { ISingleUploadRecord } from '@/components/SingleUploadForm/SingleUploadForm.script';
import endpointsList from '@/helpers/endpointsList';
import { VATTENFALL_SUPPLIER_NAME } from '@/interfaces/constants';
import { IUploadRecord } from '@/views/DocumentUpload/DocumentUpload.script';
import axios from 'axios';
import {
  IDigitalizationDocumentMetadataDto,
  IDigitalizationUploadDto,
  IDocumentMetadata,
  Serialized,
} from 'types';
import DocumentUploadHelperAbstract from './DocumentUploadHelperAbstract';

export default class DigitalizationUploadHelper extends DocumentUploadHelperAbstract {
  constructor(
    digitalizationProjectName: string,
    digitalizationProjectId: string,
    digitalizationProjectNumber: string,
    referenceNumber: string,
    contentTypeId: string,
    uploadRecords: IUploadRecord[] | ISingleUploadRecord[],
    private readonly isCheckBotInspectionDisabled: boolean,
  ) {
    super(
      digitalizationProjectName,
      digitalizationProjectId,
      digitalizationProjectNumber,
      referenceNumber,
      contentTypeId,
      uploadRecords,
    );
  }

  async createSharepointFolder() {
    await axios.post(
      endpointsList.sharePointIntegration.prepareUploadDirectories(
        this.projectNumber,
        VATTENFALL_SUPPLIER_NAME,
      ),
    );
  }

  async saveFilesInSharepoint() {
    for (const record of this.uploadRecords) {
      // Skip records without files (just metadata changes)
      if (!record.file || record.isEmptyFile) {
        continue;
      }

      this.emit('uploadStatusChange', {
        id: record.metadata!.documentId,
        status: 'uploading',
      });

      const formData = new FormData();
      formData.append('file', record.file);
      formData.append('referenceNumber', this.referenceNumber);
      formData.append('documentId', record.metadata!.documentId);
      formData.append('originalFileName', record.file.name);
      formData.append('contentTypeId', this.contentTypeId);

      await axios.post(
        endpointsList.digitalizationUploads.uploadDigitalizationDocument(
          this.projectId,
        ),
        formData,
      );

      this.emit('uploadStatusChange', {
        id: record.metadata!.documentId,
        status: 'uploaded',
      });
    }
    this.isUploadToSPFinished = true;
  }

  async postDocumentsUploadsToDB() {
    if (!this.isUploadToSPFinished) {
      return;
    }

    const files: IDigitalizationDocumentMetadataDto[] = this.uploadRecords.map(
      (record): IDigitalizationDocumentMetadataDto => {
        const file: {
          fileMetadata: IDocumentMetadata;
          rawMetadata?: Record<string, string>;
          originalFileName?: string;
        } = {
          fileMetadata: record.metadata!,
        };

        if (record.file && !record.isEmptyFile) {
          file.originalFileName = record.file.name;
        }

        if ((record as IUploadRecord).rawMetadata) {
          file.rawMetadata = (record as IUploadRecord).rawMetadata;
        }

        return file;
      },
    );

    const documentsUpload: Serialized<IDigitalizationUploadDto> = {
      referenceNumber: this.referenceNumber,
      contentTypeId: this.contentTypeId,
      digitalizationProjectId: this.projectId,
      documents: files,
      isCheckBotInspectionDisabled: this.isCheckBotInspectionDisabled,
    };

    await axios.post(
      endpointsList.digitalizationUploads.postUploadMetadata,
      documentsUpload,
    );

    this.isUploadToDBFinished = true;
  }
}
