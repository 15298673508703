import { render, staticRenderFns } from "./DigitalizationProjectInformations.vue?vue&type=template&id=5d8bd862&scoped=true"
import script from "./DigitalizationProjectInformations.script.ts?vue&type=script&lang=ts&external"
export * from "./DigitalizationProjectInformations.script.ts?vue&type=script&lang=ts&external"
import style0 from "./DigitalizationProjectInformations.style.scss?vue&type=style&index=0&id=5d8bd862&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8bd862",
  null
  
)

export default component.exports