import { render, staticRenderFns } from "./DocumentUpload.vue?vue&type=template&id=27cd9d6f&scoped=true"
import script from "./DocumentUpload.script.ts?vue&type=script&lang=js&external"
export * from "./DocumentUpload.script.ts?vue&type=script&lang=js&external"
import style0 from "./DocumentUpload.scss?vue&type=style&index=0&id=27cd9d6f&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27cd9d6f",
  null
  
)

export default component.exports