import {
  FINAL_RECORD_LIBRARY_TRANSFER_STATUSES,
  FormalControlStatus,
  IFile,
  INSPECTION_DONE_STATUSES,
  WAITING_FORMAL_CONTROL_STATUSES,
} from 'types';

export enum DocuProcessingStep {
  UPLOAD,
  FORMAL_INSPECTION,
  CONTENT_INSPECTION,
  RECORD_LIBRARY_TRANSFER,
  RECORD_LIBRARY_TRANSFER_DONE,
}

export function getCurrentDocumentProcessingStep(
  document: IFile,
): DocuProcessingStep {
  if (
    FINAL_RECORD_LIBRARY_TRANSFER_STATUSES.includes(
      document.recordLibraryTransferStatus,
    )
  ) {
    return DocuProcessingStep.RECORD_LIBRARY_TRANSFER_DONE;
  }

  if (INSPECTION_DONE_STATUSES.includes(document.inspectorStatus)) {
    return DocuProcessingStep.RECORD_LIBRARY_TRANSFER;
  }

  if (
    document.formalControlStatus &&
    !WAITING_FORMAL_CONTROL_STATUSES.includes(document.formalControlStatus)
  ) {
    return DocuProcessingStep.CONTENT_INSPECTION;
  }

  if (document.formalControlStatus === FormalControlStatus.WAITING) {
    return DocuProcessingStep.FORMAL_INSPECTION;
  }

  return DocuProcessingStep.UPLOAD;
}
