"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QualityCheckStatus = void 0;
var QualityCheckStatus;
(function (QualityCheckStatus) {
    QualityCheckStatus["WAITING_FOR_FORMAL_CONTROL"] = "waiting_for_formal_control";
    QualityCheckStatus["WAITING"] = "waiting";
    QualityCheckStatus["ACCEPTED"] = "accepted";
    QualityCheckStatus["REJECTED"] = "rejected";
})(QualityCheckStatus || (exports.QualityCheckStatus = QualityCheckStatus = {}));
