<template>
  <div>
    <styled-modal
      :closeOnOkClick="true"
      :lazy="true"
      :modalId="modalId"
      :noCancelButton="true"
      :size="modalSizes.xl"
      :title="kksCode"
      @hide="resetModal"
      @show="fetchVgbmAssignments"
    >
      <template slot="modal-content">
        <kks-vgbm-form
          :pendingVgbmClasses="pendingVgbmClasses"
          :vgbmAssignments="vgbmAssignments"
          @assignmentChanged="handleAssignmentChanged"
        />
      </template>
    </styled-modal>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import KksVgbmForm, {
    AssignmentUpdateEvent,
    FilledVgbmAssignment,
  } from '@/components/KksVgbmForm/KksVgbmForm.vue';
  import StyledModal from '../StyledModal/StyledModal.vue';
  import { VgbmService } from '@/services';
  import {
    IblImportWarningType,
    IGetVgbmAssignmentResponseDto,
    IUpdateVgbmAssignmentResponseDto,
  } from 'types';

  export default Vue.extend({
    name: 'kks-vgbm-modal',
    components: {
      KksVgbmForm,
      StyledModal,
    },
    props: {
      kksId: {
        type: String,
        required: true,
      },
    },
    data(): {
      isFetchingVgbmAssignments: boolean;
      kksCode: string | null;
      pendingVgbmClasses: string[];
      vgbmAssignments: IGetVgbmAssignmentResponseDto[];
    } {
      return {
        isFetchingVgbmAssignments: false,
        kksCode: null,
        pendingVgbmClasses: [],
        vgbmAssignments: [],
      };
    },
    computed: {
      modalId(): string {
        return 'kks-vgbm-modal';
      },
    },
    methods: {
      async fetchVgbmAssignments(): Promise<void> {
        try {
          this.isFetchingVgbmAssignments = true;
          const { kks, vgbmAssignments } =
            await VgbmService.getVgbmAssignmentsForKks(this.kksId);
          this.kksCode = kks;
          this.vgbmAssignments = vgbmAssignments;
        } catch {
          this.createErrorToast(
            this.$tt('kksVgbmModal.errors.errorGettingAssignments'),
          );
        } finally {
          this.isFetchingVgbmAssignments = false;
        }
      },
      async updateAssignment(assignment: FilledVgbmAssignment): Promise<void> {
        try {
          this.pendingVgbmClasses = [
            ...this.pendingVgbmClasses,
            assignment.class,
          ];
          const updatedAssignment =
            await VgbmService.updateVgbmAssignmentsForKks(
              this.kksId,
              assignment,
            );
          const index = VgbmService.findAssignmentIndexByClass(
            this.vgbmAssignments,
            assignment.class,
          );
          if (index === -1) return;
          this.$set(this.vgbmAssignments, index, {
            ...assignment,
            value: updatedAssignment.value,
            unit: updatedAssignment.unit,
            quality: updatedAssignment.quality,
          });
          this.displayWarningIfPresent(updatedAssignment);
        } catch {
          this.createErrorToast(
            this.$tt('kksVgbmModal.errors.errorSavingAssignment'),
          );
        } finally {
          this.pendingVgbmClasses = this.pendingVgbmClasses.filter(
            (c) => c !== assignment.class,
          );
        }
      },
      async handleAssignmentChanged({
        assignment,
        isValid,
      }: AssignmentUpdateEvent) {
        const index = VgbmService.findAssignmentIndexByClass(
          this.vgbmAssignments,
          assignment.class,
        );
        if (index === -1) return;
        this.$set(this.vgbmAssignments, index, assignment);

        if (isValid) {
          await this.updateAssignment(assignment);
        }
      },
      displayWarningIfPresent(
        updateResponse: IUpdateVgbmAssignmentResponseDto,
      ) {
        if (updateResponse.warning) {
          if (
            updateResponse.warning.type === IblImportWarningType.INVALID_UNIT
          ) {
            this.createWarningToast(
              this.$tt('kksVgbmModal.warnings.invalidUnit', {
                unit: updateResponse.warning.invalidUnit,
              }),
            );
          } else if (
            updateResponse.warning.type === IblImportWarningType.INVALID_VALUE
          ) {
            this.createWarningToast(
              this.$tt('kksVgbmModal.warnings.invalidValue', {
                value: updateResponse.warning.invalidValue,
              }),
            );
          }
        }
      },
      resetModal(): void {
        this.isFetchingVgbmAssignments = false;
        this.kksCode = null;
        this.pendingVgbmClasses = [];
        this.vgbmAssignments = [];
      },
    },
  });
</script>
