import { endpointsList } from '@/helpers';
import axios from 'axios';
import Vue from 'vue';

export default Vue.extend({
  methods: {
    async goToProjectRoom(projectTitle: string): Promise<void> {
      const response = await axios.get(
        endpointsList.redirections.getDaisyProjectRoomUrl(projectTitle),
      );

      window.open(response.data, '_blank');
    },
  },
});
