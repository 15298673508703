import {
  ContentTypeFieldType,
  IContentTypeField,
  UniversalMappingField,
} from 'types';

const universalMappingFields: IContentTypeField[] = [
  {
    field: UniversalMappingField.DOCUMENT_ID,
    fieldDisplayName: UniversalMappingField.DOCUMENT_ID,
    dataType: ContentTypeFieldType.TEXT,
    required: true,
  },
  {
    field: UniversalMappingField.REVISION,
    fieldDisplayName: UniversalMappingField.REVISION,
    dataType: ContentTypeFieldType.TEXT,
    required: true,
  },
  {
    field: UniversalMappingField.FILEPATH,
    fieldDisplayName: UniversalMappingField.FILEPATH,
    dataType: ContentTypeFieldType.TEXT,
    required: true,
  },
];

export default universalMappingFields;
