import FilterableTable from '@/components/FilterableTable/FilterableTable.vue';
import TitlePanel from '@/components/TitlePanel/TitlePanel.vue';
import { ITableField } from '@/frontendInterfaces';
import endpointsList from '@/helpers/endpointsList';
import { METADATA_GRID_DOCUMENT_IDS_LOCAL_STORAGE_KEY } from '@/interfaces/constants';
import { GlobalUtils, ToastMixin } from '@/mixins/globalUtils';
import { MetadataGridEditorMode } from '@/views/MetadataGridEditor/MetadataGridEditor.script';
import { router } from '@/vue';
import axios, { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import {
  CheckBotInferenceStatus,
  DocumentDetailViewMode,
  DocumentStatus,
  IDigitalizationDocumentSummaryDto,
  IPageDto,
  Order,
  Role,
  SharePointUploadStatus,
} from 'types';
import { isDefined } from 'utils';
import Vue from 'vue';
import {
  FilterType,
  IFilterConfiguration,
  IFiltering,
  Mode,
  SelectOptions,
} from '../FilterableTable/FilterableTable.script';
import filterableTableHelpersMixin, {
  IFilterableTableHelpersMixin,
} from '../FilterableTable/filterableTableHelpersMixin';
import { IDateRange } from '../StyledDateRangePicker/StyledDateRangePicker.script';

interface IDigitalizationDocumentsOverview {
  documents?: IDigitalizationDocumentSummaryDto[];
  filtering: Record<string, string | Date | IDateRange>;
  initialSortBy?: keyof IDigitalizationDocumentSummaryDto;
  initialSortDesc?: boolean;
  isLoadingDocuments: boolean;
  page: number;
  perPage: number;
  prefiltering?: IFiltering;
  selectedDocuments: { [documentId: string]: boolean };
  selectOptions?: SelectOptions<IDigitalizationDocumentSummaryDto>;
  sortBy: keyof IDigitalizationDocumentSummaryDto;
  sortDesc: boolean;
  tableFilterConfiguration: IFilterConfiguration;
  tableFilterGridConfiguration: string[];
  tableMode: Mode.OUTSOURCED;
  tableRows?: number;
}

export type DocumentObjectIdsAndUploadIds = {
  documentObjectId: string;
  uploadId: string;
};

export type DigitalizationDocumentsOverviewTableConfig = {
  filtering: Record<string, string | Date | IDateRange>;
  sorting?: {
    sortBy: keyof IDigitalizationDocumentSummaryDto;
    sortDesc: boolean;
  };
};

export default Vue.extend({
  name: 'digitalization-documents-overview',
  mixins: [filterableTableHelpersMixin],
  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
  },
  components: { FilterableTable, TitlePanel },
  data(): IDigitalizationDocumentsOverview {
    return {
      filtering: {},
      initialSortBy: undefined,
      initialSortDesc: undefined,
      isLoadingDocuments: false,
      documents: undefined,
      prefiltering: undefined,
      selectedDocuments: {},
      tableFilterConfiguration: {
        documentId: {
          type: FilterType.INPUT,
          width: '100px',
          column: 2,
          simpleLabel: true,
        },
        title: {
          type: FilterType.INPUT,
          width: '200px',
          column: 3,
          simpleLabel: true,
        },
        referenceNumber: {
          type: FilterType.INPUT,
          width: '100px',
          column: 4,
          simpleLabel: true,
        },
        revision: {
          type: FilterType.INPUT,
          width: '100px',
          column: 5,
          translateOptions: true,
          simpleLabel: true,
        },
        uploadDate: {
          type: FilterType.DATE_RANGE_PICKER,
          width: '170px',
          column: 6,
          simpleLabel: true,
        },
        coworker: {
          type: FilterType.INPUT,
          width: '120px',
          column: 7,
          translateOptions: true,
          simpleLabel: true,
        },
        checkBotInferenceStatus: {
          type: FilterType.SELECT,
          width: '120px',
          column: 8,
          translateOptions: true,
        },
        documentStatus: {
          type: FilterType.SELECT,
          width: '120px',
          column: 9,
          translateOptions: true,
        },
      },
      tableFilterGridConfiguration: [
        '3%',
        '10%',
        '20%',
        '10%',
        '7%',
        '11%',
        '14%',
        '12%',
        '13%',
      ],
      tableMode: Mode.OUTSOURCED,
      tableRows: 0,
      page: 1,
      perPage: 10,
      selectOptions: undefined,
      sortBy: 'uploadDate',
      sortDesc: false,
    };
  },

  computed: {
    tableFields(): ITableField<IDigitalizationDocumentSummaryDto>[] {
      return [
        {
          key: 'select',
          label: '',
          sortable: false,
          tdClass: ['w-3', 'align-middle'],
          thClass: ['w-3', 'align-middle', 'break-all-words'],
        },
        {
          key: 'documentId',
          label: this.$t('digitalizationDocumentsOverview.documentId'),
          sortable: true,
          tdClass: ['w-10', 'align-middle'],
          thClass: ['w-10', 'align-middle', 'break-all-words'],
        },
        {
          key: 'title',
          label: this.$t('digitalizationDocumentsOverview.title'),
          sortable: true,
          tdClass: ['w-20', 'align-middle'],
          thClass: ['w-20', 'align-middle', 'break-all-words'],
        },
        {
          key: 'referenceNumber',
          label: this.$t('digitalizationDocumentsOverview.referenceNumber'),
          sortable: true,
          tdClass: ['w-10', 'align-middle'],
          thClass: ['w-10', 'align-middle', 'break-all-words'],
        },
        {
          key: 'revision',
          label: this.$t('digitalizationDocumentsOverview.revision'),
          sortable: true,
          tdClass: ['w-7', 'align-middle'],
          thClass: ['w-7', 'align-middle', 'break-all-words'],
        },
        {
          key: 'uploadDate',
          label: this.$t('digitalizationDocumentsOverview.uploadDate'),
          sortable: true,
          tdClass: ['w-11', 'align-middle'],
          thClass: ['w-11', 'align-middle', 'break-all-words'],
        },
        {
          key: 'coworker',
          label: this.$t('digitalizationDocumentsOverview.coworker'),
          sortable: true,
          tdClass: ['w-14', 'align-middle'],
          thClass: ['w-14', 'align-middle', 'break-all-words'],
        },
        {
          key: 'checkBotInferenceStatus',
          label: this.$t(
            'digitalizationDocumentsOverview.checkBotInferenceStatus',
          ),
          sortable: true,
          tdClass: ['w-12', 'align-middle'],
          thClass: ['w-12', 'align-middle', 'break-all-words'],
        },
        {
          key: 'documentStatus',
          label: this.$t('digitalizationDocumentsOverview.documentStatus'),
          sortable: false,
          tdClass: ['w-13', 'align-middle'],
          thClass: ['w-13', 'align-middle', 'break-all-words'],
        },
      ];
    },
  },

  methods: {
    async getDocuments(): Promise<void> {
      try {
        /** Deselecting all documents when new documents are fetched */
        this.toggleAllDocumentsSelection(false);
        this.isLoadingDocuments = true;

        const filtering = Object.fromEntries(
          Object.entries(this.filtering).filter(
            ([_key, value]) => Boolean(value) && _key !== 'uploadDate',
          ),
        );

        const response: AxiosResponse<
          IPageDto<IDigitalizationDocumentSummaryDto>
        > = await axios.get(
          endpointsList.digitalizationUploads.getPaginatedDigitalizationDocumentsSummary(
            {
              digitalizationProjectId: this.digitalizationProjectId as any,
              order: this.sortDesc ? Order.DESC : Order.ASC,
              page: this.page,
              sortBy: this.sortBy,
              take: this.perPage,
              ...filtering,
            },
          ),
        );

        const pageDto = response.data;

        this.documents = pageDto.data;
        this.tableRows = pageDto.meta.itemCount;
      } catch {
        (this.createToast as ToastMixin)!(
          this.$t('errors.error'),
          this.$t(
            'digitalizationDocumentsOverview.errors.errorGettingDocuments.long',
          ),
          'danger',
        );
      } finally {
        this.isLoadingDocuments = false;
      }
    },

    debouncedGetDocuments: debounce(function (this: any) {
      this.getDocuments();
    }, 200),

    handlePerPageChange(perPage: number): void {
      this.perPage = perPage;
      this.getDocuments();
    },

    handlePageNumberChange(pageNumber: number): void {
      this.page = pageNumber;
      this.getDocuments();
    },

    handleFilteringChange(filtering: Record<string, string>): void {
      this.filtering = filtering;
      if (
        (filtering.uploadDate as IDateRange)?.startDate &&
        (filtering.uploadDate as IDateRange)?.endDate
      ) {
        this.filtering.uploadDateFrom = (
          filtering.uploadDate as IDateRange
        ).startDate!;
        this.filtering.uploadDateTo = (
          filtering.uploadDate as IDateRange
        ).endDate!;
      } else {
        this.filtering.uploadDateFrom = '';
        this.filtering.uploadDateTo = '';
      }
      this.debouncedGetDocuments();
    },

    handleSortDescChanged(sortDesc: boolean): void {
      this.sortDesc = sortDesc;
      this.getDocuments();
    },

    handleSortByChanged(sortBy: keyof IDigitalizationDocumentSummaryDto): void {
      if (!sortBy) return;
      this.sortBy = sortBy;
      this.getDocuments();
    },

    getSelectOptions(): void {
      const checkBotInferenceStatusOptions = (
        this as unknown as IFilterableTableHelpersMixin
      ).getSelectOptionsFromEnum(
        CheckBotInferenceStatus,
        'checkBotInferenceStatus',
      );
      const documentStatusOptions = (
        this as unknown as IFilterableTableHelpersMixin
      ).getSelectOptionsFromEnum(
        DocumentStatus,
        'digitalizationDocumentStatus',
      );

      this.selectOptions = {
        checkBotInferenceStatus: checkBotInferenceStatusOptions,
        documentStatus: documentStatusOptions,
      };
    },

    goToDocumentDetailView(row: { item: IDigitalizationDocumentSummaryDto }) {
      this.saveTableConfig();
      const document = row.item;
      router.push({
        name: 'documentDetailView',
        params: {
          mode: DocumentDetailViewMode.DIGITALIZATION_PROJECT,
          documentId: document.documentId,
          projectId: this.digitalizationProjectId,
          uploadId: document.uploadId.toString(),
        },
      });
    },

    isDocumentSelected(documentId: string) {
      return !!this.selectedDocuments[documentId];
    },

    setDocumentSelectStatus(documentId: string, isSelected: boolean) {
      Vue.set(this.selectedDocuments, documentId, isSelected);
    },

    getRowClass(
      document: IDigitalizationDocumentSummaryDto | undefined,
    ): string {
      if (document) {
        if (document.sharePointUploadStatus === SharePointUploadStatus.FAILED) {
          return 'danger-row';
        }
        if (this.selectedDocuments[document?.documentId]) {
          return 'selected-row';
        }
      }

      return 'row-class-clickable';
    },

    displayContinueProcessButton(): boolean {
      return Object.values(this.selectedDocuments).some(Boolean);
    },

    getGridEditorMode(): MetadataGridEditorMode {
      const selectedDocuments = this.getSelectedDocuments();

      if (
        selectedDocuments.length &&
        (selectedDocuments.every(
          (doc) => doc.documentStatus === DocumentStatus.METADATA_INSPECTION,
        ) ||
          selectedDocuments.every(
            (doc) => doc.documentStatus === DocumentStatus.FORMAL_CONTROL,
          ))
      ) {
        return MetadataGridEditorMode.METADATA_INSPECTION;
      } else if (
        selectedDocuments.length &&
        selectedDocuments.every(
          (doc) => doc.documentStatus === DocumentStatus.QUALITY_CHECK,
        )
      ) {
        return MetadataGridEditorMode.QUALITY_CHECK;
      } else {
        throw new Error('Could not establish MetadataGridEditorMode');
      }
    },

    handleContinueProcessButtonClick() {
      if (!this.canGoToMetadataGridEditor()) {
        return;
      }

      this.setLocalStorageDataForMetadataEditor();

      router.push({
        name: 'metadataGridEditor',
        params: {
          digitalizationProjectId: this.digitalizationProjectId,
          mode: this.getGridEditorMode(),
        },
      });
    },

    setLocalStorageDataForMetadataEditor(): void {
      const ids: DocumentObjectIdsAndUploadIds[] = Object.entries(
        this.selectedDocuments,
      )
        .filter(([_, isSelected]) => isSelected)
        .map(([documentId]) => {
          const document = this.documents?.find(
            (document) => document.documentId === documentId,
          );
          if (!document) throw new Error();
          return {
            documentObjectId: document?._id.toString(),
            uploadId: document?.uploadId.toString(),
          };
        })
        .filter(isDefined);

      localStorage.setItem(
        METADATA_GRID_DOCUMENT_IDS_LOCAL_STORAGE_KEY,
        JSON.stringify(ids),
      );
    },

    toggleAllDocumentsSelection(isSelected: boolean): void {
      this.documents?.forEach((document) => {
        Vue.set(this.selectedDocuments, document.documentId, isSelected);
      });
    },

    areAllDocumentsSelected(): boolean {
      if (!this.documents) return false;
      else
        return this.documents.every(
          (document) => this.selectedDocuments[document.documentId],
        );
    },

    getSelectedDocuments(): IDigitalizationDocumentSummaryDto[] {
      return Object.entries(this.selectedDocuments)
        .filter(([_documentId, isSelected]) => isSelected)
        .map(([documentId]) =>
          this.documents?.find(
            (document) => document.documentId === documentId,
          ),
        )
        .filter(isDefined);
    },

    canGoToMetadataGridEditor(): boolean {
      const selectedDocuments = this.getSelectedDocuments();
      let gridEditorMode: MetadataGridEditorMode | undefined = undefined;
      try {
        gridEditorMode = this.getGridEditorMode();
      } catch {
        return false;
      }

      const isSameContentType =
        new Set(selectedDocuments.map((document) => document?.contentTypeName))
          .size === 1;

      const isAllowed = !(
        !this.isQualityCheckModeAllowed() &&
        gridEditorMode === MetadataGridEditorMode.QUALITY_CHECK
      );

      return isSameContentType && isAllowed;
    },

    isQualityCheckModeAllowed(): boolean {
      return (this as unknown as GlobalUtils).isUserRole([
        Role.Admin,
        Role.DocuResponsible,
      ]);
    },

    saveTableConfig(): void {
      const tableConfig: DigitalizationDocumentsOverviewTableConfig = {
        filtering: this.filtering,
        sorting: {
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        },
      };

      this.$store.commit(
        'setDigitalizationDocumentsOverviewTableConfig',
        tableConfig,
      );
    },

    getTableConfig(): DigitalizationDocumentsOverviewTableConfig | undefined {
      return this.$store.state.digitalizationDocumentsOverviewTableConfig;
    },

    applyTableConfig(): void {
      const tableConfig = this.getTableConfig();
      if (!tableConfig) return;

      this.applyFilterConfig(tableConfig);
      this.applySortConfig(tableConfig);
    },

    applyFilterConfig(
      config: DigitalizationDocumentsOverviewTableConfig,
    ): void {
      this.prefiltering = config.filtering as IFiltering;
    },

    applySortConfig(config: DigitalizationDocumentsOverviewTableConfig): void {
      this.initialSortBy = config.sorting?.sortBy;
      this.initialSortDesc = config.sorting?.sortDesc;
    },
  },

  created() {
    this.getDocuments();
    this.getSelectOptions();
    this.applyTableConfig();
  },
});
