import Vue from 'vue';

export default Vue.extend({
  name: 'styled-pagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },

  methods: {
    emitPageChangeEvent(pageNumber: number) {
      this.$emit('page-changed', pageNumber);
    },
  },
});
