import { render, staticRenderFns } from "./DocuManagerDetailView.vue?vue&type=template&id=4c8f1100&scoped=true"
import script from "./DocuManagerDetailView.script.js?vue&type=script&lang=js&external"
export * from "./DocuManagerDetailView.script.js?vue&type=script&lang=js&external"
import style0 from "./DocuManagerDetailView.styles.scss?vue&type=style&index=0&id=4c8f1100&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c8f1100",
  null
  
)

export default component.exports