import StyledTagsInput from '@/components/StyledTagsInput/StyledTagsInput.vue';
import endpointsList from '@/helpers/endpointsList';
import {
  DEFAULT_METADATA_VALUES_SEPARATOR,
  DocumentDetailViewMode,
  EDITABLE_TERM_SET_METADATA_FIELDS,
  IUpdateMetadataOfMultipleDocumentsDto,
} from 'types';
import axios, { AxiosError } from 'axios';
import Vue from 'vue';
import { ITagClasses } from '../StyledTagsInput/StyledTagsInput.script';

export default Vue.extend({
  name: 'document-metadata-tag-input',

  components: {
    StyledTagsInput,
  },

  props: {
    mode: {
      type: String as () => DocumentDetailViewMode,
      required: true,
    },
    digitalizationProjectId: {
      type: String,
      required: false,
    },
    digitalizationDocumentObjectId: {
      type: String,
      required: false,
    },
    isWaitingForValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploadId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
    currentValues: {
      type: String,
      required: true,
    },
    possibleValues: {
      type: Array as () => string[],
      required: false,
    },
    metadataField: {
      type: String,
      required: true,
    },
    validationFunction: {
      type: Function,
      required: false,
    },
    getMetadataFunction: {
      type: Function,
      required: false,
    },
    tagClasses: {
      type: Object as () => ITagClasses,
      required: false,
    },
  },

  data() {
    return {
      isBusy: false,
      isInputValid: true,
    };
  },

  methods: {
    setCurrentValues() {
      if (this.currentValues === '') return [];
      return this.currentValues?.split(DEFAULT_METADATA_VALUES_SEPARATOR) ?? [];
    },

    async handleTagsChanged(tags: string[]) {
      const joinedString = tags.join(DEFAULT_METADATA_VALUES_SEPARATOR);

      try {
        this.isBusy = true;

        if (this.mode === DocumentDetailViewMode.PROJECT) {
          await axios.post(
            endpointsList.documentsUploads.updateValuesOfEditableMetadataFields(
              this.uploadId,
              this.documentId,
            ),
            {
              [this.metadataField]: joinedString,
            },
          );

          await this.getMetadataFunction();
        } else if (
          this.mode === DocumentDetailViewMode.DIGITALIZATION_PROJECT
        ) {
          const dto: IUpdateMetadataOfMultipleDocumentsDto = {
            [this.digitalizationDocumentObjectId]: {
              [this.metadataField]: joinedString,
            },
          };

          await axios.patch(
            endpointsList.digitalizationUploads.updateMetadataOfMultipleDocuments(
              this.digitalizationProjectId,
              false,
            ),
            dto,
          );

          await this.getMetadataFunction();
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400 && !joinedString.length) {
            (this as any).createToast(
              this.$t('documentDetailView.errors.cannotRemoveLastValue.short'),
              this.$t('documentDetailView.errors.cannotRemoveLastValue.long'),
              'danger',
            );
          } else {
            (this as any).createToast(
              this.$t('errors.error'),
              this.$t('errors.error'),
              'danger',
            );
          }
        } else {
          (this as any).createToast(
            this.$t('errors.error'),
            this.$t('errors.error'),
            'danger',
          );
        }
      } finally {
        this.isBusy = false;
        this.validateInput();
        this.$emit('document-data-changed');
      }
    },

    isTermSetField(): boolean {
      return EDITABLE_TERM_SET_METADATA_FIELDS.includes(
        this.metadataField as any,
      );
    },

    validateInput(): void {
      if (this.validationFunction) {
        this.isInputValid = this.validationFunction(this.currentValues);
      }
    },
  },

  mounted() {
    this.validateInput();
  },
});
