import { render, staticRenderFns } from "./MainTable.vue?vue&type=template&id=dae2c2fe&scoped=true"
import script from "./MainTable.script.ts?vue&type=script&lang=ts&external"
export * from "./MainTable.script.ts?vue&type=script&lang=ts&external"
import style0 from "./MainTable.styles.scss?vue&type=style&index=0&id=dae2c2fe&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae2c2fe",
  null
  
)

export default component.exports