import { IProjectAssignments, IValidateReferenceNumberDto } from 'types';
import { DocumentUploadMode } from '@/views/DocumentUploadView/DocumentUploadView.script';
import axios, { AxiosResponse } from 'axios';
import Vue from 'vue';
import StyledModal from '../../components/StyledModal/StyledModal.vue';
import StyledMultiselect from '../../components/StyledMultiselect/StyledMultiselect.vue';
import endpointsList from '../../helpers/endpointsList';
import { IContentType, ISupplier, Role } from 'types';
import { GlobalUtils, IsUserRoleMixin } from '../../mixins/globalUtils';
import { router } from '../../vue';

interface ISupplierOption {
  name: string;
  id: string;
  SAPNumber: string;
}

interface INewUplaodModal {
  allContentTypes: IProjectAssignments[];
  selectedContentType: null | IContentType;
  isLoadingContentTypes: boolean;
  referenceNumber: string;

  selectedSupplier: null | ISupplierOption;
  allSuppliers: ISupplierOption[];
  isLoadingSuppliers: boolean;

  isValidated: boolean;
  isValidatingSubmit: boolean;
  errorMessages: {
    supplier: boolean;
    referenceNumber: boolean;
    referenceNumberUnique: boolean;
    contentType: boolean;
  };
  isSubmitted: boolean;

  isNoMappingAssignedError: boolean;

  supplierSelectionAllowedRoles: Role[];

  isUserRole?: IsUserRoleMixin;
}

export default Vue.extend({
  name: 'new-upload-modal',
  components: { StyledModal, StyledMultiselect },

  props: {
    projectId: {
      type: String,
      required: true,
    },
    projectNumber: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
  },

  data(): INewUplaodModal {
    return {
      allContentTypes: [],
      selectedContentType: null,
      isLoadingContentTypes: false,
      referenceNumber: '',

      selectedSupplier: null,
      allSuppliers: [],
      isLoadingSuppliers: false,

      isValidated: false,
      isValidatingSubmit: false,
      errorMessages: {
        supplier: false,
        referenceNumber: false,
        referenceNumberUnique: false,
        contentType: false,
      },
      isSubmitted: false,

      isNoMappingAssignedError: false,

      supplierSelectionAllowedRoles: [Role.Admin],
    };
  },

  methods: {
    async getContentTypesForProject() {
      this.isLoadingContentTypes = true;

      try {
        const response = await axios.get(
          endpointsList.contentTypeMappings.getContentTypesAssignmentsForProject(
            this.projectId,
          ),
        );
        this.allContentTypes = response.data;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }

      this.isLoadingContentTypes = false;
    },

    async getSuppliersForProject() {
      this.isLoadingSuppliers = true;
      try {
        const response: AxiosResponse<
          Pick<ISupplier, '_id' | 'name' | 'SAPNumber'>[]
        > = await axios.get(
          endpointsList.projects.getProjectSuppliersById(this.projectId, [
            '_id',
            'name',
            'SAPNumber',
          ]),
        );

        this.allSuppliers = response.data.map(
          (supplier): ISupplierOption => ({
            name: supplier.name,
            id: supplier._id.toString(),
            SAPNumber: supplier.SAPNumber,
          }),
        );

        if (this.isUserRole!([Role.Coworker])) {
          this.setSelectedSupplierBasedOnRole();
        }
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }

      this.isLoadingSuppliers = false;
    },

    async handleModalOK(e: Event) {
      e.preventDefault();
      await this.validateSubmit();
      this.isSubmitted = true;
      if (
        !this.isValidated ||
        !this.selectedContentType ||
        !this.selectedSupplier
      ) {
        return;
      }

      router.push({
        name: 'documentUpload',
        params: {
          mode: DocumentUploadMode.PROJECT,
          referenceNumber: this.referenceNumber,
          contentTypeId: this.selectedContentType.contentTypeId,
          projectId: this.projectId,
          projectName: this.projectName,
          projectNumber: this.projectNumber,
          supplierId: this.selectedSupplier.id,
          supplierName: this.selectedSupplier.name,
          SAPNumber: this.selectedSupplier.SAPNumber,
        },
      });
    },

    checkIfSupplierHasContentTypeMapping() {
      if (!this.selectedContentType) {
        return;
      }

      const assignment = this.selectedContentType.assignments.find(
        (assignment) =>
          assignment.supplierId?.toString() === this.selectedSupplier?.id,
      );

      if (!assignment) {
        return false;
      }

      return (assignment as any).isMapping;
    },

    async validateSubmit() {
      this.isValidatingSubmit = true;
      this.errorMessages.supplier = !!this.selectedSupplier;
      this.errorMessages.referenceNumber = !!this.referenceNumber;
      this.errorMessages.contentType = !!this.selectedContentType;
      await this.checkIsReferenceNumberUnique();

      if (Object.values(this.errorMessages).some((value) => !value)) {
        !this.isValidated;
        this.isValidatingSubmit = false;
        return;
      }

      if (!this.checkIfSupplierHasContentTypeMapping()) {
        this.isNoMappingAssignedError = true;
        this.isValidated = false;
        this.isValidatingSubmit = false;
        return;
      }

      this.isValidated = true;
      this.isValidatingSubmit = false;
    },

    async checkIsReferenceNumberUnique() {
      const requestBody: IValidateReferenceNumberDto = {
        referenceNumber: this.referenceNumber,
      };
      try {
        const response: AxiosResponse<boolean> = await axios.post(
          endpointsList.documentsUploads.validateReferenceNumber(
            this.projectId,
          ),
          requestBody,
        );

        this.errorMessages.referenceNumberUnique = response.data;
      } catch (err) {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('documentUpload.errors.validationError'),
          'danger',
        );
      }
    },

    setSelectedSupplierBasedOnRole() {
      if (this.isUserRole!([Role.Coworker])) {
        const supplierId = this.$store.state.supplierId;
        const selectedSupplier = this.allSuppliers.find(
          (supplier) => supplier.id === supplierId,
        );
        if (!selectedSupplier) {
          return;
        }

        this.selectedSupplier = selectedSupplier;
      }
    },

    onModalShow() {
      this.getContentTypesForProject();
      this.getSuppliersForProject();
    },
  },

  watch: {
    suppliers() {
      this.getSuppliersForProject();
    },
  },

  mounted() {
    if (this.projectId === undefined) {
      return;
    }
  },
});
