var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ol',{key:_vm.rerenderKey,attrs:{"id":"bread-crumb"}},_vm._l((_vm.$route.meta.breadcrumb),function(path,index){return _c('li',{key:path},[(index !== _vm.$route.meta.breadcrumb.length - 1)?[(path === 'projectDetails')?_c('router-link',{key:_vm.rerenderKey,attrs:{"to":{
          name: path,
          params: {
            id: _vm.$store.state.currentProjectId,
            tabNumber: _vm.$store.state.projectDetailViewTabNumber.toString(),
          },
        }}},[_vm._v(_vm._s(_vm.breadCrumbNames[path] ?? _vm.$t(`breadCrumb.${path}`)))]):(path === 'digitalizationProjectDetails')?_c('router-link',{key:_vm.rerenderKey,attrs:{"to":{
          name: path,
          params: {
            id: _vm.$store.state.currentDigitalizationProjectId,
            tabNumber:
              _vm.$store.state.digitalizationProjectDetailViewTabNumber.toString(),
          },
        }}},[_vm._v(_vm._s(_vm.breadCrumbNames[path] ?? _vm.$t(`breadCrumb.${path}`)))]):(path === 'uploadReferenceNumber')?_c('div',{key:_vm.rerenderKey,staticClass:"breadcrumb-link",on:{"click":_vm.handleUploadReferenceNumberClick}},[_vm._v(" "+_vm._s(_vm.$t('breadCrumb.uploadReferenceNumber'))+" "+_vm._s(_vm.breadCrumbNames[path] ?? _vm.$t(`breadCrumb.${path}`))+" ")]):(path === 'digitalizationUploadReferenceNumber')?_c('div',{key:_vm.rerenderKey,staticClass:"breadcrumb-link",on:{"click":_vm.handleDigitalizationUploadReferenceNumberClick}},[_vm._v(" "+_vm._s(_vm.$t('breadCrumb.digitalizationUploadReferenceNumber'))+" "+_vm._s(_vm.breadCrumbNames[path] ?? _vm.$t(`breadCrumb.${path}`))+" ")]):_c('router-link',{key:_vm.rerenderKey,attrs:{"to":{ name: path }}},[_vm._v(_vm._s(_vm.breadCrumbNames[path] ?? _vm.$t(`breadCrumb.${path}`)))]),_vm._v(" / ")]:_c('div',{key:_vm.rerenderKey},[_c('p',[_vm._v(_vm._s(_vm.breadCrumbNames[path] ?? _vm.$t(`breadCrumb.${path}`)))])])],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }