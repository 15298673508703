//! This is injected globally, make sure to actually include things that a majority of components will use
//! If you need to make a more specific set of utilities you should make a new mixin and include it where it is needed

import { ModalSize } from '@/components/StyledModal/StyledModal.script';
import {
  ButtonSize,
  ButtonVariant,
} from '@/components/VfButton/VfButton.script';
import { formatDateForDisplay } from '@/helpers/formatDateForDisplay';
import { TranslationKey } from '@/types/global-utils';
import { Role } from 'types';
import Vue from 'vue';
import { TranslateResult, Values } from 'vue-i18n';

export type ToastMixin = (
  titleText: string | TranslateResult,
  content: string | TranslateResult,
  type: 'success' | 'warning' | 'danger',
  delay?: number,
  autoHide?: boolean,
) => void;

export type IsUserRoleMixin = (roles: Role[]) => boolean;

export type FormatDateForDisplayMixin = (date: Date) => string;

export type SetBreadcrumbMetadataMixin = (bradcrumbElements: string[]) => void;

export type PredefinedCreateToastMethod = (
  content?: string | TranslateResult,
  delay?: number,
) => void;

export type $tt = (key: TranslationKey, values?: Values) => TranslateResult;

export type GlobalData = {
  buttonSizes: {
    small: ButtonSize.SMALL;
    medium: ButtonSize.MEDIUM;
    large: ButtonSize.LARGE;
  };
  buttonVariants: {
    primary: ButtonVariant.PRIMARY;
    secondary: ButtonVariant.SECONDARY;
    tertiary: ButtonVariant.TERTIARY;
    quaternary: ButtonVariant.QUATERNARY;
  };
  modalSizes: {
    s: ModalSize.sm;
    l: ModalSize.LG;
    xl: ModalSize.XL;
  };
};

export type GlobalUtils = {
  createErrorToast: PredefinedCreateToastMethod;
  createSuccessToast: PredefinedCreateToastMethod;
  createWarningToast: PredefinedCreateToastMethod;
  createToast: ToastMixin;
  isUserRole: IsUserRoleMixin;
  formatDateForDisplay: FormatDateForDisplayMixin;
  setBreadcrumbMetadata: SetBreadcrumbMetadataMixin;
  $tt: $tt;
} & GlobalData;

export default Vue.extend({
  data(): GlobalData {
    return {
      buttonSizes: {
        small: ButtonSize.SMALL,
        medium: ButtonSize.MEDIUM,
        large: ButtonSize.LARGE,
      },
      buttonVariants: {
        primary: ButtonVariant.PRIMARY,
        secondary: ButtonVariant.SECONDARY,
        tertiary: ButtonVariant.TERTIARY,
        quaternary: ButtonVariant.QUATERNARY,
      },
      modalSizes: {
        s: ModalSize.sm,
        l: ModalSize.LG,
        xl: ModalSize.XL,
      },
    };
  },
  methods: {
    createToast(
      titleText: string,
      content: string,
      type: 'success' | 'warning' | 'danger',
      delay = 5000,
      autoHide = true,
    ) {
      this.$bvToast.toast(content, {
        title: titleText,
        variant: type,
        solid: true,
        autoHideDelay: delay,
        noAutoHide: !autoHide,
        toaster: 'b-toaster-bottom-right',
      });
    },

    createSuccessToast(content?: string | TranslateResult, delay?: number) {
      if (content) {
        this.createToast(
          this.$tt('generic.success').toString(),
          content.toString(),
          'success',
          delay,
        );
      } else {
        this.createToast(
          this.$tt('generic.success').toString(),
          '',
          'success',
          delay,
        );
      }
    },

    createWarningToast(content?: string | TranslateResult, delay?: number) {
      if (content) {
        this.createToast(
          this.$tt('generic.warning').toString(),
          content.toString(),
          'warning',
          delay,
        );
      } else {
        this.createToast(
          this.$tt('generic.warning').toString(),
          '',
          'warning',
          delay,
        );
      }
    },

    createErrorToast(content?: string | TranslateResult, delay?: number) {
      if (content) {
        this.createToast(
          this.$tt('errors.error').toString(),
          content.toString(),
          'danger',
          delay,
        );
      } else {
        this.createToast(
          this.$tt('errors.error').toString(),
          '',
          'danger',
          delay,
        );
      }
    },

    isUserRole(roles: Role[]): boolean {
      const isUserRole: boolean = this.$store.state.userRoles.some(
        (role: Role) => roles.includes(role),
      );

      return isUserRole;
    },

    formatDateForDisplay(date: Date): string {
      return formatDateForDisplay(date);
    },

    setBreadcrumbMetadata(breadcrumbElements: string[]) {
      this.$route.meta ??= {};
      this.$route.meta.breadcrumb = breadcrumbElements;
    },

    $tt(key: TranslationKey, values?: Values): TranslateResult {
      return this.$t(key, values);
    },
  },
});
