import StepProgress from '@/components/StepProgress/StepProgress.vue';
import {
  DigiDocuProcessingStep,
  getCurrentDigitalizationDocumentProcessingStep,
} from '@/helpers/getCurrentDigitalizationDocumentProcessingStep';
import {
  DocuProcessingStep,
  getCurrentDocumentProcessingStep,
} from '@/helpers/getCurrentDocumentProcessingStep';
import {
  DigitalizationDocumentWithStatus,
  IFile,
  SharePointUploadStatus,
} from 'types';
import { getEnumLength } from 'utils';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import ProgressStepIndicatorTooltip from './tooltips/ProgressStepIndicatorTooltip/ProgressStepIndicatorTooltip.vue';

export enum StepProgressIndicatorMode {
  PROJECT = 'project',
  DIGITALIZATION_PROJECT = 'digitalization-project',
}

export default Vue.extend({
  name: 'progress-steps-indicator',

  components: {
    ProgressStepIndicatorTooltip,
    'step-progress': StepProgress,
  },

  props: {
    mode: {
      type: String as () => StepProgressIndicatorMode,
      required: true,
    },
    file: {
      type: Object as () => IFile,
      required: false,
    },
    digitalizationDocument: {
      type: Object as () => DigitalizationDocumentWithStatus,
      required: false,
    },
  },

  methods: {
    isSharePointUploadFailed(): boolean {
      return (
        (this.file ?? this.digitalizationDocument).sharePointUploadStatus ===
        SharePointUploadStatus.FAILED
      );
    },

    getDocumentProcessingSteps(): TranslateResult[] {
      if (this.mode === StepProgressIndicatorMode.PROJECT) {
        return this.buildDocumentProcessingSteps(DocuProcessingStep, 'project');
      } else {
        return this.buildDocumentProcessingSteps(
          DigiDocuProcessingStep,
          'digitalizationProject',
        );
      }
    },

    buildDocumentProcessingSteps(
      stepEnum: object,
      projectType: 'project' | 'digitalizationProject',
    ): TranslateResult[] {
      /**
       * The last step (RECORD_LIBRARY_TRANSFER_DONE) is omitted because it
       * should not be displayed as a step. It is there so that when the
       * document is transfered to the record library the step-progress
       * component gets a number one bigger than the amount of steps passed to
       * it, so that the last displayed step can be displayed as done and not
       * active when the file is transfered to the record library.
       */
      return Array.from({
        length: getEnumLength(stepEnum) - 1,
      }).map((_, index) =>
        this.$t(`progressStepsIndicator.${projectType}.${index}`),
      );
    },

    getCurrentStep(): number {
      if (this.mode === StepProgressIndicatorMode.PROJECT) {
        return getCurrentDocumentProcessingStep(this.file);
      } else {
        return getCurrentDigitalizationDocumentProcessingStep(
          this.digitalizationDocument,
        );
      }
    },

    getTooltipTargetId(): string {
      if (
        (this.mode === StepProgressIndicatorMode.PROJECT &&
          this.getCurrentStep() ===
            DocuProcessingStep.RECORD_LIBRARY_TRANSFER_DONE) ||
        (this.mode === StepProgressIndicatorMode.DIGITALIZATION_PROJECT &&
          this.getCurrentStep() ===
            DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER_DONE)
      ) {
        /**
         * RECORD_LIBRARY_TRANSFER_DONE is not displayed as a step, so if it
         * is the current process status, the tooltip needs to get the
         * previous (last displayed) status as its target.
         */
        return `step-label-${this.getCurrentStep() - 1}`;
      } else {
        return `step-label-${this.getCurrentStep()}`;
      }
    },

    isTooltipEnabled() {
      return !(
        this.mode === StepProgressIndicatorMode.DIGITALIZATION_PROJECT &&
        [
          DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER,
          DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER_DONE,
        ].includes(this.getCurrentStep())
      );
    },

    getUserActionableStepIndexes(): number[] {
      if (this.mode === StepProgressIndicatorMode.PROJECT) {
        return [DocuProcessingStep.CONTENT_INSPECTION];
      } else {
        return [
          DigiDocuProcessingStep.METADATA_INSPECTION,
          DigiDocuProcessingStep.QUALITY_CHECK,
        ];
      }
    },

    getStepsWithDisabledTooltip() {
      if (this.mode === StepProgressIndicatorMode.DIGITALIZATION_PROJECT) {
        return [
          DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER,
          DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER_DONE,
        ];
      } else return [];
    },

    handleDocumentDataChanged(): void {
      this.$emit('document-data-changed');
    },
  },

  mounted() {
    const circles = document.querySelectorAll('.check-icon');

    circles!.forEach((circle) => {
      circle.innerHTML =
        '<svg xmlns="http://www.w3.org/2000/svg" fill="white" height="18" width="18" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>';
      (circle as HTMLElement).style.display = 'flex';
      (circle as HTMLElement).style.justifyContent = 'center';
      (circle as HTMLElement).style.alignItems = 'center';
    });
  },
});
