"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RolesHelper = void 0;
const lodash_1 = require("lodash");
const types_1 = require("types");
const SUPPLIER_PROJECT_ROLES = [
    types_1.Role.Admin,
    types_1.Role.DocuManager,
    types_1.Role.Inspector,
    types_1.Role.Coworker,
];
class RolesHelper {
    constructor(userRoles) {
        this.userRoles = userRoles;
    }
    hasRole(...roles) {
        return !!(0, lodash_1.intersection)(this.userRoles, roles).length;
    }
    get isAdmin() {
        return this.hasRole(types_1.Role.Admin);
    }
    /**
     * Check if `coworker` is the only role of the user in the context of
     * supplier projects.
     */
    get isOnlyCoworker() {
        const usersSupplierProjectRoles = this.userRoles.filter((role) => SUPPLIER_PROJECT_ROLES.includes(role));
        return (usersSupplierProjectRoles.length === 1 &&
            usersSupplierProjectRoles[0] === types_1.Role.Coworker);
    }
}
exports.RolesHelper = RolesHelper;
