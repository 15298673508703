import endpointsList from '../../helpers/endpointsList';
import { ISupplier } from 'types';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Vue from 'vue';
import StyledModal from '../StyledModal/StyledModal.vue';
import StyledMultiselect from '../StyledMultiselect/StyledMultiselect.vue';
import { debounce } from 'lodash';

interface IAddSupplierModal {
  suppliers: ISupplier[];
  selectedSupplier?: ISupplier;
  isLoadingSuppliers: boolean;
  isConflictError: boolean;
  isAddingSupplier: boolean;
}

export default Vue.extend({
  name: 'add-supplier-modal',
  components: { StyledModal, StyledMultiselect },

  props: {
    projectId: {
      type: String,
      required: true,
    },
  },

  data(): IAddSupplierModal {
    return {
      suppliers: [],
      selectedSupplier: undefined,
      isLoadingSuppliers: false,
      isConflictError: false,
      isAddingSupplier: false,
    };
  },

  methods: {
    async querySuppliers(query: string) {
      if (query.length < 2) {
        return;
      }
      this.isLoadingSuppliers = true;

      try {
        const response: AxiosResponse<ISupplier[]> = await axios.get(
          endpointsList.suppliers.searchSuppliers({
            name: query,
            SAPNumber: '',
          }),
        );

        this.suppliers = response.data;
      } catch {
        //
      }
      this.isLoadingSuppliers = false;
    },

    debouncedQuerySuppliers: new Function(),

    async addSelectedSupplier() {
      if (!this.selectedSupplier) {
        return;
      }
      this.isAddingSupplier = true;
      this.isConflictError = false;

      try {
        await axios.patch(
          endpointsList.projects.addSupplierToProject(this.projectId),
          {
            supplierId: this.selectedSupplier._id,
          },
        );

        this.$emit('supplier-added');
        this.cleanModal();
        this.$bvModal.hide('styled-modal');
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          if (err.response?.status === 409) {
            this.isConflictError = true;
          }
        }
      }

      this.isAddingSupplier = false;
    },

    cleanModal() {
      this.selectedSupplier = undefined;
      this.suppliers = [];
    },

    label({ name, SAPNumber }: { name: string; SAPNumber: string }) {
      if (SAPNumber) {
        return `${name} — [${SAPNumber}]`;
      }

      return `${name}`;
    },
  },

  created() {
    this.debouncedQuerySuppliers = debounce(this.querySuppliers, 800);
  },
});
