import { ICheckBotResult, ICheckBotResults } from 'types';
import Vue from 'vue';
import FilterableTable from '@/components/FilterableTable/FilterableTable.vue';
import { FilterType } from '../FilterableTable/FilterableTable.script';
import copyIcon from '@/assets/icons/copy.svg';
import { TableFieldsBuilder, truncateValue } from '@/helpers';

interface ITransformedCheckBotResult extends ICheckBotResult {
  check: string;
  isDetailsTruncated: boolean;
}

export default Vue.extend({
  name: 'check-bot-results-table',
  components: { FilterableTable },

  props: {
    checkBotResults: {
      type: Object as () => ICheckBotResults,
      required: true,
    },
  },

  data() {
    return {
      filterGridConfiguration: ['20%', '10%', '20%', '50%'],
      filterConfiguration: {
        check: {
          width: '280px',
          column: 1,
          type: FilterType.INPUT,
        },
        state: {
          width: '100px',
          column: 2,
          type: FilterType.SELECT,
        },
        result: {
          width: '200px',
          column: 3,
          type: FilterType.INPUT,
        },
        details: {
          width: '200px',
          column: 4,
          type: FilterType.INPUT,
        },
      },
      icons: {
        copyIcon,
      },
    };
  },

  computed: {
    transformedResults() {
      if (!this.checkBotResults) {
        return [];
      }

      const results: ITransformedCheckBotResult[] = [];

      for (const checkName in this.checkBotResults.results) {
        const checkBotResults = this.checkBotResults as ICheckBotResults;
        results.push({
          check: checkName,
          ...checkBotResults.results[checkName],
          isDetailsTruncated: true,
        });
      }

      return results;
    },

    tableFields() {
      return new TableFieldsBuilder<
        ITransformedCheckBotResult & { copyDetailsIcon: string }
      >({
        translationKeyPrefix: 'checkBotResultsTable',
        defaults: {
          sortable: true,
        },
      })
        .addField('check', { width: '20%' })
        .addField('state', { width: '10%' })
        .addField('result', { width: '20%' })
        .addField('details', { width: '47%' })
        .addField('copyDetailsIcon', {
          width: '3%',
          simpleLabel: '',
          sortable: false,
        })
        .build();
    },
  },

  methods: {
    truncateValue,
    async handleCopyDetailsClick(results: any) {
      try {
        await navigator.clipboard.writeText(results.details);
        (this as any).createToast(
          this.$t('checkBotResultsTable.detailsCopied'),
          this.$t('checkBotResultsTable.detailsCopied'),
          'success',
        );
      } catch {
        //
      }
    },
  },
});
