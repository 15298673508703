"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEnumLength = getEnumLength;
/**
 * Gets the length (number of unique members) of a numeric TypeScript enum.
 *
 * This method calculates the number of unique keys in a numeric enum by
 * dividing the length of `Object.keys()` by 2, which accounts for the
 * forward and reverse mappings present in numeric enums.
 *
 * @template T - The enum type.
 * @param enumType - The enum whose length is to be calculated.
 * @returns The number of unique members in the enum.
 *
 * @example
 * enum SampleEnum {
 *   FIRST,
 *   SECOND,
 *   THIRD
 * }
 *
 * const length = getEnumLength(SampleEnum);
 * console.log(length); // Output: 3
 */
function getEnumLength(enumType) {
    return Object.keys(enumType).length / 2;
}
