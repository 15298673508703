import { ITableField } from '@/frontendInterfaces';
import axios, { AxiosError } from 'axios';
import Vue from 'vue';
import endpointsList from '../../helpers/endpointsList';
import { IDocuResponsible, Role, UserGroup } from 'types';
import { GlobalUtils } from '../../mixins/globalUtils';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import FilterableTable from '../FilterableTable/FilterableTable.vue';
import SelectModal from '../SelectModal/SelectModal.vue';
import SpGroupModal from '../SpGroupModal/SpGroupModal.vue';

interface IDigitalizationProjectDocuResponsibles {
  docuResponsibles?: IDocuResponsible[];
  isFetchingDocuResponsibles: boolean;
  sortBy: keyof IDocuResponsible;
  sortDesc: boolean;
  filterConfiguration: IFilterConfiguration;
  filterGridConfiguration: string[];
  spGroup: UserGroup.docuResponsibles;
  addingDocuResponsibleAllowedRoles: [Role.Admin, Role.DocuResponsible];
}

export default Vue.extend({
  name: 'digitalization-project-docu-responsibles',
  components: { FilterableTable, SelectModal, SpGroupModal },
  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
  },

  data(): IDigitalizationProjectDocuResponsibles {
    return {
      docuResponsibles: undefined,
      isFetchingDocuResponsibles: false,
      sortBy: 'lastName',
      sortDesc: true,
      filterConfiguration: {
        firstName: { type: FilterType.INPUT, column: 1, width: '208px' },
        lastName: { type: FilterType.INPUT, column: 2, width: '208px' },
        email: { type: FilterType.INPUT, column: 3, width: '208px' },
      },
      filterGridConfiguration: ['36%', '28%', '34%'],
      spGroup: UserGroup.docuResponsibles,
      addingDocuResponsibleAllowedRoles: [Role.Admin, Role.DocuResponsible],
    };
  },

  computed: {
    tableFields(): ITableField[] {
      return [
        {
          key: 'firstName',
          label: this.$i18n.t(
            'digitalizationProjectDocuResponsibles.firstName',
          ),
          sortable: true,
          tdClass: ['w-36'],
        },
        {
          key: 'lastName',
          label: this.$i18n.t('digitalizationProjectDocuResponsibles.lastName'),
          sortable: true,
          tdClass: ['w-28'],
        },
        {
          key: 'email',
          label: this.$i18n.t('digitalizationProjectDocuResponsibles.email'),
          sortable: true,
          tdClass: ['w-34'],
        },
      ];
    },
  },

  methods: {
    async getDocuResponsibles() {
      try {
        this.isFetchingDocuResponsibles = true;
        const response = await axios.get(
          endpointsList.digitalizationProjects.getDigitalizationProjectDocuResponsibles(
            this.digitalizationProjectId,
          ),
        );

        this.docuResponsibles = response.data;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t(
            'digitalizationProjectDocuResponsibles.errors.errorGettingDocuResponsbiles.long',
          ),
          'danger',
        );
      } finally {
        this.isFetchingDocuResponsibles = false;
      }
    },

    async handleAddDocuResponsible(docuResponsible: IDocuResponsible) {
      try {
        await axios.patch(
          endpointsList.digitalizationProjects.addDocuResponsibleToDigitalizationProject(
            this.digitalizationProjectId,
            docuResponsible.oid,
          ),
        );

        this.getDocuResponsibles();
        (this as unknown as GlobalUtils).createToast(
          '',
          this.$t('digitalizationProjectDocuResponsibles.docuResponsibleAdded'),
          'success',
        );
      } catch (err: unknown) {
        if (err instanceof AxiosError && err.response?.status === 409) {
          (this as unknown as GlobalUtils).createToast(
            this.$t('errors.error'),
            this.$t(
              'digitalizationProjectDocuResponsibles.errors.docuResponsibleAlreadyAdded.long',
            ),
            'danger',
          );
          return;
        }

        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t(
            'digitalizationProjectDocuResponsibles.errors.errorAddingDocuResponsible.long',
          ),
          'danger',
        );
      }
    },

    displayAddDocuResponsibleButton(): boolean {
      return (this as unknown as GlobalUtils).isUserRole(
        this.addingDocuResponsibleAllowedRoles,
      );
    },

    onAddDocuResponsibleButtonClick(): void {
      this.$bvModal.show('docu-responsibles-modal');
    },
  },

  created() {
    this.getDocuResponsibles();
  },
});
