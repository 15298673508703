import { IFile, SupplierDocumentStatus } from 'types';

export enum DocuProcessingStep {
  UPLOAD,
  FORMAL_INSPECTION,
  CONTENT_INSPECTION,
  RECORD_LIBRARY_TRANSFER,
  RECORD_LIBRARY_TRANSFER_DONE,
}

export function getCurrentDocumentProcessingStep(
  document: IFile,
): DocuProcessingStep {
  switch (document.documentProcessingStatus) {
    case SupplierDocumentStatus.FORMAL_CONTROL:
      return DocuProcessingStep.FORMAL_INSPECTION;
    case SupplierDocumentStatus.FORMAL_CONTROL_FAILED:
      return DocuProcessingStep.FORMAL_INSPECTION;
    case SupplierDocumentStatus.CONTENT_CONTROL:
      return DocuProcessingStep.CONTENT_INSPECTION;
    case SupplierDocumentStatus.CONTENT_CONTROL_FAILED:
      return DocuProcessingStep.CONTENT_INSPECTION;
    case SupplierDocumentStatus.RECORD_LIBRARY_TRANSFER:
      return DocuProcessingStep.RECORD_LIBRARY_TRANSFER;
    case SupplierDocumentStatus.RECORD_LIBRARY_TRANSFER_FAILED:
      return DocuProcessingStep.RECORD_LIBRARY_TRANSFER;
    case SupplierDocumentStatus.COMPLETED:
      return DocuProcessingStep.RECORD_LIBRARY_TRANSFER_DONE;
    // TODO: Add info about timeout
    case SupplierDocumentStatus.TIMED_OUT:
      return DocuProcessingStep.UPLOAD;
  }
}

export function isStepFailed(
  documentProcessingStatus: SupplierDocumentStatus,
): boolean {
  return [
    SupplierDocumentStatus.FORMAL_CONTROL_FAILED,
    SupplierDocumentStatus.CONTENT_CONTROL_FAILED,
    SupplierDocumentStatus.RECORD_LIBRARY_TRANSFER_FAILED,
  ].includes(documentProcessingStatus);
}
