import { parse } from 'date-format-parse';

/**
 * Parsing different date formats to the US date
 * format accepted by SharePoint
 */
export function parseDateToUsFormat(date: string): string {
  const dateFormats = [
    'D.M.YYYY',
    'D/M/YYYY',
    'D-M-YYYY',
    'YYYY-M-D',
    'YYYY/M/D',
  ];

  for (const format of dateFormats) {
    const parseResult = parse(date, format);

    if (!isNaN(new Date(parseResult).getTime())) {
      return parseResult.toLocaleDateString('en-US');
    }
  }

  return date;
}
