//! This is injected globally, make sure to actually include things that a majority of components will use
//! If you need to make a more specific set of utilities you should make a new mixin and include it where it is needed

import {
  ButtonSize,
  ButtonVariant,
} from '@/components/VfButton/VfButton.script';
import { formatDateForDisplay } from '@/helpers/formatDateForDisplay';
import { Role } from 'types';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';

export type ToastMixin = (
  titleText: string | TranslateResult,
  content: string | TranslateResult,
  type: 'success' | 'warning' | 'danger',
  delay?: number,
  autoHide?: boolean,
) => void;

export type IsUserRoleMixin = (roles: Role[]) => boolean;

export type FormatDateForDisplayMixin = (date: Date) => string;

export type SetBreadcrumbMetadataMixin = (bradcrumbElements: string[]) => void;

export type GlobalUtils = {
  createToast: ToastMixin;
  isUserRole: IsUserRoleMixin;
  formatDateForDisplay: FormatDateForDisplayMixin;
  setBreadcrumbMetadata: SetBreadcrumbMetadataMixin;
};

export default Vue.extend({
  data() {
    return {
      buttonSizes: {
        small: ButtonSize.SMALL,
        medium: ButtonSize.MEDIUM,
        large: ButtonSize.LARGE,
      },
      buttonVariants: {
        primary: ButtonVariant.PRIMARY,
        secondary: ButtonVariant.SECONDARY,
        tertiary: ButtonVariant.TERTIARY,
        quaternary: ButtonVariant.QUATERNARY,
      },
    };
  },
  methods: {
    createToast(
      titleText: string,
      content: string,
      type: 'success' | 'warning' | 'danger',
      delay = 5000,
      autoHide = true,
    ) {
      this.$bvToast.toast(content, {
        title: titleText,
        variant: type,
        solid: true,
        autoHideDelay: delay,
        noAutoHide: !autoHide,
        toaster: 'b-toaster-bottom-right',
      });
    },

    isUserRole(roles: Role[]): boolean {
      const isUserRole: boolean = this.$store.state.userRoles.some(
        (role: Role) => roles.includes(role),
      );

      return isUserRole;
    },

    formatDateForDisplay(date: Date): string {
      return formatDateForDisplay(date);
    },

    setBreadcrumbMetadata(breadcrumbElements: string[]) {
      this.$route.meta ??= {};
      this.$route.meta.breadcrumb = breadcrumbElements;
    },
  },
});
