import { formatDateForDisplay } from '@/helpers/formatDateForDisplay';
import Vue from 'vue';
import VfButton from '@/components/VfButton/VfButton.vue';
import ChangeInspectionDueDateModal from '@/components/ChangeInspectionDueDateModal/ChangeInspectionDueDateModal.vue';

export default Vue.extend({
  name: 'inspection-due-date-panel',

  components: { ChangeInspectionDueDateModal, VfButton },

  props: {
    fileId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    inspectionDueDate: {
      type: String,
      required: true,
    },
  },

  methods: {
    transformInspectionDueDate(): string {
      return formatDateForDisplay(new Date(this.inspectionDueDate));
    },

    handleInspectionDueDateUpdated() {
      this.$bvModal.hide('change-inspection-due-date-modal');
      this.$emit('inspection-due-date-updated');
    },
  },
});
