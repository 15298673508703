import { render, staticRenderFns } from "./DocuResponsiblesTable.vue?vue&type=template&id=e09e048c&scoped=true"
import script from "./DocuResponsiblesTable.script.ts?vue&type=script&lang=ts&external"
export * from "./DocuResponsiblesTable.script.ts?vue&type=script&lang=ts&external"
import style0 from "./DocuResponsiblesTable.style.scss?vue&type=style&index=0&id=e09e048c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e09e048c",
  null
  
)

export default component.exports