"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentStatus = void 0;
var DocumentStatus;
(function (DocumentStatus) {
    DocumentStatus["METADATA_INSPECTION"] = "metadata_inspection";
    DocumentStatus["FORMAL_CONTROL"] = "formal_control";
    DocumentStatus["QUALITY_CHECK"] = "quality_check";
    DocumentStatus["RECORD_LIBRARY_TRANSFER_IN_PROGRESS"] = "record_library_transfer";
    DocumentStatus["RECORD_LIBRARY_TRANSFER_SUCCESSFULL"] = "record_library_transfer_successfull";
    DocumentStatus["RECORD_LIBRARY_TRANSFER_FAILED"] = "record_library_transfer_failed";
})(DocumentStatus || (exports.DocumentStatus = DocumentStatus = {}));
