import { ISupplier } from 'types';
import Vue from 'vue';

export default Vue.extend({
  name: 'supplier-information',

  props: {
    supplierData: {
      type: Object as () => ISupplier,
      required: true,
    },
  },
});
