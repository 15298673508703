var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"step-progress__wrapper"},[_c('div',{staticClass:"step-progress__wrapper-before",style:({ backgroundColor: _vm.passiveColor, height: _vm.lineThickness + 'px' })}),_c('div',{staticClass:"step-progress__bar"},_vm._l((_vm.steps),function(step,index){return _c('div',{key:index,staticClass:"step-progress__step",class:{
        'step-progress__step--active':
          !_vm.isCurrentStepFailed &&
          index === _vm.currentStep &&
          !_vm.isSecondaryActive(index),
        'step-progress__step--active-secondary':
          !_vm.isCurrentStepFailed && _vm.isSecondaryActive(index),
        'step-progress__step--active-disabled-border':
          _vm.isActiveBorderDisabled(index),
        'step-progress__step--valid':
          index < _vm.currentStep ||
          (index === _vm.currentStep && _vm.isCurrentStepFailed),
        'step-progress__step--valid--label-clickable':
          index === _vm.currentStep && _vm.isCurrentStepFailed,
      },style:({
        '--passiveColor': _vm.passiveColor,
        '--activeBorder': _vm.activeThickness + 'px',
        '--passiveBorder': _vm.passiveThickness + 'px',
      })},[_c('span',[_vm._v(_vm._s(index + 1))]),_c('div',{class:{
          'step-progress__step-icon': true,
          [_vm.validIconClass]: !(index === _vm.currentStep && _vm.isCurrentStepFailed),
          'cross-icon': index === _vm.currentStep && _vm.isCurrentStepFailed,
        }}),_c('div',{staticClass:"step-progress__step-label tooltip-target",attrs:{"id":`step-label-${index}`}},[_vm._v(" "+_vm._s(step)+" ")])])}),0),_c('div',{staticClass:"step-progress__wrapper-after",style:({
      transform:
        'scaleX(' + _vm.scaleX + ') translateY(-50%) perspective(1000px)',
      height: _vm.lineThickness + 'px',
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }