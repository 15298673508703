"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentTypeFieldType = void 0;
var ContentTypeFieldType;
(function (ContentTypeFieldType) {
    ContentTypeFieldType["TEXT"] = "Text";
    ContentTypeFieldType["FILE"] = "File";
    ContentTypeFieldType["DATE_TIME"] = "DateTime";
    ContentTypeFieldType["TAXONOMY_FIELD_TYPE_MULTI"] = "TaxonomyFieldTypeMulti";
    ContentTypeFieldType["TAXONOMY_FIELD_TYPE"] = "TaxonomyFieldType";
    ContentTypeFieldType["NOTE"] = "Note";
})(ContentTypeFieldType || (exports.ContentTypeFieldType = ContentTypeFieldType = {}));
