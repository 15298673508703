import { router } from '../../vue';
import CsvReader from '../../components/CsvReader/CsvReader.vue';
import ContentTypeMappingTable from '@/components/ContentTypeMappingTable/ContentTypeMappingTable.vue';
import GoBack from '../../components/GoBack/GoBack.vue';
import Vue from 'vue';
import axios, { AxiosResponse } from 'axios';
import endpointsList from '../../helpers/endpointsList';

export enum ContentTypeConfigurationMode {
  PROJECT = 'project',
  DIGITALIZATION_PROJECT = 'digitalization-project',
}

interface IContentTypeConfiguration {
  headers: any;
  contentTypeName: null | string;
}

export default Vue.extend({
  name: 'content-type-configuration',
  components: { CsvReader, ContentTypeMappingTable, GoBack },
  props: {
    contentTypeId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    mode: {
      type: String as () => ContentTypeConfigurationMode,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
  },

  data(): IContentTypeConfiguration {
    return {
      headers: null,
      contentTypeName: null,
    };
  },

  methods: {
    getHeaders(value: any) {
      this.headers = value;
    },

    async getContentTypeName() {
      try {
        const response: AxiosResponse<string> = await axios.get(
          endpointsList.sharePointIntegration.getContentTypeNameById(
            this.contentTypeId,
          ),
        );

        this.contentTypeName = response.data;
      } catch {
        //
      }
    },

    setBreadcrumb() {
      if (this.mode === ContentTypeConfigurationMode.PROJECT) {
        (this as any).setBreadcrumbMetadata([
          'projectsList',
          'projectDetails',
          'contentTypeConfiguration',
        ]);
      } else if (
        this.mode === ContentTypeConfigurationMode.DIGITALIZATION_PROJECT
      ) {
        (this as any).setBreadcrumbMetadata([
          'digitalizationProjectsList',
          'digitalizationProjectDetails',
          'contentTypeConfiguration',
        ]);
      }
    },
  },

  mounted() {
    if (!this.contentTypeId) {
      router.push({
        name: 'projectsList',
      });
      return;
    }

    this.setBreadcrumb();

    this.getContentTypeName();

    if (this.mode === ContentTypeConfigurationMode.PROJECT) {
      this.$store.commit('setCurrentProjectId', this.projectId);
    } else if (
      this.mode === ContentTypeConfigurationMode.DIGITALIZATION_PROJECT
    ) {
      this.$store.commit('setCurrentDigitalizationProjectId', this.projectId);
    }
  },
});
