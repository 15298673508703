import StyledMultiselect from '@/components/StyledMultiselect/StyledMultiselect.vue';
import StyledTagsInput from '@/components/StyledTagsInput/StyledTagsInput.vue';
import endpointsList from '@/helpers/endpointsList';
import { parseDateToUsFormat } from '@/helpers/parseDateToUsFormat';
import digitalizationProjectTabNumbersMixin, {
  DigitalizationProjectDetailViewTab,
  DigitalizationProjectTabNumbersMixin,
} from '@/views/DigitalizationProjectDetails/digitalizationProjectTabNumbersMixin';
import { IUploadRecord } from '@/views/DocumentUpload/DocumentUpload.script';
import DigitalizationUploadHelper from '@/views/DocumentUploadView/DocumentUploadHelper/DigitalizationUploadHelper';
import DocumentUploadHelperAbstract from '@/views/DocumentUploadView/DocumentUploadHelper/DocumentUploadHelperAbstract';
import { DocumentUploadMode } from '@/views/DocumentUploadView/DocumentUploadView.script';
import projectTabNumbersMixin, {
  ProjectDetailViewTab,
  ProjectTabNumbersMixin,
} from '@/views/ProjectDetails/projectTabNumbersMixin';
import { router } from '@/vue';
import axios, { AxiosResponse } from 'axios';
import { cloneDeep, set } from 'lodash';
import {
  ContentTypeFieldType,
  DEFAULT_METADATA_VALUES_SEPARATOR,
  ICheckBotMapping,
  IContentTypeField,
  IGetContentTypeAndCheckBotMappingDto,
  ORIGNINAL_FILE_NAME_METADATA_FIELD,
  UniversalMappingField,
} from 'types';
import { generateDocumentId, reverseKeyValuePairs } from 'utils';
import Vue from 'vue';
import { GlobalUtils } from '../../mixins/globalUtils';
import DocumentUploadHelper from '../../views/DocumentUploadView/DocumentUploadHelper/DocumentUploadHelper';
import UploadBox from '../UploadBox/UploadBox.vue';
import { UploadType } from '../UploadTable/UploadTable.script';
import { ButtonSize, ButtonVariant } from '../VfButton/VfButton.script';

enum FormFieldError {
  INVALID_DOCUMENT_ID = 'invalid_document_id',
  MISSING_REQUIRED = 'missing_required_field',
  WRONG_DATE_FORMAT = 'wrong_date_format',
}

export interface ISingleUploadRecord
  extends Omit<IUploadRecord, 'rawMetadata'> {}

interface IForm {
  [field: string | UniversalMappingField]: IFormField;
}

interface IFormField {
  value: string | File;
  type: ContentTypeFieldType;
  isValidated: boolean;
  error?: FormFieldError;
  possibleValues?: string[];
}

interface ISingleUploadForm {
  form: IForm;
  FormFieldError: typeof FormFieldError;
  isUploading: boolean;
  isUploadFinished: boolean;
  checkBotMapping?: Record<string, string>;

  buttonVariants: {
    tertiary: ButtonVariant.TERTIARY;
    primary: ButtonVariant.PRIMARY;
  };
  buttonSizes: {
    large: ButtonSize.LARGE;
  };
}

export default Vue.extend({
  name: 'single-upload-form',
  components: { UploadBox, StyledMultiselect, StyledTagsInput },
  mixins: [digitalizationProjectTabNumbersMixin, projectTabNumbersMixin],

  props: {
    mode: {
      type: String as () => DocumentUploadMode,
      required: true,
    },
    contentTypeFields: {
      type: Array as () => IContentTypeField[],
      required: true,
    },
    referenceNumber: {
      type: String,
      required: true,
    },
    contentTypeId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    projectNumber: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
    SAPNumber: {
      type: String,
      required: false,
    },
    supplierName: {
      type: String,
      required: false,
    },
    projectName: {
      type: String,
      required: true,
    },
    isCheckBotInspectionDisabled: {
      type: Boolean,
      required: false,
    },
  },

  data(): ISingleUploadForm {
    return {
      form: {},
      FormFieldError,
      checkBotMapping: undefined,
      isUploading: false,
      isUploadFinished: false,

      buttonVariants: {
        tertiary: ButtonVariant.TERTIARY,
        primary: ButtonVariant.PRIMARY,
      },

      buttonSizes: {
        large: ButtonSize.LARGE,
      },
    };
  },

  methods: {
    handleFile(file: File) {
      if (!file.size) {
        (this as unknown as GlobalUtils).createToast(
          this.$t('generic.warning'),
          this.$t('documentUpload.warnings.fileIsEmpty'),
          'warning',
        );

        return;
      }

      this.form.file.value = file;
      this.form.file.isValidated = true;
    },

    initializeForm() {
      set(this.form, 'documentId.value', '');
      set(this.form, 'documentId.isValidated', true);
      set(this.form, 'Revision.value', '');
      set(this.form, 'Revision.isValidated', true);
      set(this.form, 'file.value', null);
      set(this.form, 'file.isValidated', true);

      for (const field of this.contentTypeFields) {
        set(this.form, `${field.field}.value`, '');
        set(this.form, `${field.field}.type`, field.dataType);
        set(this.form, `${field.field}.isValidated`, true);
        set(this.form, `${field.field}.possibleValues`, field.possibleValues);
      }
      this.form = cloneDeep(this.form);
    },

    validateInputs() {
      for (const formField in this.form) {
        const { value, error } = this.form[formField];

        if (this.mode === DocumentUploadMode.PROJECT || formField === 'file') {
          /*
           * Validating fixed set of universal required fields
           */
          if (
            formField === 'file' ||
            formField === UniversalMappingField.DOCUMENT_ID ||
            formField === UniversalMappingField.REVISION ||
            formField === 'KKS'
          ) {
            if (value) {
              this.form[formField].isValidated = true;
              if (error === FormFieldError.MISSING_REQUIRED) {
                this.form[formField].error = undefined;
              }
            } else {
              this.form[formField].isValidated = false;
            }
            this.form = cloneDeep(this.form);
            continue;
          }
        }

        const contentTypeField = this.contentTypeFields.find(
          (field) => field.field === formField,
        );

        /*
         * Validating if the document id does not contain special signs
         */
        const ALLOWED_SIGNS_REGEX = /^[a-zA-Z0-9_-]*$/;
        const isIdValid =
          ALLOWED_SIGNS_REGEX.test(this.form.documentId.value as string) ||
          (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT &&
            !this.form.documentId.value);

        this.form.documentId.isValidated = isIdValid;
        this.form.documentId.error = isIdValid
          ? undefined
          : FormFieldError.INVALID_DOCUMENT_ID;

        // Workaround so that Vue sees changes in the nested value in v-if
        this.form = cloneDeep(this.form);

        if (this.mode === DocumentUploadMode.PROJECT) {
          /*
           * Validating required content type fields
           */
          const isRequired = contentTypeField?.required;

          if (isRequired) {
            if (!value) {
              this.form[formField].isValidated = false;
              this.form[formField].error = FormFieldError.MISSING_REQUIRED;
            }
            if (value) {
              this.form[formField].isValidated = true;
              if (error === FormFieldError.MISSING_REQUIRED) {
                this.form[formField].error = undefined;
              }
            }
            // Workaround so that Vue sees changes in the nested value in v-if
            this.form = cloneDeep(this.form);
          }
        }

        /**
         * Validating date fields
         */
        const isDateField =
          contentTypeField?.dataType === ContentTypeFieldType.DATE_TIME;
        if (
          isDateField &&
          typeof value === 'string' &&
          (this.mode === DocumentUploadMode.PROJECT ||
            (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT &&
              value !== ''))
        ) {
          const isDateValid = this.validateDateInput(value);
          if (!isDateValid) {
            this.form[formField].isValidated = false;
            this.form[formField].error = FormFieldError.WRONG_DATE_FORMAT;
          } else {
            this.form[formField].isValidated = true;
            if (error === FormFieldError.WRONG_DATE_FORMAT) {
              this.form[formField].error = undefined;
            }
          }
          // Workaround so that Vue sees changes in the nested value in v-if
          this.form = cloneDeep(this.form);
        }
      }

      return Object.values(this.form).every(
        (field: any) => field.isValidated === true,
      );
    },

    validateDateInput(date: string) {
      const DATE_VALIDATION_REGEX =
        /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.\d{4}$/;

      return DATE_VALIDATION_REGEX.test(date);
    },

    markRequiredField(contentTypeField: string) {
      const field = this.contentTypeFields.find(
        (field) => field.field === contentTypeField,
      );

      if (
        this.mode === DocumentUploadMode.PROJECT &&
        (field?.required ||
          contentTypeField === UniversalMappingField.DOCUMENT_ID ||
          contentTypeField === UniversalMappingField.REVISION ||
          contentTypeField === 'KKS')
      ) {
        return '*';
      }

      return '';
    },

    getCheckBotField(contentTypeField: string): string {
      const checkBotMappingField = this.checkBotMapping?.[contentTypeField];
      if (!checkBotMappingField) {
        return '';
      }

      return `(${checkBotMappingField})`;
    },

    createUploadRecord(data: IForm): ISingleUploadRecord {
      const newRecord: ISingleUploadRecord = {
        uploadType: UploadType.NEW_DOCUMENT,
        file: data.file.value as File,
        isNotFoundError: false,
        isEmptyFile: false,
      };

      for (const element in data) {
        if (element === 'file') {
          continue;
        }

        if (data[element].value) {
          if (data[element].type === ContentTypeFieldType.DATE_TIME) {
            const parsedDate = parseDateToUsFormat(
              data[element].value as string,
            );
            set(newRecord, `metadata.${element}`, parsedDate);
          } else {
            set(newRecord, `metadata.${element}`, data[element].value);
          }
        }
      }

      if (!newRecord.metadata?.documentId) {
        set(newRecord, 'metadata.documentId', generateDocumentId());
      }

      if (data.file) {
        set(
          newRecord,
          `metadata.${ORIGNINAL_FILE_NAME_METADATA_FIELD}`,
          (data.file.value as File).name,
        );
      }

      return newRecord;
    },

    async handleSubmit() {
      if (this.isUploading) {
        return;
      }

      if (!this.validateInputs()) {
        return;
      }

      const record = this.createUploadRecord(this.form);

      let uploadHelper: DocumentUploadHelperAbstract;
      if (this.mode === DocumentUploadMode.PROJECT) {
        uploadHelper = new DocumentUploadHelper(
          this.projectName,
          this.projectId,
          this.projectNumber,
          this.supplierName,
          this.supplierId,
          this.SAPNumber,
          this.referenceNumber,
          this.contentTypeId,
          [record],
        );
      } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
        uploadHelper = new DigitalizationUploadHelper(
          this.projectName,
          this.projectId,
          this.projectNumber,
          this.referenceNumber,
          this.contentTypeId,
          [record],
          this.isCheckBotInspectionDisabled,
        );
      } else {
        throw new Error(`Unknown upload mode: ${this.mode}`);
      }

      this.isUploading = true;

      await uploadHelper.finalizeUploadToSharepoint();
      await uploadHelper.postDocumentsUploadsToDB();

      this.isUploadFinished = true;
      this.isUploading = false;

      this.initializeForm();

      (this as unknown as GlobalUtils).createToast(
        this.$tc('documentUpload.uploadSuccessfullShort', 1),
        this.$tc('documentUpload.uploadSuccessfull', 1),
        'success',
      );

      setTimeout(() => {
        this.goToProjectDetails();
      }, 3000);
    },

    preventDragDefaults(e: any) {
      e.preventDefault();
      e.stopPropagation();
    },

    goToProjectDetails() {
      if (this.mode === DocumentUploadMode.PROJECT) {
        const tabNumber = (
          this as unknown as ProjectTabNumbersMixin
        ).getProjectDetailViewTabNumber(ProjectDetailViewTab.UPLOADS);

        router.push({
          name: 'projectDetails',
          params: {
            id: this.projectId,
            tabNumber,
          },
        });
      } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
        const tabNumber = (
          this as unknown as DigitalizationProjectTabNumbersMixin
        ).getDigitalizationProjectDetailViewTabNumber(
          DigitalizationProjectDetailViewTab.UPLOADS,
        );

        router.push({
          name: 'digitalizationProjectDetails',
          params: {
            id: this.projectId,
            tabNumber,
          },
        });
      }
    },

    handleCancelClick() {
      this.goToProjectDetails();
    },

    async getCheckBotMapping() {
      try {
        if (this.mode === DocumentUploadMode.PROJECT) {
          const response: AxiosResponse<Omit<ICheckBotMapping, '_id'>> =
            await axios.get(
              endpointsList.contentTypeMappings.getCheckBotMapping(
                this.contentTypeId,
              ),
            );

          const checkBotMapping = response.data;
          // Switching key value pairs to be able to access check bot fields by
          // content type fields
          this.checkBotMapping = reverseKeyValuePairs(checkBotMapping);
        } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
          const response: AxiosResponse<IGetContentTypeAndCheckBotMappingDto> =
            await axios.get(
              endpointsList.contentTypeMappings.getDigitalizationContentTypeAndCheckBotMapping(
                this.contentTypeId,
                this.projectId,
              ),
            );

          const checkBotMapping = response.data.checkBotMapping;
          // Switching key value pairs to be able to access check bot fields by
          // content type fields
          this.checkBotMapping = reverseKeyValuePairs(checkBotMapping);
        }
      } catch {
        //
      }
    },

    isDigitalizationProjectMode(): boolean {
      return this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT;
    },

    isSingleValueTaxonomyField(field: string): boolean {
      return (
        this.form[field] &&
        this.form[field].type === ContentTypeFieldType.TAXONOMY_FIELD_TYPE
      );
    },

    isMultiValueTaxonomyField(field: string): boolean {
      return (
        this.form[field] &&
        this.form[field].type === ContentTypeFieldType.TAXONOMY_FIELD_TYPE_MULTI
      );
    },

    handleTagsChanged(field: string, tags: string[]): void {
      this.form[field].value = tags.join(DEFAULT_METADATA_VALUES_SEPARATOR);
    },

    getCurrentTags(field: string): string[] {
      return (
        (this.form[field]?.value as string).split(
          DEFAULT_METADATA_VALUES_SEPARATOR,
        ) ?? []
      );
    },
  },

  mounted() {
    this.initializeForm();
    this.getCheckBotMapping();
    window.addEventListener('dragover', this.preventDragDefaults);
    window.addEventListener('drop', this.preventDragDefaults);
  },

  beforeDestroy() {
    window.removeEventListener('dragover', this.preventDragDefaults);
    window.removeEventListener('drop', this.preventDragDefaults);
  },
});
