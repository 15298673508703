import { render, staticRenderFns } from "./ContentTypeConfiguration.vue?vue&type=template&id=1c93b777&scoped=true"
import script from "./ContentTypeConfiguration.script.ts?vue&type=script&lang=js&external"
export * from "./ContentTypeConfiguration.script.ts?vue&type=script&lang=js&external"
import style0 from "./ContentTypeConfiguration.styles.scss?vue&type=style&index=0&id=1c93b777&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c93b777",
  null
  
)

export default component.exports