import { IKksValidationListDto } from 'types';
import Vue from 'vue';

export default Vue.extend({
  name: 'check-bot-established-kks',

  props: {
    isValidatingKks: {
      type: Boolean,
      default: false,
    },
    kksList: {
      type: Array as () => string[],
      default: () => [],
    },
    kksValidationList: {
      type: Object as () => IKksValidationListDto,
      default: () => {},
    },
  },

  methods: {
    handleKksClick(kks: string) {
      this.$emit('kks-clicked', kks);
    },

    emitListHeight() {
      this.$nextTick(() => {
        this.$emit(
          'height-changed',
          (this.$refs.kksList as HTMLElement).offsetHeight,
        );
      });
    },
  },

  mounted() {
    this.emitListHeight();
  },
});
