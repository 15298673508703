import { Role } from 'types';

/** Roles used in supplier projects */
export type ProjectRole =
  | Role.Admin
  | Role.DocuManager
  | Role.Inspector
  | Role.Coworker;

/** Roles used in digitalization projects */
export type DigitalizationProjectRole =
  | Role.Admin
  | Role.DocuResponsible
  | Role.DigitalizationCoworker;

export interface ITableRow<T> {
  item: T;
}

export enum ComponentMode {
  PROJECT = 'project',
  DIGITALIZATION_PROJECT = 'digitalization-project',
}
