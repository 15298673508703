import { IDigitalizationProjectContentTypesAssignmentsSummaryDto } from 'types';
import { DocumentUploadMode } from '@/views/DocumentUploadView/DocumentUploadView.script';
import axios, { AxiosResponse } from 'axios';
import Vue from 'vue';
import StyledModal from '../../components/StyledModal/StyledModal.vue';
import StyledMultiselect from '../../components/StyledMultiselect/StyledMultiselect.vue';
import endpointsList from '../../helpers/endpointsList';
import { GlobalUtils } from '../../mixins/globalUtils';
import { router } from '../../vue';

interface INewUplaodModal {
  allContentTypes: IDigitalizationProjectContentTypesAssignmentsSummaryDto[];
  errorMessages: {
    referenceNumber: boolean;
    referenceNumberUnique: boolean;
    contentType: boolean;
  };
  isCheckBotInspectionDisabled: boolean;
  isLoadingContentTypes: boolean;
  isNoMappingAssignedError: boolean;
  isNoMetadataUploadMode: boolean;
  isSubmitted: boolean;
  isValidated: boolean;
  isValidatingSubmit: boolean;
  referenceNumber: string;
  selectedContentType?: IDigitalizationProjectContentTypesAssignmentsSummaryDto;
}

export default Vue.extend({
  name: 'new-digitalization-upload-modal',
  components: { StyledModal, StyledMultiselect },

  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
    digitalizationProjectNumber: {
      type: String,
      required: true,
    },
    digitalizationProjectTitle: {
      type: String,
      required: true,
    },
  },

  data(): INewUplaodModal {
    return {
      allContentTypes: [],
      errorMessages: {
        referenceNumber: false,
        referenceNumberUnique: false,
        contentType: false,
      },
      isCheckBotInspectionDisabled: false,
      isLoadingContentTypes: false,
      isNoMappingAssignedError: false,
      isNoMetadataUploadMode: false,
      isSubmitted: false,
      isValidated: false,
      isValidatingSubmit: false,
      referenceNumber: '',
      selectedContentType: undefined,
    };
  },

  methods: {
    async getContentTypesForProject() {
      this.isLoadingContentTypes = true;

      try {
        const response = await axios.get(
          endpointsList.contentTypeMappings.getDigitalizationProjectContentTypesSummary(
            this.digitalizationProjectId,
          ),
        );
        this.allContentTypes = response.data;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }

      this.isLoadingContentTypes = false;
    },

    async handleModalOK(e: Event) {
      e.preventDefault();
      await this.validateSubmit();
      this.isSubmitted = true;
      if (!this.isValidated || !this.selectedContentType) {
        return;
      }

      router.push({
        name: 'documentUpload',
        params: {
          mode: DocumentUploadMode.DIGITALIZATION_PROJECT,
          referenceNumber: this.referenceNumber,
          contentTypeId: this.selectedContentType._id,
          projectId: this.digitalizationProjectId,
          projectName: this.digitalizationProjectTitle,
          projectNumber: this.digitalizationProjectNumber,
        },
        query: {
          isCheckBotInspectionDisabled:
            this.isCheckBotInspectionDisabled.toString(),
          isNoMetadataUploadMode: this.isNoMetadataUploadMode.toString(),
        },
      });
    },

    async validateSubmit() {
      this.isValidatingSubmit = true;
      this.errorMessages.referenceNumber = !!this.referenceNumber;
      this.errorMessages.contentType = !!this.selectedContentType;
      await this.checkIsReferenceNumberUnique();

      if (Object.values(this.errorMessages).some((value) => !value)) {
        !this.isValidated;
        this.isValidatingSubmit = false;
        return;
      }

      /**
       * If the upload is in no-metadata mode, the content type mapping is not
       * necessary.
       */
      if (
        !this.isNoMetadataUploadMode &&
        !this.selectedContentType?.isMapping
      ) {
        this.isNoMappingAssignedError = true;
        this.isValidated = false;
        this.isValidatingSubmit = false;
        return;
      }

      this.isValidated = true;
      this.isValidatingSubmit = false;
    },

    async checkIsReferenceNumberUnique() {
      try {
        const response: AxiosResponse<boolean> = await axios.post(
          endpointsList.digitalizationUploads.validateReferenceNumber(
            this.digitalizationProjectId,
            encodeURIComponent(this.referenceNumber),
          ),
        );

        this.errorMessages.referenceNumberUnique = response.data;
      } catch (err) {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('documentUpload.errors.validationError'),
          'danger',
        );
      }
    },

    onModalShow() {
      this.getContentTypesForProject();
    },
  },
});
