import DigitalizationDocumentsOverview from '@/components/DigitalizationDocumentsOverview/DigitalizationDocumentsOverview.vue';
import DigitalizationProjectCoworkers from '@/components/DigitalizationProjectCoworkers/DigitalizationProjectCoworkers.vue';
import DigitalizationProjectInformations from '@/components/DigitalizationProjectInformations/DigitalizationProjectInformations.vue';
import DigitalizationProjectSettings from '@/components/DigitalizationProjectSettings/DigitalizationProjectSettings.vue';
import DigitalizationUploadsOverview from '@/components/DigitalizationUploadsOverview/DigitalizationUploadsOverview.vue';
import endpointsList from '@/helpers/endpointsList';
import { IDigitalizationProjectSettings, Role } from 'types';
import { GlobalUtils } from '@/mixins/globalUtils';
import { router } from '@/vue';
import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';
import commonProjectUtils from '@/mixins/commonProjectUtils';

interface IDigitalizationProjectDetails {
  activeTabIndex: number;
  digitalizationProjectSettings?: IDigitalizationProjectSettings;
  allowedRoles: {
    [key: string]: Role[];
  };
}

export default Vue.extend({
  name: 'digitalization-project-details',

  mixins: [commonProjectUtils],

  components: {
    DigitalizationDocumentsOverview,
    DigitalizationProjectCoworkers,
    DigitalizationProjectInformations,
    DigitalizationProjectSettings,
    DigitalizationUploadsOverview,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    tabNumber: {
      type: String,
      required: false,
      default: '0',
    },
  },

  data(): IDigitalizationProjectDetails {
    return {
      activeTabIndex: 0,
      digitalizationProjectSettings: undefined,
      allowedRoles: {
        digitalizationProjectSettings: [Role.Admin, Role.DocuResponsible],
        digitalizationProjectCoworkers: [Role.Admin, Role.DocuResponsible],
      },
    };
  },

  methods: {
    setActiveTabIndex() {
      if (this.tabNumber !== undefined) {
        this.activeTabIndex = Number(this.tabNumber);
      }
    },

    async getDigitalizationProjectSettings() {
      try {
        const response = await axios.get(
          endpointsList.digitalizationProjects.getDigitalizationProjectSettings(
            this.id,
          ),
        );

        this.digitalizationProjectSettings = response.data;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t(
            'digitalizationProjectDetails.errors.errorLoadingProjectData',
          ),
          'danger',
        );
      }
    },

    resetDigitalizationDocumentsPrefiltering(): void {
      this.$store.commit(
        'setCurrentDigitalizationUploadReferenceNumber',
        undefined,
      );
    },
  },

  watch: {
    activeTabIndex() {
      this.$router
        .replace({
          params: { tabNumber: this.activeTabIndex.toString() },
        })
        .catch((err) => {
          if (
            VueRouter.isNavigationFailure(
              err,
              VueRouter.NavigationFailureType.duplicated,
            )
          ) {
            return;
          }
          throw err;
        });

      this.$store.commit(
        'setDigitalizationProjectDetailViewTabNumber',
        this.activeTabIndex,
      );
    },

    $route(to) {
      this.activeTabIndex = Number(to.params.tabNumber);
    },
  },

  mounted() {
    if (this.id === undefined) {
      router.push({ name: 'digitalizationProjectsList' });
      return;
    }

    this.$store.commit('setCurrentDigitalizationProjectId', this.id);
    this.$nextTick(() => this.setActiveTabIndex());
    this.getDigitalizationProjectSettings();
  },
});
