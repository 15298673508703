export function truncateValue(
  item: string | undefined,
  maxCharacters: number,
): string {
  if (item && item.length > maxCharacters) {
    const slicedItem = item.slice(0, maxCharacters);
    return `${slicedItem}...`;
  }
  return item ?? '';
}
