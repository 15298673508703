import {
  IEmittedProject,
  ProjectFormMode,
} from '@/components/ProjectForm/ProjectForm.script';
import ProjectForm from '@/components/ProjectForm/ProjectForm.vue';
import endpointsList from '@/helpers/endpointsList';
import { ICreateDigitalizationProjectDto } from 'types';
import { ToastMixin } from '@/mixins/globalUtils';
import axios, { AxiosError } from 'axios';
import Vue from 'vue';

interface ICreateDigitalizationProject {
  addedDocuResponsibles: string[];
  createToast?: ToastMixin;
  isCreatingDigitalizationProject: boolean;
  isMissingDocuResponsibleError: boolean;
  isSubmitted: boolean;
  projectFormMode: ProjectFormMode.CREATE_DIGITALIZATION_PROJECT;
}

export default Vue.extend({
  name: 'create-digitalization-project',
  components: { ProjectForm },
  data(): ICreateDigitalizationProject {
    return {
      addedDocuResponsibles: [],
      isCreatingDigitalizationProject: false,
      isMissingDocuResponsibleError: false,
      isSubmitted: false,
      projectFormMode: ProjectFormMode.CREATE_DIGITALIZATION_PROJECT,
    };
  },

  methods: {
    async handleSubmit(
      digitalizationProjectData: IEmittedProject,
    ): Promise<void> {
      if (!this.addedDocuResponsibles.length) {
        this.isMissingDocuResponsibleError = true;

        return;
      }

      const createDigitalizationProjectDto: ICreateDigitalizationProjectDto = {
        title: digitalizationProjectData.projectTitle,
        number: digitalizationProjectData.projectNumber,
        site: digitalizationProjectData.projectLocation,
        docuResponsibles: this.addedDocuResponsibles,
        description: digitalizationProjectData.projectDescription,
        duration: {
          durationStart:
            digitalizationProjectData.projectDuration.durationStart ||
            undefined,
          durationEnd:
            digitalizationProjectData.projectDuration.durationEnd || undefined,
        },
      };

      try {
        this.isCreatingDigitalizationProject = true;
        const response = await axios.post<string>(
          endpointsList.digitalizationProjects.createDigitalizationProject,
          createDigitalizationProjectDto,
        );
        const savedDigitalizationProjectId = response.data;

        this.$router.push({
          name: 'digitalizationProjectDetails',
          params: {
            id: savedDigitalizationProjectId,
          },
        });
      } catch (err) {
        if (err instanceof AxiosError && err.response?.status === 409) {
          this.createToast!(
            this.$t('errors.error'),
            this.$t(
              'createDigitalizationProject.errors.digitalizationProjectNumberNotUnique.long',
            ),
            'danger',
          );
        } else {
          this.createToast!(
            this.$t('errors.error'),
            this.$t(
              'createDigitalizationProject.errors.errorCreatingDigitalizationProject.long',
            ),
            'danger',
          );
        }
      } finally {
        this.isCreatingDigitalizationProject = false;
      }
    },

    toggleAddDocuResponsible(oid: string) {
      if (!this.addedDocuResponsibles.includes(oid)) {
        this.addDocuResponsible(oid);
      } else {
        this.deleteDocuResponsible(oid);
      }
    },

    addDocuResponsible(oid: string) {
      if (!this.addedDocuResponsibles.includes(oid)) {
        this.addedDocuResponsibles.push(oid);
      }
    },

    deleteDocuResponsible(oid: string) {
      const index = this.addedDocuResponsibles.indexOf(oid);
      this.addedDocuResponsibles.splice(index, 1);
    },
  },
});
