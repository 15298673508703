import { render, staticRenderFns } from "./UserInfo.vue?vue&type=template&id=1abc8022"
import script from "./UserInfo.script.ts?vue&type=script&lang=ts&external"
export * from "./UserInfo.script.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./UserInfo.styles.scss?vue&type=custom&index=0&blockType=styles&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fpackages%2Ffrontend%2Fsrc%2Fcomponents%2FUserInfo%2FUserInfo.vue&scoped=true&external"
if (typeof block0 === 'function') block0(component)

export default component.exports