import Vue from 'vue';
import KksPicker from '@/components/KksPicker/KksPicker.vue';
import kksValidator from '@/mixins/kksValidator';
import { DEFAULT_METADATA_VALUES_SEPARATOR } from 'types';
import { MetadataGridEditorProvider } from '../../MetadataGridEditor.script';

const inject: (keyof MetadataGridEditorProvider)[] = [
  'fieldInfo',
  'projectLocation',
];

type KksEditor = {
  currentInput: string[];
  valuesSeparator: string;
};

export default Vue.extend({
  components: { KksPicker },
  mixins: [kksValidator],
  props: {
    close: {
      type: Function,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    prop: {
      type: String,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  inject,
  data(): KksEditor {
    return {
      currentInput: [],
      valuesSeparator: DEFAULT_METADATA_VALUES_SEPARATOR,
    };
  },
  methods: {
    async handleTagsChanged(input: string[]): Promise<void> {
      this.currentInput = input;
    },

    saveInput(): void {
      this.save(this.currentInput.join(this.valuesSeparator));
    },
  },
  beforeDestroy() {
    this.saveInput();
  },
});
