"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.INSPECTION_DONE_STATUSES = exports.POSITIVE_INSPECTION_STATUSES = exports.waitingInspectorStatuses = exports.negativeFormalControlStatuses = exports.WAITING_FORMAL_CONTROL_STATUSES = exports.actionableFormalControlStatuses = exports.positiveFormalControlStatuses = exports.ORIGNINAL_FILE_NAME_METADATA_FIELD = exports.DEFAULT_TRANSFERED_DOC_STATUS = exports.DEFAULT_NEW_DOC_STATUS = exports.DEFAULT_CONFIDENTIAL_CLASS = exports.KKS_METADATA_FIELD = exports.OBJECT_ID_KKS_SEPARATOR = exports.EDITABLE_METADATA_FIELDS = void 0;
const formal_control_status_enum_1 = require("./formal-control-status.enum");
const inspector_status_enum_1 = require("./inspector-status.enum");
exports.EDITABLE_METADATA_FIELDS = [
    'KKS',
    'DocumentClass',
    'DocumentClassContent',
];
exports.OBJECT_ID_KKS_SEPARATOR = '#';
exports.KKS_METADATA_FIELD = 'KKS';
exports.DEFAULT_CONFIDENTIAL_CLASS = 'C3';
exports.DEFAULT_NEW_DOC_STATUS = 'neu';
exports.DEFAULT_TRANSFERED_DOC_STATUS = 'freigegeben';
exports.ORIGNINAL_FILE_NAME_METADATA_FIELD = 'OriginalFilename';
exports.positiveFormalControlStatuses = [
    formal_control_status_enum_1.FormalControlStatus.ACCEPTED,
    formal_control_status_enum_1.FormalControlStatus.ACCEPTED_WITH_REMARKS,
];
exports.actionableFormalControlStatuses = [
    formal_control_status_enum_1.FormalControlStatus.ACCEPTED_WITH_REMARKS,
    formal_control_status_enum_1.FormalControlStatus.REJECTED,
];
exports.WAITING_FORMAL_CONTROL_STATUSES = [
    formal_control_status_enum_1.FormalControlStatus.WAITING,
];
exports.negativeFormalControlStatuses = [
    formal_control_status_enum_1.FormalControlStatus.REJECTED,
];
exports.waitingInspectorStatuses = [
    inspector_status_enum_1.InspectorStatusEnum.waiting,
    inspector_status_enum_1.InspectorStatusEnum.waitingForFormalControl,
];
exports.POSITIVE_INSPECTION_STATUSES = [
    inspector_status_enum_1.InspectorStatusEnum.accepted,
    inspector_status_enum_1.InspectorStatusEnum.acceptedWithRemarks,
];
exports.INSPECTION_DONE_STATUSES = [
    inspector_status_enum_1.InspectorStatusEnum.accepted,
    inspector_status_enum_1.InspectorStatusEnum.acceptedWithRemarks,
    inspector_status_enum_1.InspectorStatusEnum.rejected,
];
