import ContentTypeManagementTable from '@/components/ContentTypeManagementTable/ContentTypeManagementTable';
import InspectionTimeConfiguration from '@/components/InspectionTimeConfiguration/InspectionTimeConfiguration';

export default {
  name: 'project-configuration',
  components: { ContentTypeManagementTable, InspectionTimeConfiguration },
  props: ['id'],
  data() {
    return {};
  },
};
