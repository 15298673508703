import MetadataModal from '../MetadataModal/MetadataModal.vue';
import axios from 'axios';
import endpointsList from '../../helpers/endpointsList';
import {
  IFileUploadStatuses,
  IUploadRecord,
} from '../../views/DocumentUpload/DocumentUpload.script';
import { IFileMetadata, IMetadataDifferences } from 'types';
import { TranslateResult } from 'vue-i18n';
import Vue from 'vue';
import { ToastMixin } from '../../mixins/globalUtils';
import FilterableTable from '../FilterableTable/FilterableTable.vue';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import tickIcon from '../../assets/icons/tick.svg';
import crossIcon from '../../assets/icons/cross.svg';
import uploadIcon from '../../assets/icons/upload.svg';
import redCrossIcon from '../../assets/icons/remove-cross.svg';
import penIcon from '../../assets/icons/mask.svg';
import { debounce } from 'lodash';
import { DocumentUploadMode } from '@/views/DocumentUploadView/DocumentUploadView.script';

export enum UploadType {
  NEW_DOCUMENT = 'new_document',
  UPDATED_DOCUMENT = 'updated_document',
  UPDATED_METADATA = 'updated_metadata',
}

enum ModalMode {
  NORMAL = 'normal',
  COMPARISON = 'comparison',
}

interface IUplaodTable {
  metadataForModal: IFileMetadata;
  modalMode: ModalMode;
  modalMetadataChanges: any; // TODO: check type
  IdsOfOldFileVersions: string[];
  metadataChanges: IMetadataDifferences;
  sortBy: string;
  tableFilterConfiguration: IFilterConfiguration;
  tableFilterGridConfiguration: string[];
  tickIcon: string;
  crossIcon: string;
  uploadIcon: string;
  redCrossIcon: string;
  penIcon: string;
  createToast?: ToastMixin;
}

export default Vue.extend({
  name: 'upload-table',
  props: {
    mode: {
      type: String as () => DocumentUploadMode,
      required: true,
    },
    uploadRecords: {
      type: Array as () => IUploadRecord[],
      required: true,
    },
    fileUploadStatuses: {
      type: Object as () => IFileUploadStatuses,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
    tableBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: { MetadataModal, FilterableTable },
  data(): IUplaodTable {
    return {
      metadataForModal: {} as IFileMetadata,
      modalMode: ModalMode.NORMAL,
      modalMetadataChanges: '',

      /**
       * Document IDs of files that were already
       * uploaded by this supplier in this project.
       */
      IdsOfOldFileVersions: [],

      metadataChanges: {},

      sortBy: '',
      tableFilterConfiguration: {
        'metadata.documentId': {
          type: FilterType.INPUT,
          column: 1,
          width: '400px',
          customLabel: this.$i18n.t('filterableTable.filterBy', {
            field: 'id',
          }),
        },
        uploadType: {
          type: FilterType.SELECT,
          column: 2,
          width: '190px',
          translateOptions: true,
        },
        'metadata.Filepath': {
          type: FilterType.INPUT,
          column: 3,
          width: '200px',
          customLabel: this.$i18n.t('documentUpload.filepath'),
        },
        fileName: {
          type: FilterType.INPUT,
          column: 4,
          width: '200px',
        },
      },
      tableFilterGridConfiguration: ['36%', '14%', '23%', '27%'],
      crossIcon,
      tickIcon,
      penIcon,
      redCrossIcon,
      uploadIcon: uploadIcon,
    };
  },

  computed: {
    tableFields(): {
      key: string;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
      thClass?: string[];
    }[] {
      return [
        {
          key: 'documentId',
          label: this.$i18n.t('documentUpload.id'),
          sortable: true,
          tdClass: ['w-36', 'align-middle', 'break-all-words'],
        },
        {
          key: 'uploadType',
          label: this.$i18n.t('documentUpload.upload'),
          sortable: true,
          tdClass: ['w-14', 'align-middle'],
        },
        {
          key: 'filepath',
          label: this.$i18n.t('documentUpload.filepath'),
          sortable: true,
          tdClass: ['w-23', 'align-middle', 'break-all-words'],
        },
        {
          key: 'fileName',
          label: this.$i18n.t('documentUpload.file'),
          sortable: true,
          tdClass: ['w-27', 'align-middle', 'break-all-words'],
        },
      ];
    },

    tableTotalRows(): number {
      return this.uploadRecords.length;
    },
  },

  methods: {
    deleteRecord(id: string) {
      const updatedRecords = this.uploadRecords.filter(
        (record) => record?.metadata?.documentId !== id,
      );
      this.$emit('updated-records', updatedRecords);
    },

    uploadFile(event: InputEvent, id: string) {
      const target = event.target as HTMLInputElement;
      const updatedFile = target?.files?.[0];
      if (!updatedFile) {
        return;
      }

      this.exchangeFileInRecord(id, updatedFile);
    },

    exchangeFileInRecord(id: string, file: File) {
      const indexOfRecordToUpdate = this.uploadRecords.findIndex(
        (record) => record?.metadata?.documentId === id,
      );
      const recordToUpdate = this.uploadRecords[indexOfRecordToUpdate];
      recordToUpdate.file = file;
      recordToUpdate.isNotFoundError = false;
      recordToUpdate.isEmptyFile = !file.size;

      this.$emit('updated-records', this.uploadRecords);
    },

    async getMetadataChanges() {
      const uploadRecordsMetadata = this.uploadRecords.map(
        (record) => record.metadata,
      );

      try {
        if (this.mode === DocumentUploadMode.PROJECT) {
          const response = await axios.post(
            endpointsList.documentsUploads.getMetadataChanges(
              this.projectId,
              this.supplierId,
            ),
            uploadRecordsMetadata,
          );

          this.metadataChanges = response.data;
        } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
          const response = await axios.post(
            endpointsList.digitalizationUploads.getMetadataChanges(
              this.projectId,
            ),
            uploadRecordsMetadata,
          );

          this.metadataChanges = response.data;
        }
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('documentUpload.errors.cannotGetMetadataChanges'),
          'danger',
        );
      }
    },

    async getFileChanges() {
      const ids = this.uploadRecords.map(
        (record) => record?.metadata?.documentId,
      );

      try {
        if (this.mode === DocumentUploadMode.PROJECT) {
          const response = await axios.post(
            endpointsList.documentsUploads.getIdsOfPreviouslyUploadedFilesVersions(
              this.projectId,
              this.supplierId,
            ),
            ids,
          );
          this.IdsOfOldFileVersions = response.data;
        } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
          const response = await axios.post(
            endpointsList.digitalizationUploads.getIdsOfOlderDocumentsVersions(
              this.projectId,
            ),
            ids,
          );
          this.IdsOfOldFileVersions = response.data;
        }
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('documentUpload.errors.cannotCheckPreviousVersions'),
          'danger',
        );
      }
    },

    // Adding empty functions to the Vue instance in order
    // to later assign them in the created hook preserving
    // the this context
    debouncedGetMetadataChanges: new Function(),
    debouncedGetFileChanges: new Function(),

    appendInfoAboutMetadataChangesToRecord() {
      const uploadRecords = this.uploadRecords;

      for (const documentId in this.metadataChanges) {
        const record = uploadRecords.find(
          (record) => record?.metadata?.documentId === documentId,
        );

        record!.isChangedMetadata = true;
      }

      this.$emit('updated-records', uploadRecords);
    },

    rowColors(item: IUploadRecord) {
      if (
        this.metadataChanges[item?.metadata!.documentId] &&
        (!item.file || item.isEmptyFile)
      ) {
        return 'selected-row';
      }

      if (
        (this.metadataChanges[item?.metadata!.documentId] && item.file) ||
        this.IdsOfOldFileVersions.includes(item?.metadata!.documentId)
      ) {
        return 'selected-row-secondary';
      }
    },

    assignUploadType() {
      for (const record of this.uploadRecords) {
        if (!this.metadataChanges[record.metadata!.documentId]) {
          continue;
        }

        if (record.file) {
          record.uploadType = UploadType.UPDATED_DOCUMENT;
        } else {
          record.uploadType = UploadType.UPDATED_METADATA;
        }
      }
    },

    populateModal(metadata: IFileMetadata) {
      this.metadataForModal = metadata;

      if (this.metadataChanges[metadata.documentId]) {
        this.modalMetadataChanges = this.metadataChanges[metadata.documentId];
        this.modalMode = ModalMode.COMPARISON;

        return;
      }

      this.modalMetadataChanges = {};
      this.modalMode = ModalMode.NORMAL;
    },
  },

  watch: {
    uploadRecords: {
      deep: true,
      handler() {
        this.debouncedGetFileChanges();
        this.debouncedGetMetadataChanges();
      },
    },
    metadataChanges: {
      deep: true,
      handler() {
        this.assignUploadType();
        this.appendInfoAboutMetadataChangesToRecord();
      },
    },
  },

  created() {
    this.debouncedGetMetadataChanges = debounce(this.getMetadataChanges, 500);
    this.debouncedGetFileChanges = debounce(this.getFileChanges, 500);

    this.getMetadataChanges();
    this.getFileChanges();
  },
});
