import { UploadType } from '@/components/UploadTable/UploadTable.script';
import { stringToBoolean } from '@/helpers';
import { parseDateToUsFormat } from '@/helpers/parseDateToUsFormat';
import axios, { AxiosResponse } from 'axios';
import { cloneDeep } from 'lodash';
import {
  AssignmentType,
  ContentTypeFieldType,
  IFieldMapping,
  IFileMetadata,
  IGetContentTypeAndCheckBotMappingDto,
  ORIGNINAL_FILE_NAME_METADATA_FIELD,
} from 'types';
import { generateDocumentId } from 'utils';
import Vue from 'vue';
import GoBack from '../../components/GoBack/GoBack.vue';
import MissingFilesModal from '../../components/MissingFilesModal/MissingFilesModal.vue';
import UploadFrames from '../../components/UploadFrames/UploadFrames.vue';
import UploadModal from '../../components/UploadModal/UploadModal.vue';
import UploadTable from '../../components/UploadTable/UploadTable.vue';
import endpointsList from '../../helpers/endpointsList';
import { ToastMixin } from '../../mixins/globalUtils';
import { router } from '../../vue';
import digitalizationProjectTabNumbersMixin, {
  DigitalizationProjectDetailViewTab,
  DigitalizationProjectTabNumbersMixin,
} from '../DigitalizationProjectDetails/digitalizationProjectTabNumbersMixin';
import DigitalizationUploadHelper from '../DocumentUploadView/DocumentUploadHelper/DigitalizationUploadHelper';
import DocumentUploadHelper from '../DocumentUploadView/DocumentUploadHelper/DocumentUploadHelper';
import DocumentUploadHelperAbstract from '../DocumentUploadView/DocumentUploadHelper/DocumentUploadHelperAbstract';
import { DocumentUploadMode } from '../DocumentUploadView/DocumentUploadView.script';
import projectTabNumbersMixin, {
  ProjectDetailViewTab,
  ProjectTabNumbersMixin,
} from '../ProjectDetails/projectTabNumbersMixin';

export interface IUploadRecord {
  file?: File;
  isChangedMetadata?: boolean;
  isEmptyFile: boolean;
  isNotFoundError: boolean;
  metadata?: IFileMetadata;
  rawMetadata: Record<string, string>;
  uploadType: UploadType;
}

export interface IMassUploadFile {
  file: File;
  path: string;
}

enum FileUploadStatus {
  UPLOADING = 'uploading',
  UPLOADED = 'uploaded',
}

export interface IFileUploadStatuses {
  [documentId: string]: FileUploadStatus;
}

interface IDocumentUploadView {
  contentTypeFieldsMapping?: IFieldMapping[];
  fileUploadStatuses: IFileUploadStatuses;
  isOldFilesDeleted: boolean;
  isUploadConfirmed: boolean;
  isUploadToDBFinished: boolean;
  isUploading: boolean;
  isUploadFinished: boolean;
  isWrongCsvError: boolean;
  massUploadFiles: IMassUploadFile[];
  missingFiles: string[];
  missingFilesWithChangedMetadata: string[];
  emptyFiles: string[];
  uploadRecords: IUploadRecord[];
  uploadFramesRerenderKey: number;
  createToast?: ToastMixin;
  isUploadFailed: boolean;
  isReadingZipFile: boolean;
  isNoMetadataUploadMode: boolean;
}

export default Vue.extend({
  name: 'document-upload',
  mixins: [digitalizationProjectTabNumbersMixin, projectTabNumbersMixin],
  components: {
    UploadFrames,
    UploadModal,
    UploadTable,
    MissingFilesModal,
    GoBack,
  },

  props: {
    mode: {
      type: String as () => DocumentUploadMode,
      required: true,
    },
    referenceNumber: {
      type: String,
      required: true,
    },
    contentTypeId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    projectNumber: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
    SAPNumber: {
      type: String,
      required: false,
    },
    supplierName: {
      type: String,
      required: false,
    },
    isCheckBotInspectionDisabled: {
      type: Boolean,
      required: false,
    },
  },

  data(): IDocumentUploadView {
    return {
      uploadRecords: [],
      massUploadFiles: [],

      contentTypeFieldsMapping: undefined,

      isUploadConfirmed: false,
      missingFiles: [],
      missingFilesWithChangedMetadata: [],
      emptyFiles: [],

      fileUploadStatuses: {},
      isUploading: false,
      isUploadFinished: false,
      isUploadToDBFinished: false,
      isOldFilesDeleted: false,

      /**
       * Is CSV missing fields that are needed according
       * to the saved content type mapping
       */
      isWrongCsvError: false,

      uploadFramesRerenderKey: 1,

      isUploadFailed: false,

      isReadingZipFile: false,

      isNoMetadataUploadMode: false,
    };
  },

  computed: {
    uploadProgress(): number {
      const uploadRecordsWithFiles = this.uploadRecords.filter(
        (record: IUploadRecord) => record.file,
      );

      const recordsCount = uploadRecordsWithFiles.length;

      const uploadedRecordsCount = Object.values(
        this.fileUploadStatuses,
      ).filter((status) => status === 'uploaded').length;

      const uploadedFilesPercentage =
        (uploadedRecordsCount / recordsCount) * 100;

      const DBUploadPercentage = this.isUploadToDBFinished ? 5 : 0;
      const deleteFilesPercentage = this.isOldFilesDeleted ? 5 : 0;

      if (!recordsCount) {
        return 90 + DBUploadPercentage + deleteFilesPercentage;
      }

      return Math.floor(
        uploadedFilesPercentage * 0.9 +
          DBUploadPercentage +
          deleteFilesPercentage,
      );
    },
  },

  methods: {
    async getFieldsMapping() {
      if (this.mode === DocumentUploadMode.PROJECT) {
        const response: AxiosResponse<IFieldMapping[]> = await axios.get(
          endpointsList.contentTypeMappings.getFieldsMapping(
            this.contentTypeId,
            this.projectId,
            this.supplierId,
          ),
        );

        this.contentTypeFieldsMapping = response.data;
      } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
        const response: AxiosResponse<IGetContentTypeAndCheckBotMappingDto> =
          await axios.get(
            endpointsList.contentTypeMappings.getDigitalizationContentTypeAndCheckBotMapping(
              this.contentTypeId,
              this.projectId,
            ),
          );

        this.contentTypeFieldsMapping = response.data.fieldMappings;
      }
    },

    handleUpdateRecords(updatedRecords: IUploadRecord[]) {
      this.uploadRecords = updatedRecords;
    },

    /**
     * Assigning values to content type fields according to configured
     * content type mapping
     */
    mapMetadata(record: IUploadRecord): IUploadRecord {
      if (!this.contentTypeFieldsMapping) {
        throw new Error('Fields mapping is not available');
      }

      const mappedMetadata: IFileMetadata = {} as IFileMetadata;

      this.contentTypeFieldsMapping.forEach((mapping) => {
        if (mapping.assignmentType === AssignmentType.csvField) {
          /**
           * Mapping values from CSV fields
           */
          const valueFromCsv = record.rawMetadata[mapping.value];

          if (mapping.dataType === ContentTypeFieldType.DATE_TIME) {
            const parsedDate = parseDateToUsFormat(valueFromCsv);
            mappedMetadata[mapping.field] = parsedDate;
          } else {
            mappedMetadata[mapping.field] = valueFromCsv;
          }
        } else if (
          /**
           * Assigning constant values
           */
          mapping.assignmentType === AssignmentType.constantAssignment
        ) {
          mappedMetadata[mapping.field] = mapping.value;
        } else if (mapping.assignmentType === AssignmentType.currentDate) {
          /**
           * Mapping current dates in en-US format accepted by Sharepoint
           */
          mappedMetadata[mapping.field] = new Date().toLocaleDateString(
            'en-US',
          );
        }
      });

      if (!mappedMetadata.fileName) {
        mappedMetadata[ORIGNINAL_FILE_NAME_METADATA_FIELD] =
          this.getFileNameFromFilepath(mappedMetadata.Filepath);
      }

      record.metadata = mappedMetadata;

      return record;
    },

    getFileNameFromFilepath(filepath: string): string {
      const splitFilepath = filepath.split('/');
      const fileName = splitFilepath[splitFilepath.length - 1];
      return fileName;
    },

    handleReadingZipFile() {
      this.isReadingZipFile = true;
    },

    handleZipReadingError() {
      this.isReadingZipFile = false;
    },

    addMassUploadRecords(records: IUploadRecord[]) {
      if (!this.isNoMetadataUploadMode) {
        this.validateCSV(records);
      }
      if (this.isWrongCsvError) {
        return;
      }

      records.forEach((record) => {
        if (this.isNoMetadataUploadMode) {
          record.metadata ??= {} as IFileMetadata;
          record.metadata.documentId = generateDocumentId();
          record.metadata[ORIGNINAL_FILE_NAME_METADATA_FIELD] =
            record.file?.name;

          this.uploadRecords.push(record);

          return;
        }

        const recordWithMappedMetadata = this.mapMetadata(record);

        if (!Object.values(recordWithMappedMetadata.metadata ?? {}).length) {
          return;
        }

        /**
         * If the filepath is not present, than this record is empty (probably
         * empty row from CSV) and should be ignored.
         */
        if (!recordWithMappedMetadata.metadata?.Filepath) {
          return;
        }

        if (!recordWithMappedMetadata.metadata?.documentId) {
          recordWithMappedMetadata.metadata!.documentId = generateDocumentId();
        }

        const isUploadRecordPresent = this.uploadRecords.some(
          (presentUploadRecord) =>
            presentUploadRecord.metadata?.documentId ===
            record?.metadata?.documentId,
        );

        if (!isUploadRecordPresent) {
          this.uploadRecords.push(recordWithMappedMetadata);
        }
      });
    },

    validateCSV(records: IUploadRecord[]) {
      if (!this.contentTypeFieldsMapping) {
        throw new Error('Content type fields mapping not available');
      }

      this.isWrongCsvError = false;

      /**
       * Checking if some record does not miss a field that should
       * be mapped according to the saved CSV mapping
       */
      records.forEach((record) => {
        const csvFieldsForMapping = this.contentTypeFieldsMapping!.filter(
          (mapping) => mapping.assignmentType === AssignmentType.csvField,
        );

        this.isWrongCsvError = csvFieldsForMapping.some(
          (field) =>
            record.rawMetadata[field.value] === undefined && field.value !== '',
        );
      });
    },

    addMassUploadFiles(files: IMassUploadFile[]) {
      this.massUploadFiles = this.massUploadFiles.concat(files);
    },

    mapFilesToFilePaths(warnAboutNoFilesMatched = false) {
      let isAtLeastOneFileFound = false;
      this.massUploadFiles.forEach((file) => {
        const record = this.uploadRecords.find((record) => {
          /**
           * The record can already have a file if **isNoMetadataUploadMode**
           * is on.
           */
          if (record.file) {
            warnAboutNoFilesMatched = false;
            return;
          }

          /**
           * Looking for a file in the ZIP matching the provided file path
           * with directory name (ZIP name) included
           * @example
           * ```
           * ZIP path: zip-directory-name/some-file.pdf
           * file path from CSV: zip-directory-name/some-file.pdf
           * ```
           */
          if (
            record.metadata?.Filepath?.toLowerCase().trim().normalize('NFC') ===
            file.path.toLowerCase().trim().normalize('NFC')
          ) {
            isAtLeastOneFileFound = true;
            return true;
          }

          const pathArray = file.path.split('/');
          pathArray.shift();
          const pathWithoutDirectory = pathArray.join('/');

          /**
           * Looking for a file in the ZIP ignoring the directory name
           * (ZIP name)
           * @example
           * ```
           * ZIP path: zip-directory-name/some-file.pdf
           * file path from CSV: some-file.pdf
           * ```
           */
          if (
            record?.metadata?.Filepath?.toLowerCase()
              .trim()
              .normalize('NFC') ===
            pathWithoutDirectory.toLowerCase().trim().normalize('NFC')
          ) {
            isAtLeastOneFileFound = true;
            return true;
          }

          /**
           * If path was not supplied or does not match any file, we are
           * looking for a file lying directly in the ZIP file with
           * a matching name
           * @example
           * ```
           * ZIP path: zip-directory-name/some-file.pdf
           * file name from CSV: some-file.pdf
           * ```
           */
          if (
            record?.metadata?.Name?.toLowerCase().trim().normalize('NFC') ===
            pathWithoutDirectory.toLowerCase().trim().normalize('NFC')
          ) {
            isAtLeastOneFileFound = true;
            return true;
          }
        });
        if (record) {
          record.file = file.file;
          record.isNotFoundError = false;

          if (file.file.size === 0) {
            console.log('Found empty file', file.file.name);
            record.isEmptyFile = true;
          } else {
            record.isEmptyFile = false;
          }
        }
      });

      if (warnAboutNoFilesMatched && !isAtLeastOneFileFound) {
        this.createToast!(
          this.$t('generic.warning'),
          this.$t('documentUpload.warnings.noFilesFound'),
          'warning',
        );
      }
      this.isReadingZipFile = false;
    },

    checkMissingFiles() {
      const recordsWithMissingFiles =
        DocumentUploadHelper.listRecordsWithMissingFile(this.uploadRecords);

      if (
        recordsWithMissingFiles.IdsOfRecordsWithMissingFile.length ||
        recordsWithMissingFiles.IdsOfRecordsWithChangedMetadata.length ||
        recordsWithMissingFiles.IdsOfRecordsWithEmptyFiles.length
      ) {
        this.missingFiles = recordsWithMissingFiles.IdsOfRecordsWithMissingFile;
        this.missingFilesWithChangedMetadata =
          recordsWithMissingFiles.IdsOfRecordsWithChangedMetadata;
        this.emptyFiles = recordsWithMissingFiles.IdsOfRecordsWithEmptyFiles;
        this.$bvModal.show('missing-files-modal');
      } else {
        // Proceeding directly to upload if no missing files
        this.isUploadConfirmed = true;
        this.finalizeUpload();
      }
    },

    validateUpload(): boolean {
      if (!this.uploadRecords.length) {
        this.createToast!(
          this.$t('generic.errors.error'),
          this.$t('documentUpload.errors.noUploadRecords'),
          'danger',
        );
        this.uploadFramesRerenderKey++;

        return false;
      }

      return true;
    },

    async finalizeUpload() {
      if (!this.validateUpload()) {
        return;
      }

      let uploadHelper: DocumentUploadHelperAbstract;

      if (this.mode === DocumentUploadMode.PROJECT) {
        uploadHelper = new DocumentUploadHelper(
          this.projectName,
          this.projectId,
          this.projectNumber,
          this.supplierName,
          this.supplierId,
          this.SAPNumber,
          this.referenceNumber,
          this.contentTypeId,
          this.uploadRecords,
        );
      } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
        uploadHelper = new DigitalizationUploadHelper(
          this.projectName,
          this.projectId,
          this.projectNumber,
          this.referenceNumber,
          this.contentTypeId,
          this.uploadRecords,
          this.isCheckBotInspectionDisabled,
        );
      } else {
        throw new Error(`Unknown upload mode: ${this.mode}`);
      }

      if (this.isUploadConfirmed) {
        /** to disable upload button */
        this.isUploading = true;

        /** event listener for upload status changes */
        uploadHelper.on('uploadStatusChange', (data) => {
          this.fileUploadStatuses[data.id] = data.status;
          this.fileUploadStatuses = cloneDeep(this.fileUploadStatuses);
        });

        try {
          await uploadHelper.finalizeUploadToSharepoint();
          await uploadHelper.postDocumentsUploadsToDB();
          this.isUploadToDBFinished = true;
          this.isOldFilesDeleted = true;

          this.createToast!(
            this.$tc(
              'documentUpload.uploadSuccessfullShort',
              this.uploadRecords.length ?? 2,
            ),
            this.$tc(
              'documentUpload.uploadSuccessfull',
              this.uploadRecords.length ?? 2,
            ),
            'success',
          );

          if (this.mode === DocumentUploadMode.PROJECT) {
            const tabNumber = (
              this as unknown as ProjectTabNumbersMixin
            ).getProjectDetailViewTabNumber(ProjectDetailViewTab.UPLOADS);
            setTimeout(() => {
              router.push({
                name: 'projectDetails',
                params: {
                  id: this.projectId,
                  tabNumber,
                },
              });
            }, 3000);
          } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
            const tabNumber = (
              this as unknown as DigitalizationProjectTabNumbersMixin
            ).getDigitalizationProjectDetailViewTabNumber(
              DigitalizationProjectDetailViewTab.UPLOADS,
            );
            setTimeout(() => {
              router.push({
                name: 'digitalizationProjectDetails',
                params: {
                  id: this.projectId,
                  tabNumber,
                },
              });
            }, 3000);
          }
        } catch {
          this.isUploadFailed = true;
          this.fileUploadStatuses = {};
          this.createToast!(
            this.$t('errors.error'),
            this.$t('documentUpload.errors.uploadFailed'),
            'danger',
          );
        }

        this.isUploadFinished = true;
      }
    },

    async handleIgnoreMissingFiles() {
      this.isUploadConfirmed = true;

      this.uploadRecords = this.uploadRecords.filter(
        (record) =>
          (record.file && !record.isEmptyFile) || record.isChangedMetadata,
      );

      this.finalizeUpload();
    },

    setIsNoMetadataUploadMode(): void {
      if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
        this.isNoMetadataUploadMode = stringToBoolean(
          this.$route.query.isNoMetadataUploadMode as string,
        );
      }
    },

    isDigitalizationProjectMode(): boolean {
      return this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT;
    },
  },

  mounted() {
    if (this.mode === DocumentUploadMode.PROJECT) {
      this.$store.commit('setCurrentProjectId', this.projectId);
      if (
        !(
          this.referenceNumber &&
          this.contentTypeId &&
          this.projectId &&
          this.supplierId &&
          this.projectName
        )
      ) {
        router.push({ name: 'projectsList' });
      }
    } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
      this.$store.commit('setCurrentDigitalizationProjectId', this.projectId);
      this.setIsNoMetadataUploadMode();
    }

    this.getFieldsMapping();
  },

  watch: {
    uploadRecords() {
      if (this.massUploadFiles.length) {
        this.mapFilesToFilePaths(true);
      } else this.mapFilesToFilePaths(false);
    },
    massUploadFiles() {
      if (this.uploadRecords.length) {
        this.mapFilesToFilePaths(true);
      } else this.mapFilesToFilePaths(false);
    },
  },
});
