import { render, staticRenderFns } from "./SupplierDetails.vue?vue&type=template&id=0188afcc&scoped=true"
import script from "./SupplierDetails.script.ts?vue&type=script&lang=ts&external"
export * from "./SupplierDetails.script.ts?vue&type=script&lang=ts&external"
import style0 from "./SupplierDetails.styles.scss?vue&type=style&index=0&id=0188afcc&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0188afcc",
  null
  
)

export default component.exports