import { DigitalizationDocumentWithStatus, DocumentStatus } from 'types';

export enum DigiDocuProcessingStep {
  UPLOAD_DONE,
  METADATA_INSPECTION,
  FORMAL_CONTROL,
  QUALITY_CHECK,
  RECORD_LIBRARY_TRANSFER,
  RECORD_LIBRARY_TRANSFER_DONE,
}

export function getCurrentDigitalizationDocumentProcessingStep(
  document: DigitalizationDocumentWithStatus,
): DigiDocuProcessingStep {
  switch (document.documentStatus) {
    case DocumentStatus.METADATA_INSPECTION:
      return DigiDocuProcessingStep.METADATA_INSPECTION;
    case DocumentStatus.FORMAL_CONTROL:
      return DigiDocuProcessingStep.FORMAL_CONTROL;
    case DocumentStatus.QUALITY_CHECK:
      return DigiDocuProcessingStep.QUALITY_CHECK;
    case DocumentStatus.RECORD_LIBRARY_TRANSFER_IN_PROGRESS:
      return DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER;
    /** TODO: Change when handling failed transfer is implemented */
    case DocumentStatus.RECORD_LIBRARY_TRANSFER_FAILED:
      return DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER_DONE;
    case DocumentStatus.RECORD_LIBRARY_TRANSFER_SUCCESSFULL:
      return DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER_DONE;
  }
}
