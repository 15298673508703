"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.positiveCheckBotStatuses = exports.checkBotAcceptedWithRemarksStatuses = exports.checkBotAcceptedStatuses = void 0;
const documents_uploads_1 = require("../documents-uploads");
exports.checkBotAcceptedStatuses = [
    documents_uploads_1.CheckbotStatusEnum.ok,
    documents_uploads_1.CheckbotStatusEnum.info,
];
exports.checkBotAcceptedWithRemarksStatuses = [documents_uploads_1.CheckbotStatusEnum.warning];
exports.positiveCheckBotStatuses = [
    ...exports.checkBotAcceptedStatuses,
    ...exports.checkBotAcceptedWithRemarksStatuses,
    documents_uploads_1.CheckbotStatusEnum.timedOut,
];
