import { AxiosError, isAxiosError } from 'axios';
import { ICustomHttpExceptionResponse } from 'types';

export function isCustomErrorCodeException(
  error: unknown,
): error is AxiosError<ICustomHttpExceptionResponse> {
  return (
    isAxiosError(error) &&
    error.response !== undefined &&
    typeof error.response?.data.customErrorCode === 'number'
  );
}
