import endpointsList from '@/helpers/endpointsList';
import digitalizationProjectTabNumbersMixin, {
  DigitalizationProjectDetailViewTab,
  DigitalizationProjectTabNumbersMixin,
} from '@/views/DigitalizationProjectDetails/digitalizationProjectTabNumbersMixin';
import projectTabNumbersMixin, {
  ProjectDetailViewTab,
  ProjectTabNumbersMixin,
} from '@/views/ProjectDetails/projectTabNumbersMixin';
import axios, { AxiosResponse } from 'axios';
import { DocumentDetailViewMode } from 'types';
import Vue from 'vue';
import { FilesOverviewTableConfig } from '@/components/FilesOverviewTable/FilesOverviewTable.script';
import { DigitalizationDocumentsOverviewTableConfig } from '../DigitalizationDocumentsOverview/DigitalizationDocumentsOverview.script';

interface IBreadCrumb {
  breadCrumbNames: { [path: string]: string };
  rerenderKey: number;
}

export default Vue.extend({
  name: 'bread-crumb',
  components: {},
  mixins: [digitalizationProjectTabNumbersMixin, projectTabNumbersMixin],
  props: {},
  data(): IBreadCrumb {
    return {
      breadCrumbNames: {},
      rerenderKey: 0,
    };
  },
  methods: {
    async getBreadcrumbNames() {
      const routeName = this.$route.name;

      if (routeName === 'projectDetails') {
        const projectId = this.$route.params.id;
        const projectName: string = await this.getProjectName(projectId);

        this.breadCrumbNames[routeName] = projectName;
        this.rerenderKey++;
      }

      if (routeName === 'supplierDetails') {
        const supplierId = this.$route.params.id;
        const supplierName: string = await this.getSupplierNameById(supplierId);

        this.breadCrumbNames[routeName] = supplierName;

        this.rerenderKey++;
      }

      if (routeName === 'documentDetailView') {
        const documentDetailViewMode = this.$route.params
          .mode as DocumentDetailViewMode;
        const projectId = this.$route.params.projectId;
        const supplierId = this.$route.params.supplierId;
        const documentId = this.$route.params.documentId;

        if (documentDetailViewMode === DocumentDetailViewMode.PROJECT) {
          const [projectName, fileName, uploadReferenceNumber] =
            await Promise.all([
              this.getProjectName(projectId),
              this.getDocumentTitle(projectId, supplierId, documentId),
              this.getDocumentUploadReferenceNumber(
                projectId,
                supplierId,
                documentId,
              ),
            ]);

          this.breadCrumbNames['uploadReferenceNumber'] = uploadReferenceNumber;
          this.breadCrumbNames[routeName] = fileName;
          this.breadCrumbNames['projectDetails'] = projectName;
        } else if (
          documentDetailViewMode ===
          DocumentDetailViewMode.DIGITALIZATION_PROJECT
        ) {
          const [
            digitalizationProjectName,
            documentName,
            uploadReferenceNumber,
          ] = await Promise.all([
            this.getDigitalizationProjectName(projectId),
            this.getDigitalizationDocumentTitle(projectId, documentId),
            this.getDigitalizationDocumentUploadReferenceNumber(
              projectId,
              documentId,
            ),
          ]);

          this.breadCrumbNames['digitalizationUploadReferenceNumber'] =
            uploadReferenceNumber;
          this.breadCrumbNames[routeName] = documentName;
          this.breadCrumbNames['digitalizationProjectDetails'] =
            digitalizationProjectName;
        }

        this.rerenderKey++;
      }

      if (routeName === 'projectsSupplierDetailView') {
        const supplierId = this.$route.params.supplierId;
        const supplierName: string = await this.getSupplierNameById(supplierId);

        this.breadCrumbNames[routeName] = supplierName;

        if (!this.$store.state.currentProjectId) {
          const projectId = this.$route.params.projectId;
          this.$store.commit('setCurrentProjectId', projectId);
          this.$store.commit('setProjectDetailViewTabNumber', 1);
        }
      }

      if (routeName === 'digitalizationProjectDetails') {
        const digitalizationProjectId = this.$route.params.id;
        const digitalizationProjectName: string =
          await this.getDigitalizationProjectName(digitalizationProjectId);

        this.breadCrumbNames[routeName] = digitalizationProjectName;
        this.rerenderKey++;
      }

      this.rerenderKey++;
    },

    async getProjectName(projectId: string): Promise<string> {
      const response: AxiosResponse<string> = await axios.get(
        endpointsList.projects.getProjectNameById(projectId),
      );

      return response.data;
    },

    async getDigitalizationProjectName(
      digitalizationProjectId: string,
    ): Promise<string> {
      const response: AxiosResponse<string> = await axios.get(
        endpointsList.digitalizationProjects.getDigitalizationProjectTitle(
          digitalizationProjectId,
        ),
      );

      return response.data;
    },

    async getSupplierNameById(supplierId: string): Promise<string> {
      const response: AxiosResponse<string> = await axios.get(
        endpointsList.suppliers.getSupplierNameById(supplierId),
      );

      return response.data;
    },

    async getDocumentTitle(
      projectId: string,
      supplierId: string,
      documentId: string,
    ): Promise<string> {
      const response = await axios.get<string>(
        endpointsList.documentsUploads.getDocumentTitle(
          projectId,
          supplierId,
          documentId,
        ),
      );

      return response.data;
    },

    async getDocumentUploadReferenceNumber(
      projectId: string,
      supplierId: string,
      documentId: string,
    ): Promise<string> {
      const response = await axios.get<string>(
        endpointsList.documentsUploads.getDocumentUploadReferenceNumber(
          projectId,
          supplierId,
          documentId,
        ),
      );
      return response.data;
    },

    handleUploadReferenceNumberClick(): void {
      const referenceNumber = this.breadCrumbNames.uploadReferenceNumber;
      const config: FilesOverviewTableConfig = {
        filters: {
          referenceNumber,
        },
      };
      this.$store.commit('setFilesOverviewTableConfig', config);

      this.$router.push({
        name: 'projectDetails',
        params: {
          id: this.$store.state.currentProjectId,
          tabNumber: this.getDocumentsOverviewTabNumber(),
        },
      });
    },

    async getDigitalizationDocumentUploadReferenceNumber(
      digitalizationProjectId: string,
      documentId: string,
    ): Promise<string> {
      const response = await axios.get<string>(
        endpointsList.digitalizationUploads.getDocumentUploadReferenceNumber(
          digitalizationProjectId,
          documentId,
        ),
      );

      return response.data;
    },

    handleDigitalizationUploadReferenceNumberClick(): void {
      const referenceNumber =
        this.breadCrumbNames.digitalizationUploadReferenceNumber;
      const config: DigitalizationDocumentsOverviewTableConfig = {
        filtering: {
          referenceNumber,
        },
      };
      this.$store.commit(
        'setDigitalizationDocumentsOverviewTableConfig',
        config,
      );

      this.$router.push({
        name: 'digitalizationProjectDetails',
        params: {
          id: this.$store.state.currentDigitalizationProjectId,
          tabNumber: this.getDigitalizationDocumentsOverviewTabNumber(),
        },
      });
    },

    async getDigitalizationDocumentTitle(
      digitalizationProjectId: string,
      documentId: string,
    ): Promise<string> {
      const response = await axios.get(
        endpointsList.digitalizationUploads.getDocumentTitle(
          digitalizationProjectId,
          documentId,
        ),
      );

      return response.data;
    },

    getDocumentsOverviewTabNumber() {
      return (
        this as unknown as ProjectTabNumbersMixin
      ).getProjectDetailViewTabNumber(ProjectDetailViewTab.DOCUMENTS);
    },

    getDigitalizationDocumentsOverviewTabNumber() {
      return (
        this as unknown as DigitalizationProjectTabNumbersMixin
      ).getDigitalizationProjectDetailViewTabNumber(
        DigitalizationProjectDetailViewTab.DOCUMENTS,
      );
    },
  },

  created() {
    this.$nextTick(() => this.getBreadcrumbNames());
  },

  watch: {
    '$route.name'() {
      this.$nextTick(() => this.getBreadcrumbNames());
    },
  },
});
