<template>
  <div class="ibl-import-result">
    <template v-if="areErrorsPresent">
      <h4 v-if="isImportFailed">
        {{ $tt('iblImportResult.noIblsCouldBeImported') }}:
      </h4>
      <h4 v-else>{{ $tt('iblImportResult.someIblsCouldNotBeImported') }}:</h4>
      <div
        v-for="(errors, kks) in groupByKks('errors')"
        class="issues-for-kks"
        :key="getKksKey(kks, 'errors')"
      >
        <h6>{{ $tt('iblImportResult.forKks', { kks }) }}</h6>
        <ul>
          <li
            v-for="[index, error] in getKksLevelErrors(errors).entries()"
            :key="getIssueKey(error, index)"
          >
            {{ buildKksLevelErrorText(error) }}
          </li>
        </ul>
        <ul>
          <li
            v-for="[index, error] in getVgbmLevelErrors(errors).entries()"
            :key="getIssueKey(error, index)"
          >
            {{ buildVgbmLevelErrorText(error) }}
          </li>
        </ul>
      </div>
    </template>

    <template v-if="areWarningsPresent">
      <h4>{{ $tt('iblImportResult.warnings') }}:</h4>
      <div
        v-for="(warnings, kks) in groupByKks('warnings')"
        class="issues-for-kks"
        :key="getKksKey(kks, 'warnings')"
      >
        <h6>{{ $tt('iblImportResult.forKks', { kks }) }}</h6>
        <ul>
          <li
            v-for="[index, warning] in getKksLevelWarnings(warnings).entries()"
            :key="getIssueKey(warning, index)"
          >
            {{ buildKksLevelWarningText(warning) }}
          </li>
        </ul>
        <ul>
          <li
            v-for="[index, warning] in getVgbmLevelWarnings(warnings).entries()"
            :key="getIssueKey(warning, index)"
          >
            {{ buildVgbmLevelWarningText(warning) }}
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
  import { groupBy, uniqBy } from 'lodash';
  import {
    IblImportErrorType,
    IblImportResult,
    IblImportWarningType,
    IIblImportError,
    IIblImportResponseDto,
    IIblImportWarning,
    MissingResourceType,
  } from 'types';
  import Vue from 'vue';

  type IssueType = 'errors' | 'warnings';
  type IssueList<T extends IIblImportError | IIblImportWarning> = Record<
    string,
    T[]
  >;

  const KKS_LEVEL_WARNINGS = [IblImportWarningType.INVALID_KKS];

  export default Vue.extend({
    name: 'ibl-import-result',
    props: {
      importResult: {
        type: Object as () => IIblImportResponseDto,
        required: true,
      },
    },
    computed: {
      isImportFailed(): boolean {
        return this.importResult.importResult === IblImportResult.FAILURE;
      },
      areErrorsPresent(): boolean {
        return !!this.importResult.errors.length;
      },
      areWarningsPresent(): boolean {
        return !!this.importResult.warnings.length;
      },
      warningsByKks(): Record<string, IIblImportWarning[]> {
        return groupBy(this.importResult.warnings, 'kks');
      },
    },
    methods: {
      groupByKks<T extends IssueType>(
        issueType: T,
      ): T extends 'errors'
        ? IssueList<IIblImportError>
        : IssueList<IIblImportWarning> {
        return groupBy(this.importResult[issueType], 'kks') as any;
      },
      getKksKey(kks: string, issueType: IssueType): string {
        return [kks, issueType].join('-');
      },
      getIssueKey(
        issue: IIblImportError | IIblImportWarning,
        index: number,
      ): string {
        return [issue.kks, issue.vgbm, issue.type, index].join('-');
      },
      buildVgbmLabel(issue: IIblImportError | IIblImportWarning): string {
        return issue.vgbmDescription
          ? `${issue.vgbm} (${issue.vgbmDescription})`
          : issue.vgbm;
      },
      buildVgbmLevelErrorText(error: IIblImportError): string {
        if (error.type === IblImportErrorType.MISSING_RESOURCE) {
          return `${this.buildVgbmLabel(error)} - ${this.$tt(
            `iblImportResult.missingResourceErrors.${error.missingResource!}`,
          )}`;
        }
        return `${this.buildVgbmLabel(error)} - ${this.$tt(
          `iblImportResult.errorTypes.${error.type}`,
          { kks: error.kks, vgbm: error.vgbm },
        )}`;
      },
      isKksLevelError(error: IIblImportError): boolean {
        return (
          error.type === IblImportErrorType.MISSING_RESOURCE &&
          error.missingResource === MissingResourceType.VGBK
        );
      },
      getVgbmLevelErrors(errors: IIblImportError[]): IIblImportError[] {
        return errors.filter((error) => !this.isKksLevelError(error));
      },
      getKksLevelErrors(errors: IIblImportError[]): IIblImportError[] {
        const filteredErrors = errors.filter((error) =>
          this.isKksLevelError(error),
        );
        return uniqBy(filteredErrors, 'type');
      },
      buildKksLevelErrorText(error: IIblImportError): string {
        return this.$tt(
          `iblImportResult.missingResourceErrors.${error.missingResource!}`,
        ).toString();
      },
      getVgbmLevelWarnings(warnings: IIblImportWarning[]): IIblImportWarning[] {
        return warnings.filter(
          (warning) => !KKS_LEVEL_WARNINGS.includes(warning.type),
        );
      },
      buildVgbmLevelWarningText(warning: IIblImportWarning): string {
        return `${this.buildVgbmLabel(warning)} - ${this.$tt(
          `iblImportResult.warningTypes.${warning.type}`,
          {
            invalidUnit: warning.invalidUnit,
            invalidValue: warning.invalidValue,
          },
        )}`;
      },
      getKksLevelWarnings(warnings: IIblImportWarning[]): IIblImportWarning[] {
        const filteredWarnings = warnings.filter((warning) =>
          KKS_LEVEL_WARNINGS.includes(warning.type),
        );
        return uniqBy(filteredWarnings, 'type');
      },
      buildKksLevelWarningText(warning: IIblImportWarning): string {
        return this.$tt(
          `iblImportResult.warningTypes.${warning.type}`,
        ).toString();
      },
    },
  });
</script>

<style lang="scss" scoped>
  .ibl-import-result {
    .issues-for-kks {
      margin: 1rem 0;
      ul {
        list-style-type: disc;

        li {
          margin-left: 1rem;
        }
      }
    }
  }
</style>
