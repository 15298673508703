import Vue from 'vue';
import StyledModal from '@/components/StyledModal/StyledModal.vue';
import StyledDatePicker from '@/components/StyledDatePicker/StyledDatePicker.vue';
import endpointsList from '@/helpers/endpointsList';
import axios from 'axios';
import { IChangeInspectionDueDatesRequest } from 'types';

interface IChangeInspectionDueDateModal {
  date?: Date;
}

export default Vue.extend({
  name: 'change-inspection-due-date-modal',
  components: { StyledModal, StyledDatePicker },

  props: {
    fileIds: {
      type: Array as () => string[],
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },

  data(): IChangeInspectionDueDateModal {
    return {
      date: undefined,
    };
  },

  methods: {
    handleDateUpdated(date: Date) {
      this.date = date;
    },

    async handleModalOk() {
      if (!this.date) return;

      const requestBody: IChangeInspectionDueDatesRequest = {
        fileIds: this.fileIds,
        projectId: this.projectId,
        newInspectionDueDate: this.date.toISOString(),
      };

      try {
        await axios.patch(
          endpointsList.documentsUploads.changeInspectionDueDates,
          requestBody,
        );
        this.$emit('inspection-due-dates-updated');

        (this as any).createToast!(
          this.$t('documentDetailView.inspectionDueDateChanged'),
          this.$t('documentDetailView.inspectionDueDateChanged'),
          'success',
        );
      } catch (err) {
        (this as any).createToast!(
          this.$t('errors.error'),
          this.$t('filesOverview.errors.inspectionDueDateChangeError'),
          'danger',
        );
      }
    },
  },
});
