"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DigitalizationCheckBotStatus = void 0;
var DigitalizationCheckBotStatus;
(function (DigitalizationCheckBotStatus) {
    /** Statuses returned by the check bot */
    DigitalizationCheckBotStatus["OK"] = "OK";
    DigitalizationCheckBotStatus["CRITICAL"] = "Critical";
    DigitalizationCheckBotStatus["ERROR"] = "Error";
    DigitalizationCheckBotStatus["WARNING"] = "Warning";
    DigitalizationCheckBotStatus["INFO"] = "Info";
    /** internally used checkbot statutes */
    DigitalizationCheckBotStatus["TIMED_OUT"] = "timed_out";
    DigitalizationCheckBotStatus["WAITING"] = "waiting";
    DigitalizationCheckBotStatus["WAITING_FOR_METADATA_INFERENCE"] = "waiting_for_metadata_inference";
    DigitalizationCheckBotStatus["CHECK_BOT_INSPECTION_DISABLED"] = "check_bot_inspection_disabled";
})(DigitalizationCheckBotStatus || (exports.DigitalizationCheckBotStatus = DigitalizationCheckBotStatus = {}));
