import { sortStringsAlphabetically } from '@/helpers';
import axios from 'axios';
import Vue from 'vue';
import StyledModal from '../StyledModal/StyledModal.vue';
import StyledMultiselect from '../StyledMultiselect/StyledMultiselect.vue';

interface ISelectModal {
  options: Record<string | number, any>[] | string[];
  selectedOption?: Record<string | number, any>;
  isLoadingOptions: boolean;
}

export default Vue.extend({
  name: 'select-modal',
  components: { StyledModal, StyledMultiselect },

  props: {
    modalTitle: {
      type: String,
      required: true,
    },
    okButtonLabel: {
      type: String,
      required: false,
    },
    endpoint: {
      type: String,
      required: false,
    },
    trackBy: {
      type: String,
      required: false,
    },
    label: {
      type: [String, Function],
      required: false,
    },
    modalId: {
      type: String,
      required: false,
    },
    tooltips: {
      type: Object as () => { [key: string]: string },
      required: false,
    },
    okButtonBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancelButtonBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
    preventClosingOnOk: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortAlpabetically: {
      type: Boolean,
      required: false,
    },
  },

  data(): ISelectModal {
    return {
      options: [],
      selectedOption: undefined,
      isLoadingOptions: false,
    };
  },

  methods: {
    async getOptions() {
      if (!this.endpoint) {
        return;
      }

      this.isLoadingOptions = true;

      try {
        const response = await axios.get(this.endpoint);

        const options = response.data;

        if (
          this.sortAlpabetically &&
          Array.isArray(options) &&
          options.every((element) => typeof element === 'string')
        ) {
          this.options = sortStringsAlphabetically(options);
        } else {
          this.options = options;
        }
      } catch {
        //
      }

      this.isLoadingOptions = false;
    },

    async emitSelectedOption() {
      if (!this.selectedOption) {
        return;
      }

      this.$emit('option-selected', this.selectedOption);
      this.$emit('ok');

      if (!this.preventClosingOnOk) {
        this.$bvModal.hide(this.modalId ?? 'styled-modal');
      }
    },

    cleanModal() {
      this.selectedOption = undefined;
    },
  },
});
