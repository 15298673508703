import { IUser, Role } from 'types';
import Vue from 'vue';

interface IUserInfo {
  userData?: IUser;
  userRoles?: Role[];
}

export default Vue.extend({
  name: 'user-info',
  data(): IUserInfo {
    return {
      userData: undefined,
      userRoles: undefined,
    };
  },

  methods: {
    getUserRoles() {
      const translatedRoles = this.userRoles?.map((role) =>
        this.$tc(`roles.${role}`, 1),
      );
      return translatedRoles?.join(', ');
    },
  },

  watch: {
    '$store.state.userData': function () {
      this.userData = this.$store.state.userData;
    },
    '$store.state.userRoles': function () {
      this.userRoles = this.$store.state.userRoles;
    },
  },
});
