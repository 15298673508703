import axios, { AxiosResponse } from 'axios';
import {
  IAddCoworkerToDigitalizationProjectDto,
  ICoworker,
  ICoworkerWithSupplier,
  IDigitalizationCoworkerDto,
  Role,
  Serialized,
} from 'types';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import endpointsList from '../../helpers/endpointsList';
import { GlobalUtils } from '../../mixins/globalUtils';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import FilterableTable from '../FilterableTable/FilterableTable.vue';
import SelectModal from '../SelectModal/SelectModal.vue';

export enum ProjectsCoworkersTableMode {
  PROJECT = 'project',
  DIGITALIZATION_PROJECT = 'digitalizationProject',
}

interface IProjectsCoworkers {
  coworkers: ICoworker[];
  sortBy: keyof ICoworkerWithSupplier;
  sortDesc: boolean;
  tableFilterConfiguration: IFilterConfiguration;
  tableFilterGridConfiguration: string[];
  isAddingCoworker: boolean;
  isTableBusy: boolean;
  removeCoworkerAllowedRoles: [Role.Admin, Role.DocuManager];
}

export default Vue.extend({
  name: 'projects-coworkers',
  components: { FilterableTable, SelectModal },
  props: {
    mode: {
      type: String as () => ProjectsCoworkersTableMode,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
  },

  data(): IProjectsCoworkers {
    return {
      coworkers: [],
      sortBy: 'supplierName',
      sortDesc: true,
      tableFilterConfiguration: {
        firstName: {
          type: FilterType.INPUT,
          width: '208px',
          column: 1,
        },
        lastName: {
          type: FilterType.INPUT,
          width: '208px',
          column: 2,
        },
        email: {
          type: FilterType.INPUT,
          width: '208px',
          column: 3,
        },
      },
      tableFilterGridConfiguration: ['30%', '30%', '40%'],
      isAddingCoworker: false,
      isTableBusy: false,
      removeCoworkerAllowedRoles: [Role.Admin, Role.DocuManager],
    };
  },

  computed: {
    tableFields(): {
      key: string;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
    }[] {
      return [
        {
          key: 'firstName',
          label: this.$i18n.t('generic.firstName'),
          sortable: true,
          tdClass: ['w-30'],
        },
        {
          key: 'lastName',
          label: this.$i18n.t('generic.lastName'),
          sortable: true,
          tdClass: ['w-30'],
        },
        {
          key: 'email',
          label: this.$i18n.t('generic.email'),
          sortable: true,
          tdClass: ['w-35'],
        },
        {
          key: 'action',
          label: '',
          sortable: false,
          tdClass: ['w-5', 'action-button'],
        },
      ];
    },
  },

  methods: {
    getSelectModalEndpoint() {
      if (this.mode === ProjectsCoworkersTableMode.PROJECT) {
        return endpointsList.suppliers.getCoworkersForSupplier(this.supplierId);
      } else if (
        this.mode === ProjectsCoworkersTableMode.DIGITALIZATION_PROJECT
      ) {
        return endpointsList.digitalizationCoworkers.getDigitalizationCoworkers;
      }
    },

    async getProjectsCoworkers() {
      this.isTableBusy = true;
      try {
        const response: AxiosResponse<ICoworkerWithSupplier[]> =
          await axios.get(
            endpointsList.projects.getCoworkersForProjectForSupplier(
              this.projectId,
              this.supplierId,
            ),
          );

        this.coworkers = response.data;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('projectsCoworkers.errors.couldNotLoadProjects'),
          'danger',
        );
      } finally {
        this.isTableBusy = false;
      }
    },

    async getDigitalizationProjectsCoworkers() {
      this.isTableBusy = true;
      try {
        const response: AxiosResponse<IDigitalizationCoworkerDto[]> =
          await axios.get(
            endpointsList.digitalizationProjects.getDigitalizationProjectCoworkers(
              this.projectId,
            ),
          );

        this.coworkers = response.data;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('projectsCoworkers.errors.couldNotLoadProjects'),
          'danger',
        );
      } finally {
        this.isTableBusy = false;
      }
    },

    async handleCoworkerClick() {
      (this as unknown as GlobalUtils).createToast(
        this.$t('generic.notImplemented'),
        this.$t('generic.notImplemented'),
        'warning',
      );
    },

    async handleRemoveCoworkerIconClick(coworker: ICoworker) {
      try {
        this.isTableBusy = true;

        await axios.delete(
          endpointsList.projects.removeCoworkerFromProject(
            this.projectId,
            coworker.oid,
          ),
        );

        (this as unknown as GlobalUtils).createToast(
          '',
          this.$t('projectsCoworkers.coworkerRemoved'),
          'success',
        );
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('projectsCoworkers.errors.couldNotRemoveCoworker'),
          'danger',
        );
      } finally {
        this.isTableBusy = false;
        this.getProjectsCoworkers();
      }
    },

    async handleAddCoworker(coworker: ICoworker) {
      this.isAddingCoworker = true;
      const { oid, email } = coworker;
      try {
        if (this.mode === ProjectsCoworkersTableMode.PROJECT) {
          await axios.patch(
            endpointsList.projects.addCoworkerToProject(this.projectId),
            {
              oid,
              coworkerEmail: email,
              forSupplier: this.supplierId,
            },
          );
        } else if (
          this.mode === ProjectsCoworkersTableMode.DIGITALIZATION_PROJECT
        ) {
          const body: Serialized<IAddCoworkerToDigitalizationProjectDto> = {
            digitalizationProjectId: this.projectId,
            digitalizationCoworkerOid: oid,
          };

          await axios.post(
            endpointsList.digitalizationProjects
              .addCoworkerToDigitalizationProject,
            body,
          );
        }

        if (this.mode === ProjectsCoworkersTableMode.PROJECT) {
          this.getProjectsCoworkers();
        } else if (
          this.mode === ProjectsCoworkersTableMode.DIGITALIZATION_PROJECT
        ) {
          this.getDigitalizationProjectsCoworkers();
        }
      } catch (err) {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('addCoworkerToProject.errors.couldNotAddCoworker'),
          'danger',
        );
      }

      this.isAddingCoworker = false;
      this.$bvModal.hide('add-coworker-modal');
    },

    nameAndEmailLabel({
      firstName,
      lastName,
      email,
    }: {
      firstName: TranslateResult;
      lastName: TranslateResult;
      email: TranslateResult;
    }) {
      if (firstName === null && lastName === null) {
        firstName = this.$i18n.t('ADModal.noData');
        lastName = '';
      } else if (firstName === null) {
        firstName = this.$i18n.t('ADModal.noData');
      } else if (lastName === null) {
        lastName = this.$i18n.t('ADModal.noData');
      }

      if (email) {
        return `${firstName} ${lastName} — [${email}]`;
      } else {
        return `${firstName} ${lastName}`;
      }
    },

    rowColors(item: ICoworkerWithSupplier) {
      if (item?.isSupplierDeleted) {
        return 'inactive-row';
      }
      return 'row-class';
    },
  },

  created() {
    if (!this.projectId) {
      return;
    }

    if (this.mode === ProjectsCoworkersTableMode.PROJECT) {
      this.getProjectsCoworkers();
    } else if (
      this.mode === ProjectsCoworkersTableMode.DIGITALIZATION_PROJECT
    ) {
      this.getDigitalizationProjectsCoworkers();
    }
  },

  mounted() {
    (this.$refs.firstElementForFocus as HTMLElement).focus();
  },
});
