import DocumentStatusPanel from '@/components/DocumentStatusPanel/DocumentStatusPanel.vue';
import {
  DigitalizationDocumentWithStatus,
  DocumentDetailViewMode,
  DocumentStatus,
  IFilesListFile,
  positiveFormalControlStatuses,
  Role,
  SharePointUploadStatus,
} from 'types';
import Vue from 'vue';
import InspectorPanel from '@/components/InspectorPanel/InspectorPanel.vue';
import QualityCheckPanel from '@/components/QualityCheckPanel/QualityCheckPanel.vue';
import AcceptMetadataInspectionButton from '@/components/AcceptMetadataInspectionButton/AcceptMetadataInspectionButton.vue';

export default Vue.extend({
  name: 'progress-step-indicator-tooltip',

  components: {
    AcceptMetadataInspectionButton,
    DocumentStatusPanel,
    InspectorPanel,
    QualityCheckPanel,
  },

  props: {
    digitalizationDocument: {
      type: Object as () => DigitalizationDocumentWithStatus,
      required: false,
    },
    file: {
      type: Object as () => IFilesListFile,
      required: false,
    },
    mode: {
      type: String as () => DocumentDetailViewMode,
      required: true,
    },
    targetId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      DocumentDetailViewMode,
      metadataInspectionAllowedRoles: [
        Role.Admin,
        Role.DocuResponsible,
        Role.DigitalizationCoworker,
      ],
      qualityCheckPanelAllowedRoles: [Role.Admin, Role.DocuResponsible],
    };
  },

  methods: {
    isSupplierProject() {
      return this.mode === DocumentDetailViewMode.PROJECT;
    },

    isDigitalizationProject() {
      return this.mode === DocumentDetailViewMode.DIGITALIZATION_PROJECT;
    },

    isSharePointUploadFailed(): boolean {
      return (
        (this.file ?? this.digitalizationDocument).sharePointUploadStatus ===
        SharePointUploadStatus.FAILED
      );
    },

    isQualityCheck(): boolean {
      return (
        this.digitalizationDocument?.documentStatus ===
        DocumentStatus.QUALITY_CHECK
      );
    },

    isMetadataInspection(): boolean {
      return (
        this.digitalizationDocument?.documentStatus ===
        DocumentStatus.METADATA_INSPECTION
      );
    },

    isFormalControlDone(): boolean {
      return (
        !!this.file?.formalControlStatus &&
        positiveFormalControlStatuses.includes(this.file?.formalControlStatus)
      );
    },

    emitDocumentDataChangedEvent(): void {
      this.$emit('document-data-changed');
    },
  },
});
