import { endpointsList } from '@/helpers';
import axios from 'axios';
import { ProjectType } from 'types';

export class ProjectDataService {
  constructor(private readonly mode: ProjectType) {}

  public async getProjectLocation(projectId: string): Promise<string> {
    const endpoint =
      this.mode === ProjectType.SUPPLIER_PROJECT
        ? endpointsList.projects.getProjectLocation(projectId)
        : endpointsList.digitalizationProjects.getDigitalizationProjectLocation(
            projectId,
          );

    const response = await axios.get(endpoint);
    return response.data.toString();
  }
}
