import StyledDateRangePicker from '@/components/StyledDateRangePicker/StyledDateRangePicker.vue';
import { deduplicateObjectsByKey } from '@/helpers/deduplicateObjectsByKey';
import { formatDateForDisplay } from '@/helpers/formatDateForDisplay';
import axios, { AxiosResponse } from 'axios';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import { ITableField } from '../../frontendInterfaces';
import MainTable from '../MainTable/MainTable.vue';
import {
  CalendarOpenDirection,
  IDateRange,
} from '../StyledDateRangePicker/StyledDateRangePicker.script';
import StyledPagination from '../StyledPagination/StyledPagination.vue';
import { ButtonSize, ButtonVariant } from '../VfButton/VfButton.script';

export enum Mode {
  /** Component handles paging and filtering requests */
  SERVER_SIDE = 'serverSide',
  /** Client side paging and filtering */
  CLIENT_SIDE = 'clientSide',
  /** Requests for pagind and filtering are handled in a parent component.
   * This component emits events if filtering or page has changed.
   */
  OUTSOURCED = 'outsourced',
}

export enum FilterType {
  INPUT = 'input',
  SELECT = 'select',
  DATE_RANGE_PICKER = 'dateRangePicker',
  CHECK_BOX = 'checkBox',
}

export interface IFilterConfiguration {
  [fieldKey: string]: {
    type: FilterType;
    width: string;
    column: number;
    customLabel?: TranslateResult;
    simpleLabel?: boolean;
    translateOptions?: boolean;
    dateRangePickerCalendarOpenDirection?: CalendarOpenDirection;
  };
}

export type TServerSidePaginationFunction<SortBy> = (
  searchData: any,
  currentPage: number,
  perPage: number,
  sortBy: SortBy,
  sortDesc: -1 | 1,
) => string;

export interface IFiltering {
  [field: string]: string | IDateRange;
}

interface IFilterableTable {
  currentPage: number;
  perPage: number;
  perPageOptions: (SelectOption | number)[];
  filters: IFiltering;
  sortBy?: string;
  sortDesc?: boolean;

  paginatedItems: any[];
  serverSideTableRows: number;
  isLoadingItems: boolean;

  resetFiltersButttonSize: ButtonSize;
  resetFiltersButttonVariant: ButtonVariant;
}

export type SelectOptions<T extends object = any> = {
  [key in keyof T]?: SelectOption[];
};

export type SelectOption = {
  text: string | TranslateResult | number;
  value: string | number | null;
};

export default Vue.extend({
  name: 'filterable-table',
  components: {
    MainTable,
    StyledDateRangePicker,
    StyledPagination,
  },
  props: {
    items: {
      type: Array,
      required: false,
    },
    fields: {
      type: Array as () => ITableField[],
      required: true,
    },
    componentName: {
      // For translations
      type: String,
      required: true,
    },
    filterConfiguration: {
      type: Object as () => IFilterConfiguration,
      required: true,
    },
    filterGridConfiguration: {
      type: Array as () => string[],
      required: true,
    },
    detailsClickTarget: {
      type: String,
      required: false,
    },
    detailsParam: {
      type: String,
      required: false,
    },
    mode: {
      type: String as () => Mode,
      required: false,
    },
    serverSidePaginationEndpoint: {
      type: Function,
      required: false,
    },
    serverSideRowsCountEndpoint: {
      type: String,
      required: false,
    },
    rows: {
      type: Number,
      required: false,
    },
    customActionIcon: {
      type: String,
      required: false,
    },
    isEmitOnClick: {
      type: Boolean,
      required: false,
    },
    rowClassFunction: {
      type: Function,
      required: false,
    },
    noActionIcon: {
      type: Boolean,
      required: false,
    },
    actionIconTooltipText: {
      type: String,
      required: false,
    },
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
    explicitSelectOptions: {
      type: Object as () => SelectOptions,
      required: false,
    },
    prefiltering: {
      type: Object as () => IFiltering,
      required: false,
    },
    initialSortBy: {
      type: String,
      required: false,
    },
    initialSortDesc: {
      type: Boolean,
      required: false,
    },
  },

  data(): IFilterableTable {
    return {
      currentPage: 1,
      perPage: 10,
      perPageOptions: [
        10,
        20,
        50,
        100,
        500,
        { value: 99999, text: this.$t('generic.showAll') },
      ],
      filters: {} as { [field: string]: string },
      sortBy: undefined,
      sortDesc: undefined,

      paginatedItems: [],
      serverSideTableRows: 0,
      isLoadingItems: false,

      resetFiltersButttonSize: ButtonSize.SMALL,
      resetFiltersButttonVariant: ButtonVariant.TERTIARY,
    };
  },

  computed: {
    filteredItems() {
      if (this.mode === Mode.OUTSOURCED) {
        return this.items;
      }
      let items = this.items;
      for (const field in this.filters) {
        if (this.filters[field]) {
          items = items.filter((item) => {
            const fieldContent = (item as any)[field];

            const isFieldContentMatching = String(fieldContent)
              .toLowerCase()
              .includes(String(this.filters[field]).toLowerCase().trim());

            const isTranslatedFieldContentMatching = String(
              this.$i18n.t(`${this.componentName}.${fieldContent}`),
            )
              .toLowerCase()
              .includes(String(this.filters[field]).toLowerCase().trim());

            const isDateMatching = formatDateForDisplay(new Date(fieldContent))
              ?.toLowerCase()
              ?.includes(String(this.filters[field]).toLowerCase().trim());

            const isFieldMatching =
              isFieldContentMatching ||
              isTranslatedFieldContentMatching ||
              isDateMatching;

            return isFieldMatching;
          });
        }
      }

      this.$emit('filtered-items', items);
      return items;
    },

    tableRows() {
      if (this.mode === Mode.SERVER_SIDE) {
        return 0;
      }

      if (!Array.isArray(this.filteredItems)) {
        return 0;
      }

      return (this as any).filteredItems.length;
    },

    sortedFilters(): {
      inputFilters: IFilterConfiguration;
      selectFilters: IFilterConfiguration;
    } {
      const inputFilters: IFilterConfiguration = {};
      const selectFilters: IFilterConfiguration = {};

      for (const field in this.filterConfiguration) {
        if (this.filterConfiguration[field].type === FilterType.INPUT) {
          inputFilters[field] = this.filterConfiguration[field];
        }
        if (this.filterConfiguration[field].type === FilterType.SELECT) {
          selectFilters[field] = this.filterConfiguration[field];
        }
      }

      return { inputFilters, selectFilters };
    },

    selectOptions() {
      if (this.explicitSelectOptions) {
        return this.explicitSelectOptions;
      }

      const selectFilterColumns: string[] = Object.keys(
        this.sortedFilters.selectFilters,
      );

      const selectOptions: {
        [select: string]: (
          | string
          | { text: TranslateResult; value: null | string }
        )[];
      } = {};

      for (const field of selectFilterColumns) {
        for (const item of this.items ?? []) {
          selectOptions[field] ??= [];

          if (this.filterConfiguration[field].translateOptions) {
            selectOptions[field].push({
              text: this.$i18n.t(
                `${this.componentName}.${(item as any)[field]}`,
              ) as string,
              value: (item as any)[field],
            });
            continue;
          }

          selectOptions[field].push((item as any)[field]);
        }
      }

      for (const field in selectOptions) {
        selectOptions[field] = Array.from(new Set(selectOptions[field]));
        const isArrayOfObjects = selectOptions[field].every(
          (option) => typeof option !== 'string' && option.value !== undefined,
        );
        if (isArrayOfObjects) {
          selectOptions[field] = deduplicateObjectsByKey(
            selectOptions[field] as {
              text: TranslateResult;
              value: null | string;
            }[],
            'value',
          );
        }
        selectOptions[field] = selectOptions[field].filter(
          (option): boolean => {
            if (typeof option === 'string') {
              return true;
            }

            return option.text !== '-';
          },
        );
        selectOptions[field].push({
          text: this.$i18n.t('filterableTable.all'),
          value: null,
        });
      }

      return selectOptions;
    },

    filterGrid() {
      const style = {
        'grid-template-columns': this.filterGridConfiguration.join(' '),
      };

      return style;
    },

    filterStyles() {
      const styles: {
        [field: string]: {
          'grid-column': string;
          'grid-row': string;
          width: string;
          'min-width': string;
        };
      } = {};

      for (const field in this.filterConfiguration) {
        const config = this.filterConfiguration[field];
        styles[field] = {
          'grid-column': `${config.column}/${config.column + 1}`,
          'grid-row': '1/2',
          width: config.width,
          'min-width': config.width,
        };
      }

      return styles;
    },
  },

  methods: {
    async getPaginatedItems() {
      if (this.mode !== Mode.SERVER_SIDE) return;
      try {
        this.isLoadingItems = true;
        const response: AxiosResponse<any> = await axios.get(
          this.serverSidePaginationEndpoint(
            this.filters,
            this.currentPage,
            this.perPage,
            this.sortBy ?? this.fields[0].key,
            this.sortDesc ? -1 : 1,
          ),
        );

        this.paginatedItems = response.data;
      } catch {
        //
      }

      this.isLoadingItems = false;
    },

    async getTableRows() {
      if (this.mode === Mode.SERVER_SIDE) {
        try {
          const response = await axios.get(this.serverSideRowsCountEndpoint);

          this.serverSideTableRows = response.data;
        } catch {
          //
        }
      }
    },

    async handlePageChange(currentPage: number) {
      this.currentPage = currentPage;
      if (this.mode === Mode.OUTSOURCED) {
        this.$emit('page-changed', currentPage);
      }
      if (this.mode === Mode.SERVER_SIDE) {
        await this.getPaginatedItems();
      }
    },

    initializeFilters() {
      for (const field of this.fields) {
        if (!this.filterConfiguration[(field as any).key]) {
          continue;
        }

        this.$set(this.filters, (field as any).key, '');

        if (
          this.filterConfiguration[(field as any).key]?.type ===
          FilterType.SELECT
        ) {
          this.$set(this.filters, (field as any).key, null);
        }

        if (
          this.filterConfiguration[(field as any).key]?.type ===
          FilterType.DATE_RANGE_PICKER
        ) {
          this.$set(this.filters, (field as any).key, {});
        }

        if (
          this.filterConfiguration[(field as any).key]?.type ===
          FilterType.CHECK_BOX
        ) {
          this.$set(this.filters, (field as any).key, false);
        }
      }
    },

    handleDateRangeUpdated(dateRange: IDateRange, field: string) {
      if (!dateRange.startDate || !dateRange.endDate) {
        return;
      }
      (this.filters[field] as IDateRange).startDate = dateRange.startDate;
      (this.filters[field] as IDateRange).endDate = dateRange.endDate;

      this.$emit('filtering-changed', this.filters);
    },

    passEvent(payload: any) {
      this.$emit('action-event', payload);
    },

    setSortDirection(sortDesc: boolean) {
      this.sortDesc = sortDesc;
    },

    setSortBy(sortBy: string) {
      this.sortBy = sortBy;
    },

    handlePrefiltering() {
      if (!this.prefiltering) {
        return;
      }

      this.filters = {
        ...this.filters,
        ...this.prefiltering,
      };
    },

    getInputPlaceholder(config: any, field: string) {
      return config.customLabel
        ? config.customLabel
        : config.simpleLabel
          ? this.$t(`${this.componentName}.${field}`)
          : this.$t('filterableTable.filterBy', {
              field: this.$t(`${this.componentName}.${field}`),
            });
    },
  },

  created() {
    if (this.initialSortBy) {
      this.setSortBy(this.initialSortBy);
    }
    if (this.initialSortDesc !== undefined) {
      this.setSortDirection(this.initialSortDesc);
    }
    this.initializeFilters();
    this.handlePrefiltering();
  },

  watch: {
    filters: {
      handler: function () {
        if (this.currentPage !== 1) {
          this.currentPage = 1;
          this.$emit('page-changed', this.currentPage);
        }

        if (this.mode === Mode.OUTSOURCED) {
          this.$emit('filtering-changed', this.filters);
          return;
        }
        if (this.mode === Mode.SERVER_SIDE) {
          this.getPaginatedItems();
          this.getTableRows();
        }
      },
      deep: true,
    },

    perPage() {
      if (this.mode === Mode.OUTSOURCED) {
        this.$emit('per-page-changed', this.perPage);
        return;
      }
      this.getPaginatedItems();
    },

    sortDesc() {
      if (this.mode === Mode.OUTSOURCED) {
        this.$emit('sort-desc-changed', this.sortDesc);
        return;
      }
      this.getPaginatedItems();
    },

    sortBy() {
      if (this.mode === Mode.OUTSOURCED) {
        this.$emit('sort-by-changed', this.sortBy);
        return;
      }
      this.getPaginatedItems();
    },

    prefiltering() {
      this.handlePrefiltering();
    },
  },
});
