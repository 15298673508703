import ContentTypeModal from '@/components/ContentTypeModal/ContentTypeModal.vue';
import MainTable from '@/components/MainTable/MainTable.vue';
import { TitlePanelVariant } from '@/components/TitlePanel/TitlePanel.script';
import TitlePanel from '@/components/TitlePanel/TitlePanel.vue';
import { ITableField } from '@/frontendInterfaces';
import endpointsList from '@/helpers/endpointsList';
import { IDigitalizationProjectContentTypesAssignmentsSummaryDto } from 'types';
import { ContentTypeConfigurationMode } from '@/views/ContentTypeConfiguration/ContentTypeConfiguration.script';
import { router } from '@/vue';
import axios, { AxiosResponse } from 'axios';
import Vue from 'vue';
import { ContentTypeModalMode } from '../ContentTypeModal/ContentTypeModal.script';

interface IDigitalizationProjectContentTypes {
  addContentTypeModalMode: ContentTypeModalMode.DIGITALIZATION_PROJECT;
  contentTypes: IDigitalizationProjectContentTypesAssignmentsSummaryDto[];
  isGettingContentTypes: boolean;
  titlePanelVariant: TitlePanelVariant.SMALL;
}

export default Vue.extend({
  name: 'digitalization-project-content-types',

  components: { ContentTypeModal, MainTable, TitlePanel },

  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
  },

  data(): IDigitalizationProjectContentTypes {
    return {
      addContentTypeModalMode: ContentTypeModalMode.DIGITALIZATION_PROJECT,
      contentTypes: [],
      isGettingContentTypes: false,
      titlePanelVariant: TitlePanelVariant.SMALL,
    };
  },

  computed: {
    tableFields(): ITableField<IDigitalizationProjectContentTypesAssignmentsSummaryDto>[] {
      return [
        {
          key: 'contentTypeName',
          label: this.$t(
            'digitalizationProjectContentTypes.tableFields.contentTypeName',
          ),
          sortable: true,
        },
        {
          key: 'uploadsCount',
          label: this.$t(
            'digitalizationProjectContentTypes.tableFields.uploadsCount',
          ),
          sortable: true,
        },
      ];
    },
  },

  methods: {
    async getContentTypes() {
      try {
        this.isGettingContentTypes = true;

        const response: AxiosResponse<
          IDigitalizationProjectContentTypesAssignmentsSummaryDto[]
        > = await axios.get(
          endpointsList.contentTypeMappings.getDigitalizationProjectContentTypesSummary(
            this.digitalizationProjectId,
          ),
        );

        this.contentTypes = response.data;
      } catch {
        (this as any).createToast(
          this.$t('errors.error'),
          this.$t(
            'digitalizationProjectContentTypes.errors.errorGettingContentTypes.long',
          ),
          'danger',
        );
      } finally {
        this.isGettingContentTypes = false;
      }
    },

    handleContentTypeEditIconClick(row: {
      item: IDigitalizationProjectContentTypesAssignmentsSummaryDto;
    }) {
      router.push({
        name: 'contentTypeConfiguration',
        params: {
          contentTypeId: row.item._id,
          projectId: this.digitalizationProjectId,
          mode: ContentTypeConfigurationMode.DIGITALIZATION_PROJECT,
        },
      });
    },

    handleAddContentTypeButtonClick() {
      this.$bvModal.show('add-content-type-modal');
    },
  },

  mounted() {
    this.getContentTypes();
  },
});
