import { render, staticRenderFns } from "./DocumentMetadataTagInput.vue?vue&type=template&id=f8c1d0cc&scoped=true"
import script from "./DocumentMetadataTagInput.script.ts?vue&type=script&lang=ts&external"
export * from "./DocumentMetadataTagInput.script.ts?vue&type=script&lang=ts&external"
import style0 from "./DocumentMetadataTagInput.style.scss?vue&type=style&index=0&id=f8c1d0cc&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8c1d0cc",
  null
  
)

export default component.exports