import Vue from 'vue';
import ProjectsCoworkers from '@/components/projectsCoworkers/projectsCoworkers.vue';
import ContentTypeManagementTable from '@/components/ContentTypeManagementTable/ContentTypeManagementTable.vue';
import SupplierCheckBotManagement from '@/components/SupplierCheckBotManagement/SupplierCheckBotManagement.vue';
import { IProject, ISupplier } from 'types';
import axios, { AxiosResponse } from 'axios';
import endpointsList from '@/helpers/endpointsList';
import { ProjectsCoworkersTableMode } from '@/components/projectsCoworkers/projectsCoworkers.script';

interface IProjectsSupplierDetailView {
  activeTabIndex: number;
  fetchedProjectData?: IProject;
  supplierData?: ISupplier;
  projectCoworkersTableMode: ProjectsCoworkersTableMode.PROJECT;
}

export default Vue.extend({
  name: 'projects-supplier-detail-view',

  components: {
    ProjectsCoworkers,
    ContentTypeManagementTable,
    SupplierCheckBotManagement,
  },

  props: {
    projectId: {
      type: String,
      required: true,
    },
    projectData: {
      type: Object as () => IProject,
      required: false,
    },
    supplierId: {
      type: String,
      required: true,
    },
    tabNumber: {
      type: String,
      required: false,
      default: '0',
    },
  },

  data(): IProjectsSupplierDetailView {
    return {
      activeTabIndex: 0,
      fetchedProjectData: undefined,
      supplierData: undefined,
      projectCoworkersTableMode: ProjectsCoworkersTableMode.PROJECT,
    };
  },

  methods: {
    async getProjectData() {
      if (this.projectData) return;

      const response: AxiosResponse<IProject> = await axios.get(
        endpointsList.projects.getProjectById(this.projectId),
      );

      this.fetchedProjectData = response.data;
    },

    async getSupplierData() {
      const response: AxiosResponse<ISupplier> = await axios.get(
        endpointsList.suppliers.getSupplierById(this.supplierId),
      );

      this.supplierData = response.data;
    },
  },

  created() {
    if (!this.projectData) {
      this.getProjectData();
    }

    this.getSupplierData();

    this.activeTabIndex = Number(this.tabNumber);
  },
});
