import { render, staticRenderFns } from "./StyledModal.vue?vue&type=template&id=aaf3505c&scoped=true"
import script from "./StyledModal.script.ts?vue&type=script&lang=ts&external"
export * from "./StyledModal.script.ts?vue&type=script&lang=ts&external"
import style0 from "./StyledModal.styles.scss?vue&type=style&index=0&id=aaf3505c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf3505c",
  null
  
)

export default component.exports