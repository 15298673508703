"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.divideArray = divideArray;
/**
 * Divides an array into two parts based on a callback condition.
 * @param array - The array to divide.
 * @param callback - The callback function used to test each element. If it
 * returns true, the element goes into the first part; otherwise, it goes into
 * the second part.
 * @returns A tuple with two arrays: the first containing elements that satisfy
 * the condition, and the second containing elements that do not.
 */
function divideArray(array, callback) {
    const trueArray = [];
    const falseArray = [];
    for (const element of array) {
        if (callback(element)) {
            trueArray.push(element);
        }
        else {
            falseArray.push(element);
        }
    }
    return [trueArray, falseArray];
}
