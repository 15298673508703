enum codes {
  MOUSE_LEFT = 1,
  MOUSE_RIGHT = 3,
  MOUSE_MIDDLE = 2,
  BACKSPACE = 8,
  COMMA = 188,
  INSERT = 45,
  DELETE = 46,
  END = 35,
  ENTER = 13,
  ESCAPE = 27,
  CONTROL = 17,
  COMMAND_LEFT = 91,
  COMMAND_RIGHT = 93,
  COMMAND_FIREFOX = 224,
  ALT = 18,
  HOME = 36,
  PAGE_DOWN = 34,
  PAGE_UP = 33,
  PERIOD = 190,
  SPACE = 32,
  SHIFT = 16,
  CAPS_LOCK = 20,
  TAB = 9,
  ARROW_RIGHT = 39,
  ARROW_LEFT = 37,
  ARROW_UP = 38,
  ARROW_DOWN = 40,
  F1 = 112,
  F2 = 113,
  F3 = 114,
  F4 = 115,
  F5 = 116,
  F6 = 117,
  F7 = 118,
  F8 = 119,
  F9 = 120,
  F10 = 121,
  F11 = 122,
  F12 = 123,
  A = 65,
  C = 67,
  D = 68,
  F = 70,
  L = 76,
  O = 79,
  P = 80,
  S = 83,
  V = 86,
  X = 88,
}

enum codesLetter {
  ENTER = 'Enter',
  ENTER_NUM = 'NumpadEnter',
  A = 'KeyA',
  C = 'KeyC',
  X = 'KeyX',
  V = 'KeyV',
  ESCAPE = 'Escape',
  TAB = 'Tab',
  BACKSPACE = 'Backspace',
  DELETE = 'Delete',
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  SHIFT = 'Shift',
}

export default codes;
export { codesLetter };
