"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RequestHelper = void 0;
const user_helper_1 = require("./user-helper");
class RequestHelper {
    constructor(request) {
        this.request = request;
        if (!request.session.user) {
            throw new Error('User is not authenticated');
        }
        this.session = request.session;
    }
    get user() {
        return new user_helper_1.UserHelper(this.session);
    }
    extractValue(param, source) {
        if (source === 'request') {
            return this.request[param];
        }
        else {
            return this.request[source][param];
        }
    }
    appendValue(key, value) {
        this.request[key] = value;
    }
}
exports.RequestHelper = RequestHelper;
