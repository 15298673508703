import {
  ACTOR_APP,
  CheckBotModuleStrings,
  CheckbotStatusEnum,
  DocumentEvent,
  InspectorStatusEnum,
  FileStatus,
  MetadataCheckStatus,
  RecordLibraryTransferStatus,
  FormalControlStatus,
  DigitalizationProjectStatus,
  CheckBotInferenceStatus,
  MetadataInspectionStatus,
  QualityCheckStatus,
  DigitalizationFormalControlStatus,
  Role,
  DocumentStatus,
} from 'types';
import { UploadType } from '../components/UploadTable/UploadTable.script';
import { RemarksModalMode } from '@/components/RemarksModal/RemarksModal.script';
import { DigiDocuProcessingStep } from '@/helpers/getCurrentDigitalizationDocumentProcessingStep';
import { DocuProcessingStep } from '@/helpers/getCurrentDocumentProcessingStep';

export default {
  generic: {
    placeholder: 'Placeholder',
    notImplemented: 'Not implemented',
    firstName: 'First name',
    lastName: 'Last name',
    supplier: 'Supplier | Suppliers',
    email: 'Email',
    contentType: 'Contenttyp',
    uploadDate: 'Upload date',
    inspectionDueDate: 'Inspection due date',
    inspection: 'Formal exam.',
    inspectorStatus: 'Technical exam.',
    metadataInference: 'AI proposals',
    metadataInspection: 'Metadata inspection',
    qualityCheck: 'Quality check',
    qualityCheckAbbreviated: 'Quality check',
    documentId: 'Document ID',
    documentIdAbbreviated: 'Doc.',
    administration: 'Administration',
    docuManager: 'Dokumenten-Manager',
    saveChanges: 'Save changes',
    cancel: 'Cancel',
    ok: 'Ok',
    document: 'Document',
    apply: 'Apply',
    warning: 'Warning',

    suppliers: 'Suppliers',
    coworkers: 'Coworkers',
    goBack: 'Go back',
    project: 'Project',
    projects: 'Projects',
    coworker: 'Coworker',
    save: 'Save',
    add: 'Add',
    close: 'Close',
    select: 'Select',
    removeAllFilters: 'Remove all filters',
    clear: 'clear',
    showAll: 'Show all',
    recordLibraryTransfer: 'Record library transfer',
    yes: 'yes',
    no: 'no',
    forExample: 'E.g.',

    selectOption: 'Select option',

    months: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },

    days: {
      monday: 'M',
      tuesday: 'T',
      wednesday: 'W',
      thursday: 'T',
      friday: 'F',
      saturday: 'S',
      sunday: 'S',
    },
  },

  errors: {
    error: 'An error occured',
  },

  app: {
    logOutReminder: 'Remember to log out before closing this window',
  },

  // To be removed and replaced with formalControlStatus
  checkBotStatus: {
    [CheckbotStatusEnum.ok]: 'Accepted',
    [CheckbotStatusEnum.waiting]: 'In progress',
    [CheckbotStatusEnum.critical]: 'Rejected',
    [CheckbotStatusEnum.info]: 'Accepted',
    [CheckbotStatusEnum.error]: 'Rejected',
    [CheckbotStatusEnum.timedOut]: 'Timeout',
    [CheckbotStatusEnum.warning]: 'Accepted with remarks',
  },

  formalControlStatus: {
    [FormalControlStatus.ACCEPTED]: 'Accepted',
    [FormalControlStatus.ACCEPTED_WITH_REMARKS]: 'Accepted with remarks',
    [FormalControlStatus.REJECTED]: 'Rejected',
    [FormalControlStatus.WAITING]: 'In progress',
  },

  digitalizationFormalControlStatus: {
    [DigitalizationFormalControlStatus.ACCEPTED]: 'Accepted',
    [DigitalizationFormalControlStatus.ACCEPTED_WITH_REMARKS]:
      'Accepted with remarks',
    [DigitalizationFormalControlStatus.REJECTED]: 'Rejected',
    [DigitalizationFormalControlStatus.WAITING]: 'In progress',
    [DigitalizationFormalControlStatus.WAITING_FOR_METADATA_CHECK]:
      'Waiting for metadata inspection',
  },

  inspectorStatus: {
    [InspectorStatusEnum.accepted]: 'Accepted',
    [InspectorStatusEnum.acceptedWithRemarks]: 'Accepted with remarks',
    [InspectorStatusEnum.timedOut]: 'Inspection due date exceeded',
    [InspectorStatusEnum.waiting]: 'In progress',
    [InspectorStatusEnum.rejected]: 'Rejected',
    [InspectorStatusEnum.waitingForFormalControl]: '-',
  },

  metadataCheckStatus: {
    [MetadataCheckStatus.FAILED]: 'Rejected',
    [MetadataCheckStatus.ACCEPTED]: 'Accepted',
  },

  fileStatus: {
    [FileStatus.waitingForFormalControl]: 'Waiting for formal control',
    [FileStatus.waitingForContentControl]: 'Waiting for content control',
    [FileStatus.rejected]: 'Rejected',
    [FileStatus.accepted]: 'Accepted',
    [FileStatus.acceptedWithRemarks]: 'Accepted with remarks',
  },

  recordLibraryTransferStatus: {
    [RecordLibraryTransferStatus.SUCCESSFULL]: 'Successfull',
    [RecordLibraryTransferStatus.FAILED]: 'Failed',
    [RecordLibraryTransferStatus.AWAITING_INSPECTIONS_COMPLETION]: '-',
  },

  checkBotInferenceStatus: {
    [CheckBotInferenceStatus.CHECK_BOT_INFERENCE_DISABLED]: 'Deactivated',
    [CheckBotInferenceStatus.WAITING]: 'In progress',
    [CheckBotInferenceStatus.DATA_RECEIVED]: 'Received',
  },

  metadataInspectionStatus: {
    [MetadataInspectionStatus.WAITING]: 'In progress',
    [MetadataInspectionStatus.ACCEPTED]: 'Accepted',
    [MetadataInspectionStatus.REJECTED]: 'Rejected',
  },

  qualityCheckStatus: {
    [QualityCheckStatus.WAITING_FOR_FORMAL_CONTROL]:
      'Waiting for formal control',
    [QualityCheckStatus.WAITING]: 'In progress',
    [QualityCheckStatus.ACCEPTED]: 'Accepted',
    [QualityCheckStatus.REJECTED]: 'Rejected',
  },

  digitalizationDocumentStatus: {
    [DocumentStatus.METADATA_INSPECTION]: '@:generic.metadataInspection',
    [DocumentStatus.FORMAL_CONTROL]: 'Formal control',
    [DocumentStatus.QUALITY_CHECK]: '@:generic.qualityCheck',
    [DocumentStatus.RECORD_LIBRARY_TRANSFER_IN_PROGRESS]: 'Transfer to Daisy',
    [DocumentStatus.RECORD_LIBRARY_TRANSFER_FAILED]: 'Transfer to Daisy failed',
    [DocumentStatus.RECORD_LIBRARY_TRANSFER_SUCCESSFULL]: 'Completed',
  },

  roles: {
    [Role.Admin]: 'Administrator | Administrators',
    [Role.DocuManager]: 'Document manager | document managers',
    [Role.Inspector]: 'Inspector | Inspectors',
    [Role.Coworker]: 'Coworker | Coworkers',
    [Role.DocuResponsible]: 'Docu responsible | Docu responsibles',
    [Role.DigitalizationCoworker]:
      'Digitalization coworker | Digitalization coworkers',
  },

  breadCrumb: {
    projectsList: 'Projects list',
    createProject: 'Create project',
    projectDetails: 'Project details',
    contentTypeConfiguration: 'Import configuration',
    addDocuManagerToProject: 'Add document manager to project',
    addCoworkerToProject: 'Add coworker to project',
    addSupplierToProject: 'Add supplier to project',
    documentUpload: 'Documents upload',
    documentDetailView: 'Document detail view',
    uploadReferenceNumber: 'Upload',
    digitalizationUploadReferenceNumber: 'Upload',
    suppliersList: 'Suppliers list',
    supplierDetails: 'Supplier details',
    coworkerDetails: 'Coworker details',
    administration: 'Administration',
    docuManagerDetailView: 'Document manager details',
    appConfig: 'App configuration',
    checkBotConfigurator: 'Check Bot Configurator',
    digitalizationProjectsList: 'Digitalization projects list',
    createDigitalizationProject: 'Create digitalization project',
    digitalizationProjectDetails: 'Digitalisation project details',
  },

  navBar: {
    header: 'SP Documentation Portal',
    suppliers: 'Supplier management',
    projects: 'Projects',
    administration: 'Administration',
    digitalizationProjects: 'Digitalization projects',
  },

  filterableTable: {
    filter: 'Filter',
    entriesPerPage: 'Entries per page',
    filterBy: 'Filter by {field}',
    all: 'All',
    resetAllFilters: 'Remove all filters',
  },

  projectsList: {
    projects: 'Projects',
    title: 'Titel',
    number: 'Number',
    status: 'Status',
    projectTitle: 'Title',
    projectNumber: 'Number',
    projectStatus: 'Status',
    fileStatuses: 'File statuses',
    addProject: 'Add project',
    errors: {
      couldNotLoadProjects: 'An error occured while loading projects',
    },
    description:
      'Welcome to our project overview and management! ' +
      'Here you can centrally manage and organize all information ' +
      'and progress of your projects to ensure efficient and ' +
      'successful project implementation.',
    active: 'active',
    inactive: 'inactive',
  },

  suppliersList: {
    suppliers: 'Suppliers',
    name: 'Name',
    SAPNumber: 'SAP-No.',
    coworkers: 'Coworkers',
    description:
      'Welcome to our supplier overview and management! ' +
      'Here you can bring together all information about ' +
      'your suppliers in one place.',
  },

  projectDetails: {
    project: 'Project',
    goToProjectRoom: 'Go to project room',
    tabs: {
      settings: 'Settings',
      suppliers: 'Suppliers',
      coworkers: 'Coworkers',
      contentTypes: 'Content types',
      deliveries: 'Deliveries',
      documents: 'Documents',
    },

    contentTypes: {
      contentTypes: '@:projectDetails.tabs.contentTypes',
      contentTypeName: 'Name',
      supplierName: 'Supplier',
      addContentType: 'Add content type',
      selectContentType: 'Content type',
      selectSupplier: 'Select supplier',
      errors: {
        noContentTypeSelected: 'No content type was selected',
        noSupplierSelected: 'No supplier was selected',
      },
      uploadCount: 'Uploads',
    },

    documents: {
      documents: '@:projectDetails.tabs.documents',
      documentId: '@:generic.documentId',
      supplierName: '@:generic.supplier',
      contentType: '@:generic.contentType',
      uploadDate: '@:generic.uploadDate',
      inspectionDueDate: '@:generic.inspectionDueDate',
      coworker: '@:generic.coworker',
      checkBotStatus: '@:generic.inspection',
      inspectorStatus: '@:generic.inspectorStatus',
      revision: 'Rev.',
      referenceNumber: 'Reference no.',
      rejectDocuments: 'Reject documents',
      acceptDocuments: 'Accept documents',
      errors: {
        couldNotLoadDocuments: 'An error occured while loading documents',
      },
      selectAll: 'Select all documents',
      deselectAll: 'Deselect all documents',

      [CheckbotStatusEnum.ok]: `@:checkBotStatus.${CheckbotStatusEnum.ok}`,
      [CheckbotStatusEnum.waiting]: `@:checkBotStatus.${CheckbotStatusEnum.waiting}`,
      [CheckbotStatusEnum.critical]: `@:checkBotStatus.${CheckbotStatusEnum.critical}`,
      [CheckbotStatusEnum.info]: `@:checkBotStatus.${CheckbotStatusEnum.info}`,
      [CheckbotStatusEnum.error]: `@:checkBotStatus.${CheckbotStatusEnum.error}`,
      [CheckbotStatusEnum.timedOut]: `@:checkBotStatus.${CheckbotStatusEnum.timedOut}`,
      [CheckbotStatusEnum.warning]: `@:checkBotStatus.${CheckbotStatusEnum.warning}`,
      // For formal control filter select
      [MetadataCheckStatus.FAILED]: `@:metadataCheckStatus.${MetadataCheckStatus.FAILED}`,

      [InspectorStatusEnum.accepted]: `@:inspectorStatus.${InspectorStatusEnum.accepted}`,
      [InspectorStatusEnum.acceptedWithRemarks]: `@:inspectorStatus.${InspectorStatusEnum.acceptedWithRemarks}`,
      [InspectorStatusEnum.timedOut]: `@:inspectorStatus.${InspectorStatusEnum.timedOut}`,
      // [InspectorStatusEnum.waiting]: `@:inspectorStatus.${InspectorStatusEnum.waiting}`,
      [InspectorStatusEnum.rejected]: `@:inspectorStatus.${InspectorStatusEnum.rejected}`,
      [InspectorStatusEnum.waitingForFormalControl]: '-',

      undefined: '-',

      [RecordLibraryTransferStatus.SUCCESSFULL]: `@:recordLibraryTransferStatus.${RecordLibraryTransferStatus.SUCCESSFULL}`,
      // [RecordLibraryTransferStatus.FAILED]: `@:recordLibraryTransferStatus.${RecordLibraryTransferStatus.FAILED}`,

      recordLibraryTransferStatus: 'Transfer to Daisy',
    },

    // old
    projectDetails: 'Projekt Details',
    addSupplier: 'Add supplier',
    showCoworkers: 'Show coworkers',
    hideCoworkers: 'Hide coworkers',
    addCoworker: 'New coworker',
    docuManagers: 'Document managers',
    addDocuManager: 'Add document manager',
    generalInfo: 'General information',
    suppliers: 'Suppliers',
    configuration: 'Configuration',
    csvMapping: 'Column mapping',
    contentTypeMapping: 'Content type mapping',
    chooseContentType: 'Choose content type',
    noContentTypeSelected: 'No content type selected',
    contentTypeAlreadyAdded:
      'This content type was already added for this supplier',
    filesOverview: 'Files overview',
    fileUpload: 'File upload',
    action: 'Action',
    assignedContentTypes: 'Assigned content types',
    contentTypesManagement: 'Content types management',
  },

  adminPanel: {
    appConfiguration: 'App Konfiguration',
    checkBotTimeout: 'Checkbot Timeout (in days)',
    saveChanges: '@:generic.saveChanges',
    firstName: '@:generic.firstName',
    lastName: '@:generic.lastName',
    email: '@:generic.email',
    administration: '@:generic.administration',
    docuManager: '@:generic.docuManager',
    checkCheckBotResults: 'Check check bot results',
    checkMetadataInferenceResults: 'Check check bot metadata results',
    checkCheckBotTimeouts: 'Check check bot timeouts',
    errors: {
      errorCheckingCheckBotResults:
        'An error occured while checking check bot results.',
      errorCheckingCheckBotTimeouts:
        'An error occured while checking check bot timeouts',
    },
  },

  uploadsOverview: {
    deliveries: 'Deliveries',
    newDelivery: 'New delivery',
    errors: {
      couldNotLoadDeliveries: 'An error occured while loading deliveries',
    },
    supplier: 'Supplier',
    count: 'Doc.',
    contentType: 'Content type',
    content: 'Content',
    documentStatus: 'Document status',
    uploadDate: 'Upload date',
    inspectionDueDate: 'Inspection due date',
    soonest: 'soonest',

    supplierName: 'Supplier',
    filterContentTypes: 'Filter content types',
    new: 'New',
    updated: 'Updated',

    tooltips: {
      downloadUploadConfirmation: 'Download upload confirmation',
    },

    hideProcessed: 'Hide completed uploads',
  },

  projectsSuppliers: {
    supplierName: 'Name',
    contentTypes: 'Content types',
    deliveries: 'Deliveries',
    suppliers: 'Suppliers',
    coworkers: 'Coworkers',
    assignedContentTypeNames: 'Content types',
    description:
      'Welcome to our supplier overview and management! ' +
      'Here you can bring together all information about ' +
      'your suppliers in one place.',
    addSupplier: 'Add supplier',
    errors: {
      couldNotLoadSuppliers:
        'An error occured while loading projects suppliers',
      couldNotUpdateConfigTemplate:
        'An error occured while updating the check bot configuration template',
      templateAlreadyAssigned:
        'The check bot configuration was already set for this supplier',
      couldNotLoadTestKeys:
        'An error occured while loading test keys for standard check bot configurations.',
      supplierAlreadyAdded: 'This supplier was already added to this project.',
      errorRemovingSupplier:
        'An error occured while removing this supplier from this project.',
      cannotRemoveSupplierWithUploads:
        'This supplier cannot be removed from this project because he already has made uploads.',
    },
    setCheckBotConfigTemplate: 'Set check bot configuration template',
    checkBotConfigTemplate: 'Check bot config. template',
    checkBotConfig: 'Check bot configuration',
    checkBotConfigTemplateSet:
      'Check bot configuration template was successfully set',
    checkBotConfigTemplateSetShort: 'Checkbot Konfiguration eingestellt',
    enterSupplierName: 'Enter supplier name',
    [CheckBotModuleStrings.CUSTOM_CHECK_BOT_CONFIG]: 'custom',
    tooltips: {
      editCheckBotConfig: 'Edit check bot config',
      applyCheckBotConfigTemplate: 'Apply check bot config template',
      editSupplier: 'Edit supplier',
    },
    templateReassignmentConfirmation:
      'This template was customized. Are you sure you want to overwrite it?',
    deactivated: 'Deactivated',
    supplierSuccessfullyDeleted:
      'The supplier was successfully removed from this project.',
  },

  projectsCoworkers: {
    errors: {
      couldNotLoadProjects:
        'An error occured while loading coworkers in project',
      couldNotRemoveCoworker:
        'An error occured while trying to remove the coworker from this project.',
    },
    firstName: 'First name',
    lastName: 'Last name',
    supplier: 'Supplier',
    email: 'Email',
    description:
      'Welcome to our employee overview and management! ' +
      'Here you can centrally manage and organize all ' +
      'information about supplier employees to ensure ' +
      'smooth project processes.',
    coworkers: 'Coworkers',
    addCoworker: 'Add coworker',
    coworkerRemoved: 'The coworker was successfully removed from the project.',
  },

  supplierDetailView: {
    coworkers: 'Coworkers',
    contentTypes: 'Content types',
    checkBot: 'Check bot',
    assignedConfiguration: 'Zugewiesene Konfiguration',
    configurationEditor: 'Configuration editor',
    assignConfiguration: 'Assign configuration',
    noConfigurationAssigned: 'No configuration was assigned',
  },

  pageTop: {
    languages: {
      en: 'English',
      de: 'German',
    },
    logout: 'Logout',
    currentUser: 'Current user',
    chooseLang: 'Choose language',
  },

  sidebar: {
    header: 'SP Documentation Portal',
    dashboard: 'Dashboard',
    suppliers: 'Supplier management',
    addSuplier: 'Add supplier',
    suppliersList: 'Suppliers list',
    projects: 'Projects',
    projectsList: 'Projects list',
    adminPanel: 'Admin panel',
  },

  suppliersForm: {
    addSupplier: 'Add supplier',
    name: 'Name',
    supplierId: 'SAP supplier ID',
    address: 'Address',
    street: 'Street',
    houseNumber: 'House number',
    ZIPCode: 'ZIP code',
    city: 'City',
    validationErrorEmpty: 'Field cannot be empty',
    validationErrorToLong: 'The maximum number of characters is 256',
    validationErrorZIPCode: 'The ZIP code must have exactly 5 digits',
    submit: 'Submit',
    supplierCreated: 'Supplier created',
    submittingSupplier: 'Supplier is being created',
    submitError: 'Sorry, an error occurred while processing your request',
    submittingChanges: 'Saving changes',
    changesSubmitted: 'Your changes have been saved',
  },

  supplierDetails: {
    tabs: {
      informations: 'Informations',
      projects: 'Projects',
      coworkers: 'Coworkers',
      contentTypes: 'Content types',
      deliveries: 'Deliveries',
      documents: 'Documents',
    },

    informations: {
      informations: '@:supplierDetails.tabs.informations',
      street: 'Street',
      houseNumber: 'House number',
      ZIPCode: 'ZIP code',
      city: 'City',
      SAPNumber: 'SAP-No.',

      errors: {
        couldNotGetSupplierDetails: 'An error occured while loading supplier',
      },
    },

    coworkers: {
      coworkers: '@:generic.coworkers',
      firstName: '@:generic.firstName',
      lastName: '@:generic.lastName',
      email: '@:generic.email',
      description:
        'Welcome to our employee overview and administration! ' +
        'Here, you can centrally manage ' +
        'and organize all information about supplier employees ' +
        'to ensure smooth project workflows.',
      addCoworker: 'Add Coworker',
      errors: {
        couldNotAddCoworker: 'An error occured while adding coworker',
        coworkerAlreadyAddedLong:
          'Cannot assign this coworker to this supplier because he was already assigned to supplier {supplierName}',
        coworkerAlreadyAddedShort: 'Cannot assign coworker',
      },
    },

    // old
    supplierDetails: 'Supplier details',
    editSupplier: 'Edit supplier',
    chooseCoworker: 'Choose coworker',
    updateSupplier: 'Save',
    cancelUpdateSupplier: 'Cancel',
    coworkerAlreadyAdded: 'This coworker was already added.',
    coworkersList: 'Coworkers list',
    noCoworkerSelected: 'Coworker was not selected',
    coworkerAdded: 'Mitarbeiter wurde hinzugefügt',
    addCoworkerToSupplier: 'Add coworker to supplier',
    actions: 'Actions',
    deleteCoworker: 'Delete coworker',
    coworkerDeleted: 'Coworker was succesfully deleted',
  },

  projectSettings: {
    settings: 'Settings',
  },

  projectForm: {
    title: 'Title',
    number: 'Number',
    location: 'Location',
    status: 'Status',
    duration: 'Duration',
    inspectionDueTime: 'Inspection due time (in days)',
    description: 'Description',
    projectStatus: {
      active: 'active',
      inactive: 'inactive',
    },
    changesSaved: 'Changes were successfuly saved',
    saveChanges: 'Save changes',
    cancel: 'Cancel',
    createDigitalizationProject: 'Create digitalization project',
    errors: {
      wrongProjectNumber: 'Wrong project number',
      projectNumberNotUniqueForSite:
        'A project with this number already exists for this site.',
    },

    // old
    projectTitle: 'Project title',
    projectId: 'Project ID',
    projectLocation: 'Projekt location',
    projectDescription: 'Project description',
    assignedSuppliers: 'Assigned suppliers',
    assignedCoworkers: 'Assigned coworkers',
    KKS: 'KKS',
    projectDuration: 'Project duration',
    durationStart: 'Duration start',
    durationEnd: 'Duration end',
    submit: 'Create project',
    updateProject: 'Save',
    cancelUpdateProject: 'Cancel',
    submitError: 'Sorry, an error occurred while processing your request',
    submittingChanges: 'Saving changes',
    changesSubmitted: 'Your changes have been saved',
    projectCreated: 'Project created',
    submittingProject: 'Project is being created',
    durationStartLaterThanEnd:
      'The start date cannot be later than the end date',
    mandatoryFieldsInfo: 'Mandatory fields are marked with *',
    projectNumberWrongSignes:
      'Project number has to be alphanumeric (- and _ are allowed).',
    projectTitleWrongSignes:
      'Project title has to be alphanumeric (- and _ are allowed).',
  },

  projectsDocuManagers: {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    docuManager: 'Document manager | Document managers',
    addDocuManager: 'Add docu manager',
    errors: {
      couldNotGetDocuManagers:
        'An error occured while loading projects docu managers',
      docuManagerAlreadyAdded:
        'This document manager was already added to this project.',
      cannotRemoveLast:
        'The last document manager cannot be removed from the project.',
      errorRemovingDocuManager:
        'An error occured while removing the document manager.',
      cannotRemoveSelf:
        'A document manager cannot remove themselves from the project.',
    },
  },

  inspectors: {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    inspectors: 'Inspectors',
    addInspector: 'Add inspector',
    specialization: 'Specialization',
    inspectorAdded: 'inspector succesfully added',
    inspectorAlreadyAdded: 'This inspector was already added',
    couldNotGetInspectors: 'An error occured while loading inspectors',
    couldNotAddInspector:
      'An error occured while assigning coworker to this project',
    docuManagerAlreadyAdded:
      'This document manager was already assigned to this project.',
    errors: {
      errorRemovingInspector: 'An error occured while removing the inspector.',
      cannotRemoveLast: 'The last inspector cannot be removed from a project.',
    },
  },

  createProject: {
    errors: {
      noDocuManagersAdded: 'At least one document manager has to be assigned',
      couldNotCreateProject: 'An error occured while creating this project',
    },
  },

  projectConfiguration: {
    inspectionTime: 'Inspection due time setting',
    days: 'days',
    inspectionTimeSet: 'Inspection time set',
  },

  coworkerDetailView: {
    coworkerDetails: 'Coworker details',
    projects: 'Projects',
    project: 'Project',
    supplier: 'Supplier',
    projectId: 'Project number',
    supplierId: 'Supplier ID',
  },

  addSupplierToProject: {
    addSupplierToProject: 'Add supplier to project',
    add: 'Add',
    actions: 'Actions',
    remove: 'Remove',
    supplierAdded: 'Supplier added',
    availableSuppliers: 'Available suppliers',
    assignedSuppliers: 'Assigned suppliers',
  },

  addCoworkerToProject: {
    addCoworkerToProject: 'Add coworker to project',
    coworkerAdded: 'Added coworker to project',
    errors: {
      couldNotGetSuppliersCoworkers:
        'An error occured while loading suppliers coworkers',
      couldNotGetProjectsCoworkers:
        'An error occured while loading suppliers added to this project',
      couldNotAddCoworker:
        'An error occured while adding coworker to this project',
      couldNotAddCoworkerToSupplier:
        'An error occured while adding coworker to supplier',
      coworkerAlreadyAddedLong:
        'Cannot assign this coworker to this supplier because he was already assigned to supplier {supplierName}',
      coworkerAlreadyAddedShort: 'Cannot assign coworker',
    },
  },

  addDocuManagerToProject: {
    addDocuManagerToProject: 'Add document manager to project',
    docuManagerAdded: 'Added document manager to project',
  },

  docuManagersTable: {
    addDocuManager: 'Assign document manager',
    deleteDocuManager: 'Delete document manager',
    errors: {
      couldNotGetDocuManagers:
        'An error occured while loading document managers',
    },
  },

  docuManagers: {
    docuManager: 'Document manager | Document managers',
  },

  docuManagerDetailView: {
    docuManagerDetails: 'Document manager details',
    exchangeDocuManager: 'Exchange document manager in all projects',
    selectNewDocuManager: 'Select new document manager',
    docuManagerExchanged: 'Document manager was successfully exchanged',
    noDocuManagerSelected: 'No document manager was selected',
  },

  contentTypeConfiguration: {
    contentTypeConfiguration: 'Import configuration',
    uploadCsv: 'Upload CSV sheet',
    identifiedColumns: 'Identified columns',
    csvColumns: 'CSV Columns',
    contentTypeField: 'Content type field',
    dataType: 'Data type',
    importField: 'Import value',
    importFieldType: 'Import-Wert-Typ',
    csvMapping: 'Column mapping',
    selectForAllFields: 'Select import fields for all mandatory fields',
    mappingSaved: 'Contenttype mapping saved',
    revision: 'Revision',
    relativePath: 'Relative path',
    checkBotField: 'Checkbot field',
    checkBotFieldsValidationError:
      'The following required check bot fields were not assigned to a content type field: {missingFields}',
    duplicateCheckBotFieldsAssignments:
      'Every check bot field can be assigned to only one content type field',
    checkBotFieldOnlyWithCSVField: 'Missing import value',
    constantValue: 'Constant value',
    currentDate: 'Current date',
    csvField: 'CSV field',
    presetValue: 'Preset value',
    errors: {
      couldNotGetPossibleTaxonomyValues: 'Could not get taxonomy values',
      errorGettingMapping:
        'An error occured while loading the import configuration.',
      errorSavingMapping:
        'An eror occured while saving the import configuration',
    },
  },

  documentUpload: {
    zipToolHint:
      'We recommend compressing ZIP files larger than 1.5GB using the 7-Zip tool with the deflate algorithm.',
    fileName: 'File name',
    importDataSets: 'Import metadata',
    uploadFiles: 'Upload files',
    uploadFile: 'Upload file',
    uploadFileSecondary: 'Upload file',
    dragFileHere: 'Drag & drop your file here',
    upToSize: 'Up to {size}',
    csvImport: 'CSV import',
    zipUpload: 'Zip upload',
    massUpload: 'Mass upload',
    singleUpload: 'Single upload',
    newUpload: 'New upload',
    documentUpload: 'Document upload',
    fileUpload: 'File upload',
    manualEntry: 'Manual entry',
    dataSets: 'Data sets',
    fileSetsAndFileUpload: 'File sets and file upload',
    documentId: 'Document-ID',
    upload: 'Upload',
    source: 'Source',
    documentStatus: 'Document status',
    filepath: 'Folder path',
    file: 'File',
    actions: 'Actions',
    header:
      'Document upload {uploadId} for {projectName} of content type {contentTypeName}',
    version: 'Version',
    id: 'ID',
    singleUploadInfo: 'Upload single file',
    massUploadInfo: 'Upload multiple files from an Zip archive',
    fileFound: 'File found',
    finalizeUpload: 'Finalize upload',
    metadataFor: 'Metadata for {fileName}',
    validationErrorEmpty: 'Field cannot be empty',
    fileMissing: 'File has to be added',
    filesUploaded: 'Files have been successfully uploaded to Sharepoint',
    noMappingAssigned:
      'No content type mapping was assigned for this content type by this supplier in this project. Please contact the document manager of this project.',
    wrongCsvError:
      'The uploaded CSV file does not match the configured content type mapping',
    referenceNumberError:
      'An upload with this reference number was already made in this project.',
    errorReadingCsv: 'An error occured while reading the CSV file',
    field: 'Field',
    value: 'Value',
    newValue: 'New value',
    oldValue: 'Old value',
    [UploadType.NEW_DOCUMENT]: 'New document',
    [UploadType.UPDATED_DOCUMENT]: 'Updated document',
    [UploadType.UPDATED_METADATA]: 'Updated metadata',
    singleUploadDescription: 'Upload one file according to the CSV schema.',
    errors: {
      missingFile: 'File missing.',
      missingRequiredFields: 'All required fields have to bee filled out.',
      fileToBigShort: 'File is to big',
      fileToBig:
        'The file You are trying to upload is to big. The maximum size is {maxSize}.',
      uploadError: 'An error occured while uploading documents',
      noUploadRecords: 'The upload contains no records',
      uploadFailed: 'An error occured while uploading documents',
      zipCorrupted: 'The uploaded ZIP file is corrupted.',
      forbiddenCharacters: 'The file name contains forbidden characters',
      validationError: 'An error occured while validating the upload.',
      cannotGetMetadataChanges:
        'An error occured while checking the metadata changes.',
      cannotCheckPreviousVersions:
        'An error occured while checking previous version of the documents.',
    },
    warnings: {
      noFilesFound: 'No matching files where found in the ZIP archive.',
      fileIsEmpty: 'The uploaded file is empty.',
    },
    uploadSuccessfull:
      'The file was succesfully uploaded.|The files were succesfully uploaded.',
    uploadSuccessfullShort: 'File uploaded|Files uploaded',
    wrongDateFormat:
      'The date should be in the following format: "DD.MM.YYYY".',
    invalidDocumentId:
      'Special signes are present in the ID input field. Check Your input and try again. (- and _ are allowed)',
    uploadZipFile: 'Upload ZIP file',
    uploadDirectory: 'Upload directory',
    uploadMultipleFiles: 'Upload files',
    uploadPackage: 'Upload package',
    disabledCheckBotInspection: 'Disabled check bot inspection',
    noMetadataUpload: 'No-metadata upload',
  },

  ADModal: {
    noUserSelected: 'User was not selected',
    pressEnterToSelect: 'Press enter to select',
    pressEnterToRemove: 'Press enter to remove',
    enterAtLeast3Characters: 'Enter at least 3 characters',
    noData: 'No data',
  },

  spGroupModal: {
    noUserSelected: 'User was not selected',
    pressEnterToSelect: 'Press enter to select',
    pressEnterToRemove: 'Press enter to remove',
    noData: 'No data',
    couldNotGetUsers: 'An error occured while loading Active Directory users',
    noResults: 'No results',
    selectUser: 'Select user',
  },

  filesOverview: {
    newUpload: 'New upload',
    uploadId: 'Reference no.',
    selectContentType: 'Content type',
    provideUploadId: 'Reference number has to be provided',
    provideContentType: 'Content type has to be selected',
    provideSupplier: 'Supplier has to be selected',
    id: 'ID',
    uploadedIn: 'Upload ID',
    contentType: 'Content type',
    inspectionDueDate: 'Inspection due date',
    fileStatus: 'Formal Control',
    uploadDate: 'Upload date',
    inspectorStatus: 'Inspector status',
    supplier: 'Supplier',
    coworker: 'Coworker',
    actions: 'Actions',
    all: 'All',
    select: 'Select',
    acceptSelected: 'Accept selected',
    rejectSelected: 'Reject selected',
    confirmStatusChanges: 'Confirm status changes',
    followingDocumentsWillBeAccepted: 'Following documents will be accepted:',
    followingDocumentsWillBeRejected: 'Following documents will be rejected:',
    showAfterCheckbot: 'Show only files checked by Checkbot',
    plusOthers: '+ others',
    changeInspectionDueDate: 'Change inspection due date',
    newInspectionDueDate: 'New inspection due date',
    inspectorStatusChangeSuccessfullFiles:
      'The inspector status was successfully changed for {successfullCount} document. | The inspector status was successfully changed for {successfullCount} documents.',
    inspectorStatusChangeFailedFiles:
      'An error occured while changing the inspector status of {failedCount} document. | An error occured while changing the inspector status of {failedCount} documents.',
    errors: {
      inspectionDueDateChangeError:
        'An error occured while changing the inspection due dates',
    },
  },

  missingFilesModal: {
    title: 'Missing files',
    missingFiles: 'Some files are missing and will not be uploaded',
    cancel: 'Cancel',
    submit: 'Submit',
    missingFilesWithChangedMetadata:
      'For these file IDs only their metadata will be updated, because the files are not appended',
    emptyFiles: 'Empty files (0 Bytes) were uploaded for these file IDs',
  },

  documentDetailView: {
    document: '@:generic.document',
    remarksPlaceHolder: 'Conditions under which the document is accepted.',
    documentDetails: 'Document details',
    metadata: 'Metadata',
    checkBotStatus: 'Formal control',
    inspectorStatus: 'Inspector status',
    openFile: 'Open file',
    downloadDocument: 'Download file',
    accept: 'Accept',
    acceptWithRemarks: 'Accept document w. rem.',
    reject: 'Reject',
    remarks: 'Remarks',
    inspectorStatusChanged: 'Inspector status was changed',
    checkBotDetails: 'Checkbot details',
    rerunFormalControl: 'Rerun formal control',
    successfullyRerunFormalControl: {
      short: 'Formal control rerun',
      long: 'The formal control was rerurn.',
    },
    checkBotEstablishedKksNumbers: 'KKS proposals',
    copyCheckBotKks: 'Copy all',
    copyValidatedKks: 'Copy confirmed',
    checkBotEstablishedKksCopied: {
      short: 'KKS copied',
      long: 'The check bot KKS was successfully copied. | The check bot KKS were successfully copied.',
    },
    metadataCheckAccepted: 'The metadata check was succesfully accepted.',
    tabs: {
      document: 'Document',
      history: 'Document history',
    },
    errors: {
      cannotDownloadFile: {
        long: 'An error occured while downloading the file.',
      },
      cannotRemoveLastValue: {
        short: 'Cannot remove this value',
        long: 'The last value of a mandatory field cannot be removed.',
      },
      cannotGetPossibleValues: {
        short: '@:errors.error',
        long: 'An error occured while getting the possible metadata values.',
      },
      errorRerunningMetadataCheck: {
        long: 'An error occured while executing the metadata check.',
      },
      errorCopyingEstablishedKks: {
        long: 'An error occured while copying the established KKS',
      },
      errorValidatingMetadata: 'Metadata validation failed.',
      errorAcceptingMetadataCheck:
        'An error occured while accepting the metadata check.',
    },
    history: {
      errors: {
        errorGettingHistory: 'An error occured while loading documents history',
      },
      event: 'Event',
      date: 'Date',
      actor: 'Actor',
      statusChange: 'Status change',
      note: 'Note',
      additionalInfo: 'Additional information',
      events: {
        [DocumentEvent.UPLOAD]: 'Upload performed',
        [DocumentEvent.FAILED_UPLOAD]: 'Failed upload',
        [DocumentEvent.INSPECTION]: 'Content inspection',
        [DocumentEvent.QUALITY_CHECK]: 'Quality check completed',
        [DocumentEvent.CHECKBOT_RESULT]:
          'Formal check for content accuracy completed',
        [DocumentEvent.COPIED_TO_RECORD_LIBRARY]: 'Transferred to Daisy',
        [DocumentEvent.CHECKBOT_INPUT_LIST_ENTRY]:
          'Formal check for content requested',
        [DocumentEvent.CHECKBOT_INPUT_FAILED]:
          'Failed to add entry to Checkbot input list',
        [DocumentEvent.METADATA_CHECK_RESULT]:
          'Formal check for taxonomic accuracy completed',
        [DocumentEvent.REVERTED_TO_METADATA_CHECK]:
          'Document was reverted to metadata check due to a change in the metadata.',
        [DocumentEvent.FAILED_CHECK_SUPPLIER_NOTIFICATION]:
          'Notification to the supplier about failed formal check',
        [DocumentEvent.NEW_RESULTS_INSPECTOR_NOTIFICATION]:
          'Notification to inspectors about formal check results',
        [DocumentEvent.INSPECTION_DUE_INSPECTORS_NOTIFICATION]:
          'Notification to inspectors about pending inspection deadline',
        [DocumentEvent.FAILED_INSPECTION_SUPPLIER_NOTIFICATION]:
          'Notification to the supplier about failed content inspection',
        [DocumentEvent.METADATA_INFERENCE_CHECKBOT_INPUT_LIST_ENTRY]:
          'AI suggestions requested',
        [DocumentEvent.METADATA_INFERENCE_CHECKBOT_RESULT]:
          'AI suggestions received',
      },
      [ACTOR_APP]: 'Application',
      failedCheckBotInputInfo:
        'The input to the check bot input list failed. Please contact Your documentation manager for this project.',
    },
    inspectionDueDate: 'Inspection due date',
    changeInspectionDueDate: 'Change inspection due date',
    inspectionDueDateChanged: 'Inspection due date changed succesfully',
    continueProcess: 'Continue process',
    showKksProposals: 'Show KKS proposals',
    hideKksProposals: 'Hide KKS proposals',
    showAllMetadata: 'Show all metadata',
    showLessMetadata: 'Show less metadata',
  },

  remarksModal: {
    title: {
      [RemarksModalMode.ACCEPT_WITH_REMARKS]: 'Accept document with remarks',
      [RemarksModalMode.REJECT]: 'Reject document',
    },
    remarksPlaceHolder: {
      [RemarksModalMode.ACCEPT_WITH_REMARKS]: 'Remarks about the document',
      [RemarksModalMode.REJECT]: 'Reasons of rejection',
    },
    errors: {
      enterRemarks: {
        [RemarksModalMode.ACCEPT_WITH_REMARKS]: 'Provide remarks',
        [RemarksModalMode.REJECT]: 'Provide reasons of rejection',
      },
    },
  },

  checkBotConfigurator: {
    checkBotConfiguration: 'Check bot Configuration',
    checkBotConfigurationSavedShort: 'Check bot configuration saved',
    checkBotConfigurationSaved:
      'The check bot configuration was successfully saved.',
    errors: {
      couldNotGetCheckBotConfig:
        'An error occured while loading check bot configuration.',
      couldNotSaveCheckBotConfig:
        'An error occured while saving the check bot configuration.',
    },
    warnings: {
      checkWasAlreadyAdded: 'This check is already added.',
      checkWasAlreadyAddedShort: 'Check already added',
    },
  },

  addCheckModal: {
    addCheck: 'Add check',
    errors: {
      errorLoadingChecks: 'An error occured while loading check bot checks.',
    },
  },

  appConfig: {
    appConfig: 'App configuration',
    checkBotTimeout: 'Checkbot timeout',
    save: 'Save',
    days: 'days',
    checkBotTimeoutSaved: 'Checkbot timeout saved!',
  },

  checkBotResultsTable: {
    check: 'Check',
    state: 'Status',
    details: 'Details',
    result: 'Result',
    detailsCopied: 'Details copied',
  },

  metadataCheckResultsTable: {
    metadataCheck: 'Metadata check',
    field: 'Field',
    value: 'Value',
    status: 'Status',
    error: 'Error',
    statuses: {
      true: 'Accepted',
      false: 'Rejected',
    },
  },

  progressStepsIndicator: {
    documentUploadFailed:
      'The upload of the document failed. Please contact the documentation manager of Your project.',
    project: {
      [DocuProcessingStep.UPLOAD]: 'Document uploaded',
      [DocuProcessingStep.FORMAL_INSPECTION]: 'Formal control',
      [DocuProcessingStep.CONTENT_INSPECTION]: 'Content control',
      [DocuProcessingStep.RECORD_LIBRARY_TRANSFER]: 'Transfer to Daisy',
    },
    digitalizationProject: {
      [DigiDocuProcessingStep.UPLOAD_DONE]: 'Document uploaded',
      [DigiDocuProcessingStep.METADATA_INSPECTION]: 'Metadata inspection',
      [DigiDocuProcessingStep.FORMAL_CONTROL]: 'Formal control',
      [DigiDocuProcessingStep.QUALITY_CHECK]: '@:generic.qualityCheck',
      [DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER]: 'Transfer to Daisy',
    },
  },

  footer: {
    privacy: 'Privacy',
    legalNotice: 'Legal notice',
  },

  styledTagsInput: {
    addTag: 'Add value',
  },

  forbiddenView: {
    heading: "You don't have access",
    subheading:
      "You don't have access. Contact Your document manager for the project.",
  },

  csvParsingErrorsModal: {
    csvErrors: 'The following errors occured while reading the CSV file:',
    error: 'Error',
    row: 'Row',
    InvalidQuotes: 'Trailing quote on quoted field is malformed',
    MissingQuotes: 'Quoted field unterminated',
    TooFewFields:
      'Too few fields: expected {expectedFieldsNumber} fields but parsed {parsedFieldsNumber}',
    TooManyFields:
      'Too many fields: expected {expectedFieldsNumber} fields but parsed {parsedFieldsNumber}',
    UndetectableDelimiter: 'Unable to auto-detect delimiting character',
  },

  fileNameErrorsModal: {
    forbiddenCharactersPresent:
      'There are forbidden characters in the file names.',
    documentId: 'Document ID',
    forbiddenCharacters: 'Forbidden characters',
  },

  digitalizationProjectsList: {
    addDigitalizationProject: 'Add digitalization project',
    digitalizationProjects: 'Digitalization projects',
    errors: {
      errorGettingDigitalizationProjects: {
        long: 'An error occured while loading digitalization projects.',
      },
    },
    number: 'Number',
    status: 'Status',
    statuses: {
      [DigitalizationProjectStatus.ACTIVE]: 'active',
    },
    title: 'Titel',
  },

  createDigitalizationProject: {
    addDigitalizationProject: 'Add digitalization project',
    errors: {
      digitalizationProjectNumberNotUnique: {
        long: 'A digitalization project with this project number already exists.',
      },
      errorCreatingDigitalizationProject: {
        long: 'An error occured while creating the digitalization project.',
      },
      noDocuResponsibleAdded: {
        long: 'At least one documentation responsible has to be added.',
      },
    },
    requiredFieldsMarkedWith: 'Mandatory fields are marked with *.',
  },

  docuResponsiblesTable: {
    docuResponsible: 'Documentation responsible | Documentation responsibles',
    errors: {
      couldNotGetDocuResponsibles: {
        long: 'An error occured while loading the docu responsibles.',
      },
    },
  },

  digitalizationProjectDetails: {
    errors: {
      errorLoadingProjectData: {
        long: 'An error occured while loading the project data.',
      },
    },
    goToProjectRoom: 'Go to project room',
    tabs: {
      documents: 'Documents',
      informations: 'Informations',
      settings: 'Settings',
      suppliers: 'Suppliers',
      uploads: 'Uploads',
    },
  },

  digitalizationProjectContentTypes: {
    addContentType: 'Add content type',
    contentTypes: 'Content types',
    errors: {
      errorGettingContentTypes: {
        long: 'An error occured while loading the content types.',
      },
    },
    tableFields: {
      contentTypeName: 'Name',
      uploadsCount: 'Uploads',
    },
  },

  digitalizationUploadsOverview: {
    contentTypeName: '@:generic.contentType',
    documentsCount: 'Doc.',
    documentsStatuses: 'Documents statuses',
    downloadUploadConfirmation: 'Download upload confirmation',
    errors: {
      errorGettingUploads: {
        long: 'An error occured while loading document uploads.',
      },
    },
    hideProcessed: 'Hide completed uploads',
    newAndUpdatedDocumentsCount: 'Content',
    newUpload: 'New upload',
    referenceNumber: 'Reference no.',
    uploadDate: 'Upload date',
    uploads: 'Uploads',
  },

  qualityCheckPanel: {
    accept: 'Accept',
    errors: {
      errorSettingQualityCheckStatus: {
        long: 'An error occured while changing the quality check status.',
      },
    },
    qualityCheck: '@:generic.qualityCheck',
    qualityCheckStatusSet: 'The quality check status was changed successfully.',
    reject: 'Reject',
  },

  digitalizationDocumentsOverview: {
    checkBotInferenceStatus: '@:generic.metadataInference',
    contentTypeName: '@:generic.contentType',
    continueProcess: 'Continue process',
    coworker: '@:generic.coworker',
    deselectAll: 'Deselect all documents',
    documentId: '@:generic.documentIdAbbreviated',
    documents: 'Documents',
    documentStatus: 'Process status',
    errors: {
      errorGettingDocuments: {
        long: 'An error occured while loading the documents.',
      },
    },
    formalControlStatus: '@:generic.inspection',
    plusOthers: '+ other',
    recordLibraryTransferStatus: '@:generic.recordLibraryTransfer',
    referenceNumber: 'Reference no.',
    revision: 'Rev.',
    selectAll: 'Select all documents',
    title: 'Title',
    qualityCheckStatus: '@:generic.qualityCheck',
    uploadDate: '@:generic.uploadDate',
  },

  digitalizationProjectInformations: {
    informations: 'Informations',
  },

  digitalizationProjectDocuResponsibles: {
    addDocuResponsible: 'Add docu responsible',
    docuResponsible: '@:roles.docuResponsible',
    docuResponsibleAdded: 'Docu responsible was successfully added.',
    email: '@:generic.email',
    errors: {
      docuResponsibleAlreadyAdded: {
        long: 'This docu responsible was already added to this project.',
      },
      errorAddingDocuResponsible: {
        long: 'An error occured while adding the docu responsible.',
      },
      errorGettingDocuResponsbiles: {
        long: 'An error occured while loading docu responsibles.',
      },
    },
    firstName: '@:generic.firstName',
    lastName: '@:generic.lastName',
  },

  metadataGridEditor: {
    changedHint: '✘ - Document will be moved back to formal control.',
    checkBotEstablishedKksNumbers: 'KKS proposals',
    copyCheckBotKks: 'Copy all KKS',
    copyValidatedKks: 'Copy confirmed KKS',
    cancel: '@:generic.cancel',
    docAfterInferenceHint:
      'Documents with purple-marked ID were processed by the check bot.',
    inferredValueHint:
      'Brown-marked cells contain values that were inferred by the check bot.',
    errors: {
      errorGettingMetadata: 'An error occured while getting the metadata.',
      errorSavingMetadataChanges: 'An error occured while saving the metadata.',
      errorUpdatingQualityCheck:
        'An error occurred while changing the status of the quality check.',
      errorValidatingMetadata: 'Metadata validation failed.',
      errorGettingInferenceStatuses:
        'An error occured while getting the metadata inference statuses.',
    },
    hint: 'Hint',
    metadataEditor: 'Metadata editor',
    metadataSaved: 'The metadata was saved successfully.',
    notChangedHint: '✔ - The document is ready for transfer to Daisy',
    saveChanges: '@:generic.saveChanges',
    saveChangesAndContinue: 'Continue process',
    undoAcceptingKks: 'Undo KKS acceptance',
  },

  newDigitalizationUploadModal: {
    disableCheckBotInspection: 'Skip check bot inspection',
    noMetadataUpload: 'No-metadata upload',
    noMappingAssigned:
      'No content type mapping was assigned for this content type by this supplier in this project. Please contact the document responsible of this project.',
  },

  kksValidator: {
    errors: {
      errorValidatingKks: 'An error occured while validating the KKS values.',
    },
  },

  contentTypeFields: {
    CreateDate: 'Create date',
    CreatedBy: 'Created by',
    CreatorDocumentNumber: 'Document number',
    DocLanguage: 'Language',
    DocStatus: 'Status',
    DocumentClass: 'Master DCC',
    DocumentClassContent: 'Content DCC',
    DocumentCreator: 'Creator',
    documentId: 'Document-ID',
    FolderNumber: 'Folder number',
    KKS: 'KKS',
    ObjectID: 'Object ID',
    OriginalFilename: 'Original filename',
    /** Typo in content type field on Queportdev SharePoint */
    OriginalFIlename: 'Original filename',
    Revision: 'Revision',
    Title: 'Title',
    Project: 'Project',
    ReleaseDate: 'Release date',
    ResponsibleArea: 'Responsible area',
    StationNumber: 'Station number',
  },
};
