import { Role } from 'types';
import { ProjectRole } from '@/interfaces/frontend-types';
import { IVueexState } from '@/store';
import Vue from 'vue';

export type ProjectTabNumbersMixin = {
  getProjectDetailViewTabNumber: (tab: ProjectDetailViewTab) => string;
};

export enum ProjectDetailViewTab {
  SETTINGS = 'settings',
  SUPPLIERS = 'suppliers',
  UPLOADS = 'uploads',
  DOCUMENTS = 'documents',
}

type TabNumbersByRoles = {
  [key in ProjectRole]: {
    [key in ProjectDetailViewTab]?: number;
  };
};

const tabNumbersByRoles: TabNumbersByRoles = {
  [Role.Admin]: {
    [ProjectDetailViewTab.SETTINGS]: 0,
    [ProjectDetailViewTab.SUPPLIERS]: 1,
    [ProjectDetailViewTab.UPLOADS]: 2,
    [ProjectDetailViewTab.DOCUMENTS]: 3,
  },
  [Role.DocuManager]: {
    [ProjectDetailViewTab.SETTINGS]: 0,
    [ProjectDetailViewTab.SUPPLIERS]: 1,
    [ProjectDetailViewTab.UPLOADS]: 2,
    [ProjectDetailViewTab.DOCUMENTS]: 3,
  },
  [Role.Inspector]: {
    [ProjectDetailViewTab.SETTINGS]: 0,
    [ProjectDetailViewTab.UPLOADS]: 1,
    [ProjectDetailViewTab.DOCUMENTS]: 2,
  },
  [Role.Coworker]: {
    [ProjectDetailViewTab.SETTINGS]: 0,
    [ProjectDetailViewTab.UPLOADS]: 1,
    [ProjectDetailViewTab.DOCUMENTS]: 2,
  },
};

export default Vue.extend({
  methods: {
    getProjectDetailViewTabNumber(tab: ProjectDetailViewTab): string {
      const userRoles = (this.$store.state as IVueexState).userRoles;
      if (!userRoles) {
        return '0';
      }

      for (const role in tabNumbersByRoles) {
        if (!userRoles.includes(role as ProjectRole)) continue;

        const tabNumbersForRole = tabNumbersByRoles[role as ProjectRole];
        const tabNumber = tabNumbersForRole[tab];

        if (tabNumber === undefined) {
          return '0';
        }

        return tabNumber.toString();
      }

      return '0';
    },
  },
});
