import {
  ACTOR_APP,
  DocumentHistoryEntry,
  DocumentDetailViewMode,
  DocumentEvent,
} from 'types';
import Vue from 'vue';
import MainTable from '../MainTable/MainTable.vue';
import { ToastMixin } from '../../mixins/globalUtils';
import axios, { AxiosResponse } from 'axios';
import endpointsList from '../../helpers/endpointsList';
import { ITableField } from '../../frontendInterfaces';
import { ITableRow } from '@/interfaces/frontend-types';

export interface IDocumentHistory {
  history?: DocumentHistoryEntry[];
  createToast?: ToastMixin;
  actorApp: string;
  expandedInfoItems: Set<string>;
  forceRerender: number;
}

export default Vue.extend({
  name: 'document-history',
  components: { MainTable },

  props: {
    mode: {
      type: String as () => DocumentDetailViewMode,
      required: true,
    },
    uploadId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
  },

  data(): IDocumentHistory {
    return {
      history: undefined,
      actorApp: ACTOR_APP,
      expandedInfoItems: new Set(),
      forceRerender: 0,
    };
  },

  computed: {
    tableFields(): ITableField[] {
      return [
        {
          key: 'date',
          label: this.$i18n.t('documentDetailView.history.date'),
          tdClass: ['w-20'],
        },
        {
          key: 'event',
          label: this.$i18n.t('documentDetailView.history.event'),
          tdClass: ['w-20'],
        },
        {
          key: 'actor',
          label: this.$i18n.t('documentDetailView.history.actor'),
          tdClass: ['w-20'],
        },
        {
          key: 'statusChange',
          label: this.$i18n.t('documentDetailView.history.statusChange'),
          tdClass: ['w-20'],
        },
        {
          key: 'additionalInfo',
          label: this.$i18n.t('documentDetailView.history.additionalInfo'),
          tdClass: ['w-20'],
        },
      ];
    },
  },

  methods: {
    async getHistory() {
      try {
        const endpoint =
          this.mode === DocumentDetailViewMode.PROJECT
            ? endpointsList.documentHistory.getDocumentHistory(
                this.uploadId,
                this.documentId,
              )
            : endpointsList.documentHistory.getDigitalizationDocumentHistory(
                this.uploadId,
                this.documentId,
              );
        const response: AxiosResponse<DocumentHistoryEntry[]> =
          await axios.get(endpoint);

        this.history = response.data;
      } catch (err: unknown) {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('documentDetailView.history.errors.errorGettingHistory'),
          'danger',
        );
      }
    },

    toggleInfoDisplay(item: DocumentHistoryEntry) {
      const itemId = item._id.toString();
      if (this.expandedInfoItems.has(itemId)) {
        this.expandedInfoItems.delete(itemId);
        this.forceRerender++;
      } else {
        this.forceRerender++;
        this.expandedInfoItems.add(itemId);
      }
    },

    truncateText(text: string, itemId: string) {
      const maxLength = 100;
      if (this.expandedInfoItems.has(itemId)) {
        return text;
      }
      return text.length > maxLength
        ? text.substring(0, maxLength) + '...'
        : text;
    },

    isFailedCheckBotInputHistoryEntry(row: ITableRow<DocumentHistoryEntry>) {
      return row.item.event === DocumentEvent.CHECKBOT_INPUT_FAILED;
    },
  },

  created() {
    this.getHistory();
  },
});
