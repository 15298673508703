import {
  DocumentType,
  IDigitalizationDocumentSummaryPageOptionsDto,
  IDigitalizationProjectsPageOptionsDto,
  IDigitalizationUploadSummaryPageOptionsDto,
  IDocumentsUploadsSummaryPageOptionsDto,
  IFilesQueryRequest,
  ISupplier,
  QualityCheckStatus,
} from 'types';
import { transformPageOptionsToQueryString } from './transformPageOptionsToQueryString';

export default {
  general: {
    userRoles: 'roles',
    userData: '/user',
  },

  redirections: {
    prefix: 'redirections',
    getDaisyProjectRoomUrl: function (projectTitle: string) {
      return `${this.prefix}/daisy-project-room/${projectTitle}`;
    },
  },

  projects: {
    getAllProjects: 'projects',
    addProject: 'projects',
    getProjectById: (projectId: string) => `projects/${projectId}`,
    getProjectNameById: (projectId: string) => `projects/${projectId}/name`,
    getProjectLocation: (projectId: string) => `projects/${projectId}/location`,
    updateProject: (projectId: string) => `projects/${projectId}`,
    // TODO: Refactor endpoint
    getProjectSuppliersById: (
      projectId: string,
      fields?: (keyof ISupplier)[],
      includeDeleted?: boolean,
    ) => {
      let url = `projects/suppliers/${projectId}`;
      if (fields?.length) {
        const commaSeparatedFields = fields.join(',');
        url = `${url}?fields=${commaSeparatedFields}`;
      }
      if (includeDeleted) {
        if (url.includes('?')) {
          url = `${url}&includeDeleted=true`;
        } else {
          url = `${url}?includeDeleted=true`;
        }
      }

      return url;
    },
    addSupplierToProject: (projectId: string) =>
      `projects/suppliers/add-supplier/${projectId}`,
    addCoworkerToProject: (projectId: string) =>
      `projects/coworkers/add-coworker/${projectId}`,
    getCoworkersForProjectForSupplier: (
      projectId: string,
      supplierId: string,
    ) =>
      `projects/coworkers-in-project/${projectId}/for-supplier/${supplierId}`,
    getPossibleProjectLocations:
      'projects/locations/possible-project-locations',
    addDocuManagerToProject: (projectId: string, docuManagerEmail: string) =>
      `projects/docu-managers/add/${projectId}/${docuManagerEmail}`,
    getDocuManagersForProject: (projectId: string) =>
      `projects/docu-managers/${projectId}`,
    removeDocuManagerFromProject: (projectId: string, docuManagerOid: string) =>
      `projects/docu-managers/${projectId}/${docuManagerOid}`,
    getInspectorsForProject: (projectId: string) =>
      `projects/inspectors/${projectId}`,
    addInspectorToProject: (projectId: string, inspectorOid: string) =>
      `projects/inspectors/${projectId}/${inspectorOid}`,
    removeInspectorFromProject: (projectId: string, inspectorOid: string) =>
      `projects/inspectors/${projectId}/${inspectorOid}`,
    setInspectionDueTime: (projectId: string) =>
      `projects/inspection-due-time/${projectId}`,
    getInspectionDueTime: (projectId: string) =>
      `projects/inspection-due-time/${projectId}`,
    getCoworkersInProject: (projectId: string) =>
      `projects/suppliers/coworkers/${projectId}`,
    assignCheckBotConfigTemplateToSupplier:
      'projects/check-bot/check-bot-template',
    getAllCoworkersAssignedToSuppliersInProject: (projectId: string) =>
      `projects/${projectId}/suppliers/coworkers/all`,
    removeCoworkerFromProject: (projectId: string, coworkerOid: string) =>
      `projects/coworker/${projectId}/${coworkerOid}`,
  },

  suppliers: {
    getAllSuppliers: 'suppliers',
    queryAndPaginateAllSuppliers: (
      searchData: { name?: string; SAPNumber?: string },
      page: number,
      resultsPerPage: number,
      sorting: 'name' | 'SAPNumber' | 'coworkersCount',
      sortDirection: 1 | -1,
    ) => {
      let endpoint = `suppliers/paginate/${page}/${resultsPerPage}/${sorting}/${sortDirection}`;
      if (searchData.name && searchData.SAPNumber) {
        endpoint = `${endpoint}?name=${searchData.name}&SAPNumber=${searchData.SAPNumber}`;
      } else if (searchData.name) {
        endpoint = `${endpoint}?name=${searchData.name}`;
      } else if (searchData.SAPNumber) {
        endpoint = `${endpoint}?SAPNumber=${searchData.SAPNumber}`;
      }

      return endpoint;
    },

    count: 'suppliers/count',
    searchSuppliers: (searchData: { name: string; SAPNumber: string }) =>
      `suppliers/query?name=${searchData.name}&SAPNumber=${searchData.SAPNumber}`,
    getSupplierById: (supplierId: string) => `suppliers/id/${supplierId}`,
    getSupplierNameById: (supplierId: string) => `suppliers/name/${supplierId}`,
    getCoworkersForSupplier: (supplierId: string) =>
      `suppliers/${supplierId}/coworkers`,
    getCoworkerByEmail: (coworkerEmail: string) =>
      `suppliers/coworkers/${coworkerEmail}`,
    getSupplierByCoworkerByEmail: (coworkerEmail: string) =>
      `suppliers/coworkers/get-by-email/${coworkerEmail}`,
    addCoworkerToSupplier: (supplierId: string) =>
      `suppliers/${supplierId}/coworkers`,
    removeCoworker: (email: string) => `suppliers/coworkers/${email}`,
  },

  projectsSuppliers: {
    prefix: 'projects-suppliers',
    getProjectsSuppliersSummary: function (projectId: string) {
      return `${this.prefix}/summary/${projectId}`;
    },
    getContentTypesListForSupplierInProject: function (
      projectId: string,
      supplierId: string,
    ) {
      return `${this.prefix}/upload-count-by-content-type-names/${projectId}/${supplierId}`;
    },
    removeSupplierFromProject: function (
      projectId: string,
      supplierId: string,
    ) {
      return `${this.prefix}/projects-supplier/${projectId}/${supplierId}`;
    },
  },

  activeDirectory: {
    queryUsersInCoworkerFormat: 'ad-integration/users/query',
  },

  inspectors: {
    getAllInspectors: 'inspectors',
  },

  docuManagers: {
    getAllDocuManagers: 'docu-managers',
  },

  contentTypeMappings: {
    addAssignmentToContentType: (contentTypeId: string) =>
      `content-types/assignments/${contentTypeId}`,
    getContentTypesAssignmentsForProject: (projectId: string) =>
      `content-types/assignments/${projectId}`,
    addFieldsMapping: (
      contentTypeId: string,
      projectId: string,
      supplierId: string,
    ) =>
      `content-types/fields-mapping/${contentTypeId}/${projectId}/${supplierId}`,
    getFieldsMapping: (
      contentTypeId: string,
      projectId: string,
      supplierId: string,
    ) =>
      `content-types/fields-mapping/${contentTypeId}/${projectId}/${supplierId}`,
    getCheckBotMapping: (contentTypeId: string) =>
      `content-types/checkbot-mapping/${contentTypeId}`,
    getContentTypeNamesForProject: (projectId: string) =>
      `content-types/names/${projectId}`,
    getDigitalizationProjectContentTypesSummary: (
      digitalizationProjectId: string,
    ) =>
      `content-types/digitalization-project/assignments/${digitalizationProjectId}`,
    getDigitalizationContentTypeAndCheckBotMapping: (
      contentTypeId: string,
      digitalizationProjectId: string,
    ) =>
      `content-types/digitalization-project/assignments/fields-mapping/${contentTypeId}/${digitalizationProjectId}`,
    createDigitalizationProjectContentTypeMapping:
      'content-types/digitalization-project/assignments/fields-mapping',
    addDigitalizationProjectContentTypeAssignment:
      'content-types/digitalization-project/assignments',
    getContentTypeById: (contentTypeId: string) =>
      `content-types/schemas/id/${contentTypeId}`,
    getPossibleValuesForContentTypesTaxonomyFields: (contentTypeId: string) =>
      `content-types/taxonomy-values/id/${contentTypeId}`,
  },

  sharePointIntegration: {
    getListOfContentTypesNamesAndIds:
      'share-point-integration/content-types/names-and-ids',
    getContentTypeNameById: (contentTypeId: string) =>
      `share-point-integration/content-types/name/id/${contentTypeId}`,
    getAllCoworkers: 'share-point-integration/coworkers',
    downloadUploadConfirmationFile: (
      projectNumber: string,
      sapNumber: string,
      uploadReferenceNumber: string,
    ) =>
      `share-point-integration/upload-confirmation-file/${projectNumber}/${sapNumber}/${uploadReferenceNumber}`,
    prepareUploadDirectories: (projectNumber: string, sapNumber: string) =>
      `share-point-integration/prepare-upload-directories/${projectNumber}/${sapNumber}`,
  },

  documentsUploads: {
    acceptOneCheckBotEstablishedKks: (fileId: string, kks: string) =>
      `documents-uploads/file/accept-one-check-bot-established-kks/${fileId}/${encodeURIComponent(
        kks,
      )}`,
    uploadFile: (projectId: string, supplierId: string) =>
      `documents-uploads/file/${projectId}/${supplierId}`,
    addDocumentsUpload: 'documents-uploads',
    getUploadsInUploadViewFormat: (projectId: string) =>
      `documents-uploads/upload-view-format/${projectId}`,
    getFilesForProject: (
      projectId: string,
      page: number,
      perPage: number,
      sorting: string,
      sortDirection: 1 | -1,
      query?: Partial<IFilesQueryRequest>,
    ): string => {
      let url = `documents-uploads/files/${projectId}/${page}/${perPage}/${sorting}/${sortDirection}`;

      // TODO: extract function as helper
      for (const field in query) {
        const value = query[field as keyof IFilesQueryRequest];

        if (value) {
          if (!url.includes('?')) {
            url = `${url}?${field}=${value}`;
          } else {
            url = `${url}&${field}=${value}`;
          }
        }
      }

      return url;
    },
    getMetadataChanges: (projectId: string, supplierId: string) =>
      `documents-uploads/changes/project/${projectId}/supplier/${supplierId}`,
    getIdsOfPreviouslyUploadedFilesVersions: (
      projectId: string,
      supplierId: string,
    ) =>
      `documents-uploads/older-files-versions/ids/project/${projectId}/supplier/${supplierId}`,
    getFile: (projectId: string, supplierId: string, documentId: string) =>
      `documents-uploads/file/${projectId}/${supplierId}/${documentId}`,
    assignInspectorStatus: 'documents-uploads/inspector-status',
    assignInspectorStatusToMultipleFiles:
      'documents-uploads/inspector-statuses',
    getUploadsCountForSupplierInProject: (
      projectId: string,
      supplierId: string,
    ) =>
      `documents-uploads/project/${projectId}/supplier/${supplierId}/uploads-count`,
    validateReferenceNumber: (projectId: string): string =>
      `documents-uploads/reference-number/validate/${projectId}`,
    changeInspectionDueDates: 'documents-uploads/inspection-due-dates/update',
    getPaginatedDocumentsUploadsSummaryForProject: (
      pageOptions: IDocumentsUploadsSummaryPageOptionsDto,
    ) =>
      transformPageOptionsToQueryString(
        'documents-uploads/uploads-summary',
        pageOptions,
      ),
    getValuesOfEditableDocumentMetadataFields: (
      uploadId: string,
      documentId: string,
    ) =>
      `documents-uploads/editable-metadata-fields-values/${uploadId}/${documentId}`,
    updateValuesOfEditableMetadataFields: (
      uploadId: string,
      documentId: string,
    ) =>
      `documents-uploads/editable-metadata-fields-values/${uploadId}/${documentId}`,
    moveCheckBotEstablishedKksNumbersToMainKksField: (
      fileId: string,
      validatedOnly: boolean,
    ) =>
      `documents-uploads/file/accept-check-bot-established-kks/${fileId}?validatedOnly=${validatedOnly}`,
    getFileMetadataByIds: (
      projectId: string,
      includeAutomaticallySetFields: boolean = false,
    ) =>
      `documents-uploads/file-metadata/${projectId}?includeAutomaticallySetFields=${includeAutomaticallySetFields}`,
    getDocumentTitle: (
      projectId: string,
      supplierId: string,
      documentId: string,
    ) =>
      `documents-uploads/file-metadata/title/${projectId}/${supplierId}/${documentId}`,
    getDocumentUploadReferenceNumber: (
      projectId: string,
      supplierId: string,
      documentId: string,
    ) =>
      `documents-uploads/file-metadata/upload-reference-number/${projectId}/${supplierId}/${documentId}`,
  },

  appConfig: {
    getAppConfig: 'app-config',
    setAppConfig: 'app-config',
  },

  checkBot: {
    getCheckBotConfigTemplates: 'checkbot/templates/names',
    getCheckBotConfigFile: (projectId: string, supplierId: string) =>
      `checkbot/config/check-bot-config/${projectId}/${supplierId}`,
    saveCheckBotConfigFile: 'checkbot/config/modify-check-bot-config',
    allChecks: 'checkbot/config/master-template',
    checkCheckBotResults: 'checkbot/check-results',
    checkMetadataInferenceResults: 'checkbot/check-metadata-inference-results',
    checkCheckBotTimeouts: 'checkbot/check-timeouts',
    getTestKeysForTemplates: 'checkbot/config/names-and-test-keys',
    executeMetadataCheck: (fileId: string) =>
      `checkbot/execute-metadata-check/${fileId}`,
    getDigitalizationProjectCheckBotConfigFile: (
      digitalizationProjectId: string,
    ) =>
      `checkbot/config/digitalization-project/check-bot-config/${digitalizationProjectId}`,
    saveDigitalizationProjectCheckBotConfigFile:
      'checkbot/config/digitalization-project/modify-check-bot-config',
  },

  documentHistory: {
    getDocumentHistory: (uploadId: string, documentId: string) =>
      `document-history/document/${uploadId}/${documentId}`,
    getDigitalizationDocumentHistory: (uploadId: string, documentId: string) =>
      `document-history/digitalization-document/${uploadId}/${documentId}`,
  },

  digitalizationProjects: {
    addCoworkerToDigitalizationProject: 'digitalization-projects/coworkers',
    addDocuResponsibleToDigitalizationProject: (
      digitalizationProjectId: string,
      docuResponsibleOid: string,
    ) =>
      `digitalization-projects/docu-responsibles/${digitalizationProjectId}/${docuResponsibleOid}`,
    createDigitalizationProject: 'digitalization-projects',
    getDigitalizationProjectCheckBotTemplate: (
      digitalizationProjectId: string,
    ) =>
      `digitalization-projects/check-bot-configuration/${digitalizationProjectId}`,
    getDigitalizationProjectCoworkers: (digitalizationProjectId: string) =>
      `digitalization-projects/coworkers/${digitalizationProjectId}`,
    getDigitalizationProjectDocuResponsibles: (
      digitalizationProjectId: string,
    ) => `digitalization-projects/docu-responsibles/${digitalizationProjectId}`,
    getDigitalizationProjectLocation: (digitalizationProjectId: string) =>
      `digitalization-projects/digitalization-project-data/${digitalizationProjectId}/location`,
    getDigitalizationProjectTitle: (digitalizationProjectId: string) =>
      `digitalization-projects/title/${digitalizationProjectId}`,
    getDigitalizationProjectSettings: (digitalizationProjectId: string) =>
      `digitalization-projects/digitalization-project-data/${digitalizationProjectId}`,
    getDigitalizationProjectsList: (
      pageOptions: IDigitalizationProjectsPageOptionsDto,
    ): string => {
      return transformPageOptionsToQueryString(
        'digitalization-projects',
        pageOptions,
      );
    },
    removeCoworkerFromDigitalizationProject: (
      digitalizationProjectId: string,
      coworkerOid: string,
    ) =>
      `digitalization-projects/coworkers/${digitalizationProjectId}/${coworkerOid}`,
    setCheckBotConfiguration: 'digitalization-projects/check-bot-configuration',
    updateDigitalizationProject: (digitalizationProjectId: string) =>
      `digitalization-projects/digitalization-project-data/${digitalizationProjectId}`,
  },

  digitalizationCoworkers: {
    getDigitalizationCoworkers: 'digitalization-coworkers',
  },

  docuResponsibles: {
    getAllDocuResponsibles: 'docu-responsibles',
  },

  digitalizationUploads: {
    acceptCheckBotEstablishedKksNumbers: (
      docuObjectId: string,
      validatedOnly: boolean,
    ) =>
      `digitalization-uploads/digitalization-documents-metadata/accept-check-bot-established-kks/${docuObjectId}?validatedOnly=${validatedOnly}`,
    getCheckBotEstablishedKksForMultipleDocuments:
      'digitalization-uploads/digitalization-documents-metadata/check-bot-established-kks-for-documents',
    getCheckBotInferenceStatusForMultipleDocuments:
      'digitalization-uploads/digitalization-documents/inference-statuses',
    getDigitalizationDocumentDataByProjectIdentification: (
      digitalizationProjectId: string,
      documentId: string,
    ) =>
      `digitalization-uploads/document-data/${digitalizationProjectId}/${documentId}`,
    getDocumentMetadataByIds: (
      digitalizationProjectId: string,
      includeAutomaticallySetFields: boolean = false,
      additionalFieldsToInclude: string[] = [],
    ) => {
      let url = `digitalization-uploads/digitalization-documents-metadata/${digitalizationProjectId}?includeAutomaticallySetFields=${includeAutomaticallySetFields}`;
      if (additionalFieldsToInclude.length) {
        url = `${url}&additionalFieldsToInclude=${additionalFieldsToInclude.join(',')}`;
      }
      return url;
    },
    getDocumentTitle: (digitalizationProjectId: string, documentId: string) =>
      `digitalization-uploads/document-title/${digitalizationProjectId}/${documentId}`,
    getDocumentUploadReferenceNumber: (
      digitalizationProjectId: string,
      documentId: string,
    ) =>
      `digitalization-uploads/document-upload-reference-number/${digitalizationProjectId}/${documentId}`,
    getIdsOfOlderDocumentsVersions: (digitalizationProjectId: string) =>
      `digitalization-uploads/older-document-versions/${digitalizationProjectId}`,
    getMetadataChanges: (digitalizationProjectId: string) =>
      `digitalization-uploads/metadata-changes/${digitalizationProjectId}`,
    getPaginatedDigitalizationUploadsSummary: (
      pageOptions: IDigitalizationUploadSummaryPageOptionsDto,
    ) =>
      transformPageOptionsToQueryString(
        'digitalization-uploads/digitalization-uploads-summary',
        pageOptions,
      ),
    uploadDigitalizationDocument: (digitalizationProjectId: string) =>
      `digitalization-uploads/document/${digitalizationProjectId}`,
    getPaginatedDigitalizationDocumentsSummary: (
      pageOptions: IDigitalizationDocumentSummaryPageOptionsDto,
    ) =>
      transformPageOptionsToQueryString(
        'digitalization-uploads/digitalization-documents-summary',
        pageOptions,
      ),
    downloadUploadConfirmationFile: (
      digitalizationProjectId: string,
      uploadReferenceNumber: string,
    ) =>
      `digitalization-uploads/digitalization-upload-confirmation-file/${digitalizationProjectId}/${uploadReferenceNumber}`,
    moveOneCheckBotEstablishedKksToMainKksField: (
      docuObjectId: string,
      kks: string,
    ) =>
      `digitalization-uploads/digitalization-documents-metadata/accept-one-check-bot-established-kks/${docuObjectId}/${kks}`,
    postUploadMetadata: 'digitalization-uploads',
    setQualityCheckStatusOfDocument: (
      digitalizationProjectId: string,
      documentId: string,
      qualityCheckStatus: QualityCheckStatus,
    ) =>
      `digitalization-uploads/quality-check-status/${digitalizationProjectId}/${documentId}/${qualityCheckStatus}`,
    setQualityCheckStatusesOfMultipleDocuments: `digitalization-uploads/update-multiple-quality-check-statuses`,
    updateMetadataOfMultipleDocuments: (
      digitalizationProjectId: string,
      acceptMetadataInspection: boolean,
    ) =>
      `digitalization-uploads/digitalization-documents-metadata/${digitalizationProjectId}/${acceptMetadataInspection}`,
    validateReferenceNumber: (
      digitalizationProjectId: string,
      referenceNumber: string,
    ) =>
      `digitalization-uploads/validate/${digitalizationProjectId}/${referenceNumber}`,
  },

  prodisApiIntegration: {
    prefix: 'prodis-api-integration/',
    getKksTree(): string {
      return `${this.prefix}kks-tree`;
    },
    getPreselectedNodes(): string {
      return `${this.prefix}preselected-nodes`;
    },
    validateKksList(): string {
      return `${this.prefix}validate-kks-list`;
    },
  },

  commonDocuments: {
    prefix: 'common-documents',
    streamFile(fileId: string, documentType: DocumentType): string {
      return `${this.prefix}/stream-file/${fileId}/${documentType}`;
    },
  },

  patchNotes: {
    prefix: 'patch-notes',
    createPatchNote() {
      return `${this.prefix}/create`;
    },
    getAllPatchNoteVersions() {
      return `${this.prefix}/versions`;
    },
    getCurrentPatchNote() {
      return `${this.prefix}/current`;
    },
    getCurrentVersion() {
      return `${this.prefix}/current-version`;
    },
    getPatchNoteById(patchNoteId: string) {
      return `${this.prefix}/patch-note/${patchNoteId}`;
    },
    updatePatchNoteById(patchNoteId: string) {
      return `${this.prefix}/patch-note/${patchNoteId}`;
    },
  },

  ibl: {
    prefix: 'ibl',
    getIblImportResult(taskId: string): string {
      return `${this.prefix}/import-result/${taskId}`;
    },
    getVgbmAssignmentsForKks(kksId: string): string {
      return `${this.prefix}/vgbm/${kksId}`;
    },
    updateVgbmAssignmentForKks(kksId: string): string {
      return `${this.prefix}/vgbm/${kksId}`;
    },
    getVgbkListForProject(projectId: string): string {
      return `${this.prefix}/vgbk-list/${projectId}`;
    },
    getVgbmListForProject(): string {
      return `${this.prefix}/vgbm-list`;
    },
    importIbl(projectId: string, supplierId?: string): string {
      return `${this.prefix}/import/${projectId}${
        supplierId ? `?supplierId=${supplierId}` : ''
      }`;
    },
  },
};
