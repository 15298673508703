"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VgbmAssignmentQuality = void 0;
var VgbmAssignmentQuality;
(function (VgbmAssignmentQuality) {
    VgbmAssignmentQuality[VgbmAssignmentQuality["UNKNOWN"] = 10] = "UNKNOWN";
    VgbmAssignmentQuality[VgbmAssignmentQuality["NON_COMPLIANT"] = 20] = "NON_COMPLIANT";
    VgbmAssignmentQuality[VgbmAssignmentQuality["SUPPLIER_PROVIDED_NOT_CHECKED"] = 40] = "SUPPLIER_PROVIDED_NOT_CHECKED";
    VgbmAssignmentQuality[VgbmAssignmentQuality["SUPPLIER_PROVIDED"] = 60] = "SUPPLIER_PROVIDED";
    VgbmAssignmentQuality[VgbmAssignmentQuality["MANUAL_ENTRY"] = 80] = "MANUAL_ENTRY";
})(VgbmAssignmentQuality || (exports.VgbmAssignmentQuality = VgbmAssignmentQuality = {}));
