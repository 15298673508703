import CheckBotTemplateModal from '@/components/CheckBotTemplateModal/CheckBotTemplateModal.vue';
import endpointsList from '@/helpers/endpointsList';
import { IDigitalizationProjectCheckBotTemplate } from 'types';
import { CheckBotConfiguratorMode } from '@/views/CheckBotConfigurator/CheckBotConfigurator.script';
import { router } from '@/vue';
import axios from 'axios';
import Vue from 'vue';
import { CheckBotTemplateModalMode } from '../CheckBotTemplateModal/CheckBotTemplateModal.script';

interface IDigitalizationProjectCheckBotManagement {
  assignedCheckBotTemplate: IDigitalizationProjectCheckBotTemplate | null;
  checkBotTemplateModalMode: CheckBotTemplateModalMode.DIGITALIZATION_PROJECT;
}

export default Vue.extend({
  name: 'digitalization-project-check-bot-management',

  components: {
    CheckBotTemplateModal,
  },

  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
  },

  data(): IDigitalizationProjectCheckBotManagement {
    return {
      assignedCheckBotTemplate: null,
      checkBotTemplateModalMode:
        CheckBotTemplateModalMode.DIGITALIZATION_PROJECT,
    };
  },

  methods: {
    async getAssignedCheckBotTemplate() {
      const response = await axios.get(
        endpointsList.digitalizationProjects.getDigitalizationProjectCheckBotTemplate(
          this.digitalizationProjectId,
        ),
      );

      this.assignedCheckBotTemplate = response.data;
    },

    isCustomTemplateAssigned(): boolean {
      return this.assignedCheckBotTemplate?.isModified ?? false;
    },

    goToCheckBotConfigView() {
      router.push({
        name: 'checkBotConfigurator',
        params: {
          projectId: this.digitalizationProjectId,
          mode: CheckBotConfiguratorMode.DIGITALIZATION_PROJECT,
        },
      });
    },
  },

  mounted() {
    this.getAssignedCheckBotTemplate();
  },
});
