import { render, staticRenderFns } from "./TagEditor.vue?vue&type=template&id=13aab828&scoped=true"
import script from "./TagEditor.script.ts?vue&type=script&lang=ts&external"
export * from "./TagEditor.script.ts?vue&type=script&lang=ts&external"
import style0 from "./TagEditor.style.scss?vue&type=style&index=0&id=13aab828&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13aab828",
  null
  
)

export default component.exports