"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CheckBotModuleStrings = void 0;
/**
 * Miscellaneous strings used in the check bot module
 */
var CheckBotModuleStrings;
(function (CheckBotModuleStrings) {
    CheckBotModuleStrings["CUSTOM_CHECK_BOT_CONFIG"] = "custom";
})(CheckBotModuleStrings || (exports.CheckBotModuleStrings = CheckBotModuleStrings = {}));
