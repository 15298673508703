"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResultColumnName = void 0;
var ResultColumnName;
(function (ResultColumnName) {
    ResultColumnName["SEQUENCE"] = "Sequence";
    ResultColumnName["RESULT_1"] = "result_1";
    ResultColumnName["RESULT_TYPE_1"] = "resultType_1";
    ResultColumnName["RESULT_2"] = "result_2";
    ResultColumnName["RESULT_TYPE_2"] = "resultType_2";
    ResultColumnName["RESULT_3"] = "result_3";
    ResultColumnName["RESULT_TYPE_3"] = "resultType_3";
    ResultColumnName["REGEX"] = "RegEx";
    ResultColumnName["LIST"] = "list";
    ResultColumnName["FILTER_MINPAGE"] = "filter_MinPage";
    ResultColumnName["FILTER_MAXPAGE"] = "filter_MaxPage";
    ResultColumnName["PARAMETER"] = "parameter";
})(ResultColumnName || (exports.ResultColumnName = ResultColumnName = {}));
