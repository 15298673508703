"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DocumentEvent = void 0;
var DocumentEvent;
(function (DocumentEvent) {
    DocumentEvent["UPLOAD"] = "upload";
    DocumentEvent["FAILED_UPLOAD"] = "failed_upload";
    DocumentEvent["CHECKBOT_INPUT_LIST_ENTRY"] = "checkbot_input_list_entry";
    DocumentEvent["CHECKBOT_INPUT_FAILED"] = "checkbot_input_failed";
    DocumentEvent["METADATA_INFERENCE_CHECKBOT_INPUT_LIST_ENTRY"] = "metadata_inference_checkbot_input_list_entry";
    DocumentEvent["CHECKBOT_RESULT"] = "checkbot_result";
    DocumentEvent["METADATA_INFERENCE_CHECKBOT_RESULT"] = "metadata-inference-checkbot-result";
    DocumentEvent["METADATA_CHECK_RESULT"] = "metadata_check_result";
    DocumentEvent["REVERTED_TO_METADATA_CHECK"] = "reverted_to_metadata_check";
    DocumentEvent["QUALITY_CHECK"] = "quality_check";
    DocumentEvent["NEW_RESULTS_INSPECTOR_NOTIFICATION"] = "new_results_inspector_notification";
    DocumentEvent["FAILED_CHECK_SUPPLIER_NOTIFICATION"] = "failed_check_supplier_notification";
    DocumentEvent["FAILED_INSPECTION_SUPPLIER_NOTIFICATION"] = "failed_inspection_supplier_notification";
    DocumentEvent["INSPECTION_DUE_INSPECTORS_NOTIFICATION"] = "inspection_due_inspectors_notification";
    DocumentEvent["INSPECTION"] = "inspection";
    DocumentEvent["COPIED_TO_RECORD_LIBRARY"] = "copied_to_record_library";
})(DocumentEvent || (exports.DocumentEvent = DocumentEvent = {}));
