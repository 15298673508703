import { IFilesQueryRequest } from 'types';
import { Filtering } from '../FilesOverviewTable.script';

export function getFileQuery(filtering: Filtering): IFilesQueryRequest {
  const query: IFilesQueryRequest = {
    documentId: filtering.documentId,
    revision: filtering.revision,
    referenceNumber: filtering.referenceNumber,
    supplierName: filtering.supplierName,
    contentTypeName: filtering.contentType,
    uploadDate: filtering.uploadDate,
    inspectionDueDate: filtering.inspectionDueDate,
    coworker: filtering.coworker,
    formalControlStatus: filtering.formalControlStatus,
    inspectorStatus: filtering.inspectorStatus,
    recordLibraryTransferStatus: filtering.recordLibraryTransferStatus,
  };

  for (const field in query) {
    const value = query[field as keyof IFilesQueryRequest];

    if (!value) {
      delete query[field as keyof IFilesQueryRequest];
    }
  }

  return query;
}
