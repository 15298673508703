import { endpointsList } from '@/helpers';
import axios, { HttpStatusCode } from 'axios';
import {
  IIblImportResponseDto,
  IIblImportTaskIdReponseDto,
  Serialized,
} from 'types';

export class IblImportService {
  public static async importIbl(
    file: File,
    projectId: string,
    supplierId?: string,
  ): Promise<Serialized<IIblImportResponseDto>> {
    const taskId = await this.startIblImport(file, projectId, supplierId);
    return await this.pollIblImportTask(taskId);
  }

  private static async startIblImport(
    file: File,
    projectId: string,
    supplierId?: string,
  ): Promise<string> {
    const formData = new FormData();
    formData.append('file', file);

    const response = await axios.post<IIblImportTaskIdReponseDto>(
      endpointsList.ibl.importIbl(projectId, supplierId),
      formData,
    );
    return response.data.taskId;
  }

  private static async pollIblImportTask(
    taskId: string,
  ): Promise<IIblImportResponseDto> {
    const POLLING_INTERVAL_MS = 2000;

    return new Promise((resolve, reject) => {
      async function poll() {
        try {
          const response = await axios.get<IIblImportResponseDto | undefined>(
            endpointsList.ibl.getIblImportResult(taskId),
          );
          const { status, data } = response;

          if (status === HttpStatusCode.Ok && data) {
            resolve(data);
          } else if (status === HttpStatusCode.NoContent) {
            setTimeout(poll, POLLING_INTERVAL_MS);
          }
        } catch (error) {
          reject(error);
        }
      }

      poll();
    });
  }
}
