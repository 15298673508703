import { endpointsList, sortStringsAlphabetically } from '@/helpers';
import axios from 'axios';
import { pickBy } from 'lodash';
import {
  IGetVgbmAssignmentResponseDto,
  IKksVgbmResponseDto,
  IPageDto,
  IProjectVgbmRequestDto,
  IProjectVgbmResponseDto,
  IUpdateVgbmAssignmentRequestDto,
  IUpdateVgbmAssignmentResponseDto,
  Serialized,
} from 'types';
import { deduplicateArray } from 'utils';

export type VgbmFilter = {
  kks?: string;
  vgbk?: string;
};

export class VgbmService {
  public static async getPaginatedVgbmListForProject(
    projectId: string,
    page: number,
    limit: number,
    filters: VgbmFilter,
    isVgbkExactMatch = false,
  ): Promise<IPageDto<IProjectVgbmResponseDto>> {
    const definedFilters = pickBy(filters, Boolean);

    const params: Serialized<IProjectVgbmRequestDto> = {
      projectId,
      page,
      take: limit,
      isVgbkExactMatch,
      ...definedFilters,
    };

    const response = await axios.get<
      Serialized<IPageDto<IProjectVgbmResponseDto>>
    >(endpointsList.ibl.getVgbmListForProject(), {
      params,
    });

    return response.data;
  }

  public static getVgbmClassesFromVgbmList(
    vgbmListElements: IProjectVgbmResponseDto[],
  ): string[] {
    const nonVGbmKeys: (keyof IProjectVgbmResponseDto)[] = [
      'kksId',
      'kks',
      'vgbk',
    ];
    const vgbmClasses = vgbmListElements
      .flatMap(Object.keys)
      .filter((key) => !nonVGbmKeys.includes(key));
    const deduplicated = deduplicateArray(vgbmClasses);
    return sortStringsAlphabetically(deduplicated);
  }

  public static async getVgbmAssignmentsForKks(
    kksId: string,
  ): Promise<Serialized<IKksVgbmResponseDto>> {
    const response = await axios.get<Serialized<IKksVgbmResponseDto>>(
      endpointsList.ibl.getVgbmAssignmentsForKks(kksId),
    );

    return response.data;
  }

  public static async updateVgbmAssignmentsForKks(
    kksId: string,
    vgbmAssignment: IUpdateVgbmAssignmentRequestDto,
  ): Promise<IUpdateVgbmAssignmentResponseDto> {
    const response = await axios.patch<IUpdateVgbmAssignmentResponseDto>(
      endpointsList.ibl.updateVgbmAssignmentForKks(kksId),
      vgbmAssignment,
    );

    return response.data;
  }

  public static findAssignmentIndexByClass(
    assignments: IGetVgbmAssignmentResponseDto[],
    vgbmClass: string,
  ): number {
    return assignments.findIndex(
      (assignment) => assignment.class === vgbmClass,
    );
  }
}
