import axios from 'axios';
import Vue from 'vue';
import endpointsList from '../../helpers/endpointsList';
import { ToastMixin } from '../../mixins/globalUtils';
import VfButton from '@/components/VfButton/VfButton.vue';
import { ButtonVariant } from '../VfButton/VfButton.script';

interface IAppConfig {
  buttonVariant: ButtonVariant.SECONDARY;
  checkBotTimeout: number | null;
  isCheckingCheckBotResuls: boolean;
  isCheckingMetadataInferenceResults: boolean;
  isCheckingTimeouts: boolean;
  createToast?: ToastMixin;
}

export default Vue.extend({
  name: 'app-config',

  data(): IAppConfig {
    return {
      buttonVariant: ButtonVariant.SECONDARY,
      checkBotTimeout: null,
      isCheckingCheckBotResuls: false,
      isCheckingMetadataInferenceResults: false,
      isCheckingTimeouts: false,
    };
  },

  components: { VfButton },

  methods: {
    async getCheckBotTimeout() {
      try {
        const response = await axios.get(endpointsList.appConfig.getAppConfig);

        const checkBotTimeout =
          response.data.checkBotTimeout ?? 365 * 24 * 60 * 60 * 1000;

        this.checkBotTimeout = Math.floor(
          checkBotTimeout / 1000 / 60 / 60 / 24,
        );
      } catch (err) {
        console.log(err);

        this.createToast!(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }
    },

    async setCheckBotTimeout() {
      const checkBotTimeout =
        Number(this.checkBotTimeout) * 24 * 60 * 60 * 1000;

      try {
        await axios.patch('app-config', { checkBotTimeout });

        this.createToast!(
          this.$t('appConfig.checkBotTimeoutSaved'),
          this.$t('appConfig.checkBotTimeoutSaved'),
          'success',
        );

        this.getCheckBotTimeout();
      } catch (err) {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }
    },

    async checkCheckBotResults() {
      this.isCheckingCheckBotResuls = true;
      try {
        await axios.get(endpointsList.checkBot.checkCheckBotResults);
      } catch (err: unknown) {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('adminPanel.errors.errorCheckingCheckBotResults'),
          'danger',
        );
      }

      this.isCheckingCheckBotResuls = false;
    },

    async checkMetadataInferenceResults() {
      this.isCheckingMetadataInferenceResults = true;
      try {
        await axios.get(endpointsList.checkBot.checkMetadataInferenceResults);
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('adminPanel.errors.errorCheckingCheckBotResults'),
          'danger',
        );
      } finally {
        this.isCheckingMetadataInferenceResults = false;
      }
    },

    async checkCheckBotTimeouts() {
      this.isCheckingTimeouts = true;
      try {
        await axios.get(endpointsList.checkBot.checkCheckBotTimeouts);
      } catch (err: unknown) {
        // TODO: Error is shown after navigating away
        this.createToast!(
          this.$t('errors.error'),
          this.$t('adminPanel.errors.errorCheckingCheckBotTimeouts'),
          'danger',
        );
      }

      this.isCheckingTimeouts = false;
    },
  },

  mounted() {
    this.getCheckBotTimeout();
  },
});
