<template>
  <div>
    <filterable-table
      :busy="isFetchingVgbmData"
      componentName="vgbmTable"
      :fields="tableFields"
      :filterConfiguration="filterConfiguration"
      :filterGridConfiguration="filterGridConfiguration"
      :fixed="true"
      :isEmitOnClick="true"
      :items="vgbmData"
      :mode="tableMode"
      :no-action-icon="true"
      :responsive="true"
      :rows="rowsCount"
      @action-event="emitKksClickedEvent"
      @filtering-changed="handleFilterChange"
      @filtering-reset="handleFilterReset"
      @page-changed="handlePageChange"
      @per-page-changed="handlePerPageChange"
    >
    </filterable-table>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import FilterableTable, {
    FilterType,
    IFilterConfiguration,
    Mode,
  } from '../FilterableTable/FilterableTable.vue';
  import { IProjectVgbmResponseDto, IVgbkListElementDto } from 'types';
  import { ITableField } from '@/frontendInterfaces';
  import { TableClass, TableFieldsBuilder } from '@/helpers';
  import { VgbmService, VgbmFilter } from '@/services';
  import { GlobalUtils } from '@/mixins/globalUtils';
  import { ITableRow } from '@/interfaces';

  const columnWidths = {
    kks: '250px',
    vgbk: '250px',
    vgbmClass: '150px',
  };

  export default Vue.extend({
    name: 'vgbm-table',
    components: {
      FilterableTable,
    },
    props: {
      projectId: {
        type: String,
        required: true,
      },
      selectedVgbk: {
        type: Object as () => IVgbkListElementDto | null,
        default: null,
        required: false,
      },
    },
    data(): {
      filter: VgbmFilter;
      isFetchingVgbmData: boolean;
      page: number;
      perPage: number;
      rowsCount: number;
      vgbmData: IProjectVgbmResponseDto[];
    } {
      return {
        filter: {},
        isFetchingVgbmData: false,
        page: 1,
        perPage: 10,
        rowsCount: 0,
        vgbmData: [],
      };
    },
    computed: {
      tableMode(): Mode {
        return Mode.OUTSOURCED;
      },
      tableFields(): ITableField<IProjectVgbmResponseDto>[] {
        const builder = new TableFieldsBuilder<IProjectVgbmResponseDto>({
          translationKeyPrefix: 'vgbmTable',
          defaults: {
            width: columnWidths.vgbmClass,
            tdClassFunction: this.getCellClass,
            tdClass: [TableClass.ALIGN_MIDDLE],
            thClass: [TableClass.ALIGN_MIDDLE],
          },
        })
          .addField('kks', {
            simpleLabel: 'KKS',
            width: columnWidths.kks,
          })
          .addField('vgbk', {
            width: columnWidths.vgbk,
          });

        VgbmService.getVgbmClassesFromVgbmList(this.vgbmData).forEach(
          (vgbmClass) => {
            builder.addField(vgbmClass, {
              simpleLabel: vgbmClass,
            });
          },
        );

        return builder.build();
      },
      filterConfiguration(): IFilterConfiguration {
        const filterConfiguration: IFilterConfiguration = {
          kks: {
            column: 1,
            type: FilterType.INPUT,
            width: parseInt(columnWidths.kks) * 0.75 + 'px',
            customLabel: 'KKS',
          },
        };
        /** Including VGBK text input filter only if VGBK is not selected */
        if (!this.selectedVgbk) {
          filterConfiguration.vgbk = {
            column: 2,
            type: FilterType.INPUT,
            width: parseInt(columnWidths.vgbk) * 0.75 + 'px',
            simpleLabel: true,
          };
        }
        return filterConfiguration;
      },
      filterGridConfiguration(): string[] {
        return [columnWidths.kks, columnWidths.vgbk];
      },
    },
    methods: {
      async fetchVgbmData(): Promise<void> {
        try {
          this.isFetchingVgbmData = true;
          const paginationResponse =
            await VgbmService.getPaginatedVgbmListForProject(
              this.projectId,
              this.page,
              this.perPage,
              this.filter,
              !!this.selectedVgbk,
            );
          this.vgbmData = paginationResponse.data;
          this.rowsCount = paginationResponse.meta.itemCount;
        } catch {
          (this as unknown as GlobalUtils).createErrorToast(
            this.$t('vgbmTable.errors.errorGettingVgbm'),
          );
        } finally {
          this.isFetchingVgbmData = false;
        }
      },
      handleFilterChange(filter: VgbmFilter): void {
        this.filter = filter;
        this.fetchVgbmData();
      },
      handlePageChange(page: number): void {
        this.page = page;
        this.fetchVgbmData();
      },
      handlePerPageChange(perPage: number): void {
        this.perPage = perPage;
        this.fetchVgbmData();
      },
      handleFilterReset(): void {
        /**
         * If the table filters are reset, an event has to be emitted so that
         * the wrapper component can also reset the VGBK selection.
         */
        this.$emit('filtering-reset');
      },
      getCellClass(
        _value: string | null,
        key: keyof IProjectVgbmResponseDto,
        item: IProjectVgbmResponseDto,
      ): string[] {
        /**
         * If the VGBM in the current column is not present in the VGBK of the
         * current row, the cell should be displayed as disabled.
         */
        if (item[key] === undefined) {
          return ['vgbm-not-in-vgbk'];
        }
        return [];
      },
      emitKksClickedEvent(row: ITableRow<IProjectVgbmResponseDto>): void {
        this.$emit('kks-clicked', row.item.kksId);
      },
    },
    mounted() {
      this.fetchVgbmData();
    },
    watch: {
      selectedVgbk() {
        this.filter.vgbk = this.selectedVgbk?.class;
        this.fetchVgbmData();
      },
    },
  });
</script>

<style lang="scss" scoped>
  ::v-deep .vgbm-not-in-vgbk {
    background-color: $form-control-disabled-color;
  }
</style>
