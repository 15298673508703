"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ATTEMPTED_RECORD_LIBRARY_TRANSFER_STATUSES = exports.WAITING_RECORD_LIBRARY_TRANSFER_STATUSES = exports.FAILED_RECORD_LIBRARY_TRANSFER_STATUSES = exports.SUCCESSFULL_RECORD_LIBRARY_TRANSFER_STATUSES = exports.FINAL_RECORD_LIBRARY_TRANSFER_STATUSES = exports.DEFAULT_METADATA_VALUES_SEPARATOR = exports.ACTOR_APP = void 0;
const documents_uploads_1 = require("../modules/documents-uploads");
exports.ACTOR_APP = 'app';
exports.DEFAULT_METADATA_VALUES_SEPARATOR = ';';
exports.FINAL_RECORD_LIBRARY_TRANSFER_STATUSES = [documents_uploads_1.RecordLibraryTransferStatus.FAILED, documents_uploads_1.RecordLibraryTransferStatus.SUCCESSFULL];
exports.SUCCESSFULL_RECORD_LIBRARY_TRANSFER_STATUSES = [documents_uploads_1.RecordLibraryTransferStatus.SUCCESSFULL];
exports.FAILED_RECORD_LIBRARY_TRANSFER_STATUSES = [documents_uploads_1.RecordLibraryTransferStatus.FAILED];
exports.WAITING_RECORD_LIBRARY_TRANSFER_STATUSES = [documents_uploads_1.RecordLibraryTransferStatus.AWAITING_INSPECTIONS_COMPLETION];
exports.ATTEMPTED_RECORD_LIBRARY_TRANSFER_STATUSES = [documents_uploads_1.RecordLibraryTransferStatus.FAILED, documents_uploads_1.RecordLibraryTransferStatus.SUCCESSFULL];
