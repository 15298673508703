var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"filterable-table"},[_c('div',{staticClass:"filter-panel"},[_c('div',{staticClass:"filter-reset-panel"},[_c('vf-button',{attrs:{"size":_vm.resetFiltersButttonSize,"variant":_vm.resetFiltersButttonVariant},on:{"click":_vm.initializeFilters}},[_vm._v(_vm._s(_vm.$t('filterableTable.resetAllFilters')))])],1),_c('div',{staticClass:"filter-inputs",style:(_vm.filterGrid)},[_vm._l((_vm.filterConfiguration),function(config,field){return [(config.type === 'input')?_c('b-form-input',{key:field,style:(_vm.filterStyles[field]),attrs:{"debounce":_vm.mode === 'serverSide' || _vm.mode === 'outsourced' ? 800 : 0,"placeholder":_vm.getInputPlaceholder(config, field)},model:{value:(_vm.filters[field]),callback:function ($$v) {_vm.$set(_vm.filters, field, $$v)},expression:"filters[field]"}}):_vm._e(),(config.type === 'dateRangePicker')?_c('styled-date-range-picker',{key:field,style:(_vm.filterStyles[field]),attrs:{"currentDateRange":{
            durationStart: _vm.filters[field].startDate,
            durationEnd: _vm.filters[field].endDate,
          },"isSmall":true,"placeholder":_vm.getInputPlaceholder(config, field),"calendarOpenDirection":config.dateRangePickerCalendarOpenDirection},on:{"date-range-updated":(dateRange) => _vm.handleDateRangeUpdated(dateRange, field)}}):_vm._e(),(config.type === 'select')?_c('b-form-select',{key:field,style:(_vm.filterStyles[field]),attrs:{"options":_vm.selectOptions[field],"placeholder":field},model:{value:(_vm.filters[field]),callback:function ($$v) {_vm.$set(_vm.filters, field, $$v)},expression:"filters[field]"}}):_vm._e(),(config.type === 'checkBox')?_c('b-form-checkbox',{key:field,style:(_vm.filterStyles[field]),model:{value:(_vm.filters[field]),callback:function ($$v) {_vm.$set(_vm.filters, field, $$v)},expression:"filters[field]"}},[_vm._v(_vm._s(_vm.getInputPlaceholder(config, field)))]):_vm._e()]})],2)]),(_vm.items || (this.mode === 'serverSide' && _vm.paginatedItems))?_c('main-table',{staticClass:"main-table",attrs:{"items":((_vm.mode === 'clientSide' || _vm.mode === 'outsourced' || !_vm.mode) &&
        _vm.filteredItems) ||
      (_vm.mode === 'serverSide' && _vm.paginatedItems),"noLocalSorting":_vm.mode === 'serverSide' || _vm.mode === 'outsourced',"fields":_vm.fields,"perPage":_vm.perPage,"currentPage":_vm.mode === 'clientSide' || !_vm.mode ? _vm.currentPage : 1,"detailsClickTarget":_vm.detailsClickTarget,"detailsParam":_vm.detailsParam,"isEmitOnClick":_vm.isEmitOnClick,"customActionIcon":_vm.customActionIcon,"rowClassFunction":_vm.rowClassFunction,"noActionIcon":_vm.noActionIcon,"actionIconTooltipText":_vm.actionIconTooltipText,"busy":(_vm.mode === 'serverSide' && _vm.isLoadingItems) || _vm.busy,"initialSortBy":_vm.initialSortBy ?? _vm.sortBy,"initialSortDesc":_vm.initialSortDesc ?? _vm.sortDesc,"responsive":_vm.responsive,"fixed":_vm.fixed},on:{"action-event":_vm.passEvent,"sorting-change":_vm.setSortDirection,"sort-by-change":_vm.setSortBy},scopedSlots:_vm._u([_vm._l((_vm.fields),function(field){return {key:`cell(${field.key})`,fn:function(data){return [_vm._t(`cell(${field.key})`,null,null,data)]}}})],null,true)}):_c('div',{staticClass:"spinner-container"},[_c('b-spinner')],1),(this.items || this.paginatedItems)?_c('div',{staticClass:"pagination-container"},[_c('div',{staticClass:"per-page-select-container"},[_c('p',[_vm._v(_vm._s(_vm.$t('filterableTable.entriesPerPage')))]),_c('b-form-select',{attrs:{"id":"per-page-select","options":_vm.perPageOptions},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1),_c('styled-pagination',{attrs:{"currentPage":_vm.currentPage,"totalRows":_vm.mode === 'clientSide' || !_vm.mode
          ? _vm.tableRows
          : _vm.mode === 'serverSide'
            ? _vm.serverSideTableRows
            : _vm.mode === 'outsourced'
              ? _vm.rows
              : undefined,"perPage":_vm.perPage},on:{"page-changed":_vm.handlePageChange}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }