<template>
  <div>
    <div class="vgbk-select-container">
      <vgbk-select v-model="selectedVgbk" :projectId="projectId" />
    </div>
    <vgbm-table
      :projectId="projectId"
      :selectedVgbk="selectedVgbk"
      @filtering-reset="handleFilterReset"
      @kks-clicked="handleKksRowClick"
    />
    <kks-vgbm-modal
      v-if="kksIdForModal"
      :kksId="kksIdForModal"
      :ref="kksVgbmModalRef"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import VgbkSelect from '@/components/VgbkSelect/VgbkSelect.vue';
  import VgbmTable from '@/components/VgbmTable/VgbmTable.vue';
  import { IVgbkListElementDto } from 'types';
  import KksVgbmModal from '@/components/KksVgbmModal/KksVgbmModal.vue';

  export default Vue.extend({
    name: 'vgbm-table-wrapper',
    components: {
      KksVgbmModal,
      VgbkSelect,
      VgbmTable,
    },
    props: {
      projectId: {
        type: String,
        required: true,
      },
    },
    data(): {
      kksIdForModal: string | null;
      selectedVgbk: IVgbkListElementDto | null;
    } {
      return {
        kksIdForModal: null,
        selectedVgbk: null,
      };
    },
    computed: {
      kksVgbmModalRef(): string {
        return 'kks-vgbm-modal';
      },
    },
    methods: {
      handleFilterReset() {
        this.selectedVgbk = null;
      },
      handleKksRowClick(kksId: string) {
        this.kksIdForModal = kksId;
        this.$nextTick(this.showKksVgbmModal);
      },
      showKksVgbmModal() {
        const modalId = (
          this.$refs[this.kksVgbmModalRef] as InstanceType<typeof KksVgbmModal>
        )?.modalId;
        this.$bvModal.show(modalId);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .vgbk-select-container {
    width: 40%;
    display: flex;
  }
</style>
