import StyledDatePicker from '@/components/StyledDatePicker/StyledDatePicker.vue';
import Vue from 'vue';

interface IDateEditor {
  componentStyle: {
    position: 'fixed';
    top: string;
    left: string;
    width?: string;
    minWidth?: string;
    height?: string;
  };
}

export default Vue.extend({
  components: { StyledDatePicker },
  props: {
    close: {
      type: Function,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    save: {
      type: Function,
      required: true,
    },
  },
  data(): IDateEditor {
    return {
      componentStyle: {
        position: 'fixed',
        top: '0px',
        left: '0px',
      },
    };
  },
  methods: {
    saveInput(date: Date): void {
      const transformedDate = date.toLocaleDateString('en-US');

      this.save(transformedDate);
    },

    positionDateInput(): void {
      this.$nextTick(() => {
        const element = this.$refs.styledDatePicker as HTMLElement;

        const containingCell = (
          (element as HTMLElement)?.parentNode?.parentNode as HTMLElement
        )?.getBoundingClientRect();

        const rect = element.getBoundingClientRect();

        const topOffset = `${rect.top}px`;
        const leftOffset = `${rect.left}px`;
        const dateInputWidth = `${containingCell.width}px`;
        const dateInputHeigth = `${containingCell.height}px`;

        this.componentStyle.top = topOffset;
        this.componentStyle.left = leftOffset;
        this.componentStyle.width = dateInputWidth;
        this.componentStyle.minWidth = dateInputWidth;
        this.componentStyle.height = dateInputHeigth;
      });
    },
  },
  mounted() {
    this.positionDateInput();
  },
});
