import { cloneDeep, set } from 'lodash';

export default {
  name: 'upload-modal',
  props: ['contentTypeFields'],
  data() {
    return {
      form: {},
    };
  },
  methods: {
    uploadFile() {
      const file = this.$refs.fileInput.files[0];
      this.form.file.value = file;
      this.form.file.isValidated = true;
    },

    toggleFileExplorer() {
      this.$refs.fileInput.click();
    },

    initializeForm() {
      for (const field of this.contentTypeFields) {
        set(this.form, 'id.value', '');
        set(this.form, 'id.isValidated', true);
        set(this.form, 'Revision.value', '');
        set(this.form, 'Revision.isValidated', true);

        set(this.form, `${field.field}.value`, '');
        set(this.form, `${field.field}.isValidated`, true);
        set(this.form, 'file.value', null);
        set(this.form, 'file.isValidated', true);
      }
      this.form = cloneDeep(this.form);
    },

    validateInputs() {
      for (const formField in this.form) {
        if (
          formField === 'file' ||
          formField === 'id' ||
          formField === 'Revision'
        ) {
          if (this.form[formField].value) {
            this.form[formField].isValidated = true;
          } else {
            this.form[formField].isValidated = false;
          }
          this.form = cloneDeep(this.form);
          continue;
        }

        const isRequired = this.contentTypeFields.find(
          (field) => field.field === formField,
        ).required;

        if (isRequired && !this.form[formField].value) {
          this.form[formField].isValidated = false;
          // Workaround so that Vue sees changes in the nested value in v-if
          this.form = cloneDeep(this.form);
        }
        if (this.form[formField].value) {
          this.form[formField].isValidated = true;
          // Workaround so that Vue sees changes in the nested value in v-if
          this.form = cloneDeep(this.form);
        }
      }
      return Object.values(this.form).every(
        (field) => field.isValidated === true,
      );
    },

    markRequiredField(contentTypeField) {
      const field = this.contentTypeFields.find(
        (field) => field.field === contentTypeField,
      );

      if (
        field?.required ||
        contentTypeField === 'id' ||
        contentTypeField === 'Revision'
      ) {
        return '*';
      }

      return '';
    },

    handleModalOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      if (this.validateInputs()) {
        this.$emit('document-data', this.form);
        this.initializeForm();
        this.$nextTick(() => {
          this.$refs['upload-modal'].hide();
        });
      }
    },
  },
  watch: {
    contentTypeFields() {
      this.initializeForm();
    },
  },
};
