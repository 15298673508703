import { ProjectsCoworkersTableMode } from '@/components/projectsCoworkers/projectsCoworkers.script';
import ProjectsCoworkers from '@/components/projectsCoworkers/projectsCoworkers.vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'digitalization-project-coworkers',

  components: { ProjectsCoworkers },

  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      projectCoworkersTableMode:
        ProjectsCoworkersTableMode.DIGITALIZATION_PROJECT,
    };
  },
});
