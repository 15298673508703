"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KksState = void 0;
var KksState;
(function (KksState) {
    KksState["SAP_PM"] = "sap-pm";
    KksState["DMS_PROPOSED"] = "dms-vorgeschlagen";
    KksState["DMS_CONFIRMED"] = "dms-bestaetigt";
    KksState["DMS_REJECTED"] = "dms-abgelehnt";
    KksState["PLANNED"] = "geplant";
})(KksState || (exports.KksState = KksState = {}));
