import {
  ICheckBotConfigurationWithDescriptions,
  ICheckBotResultWithDescriptions,
} from 'types';
import Vue from 'vue';
import StyledModal from '../StyledModal/StyledModal.vue';
import StyledMultiselect from '../StyledMultiselect/StyledMultiselect.vue';
import axios, { AxiosResponse } from 'axios';
import endpointsList from '../../helpers/endpointsList';
import { ToastMixin } from '../../mixins/globalUtils';

interface AddCheckModal {
  checks?: ICheckBotResultWithDescriptions[];
  isLoadingChecks: boolean;
  selectedCheck?: ICheckBotResultWithDescriptions;
  createToast?: ToastMixin;
}

export default Vue.extend({
  name: 'add-check-modal',

  components: { StyledModal, StyledMultiselect },

  data(): AddCheckModal {
    return {
      checks: undefined,
      isLoadingChecks: false,
      selectedCheck: undefined,
    };
  },

  props: {
    currentConfig: {
      type: Object as () => ICheckBotConfigurationWithDescriptions,
      required: true,
    },
  },

  methods: {
    async getChecks() {
      this.isLoadingChecks = true;
      try {
        const response: AxiosResponse<ICheckBotConfigurationWithDescriptions> =
          await axios.get(endpointsList.checkBot.allChecks);

        const checksArray = Object.values(response.data);

        this.checks = checksArray.filter(
          (check) => !this.currentConfig[check.TestKey],
        );

        if (this.checks.length) {
          this.$emit('checks-available');
        } else {
          this.$emit('all-checks-added');
        }
      } catch (err: unknown) {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('addCheckModal.errors.errorLoadingChecks'),
          'danger',
        );
      }
      this.isLoadingChecks = false;
    },

    handleModalOk() {
      if (this.selectedCheck) {
        this.$emit('check-selected', this.selectedCheck);
        this.getChecks();
        this.$bvModal.hide('add-check-modal');
        this.selectedCheck = undefined;
      }
    },
  },

  created() {
    this.getChecks();
  },
});
