var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('contentTypeConfiguration.csvMapping')))]),(_vm.dependenciesFetched)?_c('main-table',{attrs:{"items":_vm.mappingFields,"fields":_vm.tableFields,"noActionIcon":"","rowClassFunction":(item) =>
        _vm.fieldsWithValidationError.includes(item.field)
          ? 'table-danger'
          : 'row-class'},scopedSlots:_vm._u([{key:"cell(fieldDisplayName)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.contentTypeField(row.item))+" ")]}},{key:"cell(importValueType)",fn:function(row){return [_c('div',{staticClass:"import-field-container"},[_c('b-form-select',{staticClass:"import-field-type-select",attrs:{"options":_vm.importValueTypeSelectOptions,"id":`${row.item.field.replace(/ /g, '_')}-type-select`},on:{"change":function($event){return _vm.setInputType(row.item.field)}},model:{value:(_vm.selectedAssignments[row.item.field].type),callback:function ($$v) {_vm.$set(_vm.selectedAssignments[row.item.field], "type", $$v)},expression:"selectedAssignments[row.item.field].type"}})],1)]}},{key:"cell(importValue)",fn:function(row){return [_c('div',[(_vm.selectedAssignments[row.item.field]?.type === 'csvField')?_c('b-form-select',{staticClass:"import-field-select",attrs:{"id":`${row.item.fieldDisplayName.replace(/ /g, '_')}-select`,"options":_vm.headers.length
              ? [..._vm.headers, '']
              : [_vm.selectedAssignments[row.item.field].value]},model:{value:(_vm.selectedAssignments[row.item.field].value),callback:function ($$v) {_vm.$set(_vm.selectedAssignments[row.item.field], "value", $$v)},expression:"selectedAssignments[row.item.field].value"}}):_vm._e(),(
            _vm.selectedAssignments[row.item.field]?.type === 'constantAssignment'
          )?[(
              !Object.keys(_vm.possibleTaxonomyFieldValues)?.includes(
                row.item.field,
              )
            )?_c('b-form-input',{staticClass:"constant-value-input",attrs:{"id":`${row.item.field.replace(/ /g, '_')}-constant-input`},model:{value:(_vm.selectedAssignments[row.item.field].value),callback:function ($$v) {_vm.$set(_vm.selectedAssignments[row.item.field], "value", $$v)},expression:"selectedAssignments[row.item.field].value"}}):_c('b-form-select',{attrs:{"id":`${row.item.field.replace(/ /g, '_')}-constant-select`,"options":['', ..._vm.possibleTaxonomyFieldValues[row.item.field]]},model:{value:(_vm.selectedAssignments[row.item.field].value),callback:function ($$v) {_vm.$set(_vm.selectedAssignments[row.item.field], "value", $$v)},expression:"selectedAssignments[row.item.field].value"}})]:_vm._e()],2)]}}],null,false,979549636)}):_vm._e(),_c('div',{attrs:{"id":"error-message-container"}},[(_vm.validationError)?_c('p',{staticClass:"error-message"},[_vm._v(_vm._s(_vm.validationError))]):_vm._e()]),_c('div',{attrs:{"id":"buttons-container"}},[_c('vf-button',{attrs:{"id":"cancel-button","size":_vm.buttonSizes.large,"variant":_vm.buttonVariants.tertiary},on:{"click":_vm.handleCancelButtonClick}},[_vm._v(" "+_vm._s(_vm.$t('generic.cancel'))+" ")]),(_vm.dependenciesFetched)?_c('vf-button',{attrs:{"size":_vm.buttonSizes.large,"variant":_vm.buttonVariants.primary},on:{"click":_vm.submitMapping}},[_vm._v(" "+_vm._s(_vm.$t('generic.save'))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }