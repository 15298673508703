import CurrentVersion from '@/components/CurrentVersion/CurrentVersion.vue';
import { GlobalUtils } from '@/mixins/globalUtils';
import { Role } from 'types';
import Vue from 'vue';
import UserInfo from '../UserInfo/UserInfo.vue';

export default Vue.extend({
  name: 'nav-bar',

  components: { CurrentVersion, UserInfo },

  data() {
    return {
      suppliersManagementAllowedRoles: [Role.Admin, Role.DocuManager],
      digitalizationProjectsAllowedRoles: [
        Role.Admin,
        Role.DocuResponsible,
        Role.DigitalizationCoworker,
      ],
      adminRole: Role.Admin,
      forceCurrectAppSectionUpdate: 0,
    };
  },

  computed: {
    currentAppSection() {
      this.forceCurrectAppSectionUpdate;
      if (this.$route?.meta?.breadcrumb?.[0] === 'projectsList') {
        return 'projects';
      }

      if (this.$route?.meta?.breadcrumb?.[0] === 'digitalizationProjectsList') {
        return 'digitalizationProjects';
      }

      if (this.$route?.meta?.breadcrumb?.[0] === 'suppliersList') {
        return 'suppliers';
      }

      if (this.$route?.meta?.breadcrumb?.[0] === 'administration') {
        return 'administration';
      }

      return '';
    },
  },

  methods: {
    setLang(lang: string) {
      window.localStorage.setItem('lang', lang);
      this.$i18n.locale = lang;
      return this.$store.commit('setLang', lang);
    },

    logout() {
      window.location.href = '/api/oauth/logout';
    },

    isHelpPageForRole(): boolean {
      return (this as unknown as GlobalUtils).isUserRole([
        Role.DigitalizationCoworker,
        Role.DocuResponsible,
      ]);
    },

    getHelpPageLink(): string {
      if ((this as unknown as GlobalUtils).isUserRole([Role.DocuResponsible])) {
        return 'https://vattenfall.sharepoint.com/:u:/r/sites/Waerme-Berlin-Anlagen-DMS-prod/daisy-wissen/SitePages/DDP_Hanbuch_Intern_Dokumentationsverantwortliche.aspx?csf=1&web=1&e=bYi6eb';
      } else if (
        (this as unknown as GlobalUtils).isUserRole([
          Role.DigitalizationCoworker,
        ])
      ) {
        return 'https://vattenfall.sharepoint.com/:u:/r/sites/Waerme-Berlin-Anlagen-DMS-prod/daisy-wissen/SitePages/DDP_Hanbuch_Intern_Lieferant.aspx?csf=1&web=1&e=my7Tl7';
      } else {
        throw new Error('Could not get help page link for current user role.');
      }
    },
  },

  watch: {
    '$route.name'() {
      this.$nextTick(() => this.forceCurrectAppSectionUpdate++);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.forceCurrectAppSectionUpdate++;
    });
  },
});
