import { InspectorStatusEnum } from 'types';
import Vue from 'vue';
import StyledModal from '../StyledModal/StyledModal.vue';

interface IRemarksModal {
  remarks: string;
  isNoRemarksError: boolean;
}

export enum RemarksModalMode {
  ACCEPT_WITH_REMARKS = 'accept_with_remarks',
  REJECT = 'reject',
}

export interface IRemarksModalEventPayload {
  remarks: string;
  status: InspectorStatusEnum;
}

export default Vue.extend({
  name: 'remarks-modal',

  components: { StyledModal },

  props: {
    mode: {
      type: String as () => RemarksModalMode,
      required: false,
    },
  },

  data(): IRemarksModal {
    return {
      remarks: '',
      isNoRemarksError: false,
    };
  },

  methods: {
    handleOk() {
      this.isNoRemarksError = false;

      if (!this.remarks) {
        this.isNoRemarksError = true;
        return;
      }

      const modeToStatus: { [mode in RemarksModalMode]: InspectorStatusEnum } =
        {
          [RemarksModalMode.ACCEPT_WITH_REMARKS]:
            InspectorStatusEnum.acceptedWithRemarks,
          [RemarksModalMode.REJECT]: InspectorStatusEnum.rejected,
        };

      const eventPayload: IRemarksModalEventPayload = {
        remarks: this.remarks,
        status: modeToStatus[this.mode],
      };

      this.$emit('remarks-submitted', eventPayload);
      this.$bvModal.hide('remarks-modal');
    },
  },
});
