import Vue from 'vue';
import VueTagsInput from '@johmun/vue-tags-input';
import { transformStringToRegex } from 'utils';
import { DEFAULT_METADATA_VALUES_SEPARATOR } from 'types';

interface ITag {
  text: string;
  style?: string;
  classes?: string;
}

export enum TagClass {
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

export interface ITagClasses {
  [tagValue: string]: TagClass;
}

interface IAutocompleteItem {
  text: string;
}

interface IStyledTagsInput {
  tags: ITag[];
  currentInput: string;
  filteredAutoCompleteItems: IAutocompleteItem[];
}

export default Vue.extend({
  name: 'styled-tags-input',
  components: {
    VueTagsInput,
  },

  props: {
    autocompleteItems: {
      type: Array as () => string[],
      required: false,
    },
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
    addOnlyFromAutocomplete: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentTags: {
      type: Array as () => string[],
      required: true,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    separators: {
      type: Array as () => string[],
      required: false,
      default: () => [DEFAULT_METADATA_VALUES_SEPARATOR],
    },
    tagClasses: {
      type: Object as () => ITagClasses,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: (): IStyledTagsInput => {
    return {
      tags: [],
      currentInput: '',
      filteredAutoCompleteItems: [],
    };
  },

  methods: {
    setCurrentTags() {
      if (this.currentTags.length === 1 && this.currentTags[0] === '') {
        return;
      }

      this.tags = this.currentTags.map((tag) => {
        const tagObject: ITag = {
          text: tag,
        };

        if (this.tagClasses?.[tag]) {
          tagObject.classes = this.tagClasses[tag];
        }

        return tagObject;
      });
    },

    handleTagsChanged(newTags: ITag[]) {
      this.tags = newTags;

      this.$emit(
        'tags-changed',
        this.tags.map((tag) => tag.text),
      );
    },

    transformAutoCompleteItemsToObjects(
      autocompleteItems: string[],
    ): IAutocompleteItem[] {
      return autocompleteItems.map((item) => ({ text: item }));
    },
  },

  created() {
    this.setCurrentTags();
    this.filteredAutoCompleteItems = this.transformAutoCompleteItemsToObjects(
      this.autocompleteItems,
    );
  },

  watch: {
    currentTags() {
      this.setCurrentTags();
    },

    tagClasses() {
      this.setCurrentTags();
    },

    currentInput() {
      if (!this.currentInput) {
        this.filteredAutoCompleteItems =
          this.transformAutoCompleteItemsToObjects(this.autocompleteItems);
      }

      const searchRegex = transformStringToRegex(this.currentInput, 'i');

      this.filteredAutoCompleteItems = this.transformAutoCompleteItemsToObjects(
        this.autocompleteItems?.filter((item) => searchRegex.test(item)),
      );
    },

    autocompleteItems() {
      this.filteredAutoCompleteItems = this.transformAutoCompleteItemsToObjects(
        this.autocompleteItems,
      );
    },
  },

  beforeDestroy() {
    this.$emit('before-destroy');
  },
});
