import {
  ITagClasses,
  TagClass,
} from '@/components/StyledTagsInput/StyledTagsInput.script';
import { endpointsList } from '@/helpers';
import axios from 'axios';
import { IKksValidationListDto, IValidateKksListDto } from 'types';
import { removeEmptyStrings } from 'utils';
import Vue from 'vue';
import { GlobalUtils } from './globalUtils';

export type KksValidator = {
  getKksTagClasses: (kksValidationList?: IKksValidationListDto) => ITagClasses;
  validateKks: (
    kksList: string[],
  ) => Promise<IKksValidationListDto | undefined>;
} & KksValidatorData;

type KksValidatorData = {
  isValidatingKks: boolean;
  kksValidationList: IKksValidationListDto;
};

export default Vue.extend({
  data(): KksValidatorData {
    return {
      isValidatingKks: false,
      kksValidationList: {},
    };
  },

  methods: {
    async validateKks(kksList: string[]): Promise<void> {
      kksList = removeEmptyStrings(kksList);
      if (!kksList.length) return;

      const dto: IValidateKksListDto = { kksList: kksList };

      try {
        this.isValidatingKks = true;

        const response = await axios.post(
          endpointsList.prodisApiIntegration.validateKksList(),
          dto,
        );

        this.kksValidationList = response.data;
      } catch (err) {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('kksValidator.errors.errorValidatingKks'),
          'danger',
        );
      } finally {
        this.isValidatingKks = false;
      }
    },

    getKksTagClasses(kksValidationList?: IKksValidationListDto): ITagClasses {
      return Object.entries(kksValidationList ?? this.kksValidationList).reduce(
        (tagClasses, [kks, isValid]) => {
          tagClasses[kks] = isValid ? TagClass.SUCCESS : TagClass.WARNING;
          return tagClasses;
        },
        {} as ITagClasses,
      );
    },
  },
});
