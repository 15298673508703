import StyledMultiselect from '@/components/StyledMultiselect/StyledMultiselect.vue';
import { endpointsList } from '@/helpers';
import { IUpdateMetadataOfMultipleDocumentsDto } from 'types';
import axios from 'axios';
import Vue from 'vue';

interface IDocumentMetadataSelect {
  isBusy: boolean;
  isInputValidated: boolean;
  selectedOption?: string;
}

export default Vue.extend({
  name: 'document-metadata-select',

  components: { StyledMultiselect },

  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
    digitalizationDocumentObjectId: {
      type: String,
      required: true,
    },
    getMetadataFunction: {
      type: Function,
      required: false,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    isWaitingForValues: {
      type: Boolean,
      required: false,
      default: false,
    },
    currentValue: {
      type: String,
      required: true,
    },
    metadataField: {
      type: String,
      required: true,
    },
    possibleValues: {
      type: Array as () => string[],
      required: false,
    },
    validationFunction: {
      type: Function,
      required: true,
    },
  },

  data(): IDocumentMetadataSelect {
    return {
      isBusy: false,
      isInputValidated: true,
      selectedOption: undefined,
    };
  },

  methods: {
    async handleSelectionChanged(selection: string) {
      this.isBusy = true;

      try {
        const dto: IUpdateMetadataOfMultipleDocumentsDto = {
          [this.digitalizationDocumentObjectId]: {
            [this.metadataField]: selection,
          },
        };
        await axios.patch(
          endpointsList.digitalizationUploads.updateMetadataOfMultipleDocuments(
            this.digitalizationProjectId,
            false,
          ),
          dto,
        );

        await this.getMetadataFunction();
      } catch (err) {
        (this as any).createToast(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      } finally {
        this.isBusy = false;
        this.validateInput();
        this.$emit('document-data-changed');
      }
    },

    validateInput(): void {
      this.isInputValidated = this.validationFunction(this.selectedOption);
    },
  },

  mounted() {
    this.selectedOption = this.currentValue;
    this.validateInput();
  },
});
