import Vue from 'vue';
import StyledModal from '@/components/StyledModal/StyledModal.vue';
import MainTable from '@/components/MainTable/MainTable.vue';
import { ITableField } from '@/frontendInterfaces';

interface IMetadataModal {
  transformedMetadata:
    | ITransformedMetadata[]
    | ITransformedMetadataComparison[];
}

interface ITransformedMetadata {
  field: string;
  value: number;
}

interface ITransformedMetadataComparison {
  field: string;
  newValue: string;
  oldValue: string;
}

export default Vue.extend({
  name: 'metadata-modal',
  props: {
    metadata: {
      type: Object,
      required: false,
    },
    mode: {
      type: String,
      required: false,
    },
    metadataChanges: {
      type: [Object, String],
      required: false,
    },
  },

  data(): IMetadataModal {
    return {
      transformedMetadata: [],
    };
  },
  components: { MainTable, StyledModal },
  computed: {
    tableFields(): ITableField[] {
      if (this.mode === 'comparison') {
        return [
          {
            key: 'field',
            label: this.$i18n.t('documentUpload.field'),
            sortable: true,
            thClass: ['align-middle'],
            tdClass: ['align-middle', 'break-all-words', 'w-26'],
          },
          {
            key: 'oldValue',
            label: this.$i18n.t('documentUpload.oldValue'),
            sortable: true,
            thClass: ['align-middle'],
            tdClass: ['align-middle', 'break-all-words', 'w-37'],
          },
          {
            key: 'newValue',
            label: this.$i18n.t('documentUpload.newValue'),
            sortable: true,
            thClass: ['align-middle'],
            tdClass: ['align-middle', 'break-all-words', 'w-37'],
          },
        ];
      }

      return [
        {
          key: 'field',
          label: this.$i18n.t('documentUpload.field'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words', 'w-30'],
        },
        {
          key: 'value',
          label: this.$i18n.t('documentUpload.value'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle', 'break-all-words', '70'],
        },
      ];
    },
  },

  methods: {
    rowColors(item: ITransformedMetadata, type: string) {
      if (!item || type !== 'row') {
        return;
      }
      if (this.mode === 'comparison' && this.metadataChanges[item.field]) {
        return 'table-warning';
      }
    },
  },
  watch: {
    metadata() {
      if (this.mode === 'comparison' && this.metadataChanges) {
        const newMetadata: ITransformedMetadataComparison[] = [];

        for (const field in this.metadata) {
          if (this.metadataChanges[field]) {
            newMetadata.push({
              field,
              oldValue: this.metadataChanges[field].previous,
              newValue: this.metadata[field],
            });

            continue;
          }

          newMetadata.push({
            field,
            oldValue: '',
            newValue: this.metadata[field],
          });
        }

        this.transformedMetadata = newMetadata;
      }

      if (!this.mode || this.mode === 'normal') {
        const newMetadata: ITransformedMetadata[] = [];

        for (const field in this.metadata) {
          newMetadata.push({
            field,
            value: this.metadata[field],
          });
        }

        this.transformedMetadata = newMetadata;
      }
    },
  },
});
