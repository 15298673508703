import { IDocumentMetadata } from 'types';
import Vue from 'vue';

export type IconForData = {
  data: string;
  icon: string;
};

export default Vue.extend({
  name: 'cell-with-icon',

  props: {
    buildIconsForDataFunction: {
      type: Function,
      required: false,
    },
    data: {
      type: Array as () => IDocumentMetadata[],
      required: true,
    },
    icons: {
      type: Array as () => string[],
      required: true,
      default: () => [],
    },
    model: {
      type: Object as () => IDocumentMetadata,
      required: true,
    },
    prop: {
      type: String,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      iconForData: '',
    };
  },

  methods: {
    getIconForData() {
      const iconsForData = this.buildIconsForDataFunction?.();

      const icon = iconsForData?.find(
        (icon: IconForData) => icon.data === this.model[this.prop],
      )?.icon;

      this.iconForData = icon;
    },
  },

  watch: {
    data: {
      handler() {
        this.getIconForData();
      },
      deep: true,
    },
  },
});
