import fileCheckIcon from '@/assets/icons/file-check.svg';
import { ITableField } from '@/frontendInterfaces';
import projectTabNumbersMixin, {
  ProjectDetailViewTab,
  ProjectTabNumbersMixin,
} from '@/views/ProjectDetails/projectTabNumbersMixin';
import { router } from '@/vue';
import axios from 'axios';
import { debounce } from 'lodash';
import Vue from 'vue';
import FilterableTable from '../../components/FilterableTable/FilterableTable.vue';
import NewUploadModal from '../../components/NewUploadModal/NewUploadModal.vue';
import endpointsList from '../../helpers/endpointsList';
import { IDocumentsUploadsSummaryDto, IPageDto, Order, Role } from 'types';
import { GlobalUtils } from '../../mixins/globalUtils';
import {
  FilterType,
  IFilterConfiguration,
  Mode,
} from '../FilterableTable/FilterableTable.script';
import {
  CalendarOpenDirection,
  IDateRange,
} from '../StyledDateRangePicker/StyledDateRangePicker.script';

interface IUploadsOverview {
  fileCheckIcon: string;
  filtering?: Record<string, string | Date | IDateRange>;
  isLoadingUploads: boolean;
  newUploadAllowedRoles: Role[];
  page: number;
  perPage: number;
  rows: number;
  sortBy: keyof IDocumentsUploadsSummaryDto;
  sortDesc: boolean;
  tableFilterGridConfiguration: string[];
  tableFilterGridConfigurationWithSupplierColumn: string[];
  tableMode: Mode.OUTSOURCED;
  uploads?: IDocumentsUploadsSummaryDto[];
}

export default Vue.extend({
  name: 'uploads-overview',
  components: { NewUploadModal, FilterableTable },
  mixins: [projectTabNumbersMixin],
  props: {
    projectId: {
      type: String,
      required: true,
    },
    projectNumber: {
      type: String,
      required: true,
    },
    projectTitle: {
      type: String,
      required: true,
    },
  },

  data(): IUploadsOverview {
    return {
      fileCheckIcon,
      filtering: undefined,
      isLoadingUploads: false,
      newUploadAllowedRoles: [Role.Admin, Role.Coworker],
      page: 1,
      perPage: 10,
      rows: 0,
      sortBy: 'uploadDate',
      sortDesc: true,
      tableFilterGridConfiguration: [
        '8%',
        '8%',
        '18%',
        '14%',
        '22%',
        '14%',
        '14%',
      ],
      tableFilterGridConfigurationWithSupplierColumn: [
        '6%',
        '15%',
        '6%',
        '15%',
        '12%',
        '20%',
        '12%',
        '12%',
      ],
      tableMode: Mode.OUTSOURCED,
      uploads: undefined,
    };
  },

  computed: {
    isSupplierColumnVisible(): boolean {
      return (this as unknown as GlobalUtils).isUserRole([
        Role.Admin,
        Role.DocuManager,
        Role.Inspector,
      ]);
    },

    tableFields(): ITableField[] {
      const tableFields = [
        {
          key: 'referenceNumber',
          label: '#',
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        },
        {
          key: 'filesCount',
          label: this.$i18n.t('uploadsOverview.count'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        },
        {
          key: 'contentTypeName',
          label: this.$i18n.t('uploadsOverview.contentType'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        },
        {
          key: 'contents',
          label: this.$i18n.t('uploadsOverview.content'),
          sortable: false,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        },
        {
          key: 'inspectorStatuses',
          label: this.$i18n.t('uploadsOverview.documentStatus'),
          sortable: false,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        },
        {
          key: 'uploadDate',
          label: this.$i18n.t('uploadsOverview.uploadDate'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        },
        {
          key: 'earliestInspectionDueDate',
          label: this.$i18n.t('uploadsOverview.inspectionDueDate'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        },
        {
          key: 'getUploadConfirmation',
          label: '',
          sortable: false,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        },
      ];

      const tableClasses = ['tdClass', 'thClass'] as const;

      if (this.isSupplierColumnVisible) {
        tableFields.splice(1, 0, {
          key: 'supplierName',
          label: this.$i18n.t('uploadsOverview.supplier'),
          sortable: true,
          thClass: ['align-middle'],
          tdClass: ['align-middle'],
        });

        tableClasses.forEach((classArray) => {
          [
            'w-6',
            'w-15',
            'w-6',
            'w-15',
            'w-12',
            'w-20',
            'w-12',
            'w-12',
          ].forEach((widthClass, index) => {
            tableFields[index][classArray].push(widthClass);
          });
        });

        return tableFields;
      }

      tableClasses.forEach((classArray) => {
        ['w-8', 'w-8', 'w-18', 'w-14', 'w-22', 'w-14', 'w-14'].forEach(
          (widthClass, index) => {
            tableFields[index][classArray].push(widthClass);
          },
        );
      });

      return tableFields;
    },

    tableFilterConfiguration(): IFilterConfiguration {
      let tableFilterConfiguration: IFilterConfiguration = {
        referenceNumber: {
          type: FilterType.INPUT,
          width: '57px',
          column: 1,
          customLabel: '#',
        },
        contentTypeName: {
          type: FilterType.INPUT,
          width: '184px',
          column: 3,
          customLabel: this.$i18n.t('uploadsOverview.filterContentTypes'),
        },
        excludeProcessed: {
          type: FilterType.CHECK_BOX,
          width: '150px',
          column: 6,
          customLabel: this.$i18n.t('uploadsOverview.hideProcessed'),
        },
        uploadDate: {
          type: FilterType.DATE_RANGE_PICKER,
          width: '170px',
          column: 7,
          customLabel: this.$i18n.t('uploadsOverview.uploadDate'),
        },
        earliestInspectionDueDate: {
          type: FilterType.DATE_RANGE_PICKER,
          width: '170px',
          column: 8,
          customLabel: this.$i18n.t('uploadsOverview.inspectionDueDate'),
          dateRangePickerCalendarOpenDirection: CalendarOpenDirection.LEFT,
        },
      };

      if (this.isSupplierColumnVisible) {
        const supplierNameFilterConfiguration = {
          type: FilterType.INPUT,
          width: '208px',
          column: 2,
        };

        /**
         * To preserve filter inputs order and be able to use tab key for
         * selection
         */
        const tableFilterConfigurationEntries = Object.entries(
          tableFilterConfiguration,
        );
        tableFilterConfigurationEntries.splice(1, 0, [
          'supplierName',
          supplierNameFilterConfiguration,
        ]);

        tableFilterConfiguration = Object.fromEntries(
          tableFilterConfigurationEntries,
        );

        tableFilterConfiguration.contentTypeName.column = 4;
        tableFilterConfiguration.uploadDate.column = 7;
        tableFilterConfiguration.earliestInspectionDueDate.column = 8;
      }

      return tableFilterConfiguration;
    },
  },

  methods: {
    async getUploads() {
      const filtering = Object.fromEntries(
        Object.entries(this.filtering ?? {}).filter(
          ([_key, value]) =>
            Boolean(value) &&
            _key !== 'uploadDate' &&
            _key !== 'earliestInspectionDueDate',
        ),
      );

      try {
        this.isLoadingUploads = true;

        const response = await axios.get<IPageDto<IDocumentsUploadsSummaryDto>>(
          endpointsList.documentsUploads.getPaginatedDocumentsUploadsSummaryForProject(
            {
              projectId: this.projectId as any,
              order: this.sortDesc ? Order.DESC : Order.ASC,
              page: this.page,
              sortBy: this.sortBy,
              take: this.perPage,
              ...filtering,
            },
          ),
        );

        this.uploads = response.data.data;
        this.rows = response.data.meta.itemCount;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('uploadsOverview.errors.couldNotLoadDeliveries'),
          'danger',
        );
      } finally {
        this.isLoadingUploads = false;
      }
    },

    goToFilesOverview(row: { item: IDocumentsUploadsSummaryDto }) {
      const tabNumber = (
        this as unknown as ProjectTabNumbersMixin
      ).getProjectDetailViewTabNumber(ProjectDetailViewTab.DOCUMENTS);

      this.$store.commit(
        'setCurrentUploadReferenceNumber',
        row.item.referenceNumber,
      );
      router.push({
        name: 'projectDetails',
        params: {
          id: this.projectId,
          tabNumber,
        },
      });
    },

    isUploadConfirmationReady(upload: IDocumentsUploadsSummaryDto): boolean {
      const relevantUpload = this.uploads?.find(
        (u) => u.referenceNumber === upload.referenceNumber,
      );
      return !!relevantUpload?.isUploadConfirmationReady;
    },

    getUploadConfirmationFileLink(
      sapNumber: string,
      uploadReferenceNumber: string,
    ): string {
      const fileDownloadEndpoint =
        endpointsList.sharePointIntegration.downloadUploadConfirmationFile(
          this.projectNumber,
          sapNumber,
          uploadReferenceNumber,
        );

      const downloadLink = `${window.location.origin}/api/${fileDownloadEndpoint}`;

      return downloadLink;
    },

    handleNewUploadButtonClick(): void {
      this.$bvModal.show('new-upload-modal');
    },

    debouncedGetUploads: debounce(function (this: any) {
      this.getUploads();
    }, 200),

    handlePerPageChange(perPage: number): void {
      this.perPage = perPage;
      this.getUploads();
    },

    handlePageNumberChange(pageNumber: number): void {
      this.page = pageNumber;
      this.getUploads();
    },

    handleFilteringChange(filtering: Record<string, string>): void {
      this.filtering = filtering;
      if (
        (filtering.uploadDate as IDateRange).startDate &&
        (filtering.uploadDate as IDateRange).endDate
      ) {
        this.filtering.uploadDateFrom = (
          filtering.uploadDate as IDateRange
        ).startDate!;
        this.filtering.uploadDateTo = (
          filtering.uploadDate as IDateRange
        ).endDate!;
      } else {
        this.filtering.uploadDateFrom = '';
        this.filtering.uploadDateTo = '';
      }

      if (
        (filtering.earliestInspectionDueDate as IDateRange).startDate &&
        (filtering.earliestInspectionDueDate as IDateRange).endDate
      ) {
        this.filtering.inspectionDueDateFrom = (
          filtering.earliestInspectionDueDate as IDateRange
        ).startDate!;
        this.filtering.inspectionDueDateTo = (
          filtering.earliestInspectionDueDate as IDateRange
        ).endDate!;
      } else {
        this.filtering.inspectionDueDateFrom = '';
        this.filtering.inspectionDueDateTo = '';
      }
      this.debouncedGetUploads();
    },

    handleSortDescChanged(sortDesc: boolean): void {
      this.sortDesc = sortDesc;
      this.getUploads();
    },

    handleSortByChanged(sortBy: keyof IDocumentsUploadsSummaryDto): void {
      this.sortBy = sortBy;
      this.getUploads();
    },
  },

  created() {
    this.getUploads();
  },
});
