<template>
  <styled-modal
    :modalId="modalId"
    :noCancelButton="!!importResult"
    :okButtonBusy="isImporting"
    :okButtonLabel="okButtonLabel"
    :size="ModalSize.XL"
    :title="$tt('iblUploadModal.title')"
    @ok="handleModalOk"
  >
    <!--
      Showing import result component with detailed errors and warnings if
      there were any. If the import was fully successful, a success toast is
      shown and the modal is closed.
    -->
    <template slot="modal-content">
      <ibl-import-result-display
        v-if="isImportErrors"
        :importResult="importResult"
      />
      <b-form v-else>
        <b-form-group
          v-if="isSupplierSelect"
          :invalid-feedback="$tt('iblUploadModal.noSupplierSelected')"
          :label="$tt('iblUploadModal.supplier')"
          :state="isSupplierInputValidState"
        >
          <project-suppliers-select
            v-model="selectedSupplier"
            :isError="isSupplierSelectError"
            :projectId="projectId"
          />
        </b-form-group>
        <b-form-group
          :description="getMaxKksCountHint"
          :label="$tt('iblUploadModal.iblFile')"
        >
          <b-form-file
            v-model="file"
            accept=".xlsx"
            :browse-text="$tt('generic.selectFile')"
            :drop-placeholder="$tt('generic.dropFileHere')"
            required
            :state="isFileInputValidState"
          >
            <template #placeholder>
              <span class="file-input-placeholder">{{
                $tt('generic.selectFileOrDragAndDrop')
              }}</span>
            </template>
          </b-form-file>
          <b-form-invalid-feedback>{{
            $tt('generic.noFileSelected')
          }}</b-form-invalid-feedback>
        </b-form-group>
      </b-form>
    </template>
  </styled-modal>
</template>

<script lang="ts">
  import Vue from 'vue';
  import StyledModal, { ModalSize } from '../StyledModal/StyledModal.vue';
  import ProjectSuppliersSelect, {
    SupplierOption,
  } from '../ProjectSuppliersSelect/ProjectSuppliersSelect.vue';
  import IblImportResultDisplay from '../IblImportResultDisplay/IblImportResultDisplay.vue';
  import {
    IIblImportResponseDto,
    ProjectType,
    IblErrorCode,
    IBL_IMPORT_MAX_KKS_COUNT,
    IblImportResult,
    Role,
  } from 'types';
  import { IblImportService } from '@/services';
  import { isCustomErrorCodeException } from '@/helpers';

  export default Vue.extend({
    name: 'ibl-upload-modal',
    components: {
      IblImportResultDisplay,
      ProjectSuppliersSelect,
      StyledModal,
    },
    props: {
      projectId: {
        type: String,
        required: true,
      },
      projectType: {
        type: String as () => ProjectType,
        required: true,
      },
    },
    data(): {
      file: File | null;
      importResult: IIblImportResponseDto | null;
      isImporting: boolean;
      isImportErrors: boolean;
      isSubmitted: boolean;
      ModalSize: typeof ModalSize;
      selectedSupplier: SupplierOption | null;
    } {
      return {
        file: null,
        importResult: null,
        isImporting: false,
        isImportErrors: false,
        isSubmitted: false,
        ModalSize,
        selectedSupplier: null,
      };
    },
    computed: {
      modalId(): string {
        return 'ibl-upload-modal';
      },
      isSupplierProject(): boolean {
        return this.projectType === ProjectType.SUPPLIER_PROJECT;
      },
      isSupplierSelect(): boolean {
        return (
          this.isSupplierProject &&
          /**
           * Only admins can select the supplier for which the IBL is uploaded.
           * In other cases the supplier is established automatically.
           */
          this.isUserRole([Role.Admin])
        );
      },
      okButtonLabel(): string {
        return this.importResult
          ? this.$tt('generic.ok').toString()
          : this.$tt('generic.upload').toString();
      },
      /**
       * Showing no validation state if the form is not submitted
       * Returning null if the form value is correct to prevent showing
       * validated state.
       */
      isFileInputValidState(): boolean | null {
        return this.isSubmitted && !this.file ? false : null;
      },
      /** For bootstrap-vue form-group state */
      isSupplierInputValidState(): boolean | null {
        return this.isSubmitted && !this.selectedSupplier ? false : null;
      },
      /** For project-suppliers-select component */
      isSupplierSelectError(): boolean {
        return this.isSupplierInputValidState === false;
      },
      getMaxKksCountHint(): string {
        return this.$tt('iblUploadModal.maxKksCount', {
          maxKksCount: IBL_IMPORT_MAX_KKS_COUNT,
        }).toString();
      },
    },
    methods: {
      handleModalOk(): void {
        if (this.importResult) {
          this.$bvModal.hide(this.modalId);
        } else this.handleSubmit();
      },
      async handleSubmit(): Promise<void> {
        this.isSubmitted = true;
        if (!this.validateForm()) return;

        this.isImporting = true;
        try {
          this.importResult = await IblImportService.importIbl(
            this.file!,
            this.projectId,
            this.selectedSupplier?._id,
          );
          /**
           * If the import was successful, close the modal and show success
           * toast.
           */
          if (this.importResult.importResult === IblImportResult.SUCCESS) {
            this.createSuccessToast(
              this.$tt('iblUploadModal.successfullyUploadedIbl'),
            );
            this.$bvModal.hide(this.modalId);
          } else {
            /**
             * If there were errors or warnings, show the import result
             * component with detailed errors.
             */
            this.isImportErrors = true;
          }

          this.$emit('ibl-import-finished');
        } catch (e) {
          if (isCustomErrorCodeException(e)) {
            if (
              e.response?.data.customErrorCode === IblErrorCode.WRONG_IBL_FORMAT
            ) {
              this.createErrorToast(
                this.$tt('iblUploadModal.errors.wrongIblFormat'),
              );
            } else if (
              e.response?.data.customErrorCode ===
              IblErrorCode.MAX_KKS_COUNT_EXCEEDED
            ) {
              this.createErrorToast(
                this.$tt('iblUploadModal.errors.maxKksCountExceeded', {
                  maxKksCount: IBL_IMPORT_MAX_KKS_COUNT,
                }),
              );
            } else {
              this.createErrorToast(
                this.$tt('iblUploadModal.errors.errorUploadingIbl'),
              );
            }
          } else {
            this.createErrorToast(
              this.$tt('iblUploadModal.errors.errorUploadingIbl'),
            );
          }
        } finally {
          this.isImporting = false;
        }
      },
      validateForm(): boolean {
        return this.isSupplierProject
          ? !!this.selectedSupplier && !!this.file
          : !!this.file;
      },
    },
    mounted(): void {
      /**
       * If the project is a supplier project, the supplier is selected
       * automatically. If the user is an admin, the supplier can be selected
       * manually.
       */
      if (this.isSupplierProject && this.$store.state.supplierId) {
        this.selectedSupplier = {
          _id: this.$store.state.supplierId,
        } as SupplierOption;
      }
    },
  });
</script>

<style lang="scss" scoped>
  /** Using same styling as in the StyledMultiselect component */
  .file-input-placeholder {
    color: $placeholder-font-color;
    padding-top: 2px;
    margin-bottom: 10px;
    font-size: 14px;
  }
</style>
