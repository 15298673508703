import endpointsList from '@/helpers/endpointsList';
import axios from 'axios';
import GoBack from '@/components/GoBack/GoBack.vue';
import { router } from '@/vue';
import CheckBotResultsTable from '@/components/CheckBotResultsTable/CheckBotResultsTable.vue';
import Vue from 'vue';
import {
  DocumentDetailViewMode,
  IDigitalizationDocument,
  IFilesListFile,
} from 'types';
import { ToastMixin } from '@/mixins/globalUtils';
import MainTable from '@/components/MainTable/MainTable.vue';
import StyledPagination from '@/components/StyledPagination/StyledPagination.vue';
import DocumentAndMetadata from '@/components/DocumentAndMetadata/DocumentAndMetadata.vue';
import InspectorPanel from '@/components/InspectorPanel/InspectorPanel.vue';
import DocumentHistory from '@/components/DocumentHistory/DocumentHistory.vue';
import MetadataCheckResultsTable from '@/components/MetadataCheckResultsTable/MetadataCheckResultsTable.vue';
import ProgressStepsIndicator from '@/components/ProgressStepsIndicator/ProgressStepsIndicator.vue';

interface IDocumentDetailView {
  digitalizationDocument?: IDigitalizationDocument;
  file?: IFilesListFile;

  createToast?: ToastMixin;
}

export interface IMetadataEntry {
  field: string;
  value: string | string[];
}
[];

export default Vue.extend({
  name: 'document-detail-view',
  components: {
    GoBack,
    CheckBotResultsTable,
    MainTable,
    StyledPagination,
    DocumentAndMetadata,
    InspectorPanel,
    DocumentHistory,
    MetadataCheckResultsTable,
    ProgressStepsIndicator,
  },

  props: {
    mode: {
      type: String as () => DocumentDetailViewMode,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
    uploadId: {
      type: String,
      required: true,
    },
  },

  data(): IDocumentDetailView {
    return {
      file: undefined,
      digitalizationDocument: undefined,
    };
  },

  computed: {
    metadata(): IMetadataEntry[] {
      const metadata: IMetadataEntry[] = [];

      if (this.mode === DocumentDetailViewMode.PROJECT && this.file) {
        for (const field in this.file.metadata) {
          metadata.push({
            field,
            value: this.file.metadata[field],
          });
        }
      } else if (
        this.mode === DocumentDetailViewMode.DIGITALIZATION_PROJECT &&
        this.digitalizationDocument
      ) {
        for (const field in this.digitalizationDocument.fileMetadata) {
          metadata.push({
            field,
            value: this.digitalizationDocument.fileMetadata[field],
          });
        }
      }

      return metadata;
    },

    documentName(): string | undefined {
      const nameField = this.metadata.find(
        (f: { field: string; value: any }) => f.field === 'Name',
      );

      return nameField?.value as string;
    },
  },

  methods: {
    async getFile() {
      try {
        if (this.mode === DocumentDetailViewMode.PROJECT) {
          const response = await axios.get(
            endpointsList.documentsUploads.getFile(
              this.projectId,
              this.supplierId,
              this.documentId,
            ),
          );
          this.file = response.data;

          this.$store.commit(
            'setCurrentUploadReferenceNumber',
            this.file?.referenceNumber,
          );
        } else if (
          this.mode === DocumentDetailViewMode.DIGITALIZATION_PROJECT
        ) {
          const response = await axios.get(
            endpointsList.digitalizationUploads.getDigitalizationDocumentDataByProjectIdentification(
              this.projectId,
              this.documentId,
            ),
          );
          this.digitalizationDocument = response.data;

          this.$store.commit(
            'setCurrentDigitalizationUploadReferenceNumber',
            this.digitalizationDocument?.referenceNumber,
          );
        }
      } catch (err) {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }
    },

    setBreadcrumb() {
      if (this.mode === DocumentDetailViewMode.PROJECT) {
        (this as any).setBreadcrumbMetadata([
          'projectsList',
          'projectDetails',
          'uploadReferenceNumber',
          'documentDetailView',
        ]);
      } else if (this.mode === DocumentDetailViewMode.DIGITALIZATION_PROJECT) {
        (this as any).setBreadcrumbMetadata([
          'digitalizationProjectsList',
          'digitalizationProjectDetails',
          'digitalizationUploadReferenceNumber',
          'documentDetailView',
        ]);
      }
    },
  },

  mounted() {
    if (!this.projectId) {
      if (this.mode === DocumentDetailViewMode.PROJECT) {
        router.push({
          name: 'projectsList',
        });
      } else if (this.mode === DocumentDetailViewMode.DIGITALIZATION_PROJECT) {
        router.push({
          name: 'digitalizationProjectsList',
        });
      }
    }

    this.setBreadcrumb();

    if (this.mode === DocumentDetailViewMode.PROJECT) {
      this.$store.commit('setCurrentProjectId', this.projectId);
    } else if (this.mode === DocumentDetailViewMode.DIGITALIZATION_PROJECT) {
      this.$store.commit('setCurrentDigitalizationProjectId', this.projectId);
    }
    this.getFile();
  },
});
