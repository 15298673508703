import { render, staticRenderFns } from "./NewDigitalizationUploadModal.vue?vue&type=template&id=28b8020c&scoped=true"
import script from "./NewDigitalizationUploadModal.script.ts?vue&type=script&lang=ts&external"
export * from "./NewDigitalizationUploadModal.script.ts?vue&type=script&lang=ts&external"
import style0 from "./NewDigitalizationUploadModal.style.scss?vue&type=style&index=0&id=28b8020c&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28b8020c",
  null
  
)

export default component.exports