import { ToastMixin } from '../../mixins/globalUtils';
import axios, { AxiosError } from 'axios';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import redCrossIcon from '../../assets/icons/remove-cross.svg';
import endpointsList from '../../helpers/endpointsList';
import { IDocuManager, Role, UserGroup } from 'types';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import FilterableTable from '../FilterableTable/FilterableTable.vue';
import SelectModal from '../SelectModal/SelectModal.vue';
import SpGroupModal from '../SpGroupModal/SpGroupModal.vue';

interface IProjectsDocuManagers {
  docuManagers: IDocuManager[] | null;
  isFetchingDocuManagers: boolean;
  sortBy: keyof IDocuManager;
  sortDesc: boolean;
  filterConfiguration: IFilterConfiguration;
  filterGridConfiguration: string[];
  createToast?: ToastMixin;
  customActionIcon: string;
  spGroup: UserGroup.docuManagers;
  addingDocuManagerAllowedRoles: [Role.Admin, Role.DocuManager];
}

export default Vue.extend({
  name: 'projects-docu-managers',
  components: { FilterableTable, SelectModal, SpGroupModal },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },

  data(): IProjectsDocuManagers {
    return {
      docuManagers: null,
      isFetchingDocuManagers: false,
      sortBy: 'lastName',
      sortDesc: true,
      filterConfiguration: {
        firstName: { type: FilterType.INPUT, column: 1, width: '208px' },
        lastName: { type: FilterType.INPUT, column: 2, width: '208px' },
        email: { type: FilterType.INPUT, column: 3, width: '208px' },
      },
      filterGridConfiguration: ['36%', '28%', '34%'],
      customActionIcon: redCrossIcon,
      spGroup: UserGroup.docuManagers,
      addingDocuManagerAllowedRoles: [Role.Admin, Role.DocuManager],
    };
  },

  computed: {
    tableFields(): Array<{
      key: string;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
    }> {
      return [
        {
          key: 'firstName',
          label: this.$i18n.t('generic.firstName'),
          sortable: true,
          tdClass: ['w-36'],
        },
        {
          key: 'lastName',
          label: this.$i18n.t('generic.lastName'),
          sortable: true,
          tdClass: ['w-28'],
        },
        {
          key: 'email',
          label: this.$i18n.t('generic.email'),
          sortable: true,
          tdClass: ['w-34'],
        },
        {
          key: 'remove',
          label: '',
          sortable: false,
          tdClass: ['w-2', 'action-button'],
        },
      ];
    },
  },

  methods: {
    async getDocuManagers() {
      this.isFetchingDocuManagers = true;
      try {
        const response = await axios.get(
          endpointsList.projects.getDocuManagersForProject(this.projectId),
        );

        this.docuManagers = response.data;
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('projectsDocuManagers.errors.couldNotGetDocuManagers'),
          'danger',
        );
      } finally {
        this.isFetchingDocuManagers = false;
      }
    },

    async deleteDocuManagerFromProject(
      docuManager: IDocuManager,
    ): Promise<void> {
      try {
        await axios.delete(
          endpointsList.projects.removeDocuManagerFromProject(
            this.projectId,
            docuManager.oid,
          ),
        );
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === 409) {
            this.createToast!(
              this.$t('errors.error'),
              this.$t('projectsDocuManagers.errors.cannotRemoveLast'),
              'danger',
            );
          } else if (err.response?.status === 403) {
            this.createToast!(
              this.$t('errors.error'),
              this.$t('projectsDocuManagers.errors.cannotRemoveSelf'),
              'danger',
            );
          } else {
            this.createToast!(
              this.$t('errors.error'),
              this.$t('projectsDocuManagers.errors.errorRemovingDocuManager'),
              'danger',
            );
          }
        } else {
          this.createToast!(
            this.$t('errors.error'),
            this.$t('projectsDocuManagers.errors.errorRemovingDocuManager'),
            'danger',
          );
        }
      } finally {
        await this.getDocuManagers();
      }
    },

    async handleAddDocuManager(docuManager: IDocuManager) {
      try {
        const response = await axios.patch(
          endpointsList.projects.addDocuManagerToProject(
            this.projectId,
            docuManager.oid,
          ),
        );

        if (response.status === 200) {
          this.getDocuManagers();
          this.createToast!(
            this.$t('addDocuManagerToProject.docuManagerAdded'),
            this.$t('addDocuManagerToProject.docuManagerAdded'),
            'success',
          );
        }
      } catch (err: unknown) {
        if (err instanceof AxiosError && err.response?.status === 409) {
          this.createToast!(
            this.$t('errors.error'),
            this.$t('projectsDocuManagers.errors.docuManagerAlreadyAdded'),
            'danger',
          );
          return;
        }

        this.createToast!(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }
    },
  },

  created() {
    this.getDocuManagers();
  },
});
