import axios, { AxiosError, AxiosResponse } from 'axios';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import redCrossIcon from '../../assets/icons/remove-cross.svg';
import endpointsList from '../../helpers/endpointsList';
import { IDocuManager, IInspector, Role, UserGroup } from 'types';
import { ToastMixin } from '../../mixins/globalUtils';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import FilterableTable from '../FilterableTable/FilterableTable.vue';
import SpGroupModal from '../SpGroupModal/SpGroupModal.vue';

interface IInspectorsList {
  inspectors: IInspector[] | null;
  isFetchingInspectors: boolean;
  spGroup: UserGroup;
  createToast?: ToastMixin;
  sortBy: keyof IDocuManager;
  sortDesc: boolean;
  filterConfiguration: IFilterConfiguration;
  filterGridConfiguration: string[];
  customActionIcon: string;
  addInspectorAllowedRoles: [Role.Admin, Role.DocuManager];
}

export default Vue.extend({
  name: 'inspectors-list',
  components: { SpGroupModal, FilterableTable },
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data(): IInspectorsList {
    return {
      inspectors: null,
      isFetchingInspectors: false,

      spGroup: UserGroup.inspectors,

      sortBy: 'lastName',
      sortDesc: true,
      filterConfiguration: {
        firstName: { type: FilterType.INPUT, column: 1, width: '208px' },
        lastName: { type: FilterType.INPUT, column: 2, width: '208px' },
        email: { type: FilterType.INPUT, column: 3, width: '208px' },
      },
      filterGridConfiguration: ['36%', '28%', '34%'],
      customActionIcon: redCrossIcon,
      addInspectorAllowedRoles: [Role.Admin, Role.DocuManager],
    };
  },
  methods: {
    async getInspectors() {
      this.isFetchingInspectors = true;
      try {
        const response = await axios.get(
          endpointsList.projects.getInspectorsForProject(this.projectId),
        );

        this.inspectors = response.data;
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('inspectors.couldNotGetInspectors'),
          'danger',
        );
      }

      this.isFetchingInspectors = false;
    },

    async handleAddInspector(inspector: IInspector) {
      try {
        const response: AxiosResponse = await axios.patch(
          endpointsList.projects.addInspectorToProject(
            this.projectId,
            inspector.oid,
          ),
        );

        if (response.status === 200) {
          this.createToast!(
            this.$t('inspectors.inspectorAdded'),
            this.$t('inspectors.inspectorAdded'),
            'success',
          );

          this.getInspectors();
        }
      } catch (err: any) {
        if (err.response.status === 409) {
          this.createToast!(
            this.$t('errors.error'),
            this.$t('inspectors.inspectorAlreadyAdded'),
            'danger',
          );
        } else {
          this.createToast!(
            this.$t('errors.error'),
            this.$t('inspectors.couldNotAddInspector'),
            'danger',
          );
        }
      }
    },

    async deleteInspectorFromProject(inspector: IInspector): Promise<void> {
      try {
        await axios.delete(
          endpointsList.projects.removeInspectorFromProject(
            this.projectId,
            inspector.oid,
          ),
        );
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === 409) {
            this.createToast!(
              this.$t('errors.error'),
              this.$t('inspectors.errors.cannotRemoveLast'),
              'danger',
            );
          } else {
            this.createToast!(
              this.$t('errors.error'),
              this.$t('inspectors.errors.errorRemovingInspector'),
              'danger',
            );
          }
        } else {
          this.createToast!(
            this.$t('errors.error'),
            this.$t('inspectors.errors.errorRemovingInspector'),
            'danger',
          );
        }
      }

      await this.getInspectors();
    },
  },

  computed: {
    tableFields(): {
      key: string;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
    }[] {
      return [
        {
          key: 'firstName',
          label: this.$i18n.t('generic.firstName'),
          sortable: true,
          tdClass: ['w-36'],
        },
        {
          key: 'lastName',
          label: this.$i18n.t('generic.lastName'),
          sortable: true,
          tdClass: ['w-28'],
        },
        {
          key: 'email',
          label: this.$i18n.t('generic.email'),
          sortable: true,
          tdClass: ['w-34'],
        },
        {
          key: 'remove',
          label: '',
          sortable: false,
          tdClass: ['w-2', 'action-button'],
        },
      ];
    },
  },

  created() {
    this.getInspectors();
  },
});
