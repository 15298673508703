"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnrichedFileStatus = void 0;
var EnrichedFileStatus;
(function (EnrichedFileStatus) {
    EnrichedFileStatus["WAITING_FOR_CHECK_BOT_RESULT"] = "waiting_for_check_bot_result";
    EnrichedFileStatus["FILE_EXCHANGED"] = "file_exchanged";
    EnrichedFileStatus["ENRICHED_FILE_DOWNLOAD_FAILED"] = "enriched_file_download_failed";
    EnrichedFileStatus["ENRICHED_FILE_UPLOAD_FAILED"] = "enriched_file_upload_failed";
    EnrichedFileStatus["NOT_PRESENT"] = "not_present";
})(EnrichedFileStatus || (exports.EnrichedFileStatus = EnrichedFileStatus = {}));
