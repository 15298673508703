import { endpointsList } from '@/helpers';
import axios, { AxiosResponse } from 'axios';
import { ISupplier, Serialized } from 'types';

export class SuppliersService {
  static async getSuppliersForProject<T extends keyof ISupplier>(
    projectId: string,
    fields?: T[],
  ): Promise<Array<Serialized<Pick<ISupplier, T>>>> {
    const response: AxiosResponse<Array<Serialized<Pick<ISupplier, T>>>> =
      await axios.get(
        endpointsList.projects.getProjectSuppliersById(projectId, fields),
      );

    return response.data;
  }
}
