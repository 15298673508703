import AdminPanel from '@/views/AdminPanel/AdminPanel.vue';
import CheckBotConfigurator from '@/views/CheckBotConfigurator/CheckBotConfigurator.vue';
import ContentTypeConfiguration from '@/views/ContentTypeConfiguration/ContentTypeConfiguration';
import CoworkerDetailView from '@/views/CoworkerDetailView/CoworkerDetailView.vue';
import CreateDigitalizationProject from '@/views/CreateDigitalizationProject/CreateDigitalizationProject.vue';
import CreateProject from '@/views/CreateProject/CreateProject.vue';
import DigitalizationProjectDetails from '@/views/DigitalizationProjectDetails/DigitalizationProjectDetails.vue';
import DigitalizationProjectsList from '@/views/DigitalizationProjectsList/DigitalizationProjectsList.vue';
import DocuManagerDetailView from '@/views/DocuManagerDetailView/DocuManagerDetailView.vue';
import DocumentDetailView from '@/views/DocumentDetailView/DocumentDetailView';
import DocumentUploadView from '@/views/DocumentUploadView/DocumentUploadView.vue';
import ForbiddenView from '@/views/ForbiddenView/ForbiddenView.vue';
import MetadataGridEditor from '@/views/MetadataGridEditor/MetadataGridEditor.vue';
import ProjectDetails from '@/views/ProjectDetails/ProjectDetails.vue';
import ProjectsList from '@/views/ProjectsList/ProjectsList.vue';
import ProjectsSupplierDetails from '@/views/ProjectsSupplierDetailView/ProjectsSupplierDetailView.vue';
import SupplierDetails from '@/views/SupplierDetails/SupplierDetails.vue';
import SuppliersList from '@/views/SuppliersList/SuppliersList.vue';

export default [
  {
    name: 'projectsList',
    path: '/projects',
    component: ProjectsList,
    meta: {
      breadcrumb: ['projectsList'],
    },
  },
  {
    name: 'createProject',
    path: '/projects/create-project',
    component: CreateProject,
    meta: {
      breadcrumb: ['projectsList', 'createProject'],
    },
  },
  {
    name: 'projectDetails',
    path: '/project-details/:id/:tabNumber?',
    component: ProjectDetails,
    props: true,
    meta: {
      breadcrumb: ['projectsList', 'projectDetails'],
    },
  },
  {
    name: 'projectsSupplierDetailView',
    path: '/projectDetails/:projectId/:supplierId/:tabNumber?',
    component: ProjectsSupplierDetails,
    props: true,
    meta: {
      breadcrumb: [
        'projectsList',
        'projectDetails',
        'projectsSupplierDetailView',
      ],
    },
  },
  {
    name: 'contentTypeConfiguration',
    path: '/content-type-configuration/:contentTypeId/:projectId/:mode/:supplierId?',
    component: ContentTypeConfiguration,
    props: true,
    meta: {
      breadcrumb: [],
    },
  },
  {
    name: 'checkBotConfigurator',
    path: '/check-bot-configurator/:projectId/:supplierId?/:supplierName?',
    component: CheckBotConfigurator,
    props: true,
    meta: {
      breadcrumb: [],
    },
  },
  {
    name: 'documentUpload',
    path: '/document-upload/:mode/:referenceNumber/:contentTypeId/:projectId/:projectNumber/:projectName/:supplierId?/:SAPNumber?/:supplierName?',
    component: DocumentUploadView,
    props: true,
    meta: {
      breadcrumb: [],
    },
  },
  {
    name: 'documentDetailView',
    path: '/document-detail-view/:mode/:documentId/:projectId/:uploadId/:supplierId?',
    component: DocumentDetailView,
    props: true,
    meta: {
      breadcrumb: [],
    },
  },
  {
    name: 'suppliersList',
    path: '/suppliers',
    component: SuppliersList,
    meta: {
      breadcrumb: ['suppliersList'],
    },
  },
  {
    name: 'supplierDetails',
    path: '/supplier-details/:id',
    component: SupplierDetails,
    props: true,
    meta: {
      breadcrumb: ['suppliersList', 'supplierDetails'],
    },
  },
  {
    name: 'coworkerDetails',
    path: '/coworker-details',
    component: CoworkerDetailView,
    props: true,
    meta: {
      breadcrumb: ['suppliersList', 'supplierDetails', 'coworkerDetails'],
    },
  },
  {
    name: 'administration',
    path: '/administration',
    component: AdminPanel,
    meta: {
      breadcrumb: ['administration'],
    },
  },
  {
    name: 'docuManagerDetailView',
    path: '/administration/docu-manager-detail-view',
    component: DocuManagerDetailView,
    props: true,
    meta: {
      breadcrumb: ['administration', 'docuManagerDetailView'],
    },
  },
  {
    name: 'digitalizationProjectsList',
    path: '/digitalization-projects',
    component: DigitalizationProjectsList,
    meta: {
      breadcrumb: ['digitalizationProjectsList'],
    },
  },
  {
    name: 'createDigitalizationProject',
    path: '/digitalization-projects/create-project',
    component: CreateDigitalizationProject,
    meta: {
      breadcrumb: ['digitalizationProjectsList', 'createDigitalizationProject'],
    },
  },
  {
    name: 'digitalizationProjectDetails',
    path: '/digitalization-projects/digitalization-project-details/:id/:tabNumber?',
    component: DigitalizationProjectDetails,
    props: true,
    meta: {
      breadcrumb: [
        'digitalizationProjectsList',
        'digitalizationProjectDetails',
      ],
    },
  },
  {
    name: 'metadataGridEditor',
    path: '/metadata-grid-editor/:digitalizationProjectId/:mode',
    component: MetadataGridEditor,
    props: true,
    breadcrumb: [],
  },
  {
    name: 'forbiddenView',
    path: '/forbidden',
    component: ForbiddenView,
  },
  {
    path: '*',
    component: ProjectsList,
    meta: {
      breadcrumb: ['projectsList'],
    },
  },
];
