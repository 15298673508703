import axios from 'axios';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import deselectIcon from '../../assets/icons/deselect.svg';
import selectIcon from '../../assets/icons/select.svg';
import endpointsList from '../../helpers/endpointsList';
import { IDocuManager } from 'types';
import { ToastMixin } from '../../mixins/globalUtils';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import FilterableTable from '../FilterableTable/FilterableTable.vue';

interface IDocuManagers {
  docuManagers: IDocuManager[] | null;
  createToast?: ToastMixin;
  sortBy: keyof IDocuManager;
  sortDesc: boolean;
  selectIcon: string;
  deselectIcon: string;
  tableFilterConfiguration: IFilterConfiguration;
  tableFilterGridConfiguration: string[];
}

export enum DocuManagersTableMode {
  DEFAULT = 'default',
  NEW_PROJECT = 'newProject',
}

export default Vue.extend({
  name: 'docu-managers-table',
  props: {
    mode: {
      type: String as () => DocuManagersTableMode,
      required: false,
    },
    addedDocuManagers: {
      type: Array as () => string[],
      required: false,
    },
  },
  components: { FilterableTable },
  data(): IDocuManagers {
    return {
      docuManagers: null,
      sortBy: 'lastName',
      sortDesc: true,
      selectIcon,
      deselectIcon,
      tableFilterConfiguration: {
        firstName: { type: FilterType.INPUT, column: 1, width: '208px' },
        lastName: { type: FilterType.INPUT, column: 2, width: '208px' },
        email: { type: FilterType.INPUT, column: 3, width: '208px' },
      },
      tableFilterGridConfiguration: ['36%', '28%', '34%'],
    };
  },

  computed: {
    tableFields(): {
      key: string;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
    }[] {
      const fields = [
        {
          key: 'firstName',
          label: this.$i18n.t('generic.firstName'),
          sortable: true,
          tdClass: ['w-36'],
        },
        {
          key: 'lastName',
          label: this.$i18n.t('generic.lastName'),
          sortable: true,
          tdClass: ['w-28'],
        },
        {
          key: 'email',
          label: this.$i18n.t('generic.email'),
          sortable: true,
          tdClass: ['w-34'],
        },
        {
          key: 'action',
          label: '',
          sortable: false,
          tdClass: ['w-2', 'text-right', 'align-middle', 'action-button'],
        },
      ];

      return fields;
    },
  },

  methods: {
    async getAddedDocuManagers() {
      try {
        const response = await axios.get(
          endpointsList.docuManagers.getAllDocuManagers,
        );

        this.docuManagers = response.data;
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('docuManagersTable.errors.couldNotGetDocuManagers'),
          'danger',
        );
      }
    },

    toggleAddDocuManager(row: { item: IDocuManager; [key: string]: unknown }) {
      this.$emit('toggle-add-docu-manager', row.item.oid);
    },

    isDocuManagerAdded(oid: string): boolean {
      return this.addedDocuManagers?.includes(oid);
    },
  },

  mounted() {
    this.getAddedDocuManagers();
  },
});
