"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InspectorStatusHelper = void 0;
const types_1 = require("types");
class InspectorStatusHelper {
    constructor(inspectorStatus) {
        this.inspectorStatus = inspectorStatus;
    }
    isDone() {
        return types_1.INSPECTION_DONE_STATUSES.includes(this.inspectorStatus);
    }
    isPositive() {
        return types_1.POSITIVE_INSPECTION_STATUSES.includes(this.inspectorStatus);
    }
    isReadyForInspection() {
        return this.inspectorStatus === types_1.InspectorStatusEnum.waiting;
    }
    isWaiting() {
        return types_1.waitingInspectorStatuses.includes(this.inspectorStatus);
    }
    isRejected() {
        return [types_1.InspectorStatusEnum.rejected].includes(this.inspectorStatus);
    }
    isTimedOut() {
        return this.inspectorStatus === types_1.InspectorStatusEnum.timedOut;
    }
}
exports.InspectorStatusHelper = InspectorStatusHelper;
