revogr-edit {
  display: block;
  position: absolute;
  background-color: #fff;

  input {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  revo-dropdown {
    height: 100%;

    &.shrink {
      fieldset legend > span {
        display: none;
      }
    }
  }
}
