import Vue from 'vue';
import { ButtonVariant } from '../VfButton/VfButton.script';

export default Vue.extend({
  name: 'styled-modal',

  props: {
    title: {
      type: String,
      required: false,
    },
    cancelButtonLabel: {
      type: String,
      required: false,
    },
    okButtonLabel: {
      type: String,
      required: false,
    },

    noCloseOnBackdrop: {
      type: Boolean,
      required: false,
    },

    noCloseOnEsc: {
      type: Boolean,
      required: false,
    },

    size: {
      type: String,
      required: false,
    },

    modalId: {
      type: String,
      required: false,
    },

    buttonsDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    okButtonBusy: {
      type: Boolean,
      required: false,
      default: false,
    },

    cancelButtonBusy: {
      type: Boolean,
      required: false,
      default: false,
    },

    noFooter: {
      type: Boolean,
      required: false,
      default: false,
    },

    noCancelButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnOkClick: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      sortBy: 'field',
      buttonVariants: {
        primary: ButtonVariant.PRIMARY,
        tertiary: ButtonVariant.TERTIARY,
      },
    };
  },

  methods: {
    handleModalOk(bvModalEvent: Event) {
      if (this.closeOnOkClick) {
        this.closeModal();
      }
      this.$emit('ok', bvModalEvent);
    },

    handleModalCancel() {
      this.closeModal();
      this.$emit('cancel');
    },

    closeModal() {
      (this.$refs['styled-modal'] as any).hide();
    },

    emitShowEvent(event: Event) {
      this.$emit('show', event);
    },

    emitHideEvent(event: Event) {
      this.$emit('hide', event);
    },
  },

  mounted() {},
});
