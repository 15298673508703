/**
 * Converts a string to a boolean value.
 *
 * This function takes a string and returns a boolean value based on the string
 * content.
 * If the string is "true", it returns `true`.
 * If the string is "false", it returns `false`.
 * For any other string, it throws an error.
 *
 * @param str - The string to convert to a boolean.
 * @returns A boolean value corresponding to the string.
 * @throws Will throw an error if the string is not "true" or "false".
 *
 * @example
 * ```typescript
 * stringToBoolean('true'); // returns true
 * stringToBoolean('false'); // returns false
 * stringToBoolean('foo'); // throws Error: Invalid boolean string
 * ```
 */
export function stringToBoolean(str: string): boolean {
  if (str === 'true') {
    return true;
  } else if (str === 'false') {
    return false;
  } else {
    throw new Error('Invalid boolean string');
  }
}
