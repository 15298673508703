"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reverseKeyValuePairs = reverseKeyValuePairs;
/**
 * Reverses the key-value pairs of an object.
 *
 * @template T - The type of the original object's values.
 * @param obj - The object whose key-value pairs will be reversed.
 * @returns A new object with reversed key-value pairs.
 *
 * @example
 * const original = { a: '1', b: '2', c: '3' };
 * const reversed = reverseKeyValuePairs(original);
 * console.log(reversed); // { '1': 'a', '2': 'b', '3': 'c' }
 */
function reverseKeyValuePairs(obj) {
    const reversed = {};
    for (const [key, value] of Object.entries(obj)) {
        reversed[String(value)] = key;
    }
    return reversed;
}
