import CheckBotEstablishedKks from '@/components/CheckBotEstablishedKks/CheckBotEstablishedKks.vue';
import { endpointsList } from '@/helpers';
import { ComponentMode } from '@/interfaces/frontend-types';
import { GlobalUtils } from '@/mixins/globalUtils';
import axios from 'axios';
import {
  DEFAULT_METADATA_VALUES_SEPARATOR,
  IDigitalizationDocument,
  IFilesListFile,
  IKksValidationListDto,
} from 'types';
import { deduplicateArray } from 'utils';
import Vue from 'vue';

export default Vue.extend({
  name: 'check-bot-established-kks-panel',
  components: { CheckBotEstablishedKks },
  props: {
    digitalizationDocument: {
      type: [Object as () => IDigitalizationDocument, () => undefined],
      required: false,
    },
    file: {
      type: [Object as () => IFilesListFile, () => undefined],
      required: false,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isValidatingKks: {
      type: Boolean,
      required: true,
    },
    kksValidationList: {
      type: Object as () => IKksValidationListDto,
      required: true,
    },
    mode: {
      type: String as () => ComponentMode,
      required: true,
    },
  },
  data() {
    return {
      isCopyingKks: false,
    };
  },
  methods: {
    async moveCheckBotEstablishedKksNumbersToMainKksField(
      onlyValidated: boolean,
    ): Promise<void> {
      try {
        this.isCopyingKks = true;
        if (this.mode === ComponentMode.PROJECT) {
          await axios.patch(
            endpointsList.documentsUploads.moveCheckBotEstablishedKksNumbersToMainKksField(
              this.file!._id.toString(),
              onlyValidated,
            ),
          );
        } else if (this.mode === ComponentMode.DIGITALIZATION_PROJECT) {
          await axios.patch(
            endpointsList.digitalizationUploads.acceptCheckBotEstablishedKksNumbers(
              this.digitalizationDocument!._id.toString(),
              onlyValidated,
            ),
          );
        }

        await this.updateDocumentData();

        (this as unknown as GlobalUtils).createToast(
          this.$t('documentDetailView.checkBotEstablishedKksCopied.short'),
          this.$tc('documentDetailView.checkBotEstablishedKksCopied.long', 2),
          'success',
        );
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('documentDetailView.errors.errorCopyingEstablishedKks.long'),
          'danger',
        );
      } finally {
        this.isCopyingKks = false;
      }
    },

    async moveOneCheckBotEstablishedKksToMainKksField(
      kks: string,
    ): Promise<void> {
      try {
        if (this.mode === ComponentMode.PROJECT) {
          await axios.patch(
            endpointsList.documentsUploads.acceptOneCheckBotEstablishedKks(
              this.file!._id.toString(),
              kks,
            ),
          );
        } else if (this.mode === ComponentMode.DIGITALIZATION_PROJECT) {
          await axios.patch(
            endpointsList.digitalizationUploads.moveOneCheckBotEstablishedKksToMainKksField(
              this.digitalizationDocument!._id.toString(),
              kks,
            ),
          );
        }

        await this.updateDocumentData();

        (this as unknown as GlobalUtils).createToast(
          this.$t('documentDetailView.checkBotEstablishedKksCopied.short'),
          this.$tc('documentDetailView.checkBotEstablishedKksCopied.long', 1),
          'success',
        );
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('documentDetailView.errors.errorCopyingEstablishedKks.long'),
          'danger',
        );
      }
    },

    async updateDocumentData(): Promise<void> {
      this.$emit('document-data-changed');
    },

    getCheckBotKks() {
      const checkBotKks =
        this.file?.metadata.checkBotEstablishedKKS ??
        this.digitalizationDocument?.fileMetadata.checkBotEstablishedKKS ??
        [];
      const deduplicatedCheckBotKks = deduplicateArray(checkBotKks);
      const currentKks = (
        this.file?.metadata.KKS ?? this.digitalizationDocument?.fileMetadata.KKS
      )?.split(DEFAULT_METADATA_VALUES_SEPARATOR);

      return deduplicatedCheckBotKks.filter(
        (kks) => !currentKks?.includes(kks),
      );
    },
  },
});
