import axios from 'axios';
import Vue from 'vue';
import 'vue-search-select/dist/VueSearchSelect.css';
import SpGroupModal from '../../components/SpGroupModal/SpGroupModal.vue';
import SupplierInformation from '../../components/SupplierInformation/SupplierInformation.vue';
import SuppliersCoworkers from '../../components/SuppliersCoworkers/SuppliersCoworkers.vue';
import endpointsList from '../../helpers/endpointsList';
import { ISupplier } from 'types';
import { ToastMixin } from '../../mixins/globalUtils';

interface ISupplierDetails {
  activeTabIndex: number;
  currentPage: number;
  rows: number;
  supplierData: null | ISupplier;
  createToast?: ToastMixin;
}

export default Vue.extend({
  name: 'supplier-details',
  components: { SpGroupModal, SupplierInformation, SuppliersCoworkers },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data(): ISupplierDetails {
    return {
      activeTabIndex: 1,

      currentPage: 1,
      rows: 0,

      supplierData: null,
    };
  },

  watch: {
    supplierData() {
      if (this.supplierData) {
        this.rows = this.supplierData?.coworkers.length;
      }
    },
  },

  methods: {
    async getSupplierData() {
      try {
        const response = await axios.get(
          endpointsList.suppliers.getSupplierById(this.id),
        );
        this.supplierData = response.data;
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t(
            'supplierdetails.informations.couldNotGetSupplierDetails.error',
          ),
          'danger',
        );
      }
    },
  },

  created() {
    this.getSupplierData();
  },
});
