"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierDocumentStatus = void 0;
var SupplierDocumentStatus;
(function (SupplierDocumentStatus) {
    SupplierDocumentStatus["FORMAL_CONTROL"] = "formal_control";
    SupplierDocumentStatus["FORMAL_CONTROL_FAILED"] = "formal_control_failed";
    SupplierDocumentStatus["CONTENT_CONTROL"] = "content_control";
    SupplierDocumentStatus["CONTENT_CONTROL_FAILED"] = "content_control_failed";
    SupplierDocumentStatus["TIMED_OUT"] = "timed_out";
    SupplierDocumentStatus["RECORD_LIBRARY_TRANSFER"] = "record_library_transfer";
    SupplierDocumentStatus["RECORD_LIBRARY_TRANSFER_FAILED"] = "record_library_transfer_failed";
    SupplierDocumentStatus["COMPLETED"] = "completed";
})(SupplierDocumentStatus || (exports.SupplierDocumentStatus = SupplierDocumentStatus = {}));
