import axios, { AxiosResponse } from 'axios';
import Vue from 'vue';
import AddCheckModal from '../../components/AddCheckModal/AddCheckModal.vue';
import MainTable from '../../components/MainTable/MainTable.vue';
import { ITableField } from '../../frontendInterfaces';
import endpointsList from '../../helpers/endpointsList';
import {
  CheckbotStatusEnum,
  ICheckBotConfigurationWithDescriptions,
  ICheckBotResultWithDescriptions,
  IConfigFileUpdateRequest,
  IDigitalizationProjectConfigFileUpdateRequest,
  ResultColumnName,
  ResultConfigReadonlyColumns,
} from 'types';
import { ToastMixin } from '../../mixins/globalUtils';
import { router } from '../../vue';
import digitalizationProjectTabNumbersMixin, {
  DigitalizationProjectDetailViewTab,
  DigitalizationProjectTabNumbersMixin,
} from '../DigitalizationProjectDetails/digitalizationProjectTabNumbersMixin';

export enum CheckBotConfiguratorMode {
  PROJECT = 'project',
  DIGITALIZATION_PROJECT = 'digitalizationProject',
}

interface ICheckBotConfigurator {
  checkBotConfiguration?: ICheckBotConfigurationWithDescriptions;
  tableItems?: ICheckBotResultWithDescriptions[];
  addedChecks: string[];
  isSavingConfig: boolean;
  resultTypeOptions: CheckbotStatusEnum[];
  isAllChecksAdded: boolean;
  createToast?: ToastMixin;
}

export default Vue.extend({
  name: 'check-bot-configurator',

  components: { MainTable, AddCheckModal },

  mixins: [digitalizationProjectTabNumbersMixin],

  props: {
    mode: {
      type: String as () => CheckBotConfiguratorMode,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
    supplierName: {
      type: String,
      required: false,
    },
  },

  data(): ICheckBotConfigurator {
    return {
      checkBotConfiguration: undefined,
      tableItems: undefined,
      addedChecks: [],
      isSavingConfig: false,
      resultTypeOptions: [
        CheckbotStatusEnum.ok,
        CheckbotStatusEnum.info,
        CheckbotStatusEnum.error,
        CheckbotStatusEnum.waiting,
        CheckbotStatusEnum.warning,
        CheckbotStatusEnum.critical,
      ],
      /**
       * Value initially set to true so that the button stays disabled until we
       * know if there are any checks left to add
       */
      isAllChecksAdded: true,
    };
  },

  computed: {
    tableFields(): ITableField[] {
      return [
        {
          key: ResultColumnName.SEQUENCE,
          label: ResultColumnName.SEQUENCE,
          sortable: true,
        },
        {
          key: ResultConfigReadonlyColumns.TEST_KEY,
          label: ResultConfigReadonlyColumns.TEST_KEY,
          sortable: true,
        },
        {
          key: ResultConfigReadonlyColumns.DETAILS,
          label: ResultConfigReadonlyColumns.DETAILS,
          sortable: true,
        },
        {
          key: ResultConfigReadonlyColumns.DESCRIPTION,
          label: ResultConfigReadonlyColumns.DESCRIPTION,
          sortable: true,
        },
        {
          key: ResultColumnName.RESULT_1,
          label: ResultColumnName.RESULT_1,
          sortable: true,
        },
        {
          key: ResultColumnName.RESULT_TYPE_1,
          label: ResultColumnName.RESULT_TYPE_1,
          sortable: true,
        },
        {
          key: ResultColumnName.RESULT_2,
          label: ResultColumnName.RESULT_2,
          sortable: true,
        },
        {
          key: ResultColumnName.RESULT_TYPE_2,
          label: ResultColumnName.RESULT_TYPE_2,
          sortable: true,
        },
        {
          key: ResultColumnName.RESULT_3,
          label: ResultColumnName.RESULT_3,
          sortable: true,
        },
        {
          key: ResultColumnName.RESULT_TYPE_3,
          label: ResultColumnName.RESULT_TYPE_3,
          sortable: true,
        },
        {
          key: ResultColumnName.REGEX,
          label: ResultColumnName.REGEX,
          sortable: true,
        },
        {
          key: ResultColumnName.LIST,
          label: ResultColumnName.LIST,
          sortable: true,
        },
        {
          key: ResultColumnName.FILTER_MINPAGE,
          label: ResultColumnName.FILTER_MINPAGE,
          sortable: true,
        },
        {
          key: ResultColumnName.FILTER_MAXPAGE,
          label: ResultColumnName.FILTER_MAXPAGE,
          sortable: true,
        },
        {
          key: ResultColumnName.PARAMETER,
          label: ResultColumnName.PARAMETER,
          sortable: true,
        },
      ];
    },
  },

  methods: {
    async getCheckBotConfiguration() {
      try {
        if (this.mode === CheckBotConfiguratorMode.PROJECT) {
          const response: AxiosResponse<ICheckBotConfigurationWithDescriptions> =
            await axios.get(
              endpointsList.checkBot.getCheckBotConfigFile(
                this.projectId,
                this.supplierId,
              ),
            );

          const checkBotConfiguration = response.data;
          this.checkBotConfiguration = checkBotConfiguration;
          this.tableItems = Object.values(checkBotConfiguration);
        } else if (
          this.mode === CheckBotConfiguratorMode.DIGITALIZATION_PROJECT
        ) {
          const response: AxiosResponse<ICheckBotConfigurationWithDescriptions> =
            await axios.get(
              endpointsList.checkBot.getDigitalizationProjectCheckBotConfigFile(
                this.projectId,
              ),
            );

          const checkBotConfiguration = response.data;
          this.checkBotConfiguration = checkBotConfiguration;
          this.tableItems = Object.values(checkBotConfiguration);
        }
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('checkBotConfigurator.errors.couldNotGetCheckBotConfig'),
          'danger',
        );
      }
    },

    async addCheckBotCheck(check: ICheckBotResultWithDescriptions) {
      if (!this.checkBotConfiguration) {
        return;
      }

      const testKey: string = check.TestKey;

      if (this.checkBotConfiguration[testKey]) {
        this.createToast!(
          this.$t('checkBotConfigurator.warnings.checkWasAlreadyAddedShort'),
          this.$t('checkBotConfigurator.warnings.checkWasAlreadyAdded'),
          'warning',
        );

        return;
      }

      this.addedChecks.push(testKey);
      this.tableItems?.push(check);
      this.checkBotConfiguration[testKey] = check;
    },

    async handleSaveConfiguration() {
      this.isSavingConfig = true;
      if (!this.checkBotConfiguration) {
        return;
      }

      try {
        if (this.mode === CheckBotConfiguratorMode.PROJECT) {
          const requestBody: IConfigFileUpdateRequest = {
            configuration: this.checkBotConfiguration,
            projectId: this.projectId,
            supplierId: this.supplierId,
            newChecks: this.addedChecks,
          };

          await axios.post(
            endpointsList.checkBot.saveCheckBotConfigFile,
            requestBody,
          );
        } else if (
          this.mode === CheckBotConfiguratorMode.DIGITALIZATION_PROJECT
        ) {
          const requestBody: IDigitalizationProjectConfigFileUpdateRequest = {
            configuration: this.checkBotConfiguration,
            projectId: this.projectId as any,
            newChecks: this.addedChecks,
          };

          await axios.post(
            endpointsList.checkBot.saveDigitalizationProjectCheckBotConfigFile,
            requestBody,
          );
        }

        this.createToast!(
          this.$t('checkBotConfigurator.checkBotConfigurationSavedShort'),
          this.$t('checkBotConfigurator.checkBotConfigurationSaved'),
          'success',
        );

        setTimeout(() => {
          this.goToPreviousView();
        }, 3000);
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('checkBotConfigurator.errors.couldNotSaveCheckBotConfig'),
          'danger',
        );
      }
      this.isSavingConfig = false;
    },

    handleCancel() {
      this.goToPreviousView();
    },

    goToPreviousView() {
      if (this.mode === CheckBotConfiguratorMode.PROJECT) {
        router.push({
          name: 'projectsSupplierDetailView',
          params: {
            projectId: this.projectId,
            supplierId: this.supplierId,
            tabNumber: '2',
          },
        });
      } else if (
        this.mode === CheckBotConfiguratorMode.DIGITALIZATION_PROJECT
      ) {
        const tabNumber = (
          this as unknown as DigitalizationProjectTabNumbersMixin
        ).getDigitalizationProjectDetailViewTabNumber(
          DigitalizationProjectDetailViewTab.SETTINGS,
        );
        router.push({
          name: 'digitalizationProjectDetails',
          params: {
            id: this.projectId,
            tabNumber,
          },
        });
      }
    },

    setBreadCrumbMetadata() {
      if (this.mode === CheckBotConfiguratorMode.PROJECT) {
        (this.$route.meta ?? {}).breadcrumb = [
          'projectsList',
          'projectDetails',
          'checkBotConfigurator',
        ];
      } else if (
        this.mode === CheckBotConfiguratorMode.DIGITALIZATION_PROJECT
      ) {
        (this.$route.meta ?? {}).breadcrumb = [
          'digitalizationProjectsList',
          'digitalizationProjectDetails',
          'checkBotConfigurator',
        ];
      }
    },

    setCurrentProjectId() {
      if (this.mode === CheckBotConfiguratorMode.PROJECT) {
        this.$store.commit('setCurrentProjectId', this.projectId);
      } else if (
        this.mode === CheckBotConfiguratorMode.DIGITALIZATION_PROJECT
      ) {
        this.$store.commit('setCurrentDigitalizationProjectId', this.projectId);
      }
    },
  },

  created() {
    this.setBreadCrumbMetadata();
    this.setCurrentProjectId();
    this.getCheckBotConfiguration();
  },
});
