/**
 * Sorts an array of strings alphabetically.
 *
 * This function takes an array of strings and returns a new array with the
 * strings sorted in alphabetical order.
 *
 * @param array - The array of strings to sort.
 * @returns A new array of strings sorted in alphabetical order.
 *
 * @example
 * ```typescript
 * const fruits = ['banana', 'apple', 'cherry'];
 * const sortedFruits = sortStringsAlphabetically(fruits);
 * console.log(sortedFruits); // ['apple', 'banana', 'cherry']
 * ```
 */
export function sortStringsAlphabetically(array: string[]): string[] {
  return array.slice().sort((a, b) => a.localeCompare(b));
}
