<template>
  <h2>
    <slot></slot>
  </h2>
</template>

<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    name: 'styled-subheading',
  });
</script>

<style lang="scss" scoped>
  h2 {
    @include subheading;
    margin-bottom: 1.3125rem;
    margin-top: 2.125rem;
  }
</style>
