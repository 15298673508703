import DocuResponsiblesTable from '@/components/DocuResponsiblesTable/DocuResponsiblesTable.vue';
import VfButton from '@/components/VfButton/VfButton.vue';
import axios, { AxiosError, HttpStatusCode } from 'axios';
import { isEqual } from 'lodash';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import '../../../../../node_modules/@vf-dds/vf-dds-vanilla/src/style/component-css/calendar.css';
import endpointsList from '../../helpers/endpointsList';
import {
  IDigitalizationProject,
  IDocuManager,
  IDocuResponsible,
  IProject,
  IUpdateDigitalizationProjectDto,
  Role,
} from 'types';
import { GlobalUtils, IsUserRoleMixin } from '../../mixins/globalUtils';
import { DocuManagersTableMode } from '../DocuManagersTable/DocuManagersTable.script';
import DocuManagersTable from '../DocuManagersTable/DocuManagersTable.vue';
import { DocuResponsiblesTableMode } from '../DocuResponsiblesTable/DocuResponsiblesTable.script';
import { IDateRange } from '../StyledDateRangePicker/StyledDateRangePicker.script';
import StyledDateRangePicker from '../StyledDateRangePicker/StyledDateRangePicker.vue';

export enum ProjectFormMode {
  CREATE_DIGITALIZATION_PROJECT = 'create-digitalization-project',
  CREATE_PROJECT = 'create-project',
  DETAIL_VIEW = 'detail-view',
  DIGITALIZATON_DETAIL_VIEW = 'digitalization-detail-view',
}

interface IProjectForm {
  form: IForm;
  isValidationError: boolean;
  isSubmitError: boolean;
  isSaving: boolean;
  projectStatusList: IProjectStatus[];
  projectLocationsList: IProjectLocation[];
  docuManagersTableMode: DocuManagersTableMode.NEW_PROJECT;
  docuResponsiblesTableMode: DocuResponsiblesTableMode.NEW_PROJECT;
  isUserRole?: IsUserRoleMixin;
  ProjectFormMode: typeof ProjectFormMode;
}

interface IForm {
  projectTitle: IFormField;
  projectId: IFormField;
  projectLocation: IFormField;
  projectStatus: IFormField;
  inspectionDueTime: IFormField;
  projectDescription: IFormField;
  durationStart: IFormField;
  durationEnd: IFormField;
}

interface IFormField {
  value: string;
  isValidated: boolean;
  postedValue: string;
  valueBeforeEdit: string;
}

interface IProjectStatus {
  value: string;
  text: TranslateResult;
}

interface IProjectLocation {
  value: string | null;
  text: string;
}

export interface IEmittedProject {
  projectTitle: string;
  projectNumber: string;
  projectLocation: string;
  projectStatus: string;
  inspectionDueTime: string;
  projectDescription: string;
  projectDuration: {
    durationStart: Date;
    durationEnd: Date;
  };
}

export default Vue.extend({
  name: 'project-form',

  props: {
    projectData: {
      type: Object as () => IProject,
      required: false,
    },
    digitalizationProjectData: {
      type: Object as () => IDigitalizationProject,
      required: false,
    },
    mode: {
      type: String as () => ProjectFormMode,
      required: true,
    },
    addedDocuManagers: {
      type: Array as () => IDocuManager[],
      required: false,
      default: () => [],
    },
    addedDocuResponsibles: {
      type: Array as () => IDocuResponsible[],
      required: false,
      default: () => [],
    },
    isSubmitButtonBusy: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    StyledDateRangePicker,
    DocuManagersTable,
    DocuResponsiblesTable,
    VfButton,
  },

  data(): IProjectForm {
    return {
      form: {
        projectTitle: {
          value: '',
          isValidated: false,
          postedValue: '',
          valueBeforeEdit: '',
        },
        projectId: {
          value: '',
          isValidated: false,
          postedValue: '',
          valueBeforeEdit: '',
        },
        projectLocation: {
          value: '',
          isValidated: true,
          postedValue: '',
          valueBeforeEdit: '',
        },
        projectStatus: {
          value: 'active',
          /** This value is not needed for creating digitalization projects */
          isValidated:
            this.mode === ProjectFormMode.CREATE_DIGITALIZATION_PROJECT,
          postedValue: '',
          valueBeforeEdit: '',
        },
        inspectionDueTime: {
          value: '14',
          /** This value is not needed for digitalization projects */
          isValidated: [
            ProjectFormMode.CREATE_DIGITALIZATION_PROJECT,
            ProjectFormMode.DIGITALIZATON_DETAIL_VIEW,
          ].includes(this.mode),
          postedValue: '',
          valueBeforeEdit: '',
        },
        projectDescription: {
          value: '',
          isValidated: true,
          postedValue: '',
          valueBeforeEdit: '',
        },
        durationStart: {
          value: '',
          isValidated: false,
          postedValue: '',
          valueBeforeEdit: '',
        },
        durationEnd: {
          value: '',
          isValidated: false,
          postedValue: '',
          valueBeforeEdit: '',
        },
      },
      isValidationError: false,
      isSubmitError: false,
      isSaving: false,

      projectStatusList: [
        {
          value: 'active',
          text: this.$i18n.t('projectForm.projectStatus.active'),
        },
      ],
      projectLocationsList: [],
      docuManagersTableMode: DocuManagersTableMode.NEW_PROJECT,
      docuResponsiblesTableMode: DocuResponsiblesTableMode.NEW_PROJECT,
      ProjectFormMode,
    };
  },

  computed: {
    isChanges(): boolean {
      return Object.values(this.form).some(
        (field: any) => !isEqual(field.valueBeforeEdit, field.value),
      );
    },
  },

  methods: {
    invalidFeedback(field: keyof IForm) {
      if (this.isValidationError && !this.form[field].value.toString().length) {
        return this.$i18n.t('suppliersForm.validationErrorEmpty');
      } else if (this.form[field].value.length > 256) {
        return this.$i18n.t('suppliersForm.validationErrorToLong');
      } else return '';
    },

    validationState(field: keyof IForm) {
      if (
        this.form[field].value.toString().length > 0 &&
        this.form[field].value.toString().length <= 256
      ) {
        this.form[field].isValidated = true;
      } else {
        this.form[field].isValidated = false;
      }
      return this.form[field].isValidated;
    },

    invalidFeedbackProjectNumber() {
      if (
        this.isValidationError &&
        !this.form.projectId.value.toString().length
      ) {
        return this.$i18n.t('suppliersForm.validationErrorEmpty');
      } else if (this.form.projectId.value.length > 256) {
        return this.$i18n.t('suppliersForm.validationErrorToLong');
      } else if (!this.form.projectId.value.match(/^[a-zA-Z0-9_-]*$/)) {
        return this.$i18n.t('projectForm.projectNumberWrongSignes');
      } else return '';
    },

    validationStateProjectNumber() {
      if (
        this.form.projectId.value.toString().length > 0 &&
        this.form.projectId.value.toString().length <= 256 &&
        this.form.projectId.value.match(/^[a-zA-Z0-9_-]*$/)
      ) {
        this.form.projectId.isValidated = true;
      } else {
        this.form.projectId.isValidated = false;
      }
      return this.form.projectId.isValidated;
    },

    invalidFeedbackProjectTitle() {
      if (
        this.isValidationError &&
        !this.form.projectTitle.value.toString().length
      ) {
        return this.$i18n.t('suppliersForm.validationErrorEmpty');
      } else if (this.form.projectTitle.value.length > 256) {
        return this.$i18n.t('suppliersForm.validationErrorToLong');
      } else if (!this.form.projectTitle.value.match(/^[a-zA-Z0-9_-]*$/)) {
        return this.$i18n.t('projectForm.projectTitleWrongSignes');
      } else return '';
    },

    validationStateProjectTitle() {
      if (
        this.form.projectTitle.value.toString().length > 0 &&
        this.form.projectTitle.value.toString().length <= 256 &&
        this.form.projectTitle.value.match(/^[a-zA-Z0-9_-\s]*$/)
      ) {
        this.form.projectTitle.isValidated = true;
      } else {
        this.form.projectTitle.isValidated = false;
      }
      return this.form.projectTitle.isValidated;
    },

    invalidFeedbackDate() {
      if (
        !this.form.durationEnd.isValidated &&
        !this.form.durationStart.isValidated
      ) {
        return this.$i18n.t('projectForm.durationStartLaterThanEnd');
      } else return '';
    },

    validationStateDate() {
      if (
        this.form.durationStart.value &&
        this.form.durationEnd.value &&
        new Date(this.form.durationStart.value).getTime() >
          new Date(this.form.durationEnd.value).getTime()
      ) {
        this.form.durationStart.isValidated = false;
        this.form.durationEnd.isValidated = false;
        return false;
      } else {
        this.form.durationStart.isValidated = true;
        this.form.durationEnd.isValidated = true;
        return true;
      }
    },

    checkIsValidated() {
      if (
        Object.values(this.form).every(
          (element: IFormField) => element.isValidated === true,
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    transformProjectToDBFormat(field: keyof IFormField) {
      return {
        projectTitle: this.form.projectTitle[field],
        projectNumber: this.form.projectId[field],
        projectLocation: this.form.projectLocation[field],
        projectStatus: this.form.projectStatus[field],
        inspectionDueTime: this.form.inspectionDueTime[field],
        projectDescription: this.form.projectDescription[field],
        projectDuration: {
          durationStart: this.form.durationStart[field],
          durationEnd: this.form.durationEnd[field],
        },
      };
    },

    transformDigitalizationProjectToDBFormat(
      field: keyof IFormField,
    ): IUpdateDigitalizationProjectDto {
      return {
        title: this.form.projectTitle[field] as string,
        number: this.form.projectId[field] as string,
        site: this.form.projectLocation[field] as string,
        status: this.form.projectStatus[field] as any,
        description: this.form.projectDescription[field] as string,
        duration: {
          durationStart: this.form.durationStart[field] as any,
          durationEnd: this.form.durationEnd[field] as any,
        },
      };
    },

    assignFromDBFormatToProject(data: IProject, field: keyof IFormField) {
      (this.form.projectTitle[field] as any) = data.projectTitle;
      (this.form.projectId[field] as any) = data.projectNumber;
      (this.form.projectLocation[field] as any) = data.projectLocation;
      (this.form.projectStatus[field] as any) = data.projectStatus;
      (this.form.inspectionDueTime[field] as any) = data.inspectionDueTime;
      (this.form.projectDescription[field] as any) = data.projectDescription;
      (this.form.durationStart[field] as any) =
        data.projectDuration.durationStart;
      (this.form.durationEnd[field] as any) = data.projectDuration.durationEnd;
    },

    assignFromDBFormatToDigitalizationProject(
      data: IDigitalizationProject,
      field: keyof IFormField,
    ) {
      (this.form.projectTitle[field] as any) = data.title;
      (this.form.projectId[field] as any) = data.number;
      (this.form.projectLocation[field] as any) = data.site;
      (this.form.projectStatus[field] as any) = data.status;
      (this.form.projectDescription[field] as any) = data.description;
      (this.form.durationStart[field] as any) = data.duration?.durationStart;
      (this.form.durationEnd[field] as any) = data.duration?.durationEnd;
    },

    assignProjectValuesToOtherField(
      from: keyof IFormField,
      to: keyof IFormField,
    ) {
      (this.form.projectTitle[to] as any) = this.form.projectTitle[from];
      (this.form.projectId[to] as any) = this.form.projectId[from];
      (this.form.projectLocation[to] as any) = this.form.projectLocation[from];
      (this.form.projectStatus[to] as any) = this.form.projectStatus[from];
      (this.form.inspectionDueTime[to] as any) =
        this.form.inspectionDueTime[from];
      (this.form.projectDescription[to] as any) =
        this.form.projectDescription[from];
      (this.form.durationStart[to] as any) = this.form.durationStart[from];
      (this.form.durationEnd[to] as any) = this.form.durationEnd[from];
    },

    handleSubmit() {
      if (this.checkIsValidated()) {
        this.$emit('project-data', this.transformProjectToDBFormat('value'));
      } else {
        this.isValidationError = true;
      }
    },

    async handleSave() {
      if (!this.checkIsValidated()) {
        this.isValidationError = true;
        return;
      }

      try {
        this.isSaving = true;
        if (this.mode === ProjectFormMode.DETAIL_VIEW) {
          await axios.patch(
            endpointsList.projects.updateProject(
              this.projectData._id.toString(),
            ),
            this.transformProjectToDBFormat('value'),
          );
        } else if (this.mode === ProjectFormMode.DIGITALIZATON_DETAIL_VIEW) {
          await axios.put(
            endpointsList.digitalizationProjects.updateDigitalizationProject(
              this.digitalizationProjectData._id.toString(),
            ),
            this.transformDigitalizationProjectToDBFormat('value'),
          );
        }

        this.emitUpdateProjectDataEvent();

        (this as unknown as GlobalUtils).createToast(
          this.$t('projectForm.changesSaved'),
          this.$t('projectForm.changesSaved'),
          'success',
        );

        this.cleanAfterEdit();
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === HttpStatusCode.Conflict) {
            (this as unknown as GlobalUtils).createToast(
              this.$t('projectForm.errors.wrongProjectNumber'),
              this.$t('projectForm.errors.projectNumberNotUniqueForSite'),
              'danger',
            );
          }
        }
      } finally {
        this.isSaving = false;
      }
    },

    handleCancel() {
      this.assignProjectValuesToOtherField('valueBeforeEdit', 'value');
      this.cleanAfterEdit();
    },

    cleanForm() {
      for (const element in this.form) {
        this.form[element as keyof IForm] = {
          ...this.form[element as keyof IForm],
          value: '',
          postedValue: '',
          isValidated: false,
        };
        if (element === 'projectLocation' || element === 'projectDescription') {
          this.form[element].isValidated = true;
        }
      }
      this.isValidationError = false;
      this.isSubmitError = false;
    },

    cleanAfterEdit() {
      this.isSaving = false;
      this.assignProjectValuesToOtherField('value', 'valueBeforeEdit');
    },

    getProjectLocations() {
      axios
        .get(endpointsList.projects.getPossibleProjectLocations)
        .then((response) => {
          const locations: IProjectLocation[] = [];

          for (const location in response.data) {
            locations.push({
              value: location,
              text: `${location} - ${response.data[location].title}`,
            });
          }

          locations.sort((a: IProjectLocation, b: IProjectLocation) => {
            const textA = a.text.toUpperCase();
            const textB = b.text.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });

          this.projectLocationsList = locations;
        });
    },

    setDateRange(dateRange: IDateRange) {
      if (!dateRange.startDate || !dateRange.endDate) {
        return;
      }
      this.form.durationStart.value = dateRange.startDate as any;
      this.form.durationEnd.value = dateRange.endDate as any;
    },

    toggleAddDocuManager(oid: string) {
      this.$emit('toggle-add-docu-manager', oid);
    },

    toggleAddDocuResponsible(oid: string) {
      this.$emit('toggle-add-docu-responsible', oid);
    },

    emitUpdateProjectDataEvent() {
      this.$emit('update-project-data');
    },

    loadDataToForm() {
      if (this.mode === ProjectFormMode.DETAIL_VIEW) {
        this.assignFromDBFormatToProject(this.projectData, 'value');
        this.assignFromDBFormatToProject(this.projectData, 'valueBeforeEdit');
      }

      if (this.mode === ProjectFormMode.DIGITALIZATON_DETAIL_VIEW) {
        this.assignFromDBFormatToDigitalizationProject(
          this.digitalizationProjectData,
          'value',
        );
        this.assignFromDBFormatToDigitalizationProject(
          this.digitalizationProjectData,
          'valueBeforeEdit',
        );
      }

      (this.$refs.firstInput as HTMLElement).focus();
    },

    isFormViewOnly() {
      const editProjectAllowedRoles = [Role.Admin];

      if (this.mode === ProjectFormMode.DETAIL_VIEW) {
        editProjectAllowedRoles.push(Role.DocuManager);
      } else if (this.mode === ProjectFormMode.DIGITALIZATON_DETAIL_VIEW) {
        editProjectAllowedRoles.push(Role.DocuResponsible);
      }

      return !(this as unknown as GlobalUtils).isUserRole(
        editProjectAllowedRoles,
      );
    },

    isReadOnlyInput(): boolean {
      return [
        ProjectFormMode.DETAIL_VIEW,
        ProjectFormMode.DIGITALIZATON_DETAIL_VIEW,
      ].includes(this.mode);
    },
  },

  mounted() {
    this.getProjectLocations();
    this.loadDataToForm();
  },

  watch: {
    projectData() {
      this.loadDataToForm();
    },
    digitalizationProjectData() {
      this.loadDataToForm();
    },
  },
});
