import ChangeInspectionDueDateModal from '@/components/ChangeInspectionDueDateModal/ChangeInspectionDueDateModal.vue';
import filterableTableHelpersMixin, {
  IFilterableTableHelpersMixin,
} from '@/components/FilterableTable/filterableTableHelpersMixin';
import { ITableField } from '@/frontendInterfaces';
import { TableClass, TableFieldsBuilder } from '@/helpers';
import axios, { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import {
  CheckbotStatusEnum,
  DocumentDetailViewMode,
  FormalControlStatus,
  IFilesListFile,
  IFilesQueryRequest,
  IFileTableData,
  IInspectorStatusAssignmentResponseDto,
  InspectorStatusEnum,
  IPaginatedFileTableDataResponse,
  ISetMultipleInspectorStatusesDto,
  RecordLibraryTransferStatus,
  Role,
  Serialized,
  SharePointUploadStatus,
  SupplierDocumentStatus,
} from 'types';
import { isDefined } from 'utils';
import Vue from 'vue';
import FilterableTable from '../../components/FilterableTable/FilterableTable.vue';
import endpointsList from '../../helpers/endpointsList';
import { GlobalUtils, IsUserRoleMixin } from '../../mixins/globalUtils';
import { router } from '../../vue';
import {
  FilterType,
  IFilterConfiguration,
  IFiltering,
  Mode,
  SelectOptions,
} from '../FilterableTable/FilterableTable.script';
import InspectorConfirmationModal from '../InspectorConfirmationModal/InspectorConfirmationModal.vue';
import MetadataModal from '../MetadataModal/MetadataModal.vue';
import { getFileQuery } from './helpers/getFileQuery';
import { getFileQueryItem } from './helpers/getFileQueryItem';

interface IFilesOverviewTable {
  files: IFileTableData[] | null;
  selectedFiles: { [documentId: string]: boolean };
  statusChanges: IStatusChanges | Record<string, never>;
  isChangingInspectorStatus: boolean;
  isUserRole?: IsUserRoleMixin;
  sortBy: keyof IFilesQueryRequest;
  initialSortBy?: keyof IFilesQueryRequest;
  sortDesc: boolean;
  initialSortDesc?: boolean;
  page: number;
  perPage: number;
  inspectionAllowedRoles: [Role.Admin, Role.Inspector];
  changeInspectionDueDateAllowedRoles: [
    Role.Admin,
    Role.Inspector,
    Role.DocuManager,
  ];
  waitingInspectorStatus: InspectorStatusEnum.waiting;
  waitingCheckBotStatus: CheckbotStatusEnum;
  tableMode: Mode.OUTSOURCED;
  tableRows?: number;
  filtering?: Partial<IFilesQueryRequest>;
  isLoadingFiles: boolean;
  selectOptions?: SelectOptions<IFileTableData>;
  prefiltering?: IFiltering;
  inspectorStatusChangeResults?: IInspectorStatusAssignmentResponseDto;
}

interface IStatusChanges {
  files: IStatusChangeFile[];
  statusChangeType: InspectorStatusEnum;
}

interface IStatusChangeFile {
  _id: string;
  documentId: string;
  supplierId: string;
  uploadId: string;
}

export type Filtering = {
  [key in keyof IFileTableData]?: any;
};

export type FilesOverviewTableFilterConfig = {
  documentId?: string;
  referenceNumber?: string;
  revision?: string;
  supplierName?: string;
  contentType?: string;
  coworker?: string;
  formalControlStatus?: FormalControlStatus;
  inspectorStatus?: InspectorStatusEnum;
  recordLibraryTransferStatus?: RecordLibraryTransferStatus;
};

export type FilesOverviewTableSortConfig = {
  sortBy: keyof IFilesQueryRequest;
  sortDesc: boolean;
};

export type FilesOverviewTableConfig = {
  filters?: FilesOverviewTableFilterConfig;
  sorting?: FilesOverviewTableSortConfig;
};

const tableWidthsManagerVariant = [2, 9, 8, 8, 14, 13, 11, 9, 12, 12];
const tableWidthsCoworkerVariant = [20, 8, 8, 14, 11, 10, 12, 15];

export default Vue.extend({
  name: 'files-overview-table',
  components: {
    MetadataModal,
    InspectorConfirmationModal,
    FilterableTable,
    ChangeInspectionDueDateModal,
  },
  mixins: [filterableTableHelpersMixin],
  props: {
    projectId: {
      type: String,
      required: true,
    },
  },
  data(): IFilesOverviewTable {
    return {
      files: null,
      sortBy: 'uploadDate',
      initialSortBy: undefined,
      sortDesc: true,
      initialSortDesc: undefined,
      page: 1,
      perPage: 10,
      selectedFiles: {},
      statusChanges: {},
      isChangingInspectorStatus: false,
      inspectionAllowedRoles: [Role.Admin, Role.Inspector],
      changeInspectionDueDateAllowedRoles: [
        Role.Admin,
        Role.Inspector,
        Role.DocuManager,
      ],
      waitingInspectorStatus: InspectorStatusEnum.waiting,
      waitingCheckBotStatus: CheckbotStatusEnum.waiting,
      tableMode: Mode.OUTSOURCED,
      tableRows: undefined,
      filtering: undefined,
      isLoadingFiles: false,
      selectOptions: undefined,
      prefiltering: undefined,
      inspectorStatusChangeResults: undefined,
    };
  },

  computed: {
    tableFields(): ITableField<IFileTableData>[] {
      const tableFieldsBuilder = new TableFieldsBuilder<IFileTableData>({
        translationKeyPrefix: 'projectDetails.documents',
        defaults: {
          sortable: true,
          thClass: [TableClass.ALIGN_MIDDLE],
          tdClass: [TableClass.ALIGN_MIDDLE, TableClass.BREAK_ALL_WORDS],
        },
      })
        .addField('documentId', {
          simpleLabel: '#',
        })
        .addField('referenceNumber')
        .addField('revision')
        .addField('contentType')
        .addField('uploadDate')
        .addField('inspectionDueDate')
        .addField('coworker')
        .addField('documentProcessingStatus', { sortable: false });

      // Admin/Docu Manager/Inspector variant
      if (this.isUserRole!([Role.Admin, Role.Inspector, Role.DocuManager])) {
        tableFieldsBuilder
          .addField('supplierName', {
            simpleLabel: this.$tc('projectDetails.documents.supplierName', 1),
            index: 3,
          })
          .addField('select' as any, {
            simpleLabel: '',
            index: 0,
            sortable: false,
          })
          .addWidths(tableWidthsManagerVariant, true);

        return tableFieldsBuilder.build();
      }

      // Coworker variant
      tableFieldsBuilder.addWidths(tableWidthsCoworkerVariant, true);

      return tableFieldsBuilder.build();
    },

    tableFilterConfiguration(): IFilterConfiguration {
      if (this.isUserRole!([Role.Admin, Role.Inspector, Role.DocuManager])) {
        return {
          documentId: {
            column: 2,
            width: '130px',
            type: FilterType.INPUT,
            simpleLabel: true,
          },
          referenceNumber: {
            column: 3,
            width: '120px',
            type: FilterType.INPUT,
            simpleLabel: true,
          },
          revision: {
            column: 4,
            width: '70px',
            type: FilterType.INPUT,
            simpleLabel: true,
          },
          supplierName: {
            column: 5,
            width: '180px',
            type: FilterType.INPUT,
            customLabel: this.$tc('projectDetails.documents.supplierName', 1),
          },
          contentType: {
            column: 6,
            width: '140px',
            type: FilterType.SELECT,
            simpleLabel: true,
          },
          coworker: {
            column: 9,
            width: '120px',
            type: FilterType.INPUT,
            simpleLabel: true,
          },
          documentProcessingStatus: {
            column: 10,
            width: '110px',
            type: FilterType.SELECT,
            simpleLabel: true,
            translateOptions: true,
          },
        };
      }
      return {
        documentId: {
          column: 1,
          width: '130px',
          type: FilterType.INPUT,
          simpleLabel: true,
        },
        referenceNumber: {
          column: 2,
          width: '70px',
          type: FilterType.INPUT,
          simpleLabel: true,
        },
        revision: {
          column: 3,
          width: '70px',
          type: FilterType.INPUT,
          simpleLabel: true,
        },
        contentType: {
          column: 4,
          width: '140px',
          type: FilterType.SELECT,
          simpleLabel: true,
        },
        coworker: {
          column: 7,
          width: '120px',
          type: FilterType.INPUT,
          simpleLabel: true,
        },
        documentProcessingStatus: {
          column: 8,
          width: '110px',
          type: FilterType.SELECT,
          simpleLabel: true,
          translateOptions: true,
        },
      };
    },

    tableFilterGridConfiguration() {
      if (this.isUserRole!([Role.Admin, Role.DocuManager, Role.Inspector])) {
        return [...tableWidthsManagerVariant, 2].map((width) => `${width}%`);
      } else {
        return [...tableWidthsCoworkerVariant, 2].map((width) => `${width}%`);
      }
    },
  },

  methods: {
    async getFiles() {
      this.isLoadingFiles = true;
      try {
        const response: AxiosResponse<IPaginatedFileTableDataResponse> =
          await axios.get(
            endpointsList.documentsUploads.getFilesForProject(
              this.projectId,
              this.page,
              this.perPage,
              this.sortBy,
              this.sortDesc ? -1 : 1,
              this.filtering,
            ),
          );

        const filesAndCount = response.data;

        this.files = filesAndCount.files;
        this.tableRows = filesAndCount.resultsCount;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('projectDetails.documents.errors.couldNotLoadDocuments'),
          'danger',
        );
      }
      this.isLoadingFiles = false;
    },

    // Adding empty function to the Vue instance in order
    // to later assign them in the created hook preserving
    // the this context
    debouncedGetFiles: new Function(),

    async handlePerPageChange(perPage: number) {
      this.perPage = perPage;
      this.debouncedGetFiles();
    },

    async handlePageNumberChange(pageNumber: number) {
      this.page = pageNumber;
      this.debouncedGetFiles();
      /** Deselecting all documents on page change */
      this.toggleAllDocumentsSelection(false);
    },

    async handleFilteringChange(filtering: Filtering) {
      const transformedFiltering = getFileQuery(filtering);

      this.filtering = transformedFiltering;
      this.debouncedGetFiles();
    },

    async handleSortDescChanged(sortDesc: boolean) {
      this.sortDesc = sortDesc;
      this.debouncedGetFiles();
    },

    async handleSortByChanged(sortBy: keyof IFileTableData) {
      const sortByQueryFormat = getFileQueryItem(sortBy);
      this.sortBy = sortByQueryFormat;
      this.debouncedGetFiles();
    },

    getFormalControlStatusOfFile(file: IFilesListFile): string {
      if (file.sharePointUploadStatus === SharePointUploadStatus.FAILED) {
        return '-';
      }
      if (file.formalControlStatus) {
        return this.$t(
          `formalControlStatus.${file.formalControlStatus}`,
        ).toString();
      }
      return this.$t('formalControlStatus.waiting').toString();
    },

    goToDocumentDetailView(row: any) {
      this.saveFilterConfig();

      const { documentId, supplierId, uploadId } = row.item;
      router.push({
        name: 'documentDetailView',
        params: {
          mode: DocumentDetailViewMode.PROJECT,
          documentId,
          projectId: this.projectId,
          supplierId,
          uploadId,
        },
      });
    },

    async getSelectOptions() {
      const contentTypeNamesResponse: AxiosResponse<string[]> = await axios.get(
        endpointsList.contentTypeMappings.getContentTypeNamesForProject(
          this.projectId,
        ),
      );
      const contentTypeNames = contentTypeNamesResponse.data;

      const selectOptions: SelectOptions = {
        documentProcessingStatus: (
          this as unknown as IFilterableTableHelpersMixin
        ).getSelectOptionsFromEnum(
          SupplierDocumentStatus,
          'documentProcessingStatus',
        ),
        contentType: (
          this as unknown as IFilterableTableHelpersMixin
        ).getSelectOptionsFromValues(contentTypeNames),
      };

      for (const column in selectOptions) {
        const columnOptions = selectOptions[column];
        columnOptions?.push({
          text: this.$i18n.t('filterableTable.all'),
          value: null,
        });
      }

      this.selectOptions = selectOptions;
    },

    setFileSelectStatus(documentId: string, status: boolean) {
      Vue.set(this.selectedFiles, documentId, status);
    },

    isFileSelected(documentId: string) {
      return !!this.selectedFiles[documentId];
    },

    handleStatusChangeConfirmation(statusChangeType: InspectorStatusEnum) {
      const files: IStatusChangeFile[] = [];

      for (const documentId in this.selectedFiles) {
        if (this.selectedFiles[documentId] && this.files) {
          const file = this.files.find(
            (file) => file.documentId === documentId,
          )!;

          const { _id, uploadId, supplierId } = file;

          files.push({
            _id: _id.toString(),
            documentId,
            uploadId: uploadId.toString(),
            supplierId: supplierId.toString(),
          });
        }
      }

      this.statusChanges = {
        statusChangeType,
        files,
      };
    },

    async addInspectorStatus() {
      try {
        this.isChangingInspectorStatus = true;
        const requestBody: Serialized<ISetMultipleInspectorStatusesDto> = {
          fileIds: this.statusChanges.files.map((file) => file._id),
          inspectorStatus: this.statusChanges.statusChangeType,
        };
        const response =
          await axios.patch<IInspectorStatusAssignmentResponseDto>(
            endpointsList.documentsUploads.assignInspectorStatusToMultipleFiles,
            requestBody,
          );

        this.inspectorStatusChangeResults = response.data;
      } catch (err) {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      } finally {
        this.isChangingInspectorStatus = false;
        this.statusChanges = {};
        this.selectedFiles = {};
        this.getFiles();
      }
    },

    clearInspectorStatusChangeResults(): void {
      this.inspectorStatusChangeResults = undefined;
    },

    toggleAllDocumentsSelection(isSelected: boolean) {
      for (const file of this.files ?? []) {
        this.setFileSelectStatus(file.documentId, isSelected);
      }
    },

    areAllDocumentsSelected(): boolean {
      if (!this.files) return false;
      else
        return this.files.every((file) => this.selectedFiles[file.documentId]);
    },

    getIdsOfSelectedFiles(): string[] {
      if (!this.files) return [];

      return Object.entries(this.selectedFiles)
        .filter(([_documentId, isSelected]) => isSelected)
        .map(([documentId]) =>
          (this.files ?? [])
            .find((file) => file.documentId === documentId)
            ?._id.toString(),
        )
        .filter(isDefined);
    },

    handleInspectionDueDatesUpdated() {
      this.$bvModal.hide('change-inspection-due-date-modal');
      this.selectedFiles = {};
      this.getFiles();
    },

    isInspectionForSelectedDocumentsAllowed(): boolean {
      return Object.entries(this.selectedFiles)
        .filter(([_documentId, isSelected]) => isSelected)
        .map(([documentId]) =>
          this.files?.find((file) => file.documentId === documentId),
        )
        .every((file) => file?.inspectorStatus === InspectorStatusEnum.waiting);
    },

    getRowClass(file: IFilesListFile | undefined): string {
      if (file) {
        if (file.sharePointUploadStatus === SharePointUploadStatus.FAILED) {
          return 'danger-row';
        }
        if (this.selectedFiles[file?.documentId]) {
          return 'selected-row';
        }
      }

      return 'row-class-clickable';
    },

    saveFilterConfig() {
      const config: FilesOverviewTableConfig = {
        filters: this.filtering,
        sorting: {
          sortBy: this.sortBy,
          sortDesc: this.sortDesc,
        },
      };
      this.$store.commit('setFilesOverviewTableConfig', config);
    },

    getTableConfig(): FilesOverviewTableConfig | undefined {
      return this.$store.state.filesOverviewTableConfig;
    },

    applyTableConfig(): void {
      const config = this.getTableConfig();
      this.applyFilterConfig(config);
      this.applySortConfig(config);
    },

    applyFilterConfig(config: FilesOverviewTableConfig | undefined) {
      this.prefiltering = config?.filters;
    },

    async applySortConfig(config: FilesOverviewTableConfig | undefined) {
      this.initialSortBy = config?.sorting?.sortBy ?? 'uploadDate';
      this.initialSortDesc = config?.sorting?.sortDesc ?? true;
    },
  },

  mounted() {
    if (!this.projectId) {
      return;
    }

    this.debouncedGetFiles = debounce(this.getFiles, 200);

    this.debouncedGetFiles();
    this.getSelectOptions();
    this.applyTableConfig();
  },
});
