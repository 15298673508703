import endpointsList from '@/helpers/endpointsList';
import digitalizationProjectTabNumbersMixin, {
  DigitalizationProjectDetailViewTab,
  DigitalizationProjectTabNumbersMixin,
} from '@/views/DigitalizationProjectDetails/digitalizationProjectTabNumbersMixin';
import projectTabNumbersMixin, {
  ProjectDetailViewTab,
  ProjectTabNumbersMixin,
} from '@/views/ProjectDetails/projectTabNumbersMixin';
import axios, { AxiosResponse } from 'axios';
import { DocumentDetailViewMode } from 'types';
import Vue from 'vue';

interface IBreadCrumb {
  breadCrumbNames: { [path: string]: string };
  rerenderKey: number;
}

export default Vue.extend({
  name: 'bread-crumb',
  components: {},
  mixins: [digitalizationProjectTabNumbersMixin, projectTabNumbersMixin],
  props: {},
  data(): IBreadCrumb {
    return {
      breadCrumbNames: {},
      rerenderKey: 0,
    };
  },
  methods: {
    async getBreadcrumbNames() {
      const routeName = this.$route.name;

      if (routeName === 'projectDetails') {
        const projectId = this.$route.params.id;
        const projectName: string = await this.getProjectName(projectId);

        this.breadCrumbNames[routeName] = projectName;
        this.rerenderKey++;
      }

      if (routeName === 'supplierDetails') {
        const supplierId = this.$route.params.id;
        const supplierName: string = await this.getSupplierNameById(supplierId);

        this.breadCrumbNames[routeName] = supplierName;

        this.rerenderKey++;
      }

      if (routeName === 'documentDetailView') {
        const documentDetailViewMode = this.$route.params
          .mode as DocumentDetailViewMode;
        const projectId = this.$route.params.projectId;
        const supplierId = this.$route.params.supplierId;
        const documentId = this.$route.params.documentId;

        if (documentDetailViewMode === DocumentDetailViewMode.PROJECT) {
          const projectName = await this.getProjectName(projectId);
          const fileName = await this.getDocumentTitle(
            projectId,
            supplierId,
            documentId,
          );

          this.breadCrumbNames['uploadReferenceNumber'] = `${this.$t(
            'breadCrumb.uploadReferenceNumber',
          )} ${this.$store.state.currentUploadReferenceNumber}`;
          this.breadCrumbNames[routeName] = fileName;
          this.breadCrumbNames['projectDetails'] = projectName;
        } else if (
          documentDetailViewMode ===
          DocumentDetailViewMode.DIGITALIZATION_PROJECT
        ) {
          const digitalizationProjectName =
            await this.getDigitalizationProjectName(projectId);
          const documentName = await this.getDigitalizationDocumentTitle(
            projectId,
            documentId,
          );

          this.breadCrumbNames['digitalizationUploadReferenceNumber'] =
            `${this.$t('breadCrumb.digitalizationUploadReferenceNumber')} ${
              this.$store.state.currentDigitalizationUploadReferenceNumber
            }`;
          this.breadCrumbNames[routeName] = documentName;
          this.breadCrumbNames['digitalizationProjectDetails'] =
            digitalizationProjectName;
        }

        this.rerenderKey++;
      }

      if (routeName === 'projectsSupplierDetailView') {
        const supplierId = this.$route.params.supplierId;
        const supplierName: string = await this.getSupplierNameById(supplierId);

        this.breadCrumbNames[routeName] = supplierName;

        if (!this.$store.state.currentProjectId) {
          const projectId = this.$route.params.projectId;
          this.$store.commit('setCurrentProjectId', projectId);
          this.$store.commit('setProjectDetailViewTabNumber', 1);
        }
      }

      if (routeName === 'digitalizationProjectDetails') {
        const digitalizationProjectId = this.$route.params.id;
        const digitalizationProjectName: string =
          await this.getDigitalizationProjectName(digitalizationProjectId);

        this.breadCrumbNames[routeName] = digitalizationProjectName;
        this.rerenderKey++;
      }

      this.rerenderKey++;
    },

    async getProjectName(projectId: string): Promise<string> {
      const response: AxiosResponse<string> = await axios.get(
        endpointsList.projects.getProjectNameById(projectId),
      );

      return response.data;
    },

    async getDigitalizationProjectName(
      digitalizationProjectId: string,
    ): Promise<string> {
      const response: AxiosResponse<string> = await axios.get(
        endpointsList.digitalizationProjects.getDigitalizationProjectTitle(
          digitalizationProjectId,
        ),
      );

      return response.data;
    },

    async getSupplierNameById(supplierId: string): Promise<string> {
      const response: AxiosResponse<string> = await axios.get(
        endpointsList.suppliers.getSupplierNameById(supplierId),
      );

      return response.data;
    },

    async getDocumentTitle(
      projectId: string,
      supplierId: string,
      documentId: string,
    ): Promise<string> {
      const response: AxiosResponse = await axios.get(
        endpointsList.documentsUploads.getDocumentTitle(
          projectId,
          supplierId,
          documentId,
        ),
      );

      return response.data;
    },

    async getDigitalizationDocumentTitle(
      digitalizationProjectId: string,
      documentId: string,
    ): Promise<string> {
      const response = await axios.get(
        endpointsList.digitalizationUploads.getDocumentTitle(
          digitalizationProjectId,
          documentId,
        ),
      );

      return response.data;
    },

    getDocumentsOverviewTabNumber() {
      return (
        this as unknown as ProjectTabNumbersMixin
      ).getProjectDetailViewTabNumber(ProjectDetailViewTab.DOCUMENTS);
    },

    getDigitalizationDocumentsOverviewTabNumber() {
      return (
        this as unknown as DigitalizationProjectTabNumbersMixin
      ).getDigitalizationProjectDetailViewTabNumber(
        DigitalizationProjectDetailViewTab.DOCUMENTS,
      );
    },
  },

  created() {
    this.$nextTick(() => this.getBreadcrumbNames());
  },

  watch: {
    '$route.name'() {
      this.$nextTick(() => this.getBreadcrumbNames());
    },
  },
});
