import { DEFAULT_METADATA_VALUES_SEPARATOR, MetadataCheckResult } from 'types';
import Vue from 'vue';
import MainTable from '@/components/MainTable/MainTable.vue';
import { ITableField } from '@/frontendInterfaces';
import { TableFieldsBuilder, truncateValue } from '@/helpers';
import { ITableRow } from '@/interfaces';

export default Vue.extend({
  name: 'metadata-check-results-table',

  components: { MainTable },

  props: {
    metadataCheckResults: {
      type: [Array as () => MetadataCheckResult[], () => undefined],
      required: true,
      default: () => [],
    },
  },

  data(): {
    truncations: Record<string, boolean>;
  } {
    return {
      truncations: {},
    };
  },

  computed: {
    fields(): ITableField[] {
      return new TableFieldsBuilder<MetadataCheckResult>({
        translationKeyPrefix: 'metadataCheckResultsTable',
      })
        .addField('field', { width: '15%' })
        .addField('value', { width: '50%', tdClass: ['value-row'] })
        .addField('accepted', { width: '15%' })
        .addField('errorMessage' as keyof MetadataCheckResult, { width: '20%' })
        .build();
    },
  },
  methods: {
    metadataValue(value: string): string {
      /**
       * If multiple values are being passed to a taxonomy field, they are
       * being separated with a semicolon.
       */
      if (value.includes(DEFAULT_METADATA_VALUES_SEPARATOR)) {
        return value
          .split(';')
          .map((value) => value.split('|')[0])
          .join(', ');
      } else {
        return value.split('|')[0];
      }
    },
    toggleTruncation(row: ITableRow<MetadataCheckResult>): void {
      this.$set(
        this.truncations,
        row.item.field,
        !this.truncations[row.item.field],
      );
    },
    truncateValue,
  },
  watch: {
    metadataCheckResults() {
      const fields = this.metadataCheckResults?.map((result) => result.field);
      this.truncations =
        fields?.reduce((acc, field) => ({ ...acc, [field]: true }), {}) ?? {};
    },
  },
});
