import StyledSubheading from '@/components/StyledSubheading/StyledSubheading.vue';
import TitlePanel from '@/components/TitlePanel/TitlePanel.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeftLong,
  faArrowUpRightFromSquare,
  faBars,
  faCheck,
  faChevronDown,
  faChevronRight,
  faCircleInfo,
  faDownload,
  faFile,
  faFileArrowDown,
  faFolderOpen,
  faHouse,
  faMagnifyingGlass,
  faPenToSquare,
  faPlus,
  faRightFromBracket,
  faToolbox,
  faTrash,
  faUpload,
  faUser,
  faUsers,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueMarkdownEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import App from './App.vue';
import VfButton from './components/VfButton/VfButton.vue';
import messages from './localization/root';
import globalUtils from './mixins/globalUtils';
import routes from './routes';
import store from './store';
import deDE from '@kangc/v-md-editor/lib/lang/de-DE';

library.add(
  faHouse,
  faBars,
  faFolderOpen,
  faUsers,
  faUser,
  faRightFromBracket,
  faChevronRight,
  faChevronDown,
  faPlus,
  faCircleInfo,
  faArrowLeftLong,
  faToolbox,
  faMagnifyingGlass,
  faPenToSquare,
  faUpload,
  faTrash,
  faCheck,
  faXmark,
  faFileArrowDown,
  faFile,
  faArrowUpRightFromSquare,
  faDownload,
);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('vf-button', VfButton);
Vue.component('styled-subheading', StyledSubheading);
Vue.component('title-panel', TitlePanel);

Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueMarkdownEditor);

export const i18n = new VueI18n({
  /** dynamically set in App.vue mounted fn */
  locale: 'de',
  messages,
});

VueMarkdownEditor.use(vuepressTheme);
/** dynamically set in App.vue mounted fn */
VueMarkdownEditor.lang.use('de-DE', deDE);

Vue.config.productionTip = false;

export const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

Vue.mixin(globalUtils);

new Vue({
  render: (h) => h(App),
  router,
  store,
  i18n,
}).$mount('#app');
