import Vue from 'vue';
import { SelectOption } from './FilterableTable.script';

export interface IFilterableTableHelpersMixin {
  getSelectOptionsFromEnum<T>(
    enumForOptions: T,
    translationKey: string,
  ): SelectOption[];
  getSelectOptionsFromValues<T>(
    values: T[],
    translationKey?: string,
  ): SelectOption[];
}

export default Vue.extend({
  methods: {
    getSelectOptionsFromEnum<T extends Object>(
      enumForOptions: T,
      translationKey: string,
    ): SelectOption[] {
      const enumValues: T[] = Object.values(enumForOptions);

      const selectOptionsFromValues = this.getSelectOptionsFromValues(
        enumValues,
        translationKey,
      );
      selectOptionsFromValues.push({
        text: this.$i18n.t('filterableTable.all'),
        value: null,
      });

      return selectOptionsFromValues;
    },

    getSelectOptionsFromValues<T>(
      values: T[],
      translationKey?: string,
    ): SelectOption[] {
      const options: SelectOption[] = values.map((value) => ({
        value,
        text: translationKey
          ? this.$i18n.t(`${translationKey}.${value}`)
          : value,
      })) as unknown as SelectOption[];

      return options;
    },
  },
});
