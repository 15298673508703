import Vue from 'vue';
import ProjectForm from '../../components/ProjectForm/ProjectForm.vue';
import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import endpointsList from '../../helpers/endpointsList';
import { IProject } from 'types';
import { router } from '../../vue';
import { ToastMixin } from '../../mixins/globalUtils';

interface ICreateProject {
  addedDocuManagers: string[];
  isMissingDocuManagersError: boolean;
  isSubmitted: boolean;
  createToast?: ToastMixin;
}

export default Vue.extend({
  name: 'create-project',
  components: { ProjectForm },
  props: [],
  data(): ICreateProject {
    return {
      addedDocuManagers: [],
      isMissingDocuManagersError: false,
      isSubmitted: false,
    };
  },

  methods: {
    async handleSubmit(projectData: IProject) {
      if (!this.addedDocuManagers.length) {
        this.isMissingDocuManagersError = true;

        return;
      }

      try {
        projectData.assignedDocuManagers = this.addedDocuManagers;

        const response: AxiosResponse<IProject> = await axios.post(
          endpointsList.projects.addProject,
          projectData,
        );

        if (response.status === 201) {
          router.push({
            name: 'projectDetails',
            params: { id: response.data._id.toString() },
          });
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err.response?.status === HttpStatusCode.Conflict) {
            this.createToast!(
              this.$t('projectForm.errors.wrongProjectNumber'),
              this.$t('projectForm.errors.projectNumberNotUniqueForSite'),
              'danger',
            );
          }

          return;
        }

        this.createToast!(
          this.$t('errors.error'),
          this.$t('createProject.errors.couldNotCreateProject'),
          'danger',
        );
      }
    },

    toggleAddDocuManager(oid: string) {
      if (!this.addedDocuManagers.includes(oid)) {
        this.addDocuManager(oid);
      } else {
        this.deleteDocuManager(oid);
      }
    },

    addDocuManager(oid: string) {
      if (!this.addedDocuManagers.includes(oid)) {
        this.addedDocuManagers.push(oid);
      }
    },

    deleteDocuManager(oid: string) {
      const index = this.addedDocuManagers.indexOf(oid);
      this.addedDocuManagers.splice(index, 1);
    },
  },
});
