import Vue from 'vue';
import DocumentUpload from '../../views/DocumentUpload/DocumentUpload.vue';
import SingleUploadForm from '../../components/SingleUploadForm/SingleUploadForm.vue';
import axios, { AxiosResponse } from 'axios';
import endpointsList from '../../helpers/endpointsList';
import { IContentTypeField } from 'types';
import { stringToBoolean } from '@/helpers';

export enum DocumentUploadMode {
  PROJECT = 'project',
  DIGITALIZATION_PROJECT = 'digitalization-project',
}

interface IDocumentUploadView {
  activeTabIndex: 0 | 1;
  contentTypeFields?: IContentTypeField[];
  isCheckBotInspectionDisabled?: boolean;
  isNoMetadataUploadMode?: boolean;
}

export default Vue.extend({
  name: 'document-upload-view',
  components: { DocumentUpload, SingleUploadForm },

  props: {
    mode: {
      type: String as () => DocumentUploadMode,
      required: true,
    },
    referenceNumber: {
      type: String,
      required: true,
    },
    contentTypeId: {
      type: String,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
    projectNumber: {
      type: String,
      required: true,
    },
    projectName: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
    SAPNumber: {
      type: String,
      required: false,
    },
    supplierName: {
      type: String,
      required: false,
    },
  },

  data(): IDocumentUploadView {
    return {
      activeTabIndex: 0,
      contentTypeFields: undefined,
      isCheckBotInspectionDisabled: undefined,
      isNoMetadataUploadMode: undefined,
    };
  },

  methods: {
    getContentTypeFields() {
      axios
        .get(
          endpointsList.contentTypeMappings.getContentTypeById(
            this.contentTypeId,
          ),
        )
        .then((response: AxiosResponse<IContentTypeField[]>) => {
          this.contentTypeFields = response.data;
        });
    },

    setIsCheckBotInspectionDisabled(): void {
      if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
        this.isCheckBotInspectionDisabled = stringToBoolean(
          this.$route.query.isCheckBotInspectionDisabled as string,
        );
      }
    },

    setIsNoMetadataUploadMode(): void {
      if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
        this.isNoMetadataUploadMode = stringToBoolean(
          this.$route.query.isNoMetadataUploadMode as string,
        );
      }
    },
  },

  created() {
    this.setIsCheckBotInspectionDisabled();
    this.getContentTypeFields();
    this.setIsNoMetadataUploadMode();

    if (this.mode === DocumentUploadMode.PROJECT) {
      (this as any).setBreadcrumbMetadata([
        'projectsList',
        'projectDetails',
        'documentUpload',
      ]);
    } else if (this.mode === DocumentUploadMode.DIGITALIZATION_PROJECT) {
      (this as any).setBreadcrumbMetadata([
        'digitalizationProjectsList',
        'digitalizationProjectDetails',
        'documentUpload',
      ]);
    }
  },
});
