import StyledModal from '@/components/StyledModal/StyledModal.vue';
import { ParseError } from 'papaparse';
import Vue from 'vue';

type ExpectedAndReceivedFields = {
  expectedFieldsNumber: number;
  parsedFieldsNumber: number;
};

export default Vue.extend({
  name: 'csv-parsing-errors-modal',
  components: { StyledModal },
  props: {
    csvParsingErrors: {
      type: Array as () => ParseError[],
      required: false,
      default: () => [],
    },
  },
  methods: {
    getExpectedAndReceivedFieldsFromErrorMessage(
      errorMessage: string,
    ): ExpectedAndReceivedFields {
      const EXPECTED_FIELDS_REGEX = /expected (\d+) fields/i;
      const PARSED_FIELDS_REGEX = /parsed (\d+)$/i;

      const expectedFieldsNumber = Number(
        errorMessage.match(EXPECTED_FIELDS_REGEX)?.[1],
      );
      const parsedFieldsNumber = Number(
        errorMessage.match(PARSED_FIELDS_REGEX)?.[1],
      );

      return {
        expectedFieldsNumber,
        parsedFieldsNumber,
      };
    },

    deduplicateErrors(errors: ParseError[]): ParseError[] {
      const stringifiedErrors = errors.map((error) => JSON.stringify(error));
      const deduplicatedStringifiedErrors = Array.from(
        new Set(stringifiedErrors),
      );
      return deduplicatedStringifiedErrors.map((error) => JSON.parse(error));
    },

    sortErrorsByRowNumber(errors: ParseError[]): ParseError[] {
      return errors.sort((a, b) => a.row! - b.row!);
    },
  },
});
