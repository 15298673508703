import CheckBotTemplateModal from '@/components/CheckBotTemplateModal/CheckBotTemplateModal.vue';
import { CheckBotModuleStrings, IProject } from 'types';
import { CheckBotConfiguratorMode } from '@/views/CheckBotConfigurator/CheckBotConfigurator.script';
import { router } from '@/vue';
import Vue from 'vue';

export default Vue.extend({
  name: 'supplier-check-bot-management',

  components: {
    CheckBotTemplateModal,
  },

  props: {
    projectData: {
      type: Object as () => IProject,
      required: true,
    },
    supplierId: {
      type: String,
      required: true,
    },
    supplierName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      customCheckBotConfigurationString:
        CheckBotModuleStrings.CUSTOM_CHECK_BOT_CONFIG,
      checkBotConfiguratorMode: CheckBotConfiguratorMode.PROJECT,
    };
  },

  computed: {
    assignedCheckBotTemplate(): string | undefined {
      return this.projectData.assignedCheckBotTemplates?.[this.supplierId];
    },
    isCustomTemplateAssigned(): boolean {
      return (
        this.assignedCheckBotTemplate?.includes(
          CheckBotModuleStrings.CUSTOM_CHECK_BOT_CONFIG,
        ) ?? false
      );
    },
  },

  methods: {
    goToCheckBotConfigView() {
      router.push({
        name: 'checkBotConfigurator',
        params: {
          mode: CheckBotConfiguratorMode.PROJECT,
          projectId: this.projectData._id.toString(),
          supplierId: this.supplierId,
          supplierName: this.supplierName,
        },
      });
    },
  },
});
