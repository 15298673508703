import { render, staticRenderFns } from "./MetadataCheckResultsTable.vue?vue&type=template&id=aa470118&scoped=true"
import script from "./MetadataCheckResultsTable.script.ts?vue&type=script&lang=ts&external"
export * from "./MetadataCheckResultsTable.script.ts?vue&type=script&lang=ts&external"
import style0 from "./MetadataCheckResultsTable.style.scss?vue&type=style&index=0&id=aa470118&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa470118",
  null
  
)

export default component.exports