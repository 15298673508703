import { IPageOptionsDto } from 'types';

/**
 * Converts an object containing query parameters into a URL query string. This
 * function iterates over each key-value pair in the provided object, encoding
 * them for URL usage and appending them to the base URL as query parameters.
 * If the object is empty or all its properties are undefined, the base URL is
 * returned unchanged.
 *
 * @param {string} baseUrl - The base URL to which query parameters will be
 * appended.
 * @param {T} params - An object representing the query parameters. Each
 * key-value pair in the object will be converted into a query string
 * parameter. Only own properties of the object are considered, and properties
 * with undefined values are omitted from the resulting query string.
 * @returns {string} The resulting URL with encoded query parameters appended.
 * If no valid parameters are provided, returns the base URL without
 * modification.
 *
 * @example
 * // Define a base URL and parameters object
 * const baseUrl = 'http://example.com/projects';
 * const pageOptions = {
 *   order: 'ASC',
 *   page: 1,
 *   take: 10,
 *   title: 'Project A',
 *   number: '001',
 *   status: 'Active',
 * };
 *
 * // Convert to query string URL
 * const queryStringUrl = transformPageOptionsToQueryString(baseUrl,
 * pageOptions);
 * // Output:
 * http://example.com/projects?order=ASC&page=1&take=10&title=Project%20A&number=001&status=Active
 */
export function transformPageOptionsToQueryString<T extends object>(
  baseUrl: string,
  params: IPageOptionsDto<T>,
): string {
  const queryParts: string[] = [];
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      const value = params[key as keyof IPageOptionsDto<T>];
      if (value !== undefined) {
        queryParts.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(
            value as string | number,
          )}`,
        );
      }
    }
  }
  return queryParts.length > 0 ? `${baseUrl}?${queryParts.join('&')}` : baseUrl;
}
