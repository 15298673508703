import { MetadataCheckResult } from 'types';
import Vue from 'vue';
import MainTable from '@/components/MainTable/MainTable.vue';
import { ITableField } from '@/frontendInterfaces';

export default Vue.extend({
  name: 'metadata-check-results-table',

  components: { MainTable },

  props: {
    metadataCheckResults: {
      type: [Array as () => MetadataCheckResult[], () => undefined],
      required: true,
      default: () => [],
    },
  },

  computed: {
    fields(): ITableField[] {
      return [
        {
          key: 'field',
          label: this.$i18n.t('metadataCheckResultsTable.field'),
          sortable: true,
        },
        {
          key: 'value',
          label: this.$i18n.t('metadataCheckResultsTable.value'),
          sortable: true,
        },
        {
          key: 'accepted',
          label: this.$i18n.t('metadataCheckResultsTable.status'),
          sortable: true,
        },
        {
          key: 'errorMessage',
          label: this.$i18n.t('metadataCheckResultsTable.error'),
          sortable: true,
        },
      ];
    },
  },
});
