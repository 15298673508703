import { ITableField } from '@/frontendInterfaces';
import endpointsList from '@/helpers/endpointsList';
import { IDocuResponsible, IDocuResponsibleDto } from 'types';
import { ToastMixin } from '@/mixins/globalUtils';
import axios, { AxiosResponse } from 'axios';
import Vue from 'vue';
import deselectIcon from '../../assets/icons/deselect.svg';
import selectIcon from '../../assets/icons/select.svg';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import FilterableTable from '../FilterableTable/FilterableTable.vue';

interface IDocuResponsibles {
  createToast?: ToastMixin;
  deselectIcon: string;
  docuResponsibles?: IDocuResponsible[];
  selectIcon: string;
  sortBy: keyof IDocuResponsible;
  sortDesc: boolean;
  tableFilterConfiguration: IFilterConfiguration;
  tableFilterGridConfiguration: string[];
}

export enum DocuResponsiblesTableMode {
  DEFAULT = 'default',
  NEW_PROJECT = 'newProject',
}

export default Vue.extend({
  name: 'docu-responsibles-table',

  props: {
    mode: {
      type: String as () => DocuResponsiblesTableMode,
      required: false,
    },
    addedDocuResponsibles: {
      type: Array as () => string[],
      required: false,
    },
  },
  components: { FilterableTable },
  data(): IDocuResponsibles {
    return {
      docuResponsibles: undefined,
      sortBy: 'lastName',
      sortDesc: true,
      selectIcon,
      deselectIcon,
      tableFilterConfiguration: {
        firstName: { type: FilterType.INPUT, column: 1, width: '208px' },
        lastName: { type: FilterType.INPUT, column: 2, width: '208px' },
        email: { type: FilterType.INPUT, column: 3, width: '208px' },
      },
      tableFilterGridConfiguration: ['36%', '28%', '34%'],
    };
  },

  computed: {
    tableFields(): ITableField[] {
      const fields = [
        {
          key: 'firstName',
          label: this.$i18n.t('generic.firstName'),
          sortable: true,
          tdClass: ['w-36'],
        },
        {
          key: 'lastName',
          label: this.$i18n.t('generic.lastName'),
          sortable: true,
          tdClass: ['w-28'],
        },
        {
          key: 'email',
          label: this.$i18n.t('generic.email'),
          sortable: true,
          tdClass: ['w-34'],
        },
        {
          key: 'action',
          label: '',
          sortable: false,
          tdClass: ['w-2', 'text-right', 'align-middle', 'action-button'],
        },
      ];

      return fields;
    },
  },

  methods: {
    async getAddedDocuResponsibles() {
      try {
        const response: AxiosResponse<IDocuResponsibleDto[]> = await axios.get(
          endpointsList.docuResponsibles.getAllDocuResponsibles,
        );

        this.docuResponsibles = response.data;
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('docuResponsiblesTable.errors.couldNotGetDocuResponsibles'),
          'danger',
        );
      }
    },

    toggleAddDocuResponsible(row: {
      item: IDocuResponsible;
      [key: string]: unknown;
    }) {
      this.$emit('toggle-add-docu-responsible', row.item.oid);
    },

    isDocuResponsibleAdded(oid: string): boolean {
      return this.addedDocuResponsibles?.includes(oid);
    },
  },

  mounted() {
    this.getAddedDocuResponsibles();
  },
});
