import DocumentsMetadata from '@/components/DocumentsMetadata/DocumentsMetadata.vue';
import InspectionDueDatePanel from '@/components/InspectionDueDatePanel/InspectionDueDatePanel.vue';
import VfButton from '@/components/VfButton/VfButton.vue';
import { IMetadataEntry } from '@/views/DocumentDetailView/DocumentDetailView.script';
import axios, { AxiosError, AxiosResponse, CancelTokenSource } from 'axios';
import Vue from 'vue';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import tickIcon from '../../assets/icons/tick.svg';
import MainTable from '../../components/MainTable/MainTable.vue';
import StyledPagination from '../../components/StyledPagination/StyledPagination.vue';
import endpointsList from '../../helpers/endpointsList';
import {
  DocumentDetailViewMode,
  IDigitalizationDocument,
  IFilesListFile,
  positiveCheckBotStatuses,
  Role,
  SharePointUploadStatus,
  waitingInspectorStatuses,
} from 'types';
import DocumentMetadataTagInput from '../DocumentMetadataTagInput/DocumentMetadataTagInput.vue';
import DocumentStatusPanel from '../DocumentStatusPanel/DocumentStatusPanel.vue';
import InspectorPanel from '../InspectorPanel/InspectorPanel.vue';
import { ButtonVariant } from '../VfButton/VfButton.script';

interface IDocumentAndMetadata {
  modes: {
    project: DocumentDetailViewMode.PROJECT;
    digitalizationProject: DocumentDetailViewMode.DIGITALIZATION_PROJECT;
  };
  encodedFile: null | string;
  fileForDownload: null | string;
  pdfCurrentPage: number;
  pdfPageCount: number;
  pdfDownloadCancelTokenSource: CancelTokenSource;
  rerunningFormalControlAllowedRoles: [Role.Admin, Role.DocuManager];
  rerunFormalControlButtonVariant: ButtonVariant.SECONDARY;
  isRerunningFormalControl: boolean;
  tickIcon: string;
}

export default Vue.extend({
  name: 'document-and-metadata',

  components: {
    DocumentsMetadata,
    DocumentMetadataTagInput,
    MainTable,
    StyledPagination,
    VuePdfEmbed,
    DocumentStatusPanel,
    InspectorPanel,
    InspectionDueDatePanel,
    VfButton,
  },

  props: {
    mode: {
      type: String as () => DocumentDetailViewMode,
      required: true,
    },
    file: {
      type: Object as () => IFilesListFile,
      required: false,
    },
    digitalizationDocument: {
      type: Object as () => IDigitalizationDocument,
      required: false,
    },
    projectId: {
      type: String,
      required: true,
    },
    documentId: {
      type: String,
      required: true,
    },
    uploadId: {
      type: String,
      required: true,
    },
    metadata: {
      type: Array as () => IMetadataEntry[],
      required: true,
    },
  },

  data(): IDocumentAndMetadata {
    return {
      modes: {
        project: DocumentDetailViewMode.PROJECT,
        digitalizationProject: DocumentDetailViewMode.DIGITALIZATION_PROJECT,
      },
      encodedFile: null,
      fileForDownload: null,
      pdfCurrentPage: 1,
      pdfPageCount: 1,
      pdfDownloadCancelTokenSource: axios.CancelToken.source(),
      rerunningFormalControlAllowedRoles: [Role.Admin, Role.DocuManager],
      rerunFormalControlButtonVariant: ButtonVariant.SECONDARY,
      isRerunningFormalControl: false,
      tickIcon,
    };
  },

  methods: {
    async getFileFromSharepoint() {
      if (!(this.file || this.digitalizationDocument)) {
        return;
      }

      try {
        const endpoint =
          this.mode == DocumentDetailViewMode.PROJECT
            ? endpointsList.documentsUploads.downloadFile(
                this.file.fileName,
                this.file.projectId.toString(),
                this.file.supplierId.toString(),
              )
            : endpointsList.digitalizationUploads.getFileAsStream(
                this.digitalizationDocument.fileName,
                this.digitalizationDocument.digitalizationProjectId.toString(),
              );
        const response: AxiosResponse<Blob> = await axios.get(endpoint, {
          responseType: 'blob',
          cancelToken: this.pdfDownloadCancelTokenSource.token,
        });

        const blob = response.data;
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          this.encodedFile = reader.result as string;
        };

        this.fileForDownload = URL.createObjectURL(blob);
      } catch (err) {
        if (err instanceof AxiosError && err.code === 'ERR_CANCELED') {
          return;
        }
        (this as any).createToast(
          this.$t('errors.error'),
          this.$t('documentDetailView.errors.cannotDownloadFile.long'),
          'danger',
        );
      }
    },

    getPageCount() {
      const pdfRef = this.$refs?.pdfRef;

      if (!pdfRef) {
        return;
      }

      this.pdfPageCount = (pdfRef as any).pageCount;
    },

    handlePageChange(page: number) {
      this.pdfCurrentPage = page;
    },

    isSharePointUploadFailed(): boolean {
      return (
        this.file?.sharePointUploadStatus === SharePointUploadStatus.FAILED ||
        this.digitalizationDocument?.sharePointUploadStatus ===
          SharePointUploadStatus.FAILED
      );
    },

    async handleRerunFormalControlButtonClick() {
      this.isRerunningFormalControl = true;
      try {
        await axios.post(
          endpointsList.checkBot.executeMetadataCheck(this.file._id.toString()),
        );

        this.$emit('document-data-changed');

        (this as any).createToast(
          this.$t('documentDetailView.successfullyRerunFormalControl.short'),
          this.$t('documentDetailView.successfullyRerunFormalControl.long'),
          'success',
        );
      } catch (err) {
        (this as any).createToast(
          this.$t('errors.error'),
          this.$t('documentDetailView.errors.errorRerunningMetadataCheck.long'),
          'danger',
        );
      } finally {
        this.isRerunningFormalControl = false;
      }
    },

    isRerunFormalControlButtonDisabled(): boolean {
      return (
        !waitingInspectorStatuses.includes(this.file.inspectorStatus) ||
        !positiveCheckBotStatuses.includes(this.file.checkBotStatus)
      );
    },
  },

  watch: {
    file() {
      if (!this.file) {
        return;
      }

      this.getFileFromSharepoint();
    },
  },

  created() {
    if (!(this.file || this.digitalizationDocument)) {
      return;
    }

    this.getFileFromSharepoint();
  },

  beforeDestroy() {
    this.pdfDownloadCancelTokenSource.cancel('Component destroyed');
  },
});
