import { ToastMixin } from '../../mixins/globalUtils';
import Vue from 'vue';

export interface IUploadBox {
  file?: File;
  createToast?: ToastMixin;
  UploadBoxMode: typeof UploadBoxMode;
}

export enum UploadBoxMode {
  FILE = 'file',
  MULTIPLE_FILES = 'multipleFiles',
  DIRECTORY = 'directory',
}

export default Vue.extend({
  name: 'upload-box',

  props: {
    buttonLabel: {
      type: String,
      required: true,
    },
    acceptExtensions: {
      type: String,
      required: false,
    },
    maxSize: {
      type: String,
      required: true,
    },
    uploadProgress: {
      type: Number,
      required: false,
    },
    isUploading: {
      type: Boolean,
      required: false,
    },
    resetInputOnButtonClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    uploadBoxMode: {
      type: String as () => UploadBoxMode,
      required: false,
      default: UploadBoxMode.FILE,
    },
  },

  data(): IUploadBox {
    return {
      file: undefined,
      UploadBoxMode,
    };
  },

  computed: {
    displayedMaxSize(): string {
      const maxSize = Number(this.maxSize);

      if (maxSize > 1024) {
        return `${maxSize / 1024} GB`;
      }

      return `${maxSize} MB`;
    },
  },

  methods: {
    handleDrop(event: DragEvent): void {
      event.preventDefault();
      if (event.dataTransfer) {
        const items = event.dataTransfer.items;
        if (items) {
          for (let i = 0; i < items.length; i++) {
            const item = items[i].webkitGetAsEntry();
            if (item) {
              if (item.isFile) {
                this.processFileEntry(item as FileSystemFileEntry);
              } else if (item.isDirectory) {
                this.processDirectoryEntry(item as FileSystemDirectoryEntry);
              }
            }
          }
        }
      }
    },

    processFileEntry(fileEntry: FileSystemFileEntry): void {
      fileEntry.file((file) => {
        if (this.validateMaxFileSize(file)) {
          this.file = file;
          this.$emit('change', file);
        }
      });
    },

    processDirectoryEntry(
      directoryEntry: FileSystemDirectoryEntry,
      path: string = '',
    ): void {
      const dirReader = directoryEntry.createReader();
      dirReader.readEntries((entries) => {
        for (const entry of entries) {
          if (entry.isFile) {
            this.processFileEntry(entry as FileSystemFileEntry);
          } else if (entry.isDirectory) {
            this.processDirectoryEntry(
              entry as FileSystemDirectoryEntry,
              `${path}${entry.name}/`,
            );
          }
        }
      });
    },

    handleUpload(event: InputEvent) {
      const target = event.target as HTMLInputElement;
      const files = target.files;
      if (!files) {
        return;
      }

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (this.validateMaxFileSize(file)) {
          this.file = file;
          this.$emit('change', file);
        }
      }
    },

    resetFileInput() {
      (this.$refs.fileInput as HTMLInputElement).value = '';
    },

    validateMaxFileSize(file: File): boolean {
      if (file.size > Number(this.maxSize) * 1024 * 1024) {
        this.createToast!(
          this.$t('documentUpload.errors.fileToBigShort'),
          this.$t('documentUpload.errors.fileToBig', {
            maxSize: this.displayedMaxSize,
          }),
          'danger',
        );

        return false;
      }

      return true;
    },
  },

  watch: {
    isUploading() {
      if (this.isUploading) {
        this.file = undefined;
      }
    },
  },
});
