import axios from 'axios';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import {
  FilterType,
  IFilterConfiguration,
  TServerSidePaginationFunction,
} from '../../components/FilterableTable/FilterableTable.script';
import FilterableTable from '../../components/FilterableTable/FilterableTable.vue';
import endpointsList from '../../helpers/endpointsList';

interface ISuppliersList {
  filterConfiguration: IFilterConfiguration;
  serverSidePaginationEndpoint: TServerSidePaginationFunction<
    'name' | 'SAPNumber'
  >;
  serverSideRowsCountEndpoint: string;
  suppliersCount: number;
}

export default Vue.extend({
  name: 'dlp-suppliers-list',
  components: { FilterableTable },
  data(): ISuppliersList {
    return {
      filterConfiguration: {
        name: { type: FilterType.INPUT, column: 1, width: '208px' },
        SAPNumber: { type: FilterType.INPUT, column: 2, width: '208px' },
      },

      serverSidePaginationEndpoint:
        endpointsList.suppliers.queryAndPaginateAllSuppliers,
      serverSideRowsCountEndpoint: endpointsList.suppliers.count,
      suppliersCount: 0,
    };
  },
  computed: {
    fields(): {
      key: string;
      label: TranslateResult;
      sortable?: boolean;
      tdClass: string[];
    }[] {
      return [
        {
          key: 'name',
          label: this.$i18n.t('suppliersList.name'),
          sortable: true,
          tdClass: ['w-50'],
        },
        {
          key: 'SAPNumber',
          label: this.$i18n.t('suppliersList.SAPNumber'),
          sortable: true,
          tdClass: ['w-25'],
        },
        {
          key: 'coworkersCount',
          label: this.$i18n.t('suppliersList.coworkers'),
          sortable: true,
          tdClass: ['w-25'],
        },
      ];
    },
  },

  methods: {
    async getSuppliersCount() {
      try {
        const response = await axios.get(endpointsList.suppliers.count);

        this.suppliersCount = response.data;
      } catch {
        //
      }
    },
  },

  created() {
    this.getSuppliersCount();
  },
});
