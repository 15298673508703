import { render, staticRenderFns } from "./CreateDigitalizationProject.vue?vue&type=template&id=bf9959d6&scoped=true"
import script from "./CreateDigitalizationProject.script.ts?vue&type=script&lang=ts&external"
export * from "./CreateDigitalizationProject.script.ts?vue&type=script&lang=ts&external"
import style0 from "./CreateDigitalizationProject.style.scss?vue&type=style&index=0&id=bf9959d6&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf9959d6",
  null
  
)

export default component.exports