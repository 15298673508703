import commonProjectUtils from '@/mixins/commonProjectUtils';
import axios from 'axios';
import { IProject, ProjectType, Role } from 'types';
import Vue from 'vue';
import VueRouter from 'vue-router';
import FilesOverviewTable from '../../components/FilesOverviewTable/FilesOverviewTable.vue';
import InspectorsList from '../../components/InspectorsList/InspectorsList.vue';
import NewUploadModal from '../../components/NewUploadModal/NewUploadModal.vue';
import ProjectConfiguration from '../../components/ProjectConfiguration/ProjectConfiguration.vue';
import ProjectForm from '../../components/ProjectForm/ProjectForm.vue';
import ProjectInformations from '../../components/ProjectInformations/ProjectInformations.vue';
import ProjectsSuppliersList from '../../components/ProjectsSuppliersList/ProjectsSuppliersList.vue';
import UploadsOverview from '../../components/UploadsOverview/UploadsOverview.vue';
import endpointsList from '../../helpers/endpointsList';
import { GlobalUtils } from '../../mixins/globalUtils';
import { router } from '../../vue';
import IblView from '@/components/IblView/IblView.vue';

interface IProjectDetails {
  activeTabIndex: number;
  /** Temporarily only admins are allowed to access the IBL view */
  iblAllowedRoles: [Role.Admin];
  projectData?: IProject;
  suppliersAllowedRoles: [Role.Admin, Role.DocuManager];
}

export default Vue.extend({
  name: 'project-details',
  mixins: [commonProjectUtils],
  components: {
    ProjectForm,
    InspectorsList,
    NewUploadModal,
    FilesOverviewTable,
    UploadsOverview,
    ProjectConfiguration,
    ProjectInformations,
    ProjectsSuppliersList,
    IblView,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    tabNumber: {
      type: String,
      required: false,
      default: '0',
    },
    referenceNumberForFilesFiltering: {
      type: String,
      required: false,
    },
  },

  data(): IProjectDetails {
    return {
      activeTabIndex: 0,
      iblAllowedRoles: [Role.Admin],
      projectData: undefined,
      suppliersAllowedRoles: [Role.Admin, Role.DocuManager],
    };
  },

  computed: {
    projectType() {
      return ProjectType.SUPPLIER_PROJECT;
    },
  },

  methods: {
    fetchProjectData() {
      axios
        .get(endpointsList.projects.getProjectById(this.id))
        .then((response) => (this.projectData = response.data))
        .catch(() => {
          (this as unknown as GlobalUtils).createToast(
            this.$t('errors.error'),
            this.$t('errors.error'),
            'danger',
          );
        });
    },

    setActiveTabIndex() {
      if (this.tabNumber !== undefined) {
        this.activeTabIndex = Number(this.tabNumber);
        return;
      }
    },

    resetFilesOverviewTableConfig() {
      this.$store.commit('setFilesOverviewTableConfig', undefined);
    },
  },

  watch: {
    activeTabIndex() {
      this.$router
        .replace({
          params: { tabNumber: this.activeTabIndex.toString() },
        })
        .catch((err) => {
          if (
            VueRouter.isNavigationFailure(
              err,
              VueRouter.NavigationFailureType.duplicated,
            )
          ) {
            return;
          }
          throw err;
        });

      this.$store.commit('setProjectDetailViewTabNumber', this.activeTabIndex);
    },

    $route(to) {
      this.activeTabIndex = Number(to.params.tabNumber);
    },
  },

  mounted() {
    if (this.id === undefined) {
      router.push({ name: 'projectsList' });
      return;
    }

    this.$store.commit('setCurrentProjectId', this.id);
    this.fetchProjectData();

    this.$nextTick(() => this.setActiveTabIndex());
  },
});
