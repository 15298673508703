import Vue from 'vue';
import StyledModal from '@/components/StyledModal/StyledModal.vue';

export default Vue.extend({
  name: 'metadata-validation-modal',
  components: { StyledModal },
  props: {
    invalidDocumentIds: {
      type: Array as () => string[],
      required: true,
      default: () => [],
    },
  },
});
