import { Role } from 'types';
import { DigitalizationProjectRole } from '@/interfaces/frontend-types';
import { IVueexState } from '@/store';
import Vue from 'vue';

export type DigitalizationProjectTabNumbersMixin = {
  getDigitalizationProjectDetailViewTabNumber: (
    tab: DigitalizationProjectDetailViewTab,
  ) => string;
};

export enum DigitalizationProjectDetailViewTab {
  INFORMATIONS = 'informations',
  SETTINGS = 'settings',
  COWORKERS = 'coworkers',
  UPLOADS = 'uploads',
  DOCUMENTS = 'documents',
}

type TabNumbersByRoles = {
  [key in DigitalizationProjectRole]: {
    [key in DigitalizationProjectDetailViewTab]?: number;
  };
};

const tabNumbersByRoles: TabNumbersByRoles = {
  [Role.Admin]: {
    [DigitalizationProjectDetailViewTab.INFORMATIONS]: 0,
    [DigitalizationProjectDetailViewTab.SETTINGS]: 1,
    [DigitalizationProjectDetailViewTab.COWORKERS]: 2,
    [DigitalizationProjectDetailViewTab.UPLOADS]: 3,
    [DigitalizationProjectDetailViewTab.DOCUMENTS]: 4,
  },
  [Role.DocuResponsible]: {
    [DigitalizationProjectDetailViewTab.INFORMATIONS]: 0,
    [DigitalizationProjectDetailViewTab.SETTINGS]: 1,
    [DigitalizationProjectDetailViewTab.COWORKERS]: 2,
    [DigitalizationProjectDetailViewTab.UPLOADS]: 3,
    [DigitalizationProjectDetailViewTab.DOCUMENTS]: 4,
  },
  [Role.DigitalizationCoworker]: {
    [DigitalizationProjectDetailViewTab.INFORMATIONS]: 0,
    [DigitalizationProjectDetailViewTab.UPLOADS]: 1,
    [DigitalizationProjectDetailViewTab.DOCUMENTS]: 2,
  },
};

export default Vue.extend({
  methods: {
    getDigitalizationProjectDetailViewTabNumber(
      tab: DigitalizationProjectDetailViewTab,
    ): string {
      const userRoles = (this.$store.state as IVueexState).userRoles;
      if (!userRoles) {
        return '0';
      }

      for (const role in tabNumbersByRoles) {
        if (!userRoles.includes(role as DigitalizationProjectRole)) continue;

        const tabNumbersForRole =
          tabNumbersByRoles[role as DigitalizationProjectRole];
        const tabNumber = tabNumbersForRole[tab];

        if (tabNumber === undefined) {
          return '0';
        }

        return tabNumber.toString();
      }

      return '0';
    },
  },
});
