import Vue from 'vue';

export enum ButtonSize {
  LARGE = 'vf-btn--lg',
  MEDIUM = 'vf-btn',
  SMALL = 'vf-btn--sm',
}

export enum ButtonVariant {
  PRIMARY = 'vf-btn__primary',
  SECONDARY = 'vf-btn__secondary',
  TERTIARY = 'vf-btn__tertiary',
  QUATERNARY = 'vf-btn__tertiary vf-btn__quaternary',
}

export default Vue.extend({
  name: 'vf-button',

  props: {
    size: {
      type: String as () => ButtonSize,
      required: false,
      default: ButtonSize.MEDIUM,
    },
    variant: {
      type: String as () => ButtonVariant,
      required: false,
      default: ButtonVariant.PRIMARY,
    },
    busy: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    handleClick(event: MouseEvent) {
      this.$emit('click', event);
    },
  },
});
