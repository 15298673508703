"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isDefined = isDefined;
/**
 * Type guard that checks if a value is not `undefined`.
 *
 * This function acts as a type guard by asserting that the value passed to it
 * is not `undefined`. When used with array filtering operations, it helps TypeScript
 * to infer a more specific type for the resulting array by excluding `undefined` values.
 *
 * @template T - The type of the value being checked. This allows the function to be used generically.
 *
 * @param {T | undefined} value - The value to check for `undefined`. The value can be of any type `T` or `undefined`.
 *
 * @returns {boolean} `true` if the value is not `undefined`, otherwise `false`. When returning `true`, TypeScript narrows the type of the value to `T` in the scope where the type guard is used.
 *
 * @example
 * // Given an array of numbers that might contain undefined values
 * const numbers: (number | undefined)[] = [1, undefined, 2, 3, undefined];
 *
 * // Using the isDefined function to filter out undefined values
 * const definedNumbers: number[] = numbers.filter(isDefined);
 * // At this point, TypeScript knows `definedNumbers` is strictly an array of numbers.
 */
function isDefined(value) {
    return value !== undefined;
}
