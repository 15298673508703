"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileStatus = void 0;
var FileStatus;
(function (FileStatus) {
    FileStatus["waitingForFormalControl"] = "waitingForFormalControl";
    FileStatus["waitingForContentControl"] = "waitingForContentControl";
    FileStatus["accepted"] = "accepted";
    FileStatus["acceptedWithRemarks"] = "acceptedWithRemarks";
    FileStatus["inspectorRejected"] = "inspectorRejected";
    FileStatus["rejected"] = "rejected";
})(FileStatus || (exports.FileStatus = FileStatus = {}));
