import { render, staticRenderFns } from "./DocuManagersTable.vue?vue&type=template&id=0ce18df0&scoped=true"
import script from "./DocuManagersTable.script.ts?vue&type=script&lang=ts&external"
export * from "./DocuManagersTable.script.ts?vue&type=script&lang=ts&external"
import style0 from "./DocuManagersTable.styles.scss?vue&type=style&index=0&id=0ce18df0&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ce18df0",
  null
  
)

export default component.exports