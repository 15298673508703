"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserHelper = void 0;
const roles_helper_1 = require("./roles-helper");
class UserHelper {
    constructor(userSession) {
        this.userSession = userSession;
        this._user = userSession.user;
        this._roles = new roles_helper_1.RolesHelper(this._user.roles);
    }
    get oid() {
        return this._user.oid;
    }
    get roles() {
        return this._roles;
    }
}
exports.UserHelper = UserHelper;
