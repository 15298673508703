var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"documents-metadata"},[_c('h4',[_vm._v(_vm._s(_vm.$t('documentDetailView.metadata')))]),_c('main-table',{attrs:{"id":"metadata-table","busy":!_vm.metadata,"customFilterFunction":_vm.isShowingOnlyEditableFields ? _vm.metadataFiltering : undefined,"customSortFunction":_vm.sortMetadata,"items":_vm.metadata ?? [],"fields":_vm.tableFields,"noActionIcon":""},scopedSlots:_vm._u([{key:"cell(field)",fn:function(row){return [(row.item.field === _vm.checkBotEstablishedKksNumbersField)?[_vm._v(" "+_vm._s(_vm.$t('documentDetailView.checkBotEstablishedKksNumbers'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t(`contentTypeFields.${row.item.field}`))+" ")]]}},{key:"cell(value)",fn:function(row){return [(
          _vm.mode === _vm.modes.project &&
          _vm.isUserRole(_vm.EDITING_METADATA_ALLOWED_ROLES) &&
          _vm.EDITABLE_METADATA_FIELDS.includes(row.item.field)
        )?[_c('div',{staticClass:"tag-input-cell taxonomy-multi-field"},[_c('document-metadata-tag-input',{attrs:{"currentValues":row.item.value,"documentId":_vm.documentId,"getMetadataFunction":_vm.getMetadataAndFieldInfos,"isWaitingForValues":_vm.isFetchingMetadataWithFieldInfos ||
              (row.item.field === _vm.KKS_METADATA_FIELD && _vm.isValidatingKks),"metadataField":row.item.field,"mode":_vm.mode,"possibleValues":_vm.getPossibleValuesForField(row.item.field),"tagClasses":_vm.getKksTagsClasses(row),"uploadId":_vm.uploadId},on:{"document-data-changed":function($event){return _vm.$emit('document-data-changed')}}}),(
              row.item.field === _vm.KKS_METADATA_FIELD &&
              _vm.getCheckBotKks()?.length
            )?_c('b-button',{staticClass:"show-kks-proposals-button",attrs:{"size":"sm"},on:{"click":_vm.toggleIsShowingKksProposals}},[_vm._v(" "+_vm._s(_vm.getKksProposalsButtonLabel())+" ")]):_vm._e()],1),_c('div',[(
              row.item.field === _vm.KKS_METADATA_FIELD &&
              _vm.isUserRole(_vm.EDITING_METADATA_ALLOWED_ROLES)
            )?[_c('div',{staticClass:"check-bot-established-kks"},[_c('check-bot-established-kks-panel',{attrs:{"digitalizationDocument":_vm.digitalizationDocument,"file":_vm.file,"isActive":_vm.isShowingKksProposals,"isValidatingKks":_vm.isValidatingKks,"kksValidationList":_vm.kksValidationList,"mode":_vm.mode},on:{"document-data-changed":_vm.updateDocumentData}})],1)]:_vm._e()],2)]:(
          _vm.mode === _vm.modes.digitalizationProject &&
          _vm.isFieldEditable(row.item.field) &&
          _vm.documentMetadataAndFieldInfos &&
          _vm.isUserRole(_vm.EDITING_METADATA_ALLOWED_ROLES)
        )?[(
            _vm.isTaxonomyMultiField(row.item.field) ||
            row.item.field === _vm.KKS_METADATA_FIELD
          )?[_c('div',{staticClass:"taxonomy-multi-field"},[_c('document-metadata-tag-input',{attrs:{"currentValues":row.item.value,"digitalizationDocumentObjectId":_vm.digitalizationDocument._id,"digitalizationProjectId":_vm.digitalizationDocument.digitalizationProjectId,"documentId":_vm.documentId,"getMetadataFunction":_vm.getMetadataAndFieldInfos,"isWaitingForValues":_vm.isFetchingMetadataWithFieldInfos,"metadataField":row.item.field,"mode":_vm.mode,"possibleValues":_vm.getPossibleValuesForField(row.item.field),"tagClasses":_vm.getKksTagsClasses(row),"uploadId":_vm.uploadId,"validationFunction":_vm.validateMetadataForField(row.item.field)},on:{"document-data-changed":function($event){return _vm.$emit('document-data-changed')}}}),(
                row.item.field === _vm.KKS_METADATA_FIELD &&
                _vm.getCheckBotKks()?.length
              )?_c('b-button',{staticClass:"show-kks-proposals-button",attrs:{"size":"sm"},on:{"click":_vm.toggleIsShowingKksProposals}},[_vm._v(" "+_vm._s(_vm.getKksProposalsButtonLabel())+" ")]):_vm._e()],1),_c('div',[(
                row.item.field === _vm.KKS_METADATA_FIELD &&
                _vm.isUserRole(_vm.EDITING_METADATA_ALLOWED_ROLES)
              )?[_c('div',{staticClass:"check-bot-established-kks"},[_c('check-bot-established-kks-panel',{attrs:{"digitalizationDocument":_vm.digitalizationDocument,"file":_vm.file,"isActive":_vm.isShowingKksProposals,"isValidatingKks":_vm.isValidatingKks,"kksValidationList":_vm.kksValidationList,"mode":_vm.mode},on:{"document-data-changed":_vm.updateDocumentData}})],1)]:_vm._e()],2)]:(_vm.isTaxonomyField(row.item.field))?[_c('document-metadata-select',{attrs:{"currentValue":row.item.value,"digitalizationDocumentObjectId":_vm.digitalizationDocument._id,"digitalizationProjectId":_vm.digitalizationDocument.digitalizationProjectId,"getMetadataFunction":_vm.getMetadataAndFieldInfos,"isWaitingForValues":_vm.isFetchingMetadataWithFieldInfos,"metadataField":row.item.field,"possibleValues":_vm.getPossibleValuesForField(row.item.field),"validationFunction":_vm.validateMetadataForField(row.item.field)},on:{"document-data-changed":function($event){return _vm.$emit('document-data-changed')}}})]:(_vm.isDateField(row.item.field))?[_c('document-metadata-calendar-input',{attrs:{"digitalizationDocumentObjectId":_vm.digitalizationDocument._id,"digitalizationProjectId":_vm.digitalizationDocument.digitalizationProjectId,"getMetadataFunction":_vm.getMetadataAndFieldInfos,"initialValue":row.item.value,"metadataField":row.item.field,"validationFunction":_vm.validateMetadataForField(row.item.field)},on:{"document-data-changed":function($event){return _vm.$emit('document-data-changed')}}})]:(
            row.item.field !== 'documentId' &&
            row.item.field !== _vm.KKS_METADATA_FIELD
          )?[_c('document-metadata-free-text-input',{attrs:{"digitalizationDocumentObjectId":_vm.digitalizationDocument._id,"digitalizationProjectId":_vm.digitalizationDocument.digitalizationProjectId,"getMetadataFunction":_vm.getMetadataAndFieldInfos,"initialValue":row.item.value,"metadataField":row.item.field,"validationFunction":_vm.validateMetadataForField(row.item.field)},on:{"document-data-changed":function($event){return _vm.$emit('document-data-changed')}}})]:[_vm._v(" "+_vm._s(row.item.value)+" ")]]:[_vm._v(" "+_vm._s(row.item.value)+" ")]]}}])}),_c('div',{staticClass:"table-bottom-panel"},[(_vm.metadata)?_c('vf-button',{attrs:{"variant":_vm.buttonVariants.secondary,"size":_vm.buttonSizes.medium},on:{"click":_vm.toggleIsShowingOnlyEditableFields}},[_vm._v(" "+_vm._s(_vm.isShowingOnlyEditableFields ? _vm.$t('documentDetailView.showAllMetadata') : _vm.$t('documentDetailView.showLessMetadata'))+" ")]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }