import VfButton from '@/components/VfButton/VfButton.vue';
import endpointsList from '@/helpers/endpointsList';
import axios from 'axios';
import {
  IDigitalizationDocument,
  POSITIVE_DIGITALIZATION_FORMAL_CONTROL_STATUSES,
  QualityCheckStatus,
} from 'types';
import Vue from 'vue';
import { ButtonVariant } from '../VfButton/VfButton.script';

interface IQualityCheckPanel {
  buttonVariants: {
    primary: ButtonVariant.PRIMARY;
    secondary: ButtonVariant.SECONDARY;
  };
  isChangingStatus: boolean;
  qualityCheckStatuses: {
    accepted: QualityCheckStatus.ACCEPTED;
    rejected: QualityCheckStatus.REJECTED;
  };
}

export default Vue.extend({
  name: 'quality-check-panel',
  components: { VfButton },

  props: {
    digitalizationDocument: {
      type: Object as () => IDigitalizationDocument,
      required: true,
    },
  },

  data(): IQualityCheckPanel {
    return {
      buttonVariants: {
        primary: ButtonVariant.PRIMARY,
        secondary: ButtonVariant.SECONDARY,
      },
      isChangingStatus: false,
      qualityCheckStatuses: {
        accepted: QualityCheckStatus.ACCEPTED,
        rejected: QualityCheckStatus.REJECTED,
      },
    };
  },

  methods: {
    isButtonDisabled(buttonForStatus: QualityCheckStatus): boolean {
      return (
        !POSITIVE_DIGITALIZATION_FORMAL_CONTROL_STATUSES.includes(
          this.digitalizationDocument.formalControlStatus,
        ) ||
        this.digitalizationDocument.qualityCheckStatus ===
          QualityCheckStatus.ACCEPTED ||
        (buttonForStatus === QualityCheckStatus.REJECTED &&
          this.digitalizationDocument.qualityCheckStatus ===
            QualityCheckStatus.REJECTED)
      );
    },

    async setQualityCheckStatus(
      qualityCheckStatus: QualityCheckStatus,
    ): Promise<void> {
      this.isChangingStatus = true;
      try {
        await axios.patch(
          endpointsList.digitalizationUploads.setQualityCheckStatusOfDocument(
            this.digitalizationDocument.digitalizationProjectId.toString(),
            this.digitalizationDocument.documentId,
            qualityCheckStatus,
          ),
        );

        this.$emit('document-data-changed');

        (this as any).createToast(
          this.$t(''),
          this.$t('qualityCheckPanel.qualityCheckStatusSet'),
          'success',
        );
      } catch {
        (this as any).createToast(
          this.$t('errors.error'),
          this.$t(
            'qualityCheckPanel.errors.errorSettingQualityCheckStatus.long',
          ),
          'danger',
        );
      } finally {
        this.isChangingStatus = false;
      }
    },
  },
});
