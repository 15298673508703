"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBoolean = parseBoolean;
/**
 * Converts a boolean-like string to a boolean value.
 * Accepts "true" (case-insensitive) as `true` and
 * "false" (case-insensitive) as `false`.
 * Returns `undefined` for invalid values.
 *
 * @param value - The string to convert.
 * @returns The boolean representation or `undefined` if invalid.
 */
function parseBoolean(value) {
    if (typeof value !== 'string')
        return undefined;
    const normalized = value.trim().toLowerCase();
    if (normalized === 'true')
        return true;
    if (normalized === 'false')
        return false;
    return undefined;
}
