import axios, { AxiosError, AxiosResponse } from 'axios';
import { UpdateWriteOpResult } from 'mongoose';
import {
  IAddDigitalizationProjectContentTypeAssignmentDto,
  IContentType,
  IContentTypeNameAndId,
  Serialized,
} from 'types';
import Vue from 'vue';
import { TranslateResult } from 'vue-i18n';
import StyledModal from '../../components/StyledModal/StyledModal.vue';
import StyledMultiselect from '../../components/StyledMultiselect/StyledMultiselect.vue';
import endpointsList from '../../helpers/endpointsList';
import { ToastMixin } from '../../mixins/globalUtils';

export enum ContentTypeModalMode {
  PROJECT = 'project',
  DIGITALIZATION_PROJECT = 'digitalization-project',
}

interface IContentTypeModal {
  allContentTypes: IContentTypeNameAndId[];
  selectedContentType: IContentTypeNameAndId | null;
  isLoadingContentTypes: boolean;
  isSavingContentType: boolean;
  createToast?: ToastMixin;
  supplierErrorMessage: null | TranslateResult;
  contentTypeErrorMessage: null | TranslateResult;
  errorMessage: null | TranslateResult;
}

export default Vue.extend({
  name: 'content-type-modal',

  components: { StyledModal, StyledMultiselect },

  props: {
    projectId: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
    mode: {
      type: String as () => ContentTypeModalMode,
      required: true,
    },
  },

  data(): IContentTypeModal {
    return {
      allContentTypes: [],
      selectedContentType: null,
      isLoadingContentTypes: false,
      isSavingContentType: false,
      supplierErrorMessage: null,
      contentTypeErrorMessage: null,
      errorMessage: null,
    };
  },

  methods: {
    cleanAfterAddingContentType() {
      this.selectedContentType = null;
      this.isLoadingContentTypes = false;
      this.isSavingContentType = false;
      this.resetErrors();
    },

    resetErrors() {
      this.errorMessage = null;
      this.contentTypeErrorMessage = null;
    },

    async handleModalOK(bvModalEvent: Event) {
      bvModalEvent.preventDefault();

      this.resetErrors();

      if (!this.selectedContentType) {
        this.contentTypeErrorMessage = this.$t(
          'projectDetails.contentTypes.errors.noContentTypeSelected',
        );
      }
      if (!this.selectedContentType) {
        return;
      }

      this.isSavingContentType = true;

      try {
        if (this.mode === ContentTypeModalMode.PROJECT) {
          await this.submitProjectContentType();
        } else if (this.mode === ContentTypeModalMode.DIGITALIZATION_PROJECT) {
          await this.submitDigitalizationProjectContentTypeMapping();
        }
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          if (err.request.status === 409) {
            this.errorMessage = this.$t(
              'projectDetails.contentTypeAlreadyAdded',
            );
          } else {
            this.errorMessage = this.$t('suppliersForm.submitError');
          }
        } else {
          this.errorMessage = this.$t('suppliersForm.submitError');
        }
      } finally {
        this.isSavingContentType = false;
      }
    },

    async submitProjectContentType() {
      if (!this.selectedContentType) return;

      const assignmentObject = {
        supplierId: this.supplierId,
        projectId: this.projectId,
        fieldsMapping: [],
      };

      const response: AxiosResponse<IContentType | UpdateWriteOpResult> =
        await axios.post(
          endpointsList.contentTypeMappings.addAssignmentToContentType(
            this.selectedContentType.id,
          ),
          assignmentObject,
        );

      if (response.status === 201) {
        this.isSavingContentType = false;
        this.$bvModal.hide('add-content-type-modal');
        this.cleanAfterAddingContentType();
        this.$emit('content-type-added');
      }
    },

    async submitDigitalizationProjectContentTypeMapping() {
      if (!this.selectedContentType) return;

      const body: Serialized<IAddDigitalizationProjectContentTypeAssignmentDto> =
        {
          contentTypeId: this.selectedContentType.id,
          digitalizationProjectId: this.projectId,
        };

      await axios.post(
        endpointsList.contentTypeMappings
          .addDigitalizationProjectContentTypeAssignment,
        body,
      );

      this.isSavingContentType = false;
      this.$bvModal.hide('add-content-type-modal');
      this.cleanAfterAddingContentType();
      this.$emit('content-type-added');
    },

    handleModalCancel() {
      this.cleanAfterAddingContentType();
    },

    async getAllContentTypes() {
      this.isLoadingContentTypes = true;
      try {
        const response: AxiosResponse<IContentTypeNameAndId[]> =
          await axios.get(
            endpointsList.sharePointIntegration
              .getListOfContentTypesNamesAndIds,
          );

        this.allContentTypes = response.data;
      } catch {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      }
      this.isLoadingContentTypes = false;
    },

    onModalShow() {
      this.getAllContentTypes();
    },
  },
});
