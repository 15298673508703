"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordLibraryTransferStatus = void 0;
var RecordLibraryTransferStatus;
(function (RecordLibraryTransferStatus) {
    RecordLibraryTransferStatus["SUCCESSFULL"] = "successfull";
    RecordLibraryTransferStatus["FAILED"] = "failed";
    RecordLibraryTransferStatus["AWAITING_INSPECTIONS_COMPLETION"] = "awaitingInspectionsCompletion";
    RecordLibraryTransferStatus["IN_PROGRESS"] = "inProgress";
})(RecordLibraryTransferStatus || (exports.RecordLibraryTransferStatus = RecordLibraryTransferStatus = {}));
