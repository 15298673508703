import { render, staticRenderFns } from "./ProjectForm.vue?vue&type=template&id=aaf76c5e&scoped=true"
import script from "./ProjectForm.script.ts?vue&type=script&lang=ts&external"
export * from "./ProjectForm.script.ts?vue&type=script&lang=ts&external"
import style0 from "./ProjectForm.styles.scss?vue&type=style&index=0&id=aaf76c5e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf76c5e",
  null
  
)

export default component.exports