import { endpointsList } from '@/helpers';
import { GlobalUtils } from '@/mixins/globalUtils';
import axios from 'axios';
import { IDigitalizationDocument } from 'types';
import Vue from 'vue';

export default Vue.extend({
  name: 'accept-metadata-inspection-button',

  props: {
    digitalizationDocument: {
      type: Object as () => IDigitalizationDocument,
      required: false,
    },
  },

  data() {
    return {
      isContinueProcessRequestPending: false,
    };
  },

  methods: {
    async handleContinueProcessClick() {
      try {
        this.isContinueProcessRequestPending = true;

        await axios.patch(
          endpointsList.digitalizationUploads.updateMetadataOfMultipleDocuments(
            this.digitalizationDocument.digitalizationProjectId.toString(),
            true,
          ),
          {
            [this.digitalizationDocument._id.toString()]: {},
          },
        );

        (this as unknown as GlobalUtils).createToast(
          '',
          this.$t('documentDetailView.metadataCheckAccepted'),
          'success',
        );
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('documentDetailView.errors.errorAcceptingMetadataCheck'),
          'danger',
        );
      } finally {
        this.$emit('document-data-changed');
        this.isContinueProcessRequestPending = false;
      }
    },
  },
});
