<template>
  <div class="ibl-view-container">
    <div class="top-panel">
      <styled-subheading>{{ $t('iblView.title') }}</styled-subheading>
      <vf-button
        v-if="canUserUpload"
        :size="ButtonSize.LARGE"
        :variant="ButtonVariant.SECONDARY"
        @click="handleUploadListClick()"
        >{{ $t('iblView.uploadList') }}</vf-button
      >
      <ibl-upload-modal
        :projectId="projectId"
        :projectType="projectType"
        :ref="iblUploadModalRef"
        @ibl-import-finished="handleIblImportFinished"
      />
    </div>
    <vgbm-table-wrapper
      :key="vgbmTableWrapperRerenderKey"
      :projectId="projectId"
    />
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { ButtonVariant, ButtonSize } from '../VfButton/VfButton.script';
  import IblUploadModal from '../IblUploadModal/IblUploadModal.vue';
  import { ProjectType, Role } from 'types';
  import { GlobalUtils } from '@/mixins/globalUtils';
  import VgbmTableWrapper from '@/components/VgbmTableWrapper/VgbmTableWrapper.vue';

  export default Vue.extend({
    name: 'ibl-view',
    components: { IblUploadModal, VgbmTableWrapper },
    props: {
      projectId: {
        type: String,
        required: true,
      },
      projectType: {
        type: String as () => ProjectType,
        required: true,
      },
    },
    data() {
      return {
        ButtonSize,
        ButtonVariant,
        vgbmTableWrapperRerenderKey: 0,
      };
    },
    computed: {
      canUserUpload(): boolean {
        return (this as unknown as GlobalUtils).isUserRole([
          Role.Admin,
          Role.DocuResponsible,
          Role.Coworker,
          Role.DigitalizationCoworker,
        ]);
      },
      iblUploadModalRef(): string {
        return 'ibl-upload-modal';
      },
    },
    methods: {
      handleUploadListClick() {
        const modalId = (
          this.$refs[this.iblUploadModalRef] as InstanceType<
            typeof IblUploadModal
          >
        )?.modalId;
        this.$bvModal.show(modalId);
      },
      handleIblImportFinished() {
        this.vgbmTableWrapperRerenderKey++;
      },
    },
  });
</script>

<style lang="scss" scoped>
  .ibl-view-container {
    .top-panel {
      margin-bottom: 1.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
