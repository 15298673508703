"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapKeyValue = mapKeyValue;
/**
 * Maps an array of objects to a key-value object based on the specified keys,
 * ensuring that `key2` only accepts keys whose values are strings.
 *
 * @template T - The type of objects in the input array.
 * @param array - The array of objects to transform.
 * @param key1 - The key in the object to use as the keys in the resulting
 * object.
 * @param key2 - The key in the object to use as the values in the resulting
 * object.
 * @returns A `KeyValueObject` where the keys are derived from `key1` and
 * values from `key2`.
 *
 * @example
 * const data = [
 *   { id: "1", value: "Alice" },
 *   { id: "2", value: "Bob" },
 *   { id: "3", value: 42 }, // TypeScript will prevent using "value" as key2
 *   in this case
 * ];
 *
 * const result = mapKeyValue(data, "id", "value");
 * // Output: { "1": "Alice", "2": "Bob" }
 */
function mapKeyValue(array, key1, key2) {
    return array.reduce((acc, item) => {
        const key1Value = item[key1];
        const key2Value = item[key2];
        if (typeof key1Value === 'string' && typeof key2Value === 'string') {
            acc[key1Value] = key2Value;
        }
        return acc;
    }, {});
}
