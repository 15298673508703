"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RecordLibraryTransferStatusHelper = void 0;
const types_1 = require("types");
class RecordLibraryTransferStatusHelper {
    constructor(recordLibraryTransferStatus) {
        this.recordLibraryTransferStatus = recordLibraryTransferStatus;
    }
    get isSuccessfull() {
        return (this.recordLibraryTransferStatus ===
            types_1.RecordLibraryTransferStatus.SUCCESSFULL);
    }
    get isFailed() {
        return (this.recordLibraryTransferStatus === types_1.RecordLibraryTransferStatus.FAILED);
    }
    get isWaiting() {
        return (this.recordLibraryTransferStatus ===
            types_1.RecordLibraryTransferStatus.AWAITING_INSPECTIONS_COMPLETION);
    }
}
exports.RecordLibraryTransferStatusHelper = RecordLibraryTransferStatusHelper;
