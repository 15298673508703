import {
  ACTOR_APP,
  CheckBotModuleStrings,
  CheckbotStatusEnum,
  DocumentEvent,
  InspectorStatusEnum,
  FileStatus,
  MetadataCheckStatus,
  RecordLibraryTransferStatus,
  FormalControlStatus,
  DigitalizationProjectStatus,
  CheckBotInferenceStatus,
  QualityCheckStatus,
  MetadataInspectionStatus,
  DigitalizationFormalControlStatus,
  Role,
  DocumentStatus,
} from 'types';
import { UploadType } from '../components/UploadTable/UploadTable.script';
import { RemarksModalMode } from '@/components/RemarksModal/RemarksModal.script';
import { DigiDocuProcessingStep } from '@/helpers/getCurrentDigitalizationDocumentProcessingStep';
import { DocuProcessingStep } from '@/helpers/getCurrentDocumentProcessingStep';

export default {
  generic: {
    placeholder: 'Platzhalter',
    notImplemented: 'Nicht implementiert',
    firstName: 'Vorname',
    lastName: 'Name',
    supplier: 'Lieferant | Lieferanten',
    email: 'Email',
    contentType: 'Inhaltstyp',
    uploadDate: 'Upload-Datum',
    inspectionDueDate: 'Prüffrist',
    inspection: 'Formale Prüf.',
    inspectorStatus: 'Fachliche Prüf.',
    metadataInference: 'KI Vorschläge',
    metadataInspection: 'Metadatenprüfung',
    qualityCheck: 'Qualitätsprüfung',
    qualityCheckAbbreviated: 'Qualitätsprüf.',
    documentId: 'Dokument ID',
    documentIdAbbreviated: 'Dok.',
    administration: 'Administration',
    docuManager: 'Dokumenten-Manager',
    saveChanges: 'Änderungen speichern',
    cancel: 'Abbrechen',
    ok: 'Ok',
    document: 'Dokument',
    apply: 'Anwenden',
    warning: 'Achtung',
    suppliers: 'Lieferanten',
    coworkers: 'Mitarbeiter',
    goBack: 'Zurück',
    project: 'Projekt',
    projects: 'Projekte',
    coworker: 'Mitarbeiter',
    save: 'Speichern',
    add: 'Hinzufügen',
    close: 'Schließen',
    select: 'Auswählen',
    removeAllFilters: 'Alle Filter entfernen',
    clear: 'löschen',
    showAll: 'Alle',
    recordLibraryTransfer: 'Übertragung ins Daisy',
    yes: 'ja',
    no: 'nein',
    forExample: 'z.B.',

    selectOption: 'Option auswählen',

    months: {
      january: 'Januar',
      february: 'Februar',
      march: 'März',
      april: 'April',
      may: 'Mai',
      june: 'Juni',
      july: 'Juli',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'Dezember',
    },

    days: {
      monday: 'M',
      tuesday: 'D',
      wednesday: 'M',
      thursday: 'D',
      friday: 'F',
      saturday: 'S',
      sunday: 'S',
    },
  },

  // To be removed and replaced with formalControlStatus
  checkBotStatus: {
    [CheckbotStatusEnum.ok]: 'Angenommen',
    [CheckbotStatusEnum.waiting]: 'In Bearbeitung',
    [CheckbotStatusEnum.critical]: 'Abgelehnt',
    [CheckbotStatusEnum.info]: 'Angenommen',
    [CheckbotStatusEnum.error]: 'Abgelehnt',
    [CheckbotStatusEnum.timedOut]: 'Timeout',
    [CheckbotStatusEnum.warning]: 'Angenommen mit Auflagen',
  },

  formalControlStatus: {
    [FormalControlStatus.ACCEPTED]: 'Angenommen',
    [FormalControlStatus.ACCEPTED_WITH_REMARKS]: 'Angenommen mit Auflagen',
    [FormalControlStatus.REJECTED]: 'Abgelehnt',
    [FormalControlStatus.WAITING]: 'In Bearbeitung',
  },

  digitalizationFormalControlStatus: {
    [DigitalizationFormalControlStatus.ACCEPTED]: 'Angenommen',
    [DigitalizationFormalControlStatus.ACCEPTED_WITH_REMARKS]:
      'Angenommen mit Auflagen',
    [DigitalizationFormalControlStatus.REJECTED]: 'Abgelehnt',
    [DigitalizationFormalControlStatus.WAITING]: 'In Bearbeitung',
    [DigitalizationFormalControlStatus.WAITING_FOR_METADATA_CHECK]:
      'Wartet auf Metadatenprüfung',
  },

  inspectorStatus: {
    [InspectorStatusEnum.accepted]: 'Angenommen',
    [InspectorStatusEnum.acceptedWithRemarks]: 'Angenommen mit Auflagen',
    [InspectorStatusEnum.timedOut]: 'Prüffrist abgelaufen',
    [InspectorStatusEnum.waiting]: 'In Bearbeitung',
    [InspectorStatusEnum.rejected]: 'Abgelehnt',
    [InspectorStatusEnum.waitingForFormalControl]: '-',
  },

  metadataCheckStatus: {
    [MetadataCheckStatus.FAILED]: 'Abgelehnt',
    [MetadataCheckStatus.ACCEPTED]: 'Angenommen',
  },

  fileStatus: {
    [FileStatus.waitingForFormalControl]: 'Wartet auf formale Prüfung',
    [FileStatus.waitingForContentControl]: 'Wartet auf inhaltliche Prüfung',
    [FileStatus.rejected]: 'Abgelehnt',
    [FileStatus.accepted]: 'Angenommen',
    [FileStatus.acceptedWithRemarks]: 'Angenommen mit Auflagen',
  },

  recordLibraryTransferStatus: {
    [RecordLibraryTransferStatus.SUCCESSFULL]: 'Erfolgreich',
    [RecordLibraryTransferStatus.FAILED]: 'Fehlgeschlagen',
    [RecordLibraryTransferStatus.AWAITING_INSPECTIONS_COMPLETION]: '-',
  },

  checkBotInferenceStatus: {
    [CheckBotInferenceStatus.CHECK_BOT_INFERENCE_DISABLED]: 'Deaktiviert',
    [CheckBotInferenceStatus.WAITING]: 'Ausstehend',
    [CheckBotInferenceStatus.DATA_RECEIVED]: 'Erhalten',
  },

  metadataInspectionStatus: {
    [MetadataInspectionStatus.WAITING]: 'In Bearbeitung',
    [MetadataInspectionStatus.ACCEPTED]: 'Angenommen',
    [MetadataInspectionStatus.REJECTED]: 'Abgelehnt',
  },

  qualityCheckStatus: {
    [QualityCheckStatus.WAITING_FOR_FORMAL_CONTROL]:
      'Wartet auf formale Prüfung',
    [QualityCheckStatus.WAITING]: 'In Bearbeitung',
    [QualityCheckStatus.ACCEPTED]: 'Angenommen',
    [QualityCheckStatus.REJECTED]: 'Abgelehnt',
  },

  digitalizationDocumentStatus: {
    [DocumentStatus.METADATA_INSPECTION]: '@:generic.metadataInspection',
    [DocumentStatus.FORMAL_CONTROL]: 'Formale Prüfung',
    [DocumentStatus.QUALITY_CHECK]: '@:generic.qualityCheck',
    [DocumentStatus.RECORD_LIBRARY_TRANSFER_IN_PROGRESS]:
      'Übertragung ins Daisy',
    [DocumentStatus.RECORD_LIBRARY_TRANSFER_FAILED]:
      'Übertragung ins Daisy fehlgeschlagen',
    [DocumentStatus.RECORD_LIBRARY_TRANSFER_SUCCESSFULL]: 'Abgeschlossen',
  },

  roles: {
    [Role.Admin]: 'Administrator | Administratoren',
    [Role.DocuManager]: 'Dokumenten-Manager | Dokumenten-Manager',
    [Role.Inspector]: 'Prüfer | Prüfer',
    [Role.Coworker]: 'Mitarbeiter | Mitarbeiter',
    [Role.DocuResponsible]:
      'Dokumentationsverantwortlicher | Dokumentationsverantwortliche',
    [Role.DigitalizationCoworker]:
      'Digitalisierungs-Lieferant | Digitalisierungs-Lieferanten',
  },

  app: {
    logOutReminder: 'Melden Sie sich ab, bevor Sie dieses Fenster schließen',
  },

  breadCrumb: {
    projectsList: 'Projektliste',
    createProject: 'Projekt anlegen',
    projectDetails: 'Projekt Details',
    contentTypeConfiguration: 'Import Konfiguration',
    addDocuManagerToProject: 'Dokumentenmanager zum Projekt zuweisen',
    addCoworkerToProject: 'Mitarbeiter zu Projekt hinzufügen',
    addSupplierToProject: 'Lieferant zu Projekt hinzufügen',
    documentUpload: 'Dokument-Anlieferung',
    documentDetailView: 'Dokument Details',
    uploadReferenceNumber: 'Anlieferung',
    digitalizationUploadReferenceNumber: 'Anlieferung',
    suppliersList: 'Lieferantenliste',
    supplierDetails: 'Lieferantendetails',
    coworkerDetails: 'Mitarbeiterdetails',
    administration: 'Administration',
    docuManagerDetailView: 'Dokumentenmanager details',
    appConfig: 'App Konfiguration',
    checkBotConfigurator: 'Checkbot Konfigurator',
    digitalizationProjectsList: 'Liste interner Projekte',
    createDigitalizationProject: 'Internes Projekt anlegen',
    digitalizationProjectDetails: 'Projekt Details',
  },

  navBar: {
    header: 'DL Doku Portal',
    suppliers: 'Lieferantenverwaltung',
    projects: 'Projekte',
    administration: 'Administration',
    digitalizationProjects: 'Interne Projekte',
  },

  filterableTable: {
    filter: 'Filter',
    entriesPerPage: 'Einträge pro Seite',
    filterBy: 'Nach {field} filtern',
    all: 'Alle',
    resetAllFilters: 'Alle Filter zurücksetzen',
  },

  projectsList: {
    projects: 'Projekte',
    title: 'Titel',
    number: 'Nummer',
    status: 'Status',
    projectTitle: 'Titel',
    projectNumber: 'Nummer',
    projectStatus: 'Status',
    fileStatuses: 'Anlieferungsstatus',
    addProject: 'Projekt hinzufügen',
    errors: {
      couldNotLoadProjects:
        'Beim Laden der Projekte ist ein Fehler aufgetreten',
    },
    description:
      'Willkommen in unserer Projektübersicht und -verwaltung! ' +
      'Hier können Sie alle Informationen und Fortschritte Ihrer ' +
      'Projekte zentral verwalten und organisieren, um eine effiziente ' +
      'und erfolgreiche Projektumsetzung sicherzustellen.',
    active: 'aktiv',
    inactive: 'inaktiv',
  },

  suppliersList: {
    suppliers: 'Lieferanten',
    name: 'Name',
    SAPNumber: 'SAP-Nr',
    coworkers: 'Mitarbeiter',
    description:
      'Willkommen in unserer Lieferantenübersicht und -verwaltung! ' +
      'Hier können Sie alle Informationen über Ihre Lieferanten an ' +
      'einem Ort zusammenführen.',
  },

  projectDetails: {
    project: 'Projekt',
    goToProjectRoom: 'Zum Projektraum abspringen',
    tabs: {
      settings: 'Einstellungen',
      suppliers: 'Lieferanten',
      coworkers: 'Mitarbeiter',
      contentTypes: 'Inhaltstypen',
      deliveries: 'Anlieferungen',
      documents: 'Dokumente',
    },

    contentTypes: {
      contentTypes: '@:projectDetails.tabs.contentTypes',
      contentTypeName: 'Name',
      supplierName: 'Lieferant',
      addContentType: 'Inhaltstyp hinzufügen',
      selectContentType: 'Inhaltstyp',
      selectSupplier: 'Lieferant auswählen',
      errors: {
        noContentTypeSelected: 'Kein Inhaltstyp wurde ausgewählt',
        noSupplierSelected: 'Kein Lieferant wurde ausgewählt',
      },
      uploadCount: 'Anlieferungen',
    },

    documents: {
      documents: '@:projectDetails.tabs.documents',
      documentId: '@:generic.documentId',
      supplierName: '@:generic.supplier',
      contentType: '@:generic.contentType',
      uploadDate: '@:generic.uploadDate',
      inspectionDueDate: '@:generic.inspectionDueDate',
      coworker: '@:generic.coworker',
      checkBotStatus: '@:generic.inspection',
      inspectorStatus: '@:generic.inspectorStatus',
      revision: 'Rev.',
      referenceNumber: 'Referenznr.',
      rejectDocuments: 'Dokumente ablehnen',
      acceptDocuments: 'Dokumente akzeptieren',
      errors: {
        couldNotLoadDocuments:
          'Beim Laden der Dokumente ist ein Fehler aufgetreten',
      },
      selectAll: 'Alle Dokumente auswählen',
      deselectAll: 'Alle Dokumente abwählen',

      [CheckbotStatusEnum.ok]: `@:checkBotStatus.${CheckbotStatusEnum.ok}`,
      [CheckbotStatusEnum.waiting]: `@:checkBotStatus.${CheckbotStatusEnum.waiting}`,
      [CheckbotStatusEnum.critical]: `@:checkBotStatus.${CheckbotStatusEnum.critical}`,
      [CheckbotStatusEnum.info]: `@:checkBotStatus.${CheckbotStatusEnum.info}`,
      [CheckbotStatusEnum.error]: `@:checkBotStatus.${CheckbotStatusEnum.error}`,
      [CheckbotStatusEnum.timedOut]: `@:checkBotStatus.${CheckbotStatusEnum.timedOut}`,
      [CheckbotStatusEnum.warning]: `@:checkBotStatus.${CheckbotStatusEnum.warning}`,
      // For formal control filter select
      [MetadataCheckStatus.FAILED]: `@:metadataCheckStatus.${MetadataCheckStatus.FAILED}`,

      [InspectorStatusEnum.accepted]: `@:inspectorStatus.${InspectorStatusEnum.accepted}`,
      [InspectorStatusEnum.acceptedWithRemarks]: `@:inspectorStatus.${InspectorStatusEnum.acceptedWithRemarks}`,
      [InspectorStatusEnum.timedOut]: `@:inspectorStatus.${InspectorStatusEnum.timedOut}`,
      // [InspectorStatusEnum.waiting]: `@:inspectorStatus.${InspectorStatusEnum.waiting}`,
      [InspectorStatusEnum.rejected]: `@:inspectorStatus.${InspectorStatusEnum.rejected}`,
      [InspectorStatusEnum.waitingForFormalControl]: '-',

      undefined: '-',

      [RecordLibraryTransferStatus.SUCCESSFULL]: `@:recordLibraryTransferStatus.${RecordLibraryTransferStatus.SUCCESSFULL}`,
      // [RecordLibraryTransferStatus.FAILED]: `@:recordLibraryTransferStatus.${RecordLibraryTransferStatus.FAILED}`,

      recordLibraryTransferStatus: 'Übertragung ins Daisy',
    },

    // old
    projectDetails: 'Projekt Details',
    addSupplier: 'Neuer Lieferant',
    showCoworkers: 'Mitarbeiterliste',
    hideCoworkers: 'Mitarbeiterliste ausblenden',
    addCoworker: 'Neuer Mitarbeiter',
    docuManagers: 'Dokumentenmanager',
    addDocuManager: 'Dokumentenmanager',
    generalInfo: 'Allgemeine Informationen',
    suppliers: 'Lieferanten',
    configuration: 'Konfiguration',
    csvMapping: 'Spaltenzuordnung',
    contentTypeMapping: 'Contenttyp mapping',
    chooseContentType: 'Contenttyp auswählen',
    contentTypeAlreadyAdded: 'Dieser Contenttyp wurde schon hinzugefügt',
    filesOverview: 'Dateiübersicht',
    fileUpload: 'Anlieferungen',
    action: 'Aktion',
    assignedContentTypes: 'Zugewiesene Content Typen',
    contentTypesManagement: 'Content Typen Verwaltung',
  },

  adminPanel: {
    appConfiguration: 'App Konfiguration',
    checkBotTimeout: 'Checkbot Timeout (in Tagen)',
    saveChanges: '@:generic.saveChanges',
    firstName: '@:generic.firstName',
    lastName: '@:generic.lastName',
    email: '@:generic.email',
    administration: '@:generic.administration',
    docuManager: '@:generic.docuManager',
    checkCheckBotResults: 'Checkbot Resultate prüfen',
    checkMetadataInferenceResults: 'Checkbot Metadaten-Resultate prüfen',
    checkCheckBotTimeouts: 'Checkbot Timeouts prüfen',
    errors: {
      errorCheckingCheckBotResults:
        'Beim prüfen der Checkbot Resultate ist ein Fehler aufgetreten.',
      errorCheckingCheckBotTimeouts:
        'Beim prüfen der Checkbot Timeouts ist ein Fehler aufgetreten.',
    },
  },

  uploadsOverview: {
    deliveries: 'Anlieferungen',
    newDelivery: 'Neue Anlieferung',
    errors: {
      couldNotLoadDeliveries:
        'Beim Laden der Anlieferungen ist ein Fehler aufgetreten',
    },
    supplier: 'Lieferant',
    count: 'Dok.',
    contentType: 'Inhaltstyp',
    content: 'Inhalt',
    documentStatus: 'Dokumentenstatus',
    uploadDate: 'Upload-Datum',
    inspectionDueDate: 'Prüffrist',
    soonest: 'kürzeste',

    supplierName: 'Lieferant',
    filterContentTypes: 'Inhaltstypen filtern',
    new: 'Neu',
    updated: 'Aktualisiert',

    tooltips: {
      downloadUploadConfirmation: 'Anlieferungsbestätigung herunterladen',
    },

    hideProcessed: 'Abgeschlossene Anlieferungen ausblenden',
  },

  projectsSuppliers: {
    supplierName: 'Name',
    contentTypes: 'Inhaltstypen',
    deliveries: 'Anlieferungen',
    suppliers: 'Lieferanten',
    coworkers: 'Mitarbeiter',
    assignedContentTypeNames: 'Inhaltstypen',
    description:
      'Willkommen in unserer Lieferantenübersicht und -verwaltung! ' +
      'Hier können Sie alle Informationen über Ihre Lieferanten an ' +
      'einem Ort zusammenführen.',
    addSupplier: 'Lieferant hinzufügen',
    errors: {
      couldNotLoadSuppliers:
        'Beim Laden der Lieferanten im Projekt ist ein Fehler aufgetreten',
      couldNotUpdateConfigTemplate:
        'Beim einstellen der Checkbot Konfiguration Template ist ein Fehler aufgetreten',
      templateAlreadyAssignedShort:
        'Checkbot Konfiguration bereits eingestellt',
      templateAlreadyAssigned:
        'Das Checkbot Template für diesen Lieferant wurde bereits eingestellt',
      couldNotLoadTestKeys:
        'Beim Laden der Prüfungen für Checkbot Standardkonfigurationen ist ein Fehler aufgetreten',
      supplierAlreadyAdded:
        'Dieser Lieferant wurde zu diesem Projekt bereits hinzugefügt.',
      errorRemovingSupplier:
        'Beim entfernen des Lieferanten vom Projekt ist ein Fehler aufgetreten.',
      cannotRemoveSupplierWithUploads:
        'Dieser Lieferant kann nicht von diesem Projekt enfernt werden, da er bereits Anlieferungen durchgeführt hat.',
    },
    setCheckBotConfigTemplate: 'Checkbot Konfiguration Template einstellen',
    checkBotConfigTemplate: 'Checkbot Konfig. Template',
    checkBotConfig: 'Checkbot Konfiguration',
    checkBotConfigTemplateSet:
      'Checkbot Konfiguration Template wurde erfolgreich eingestellt',
    checkBotConfigTemplateSetShort: 'Checkbot Konfiguration eingestellt',
    enterSupplierName: 'Lieferantname eingeben',
    [CheckBotModuleStrings.CUSTOM_CHECK_BOT_CONFIG]: 'modifiziert',
    tooltips: {
      editCheckBotConfig: 'Checkbot Konfiguration bearbeiten',
      applyCheckBotConfigTemplate: 'Checkbot Konfigurationsvorlage anwenden',
      editSupplier: 'Lieferant bearbeiten',
    },
    templateReassignmentConfirmation:
      'Diese Vorlage wurde angepasst. Sind Sie sicher, dass Sie es überschreiben möchten?',
    deactivated: 'Deaktiviert',
    supplierSuccessfullyDeleted:
      'Der Lieferant wurde erflogreich von dem Projekt entfernt.',
  },

  projectsCoworkers: {
    errors: {
      couldNotLoadProjects:
        'Beim Laden der Mitarbeiter im Projekt ist ein Fehler aufgetreten',
      couldNotRemoveCoworker:
        'Beim Entfernen des Mitarbeiters von diesem Projekt ist ein Fehler aufgetreten.',
    },
    firstName: 'Vorname',
    lastName: 'Nachname',
    supplier: 'Lieferant',
    email: 'Email',
    description:
      'Willkommen in unserer Mitarbeiterübersicht und -verwaltung! ' +
      'Hier können Sie alle Informationen über Mitarbeiter des Lieferanten ' +
      'zentral verwalten und organisieren, um reibungslose Projektabläufe ' +
      'sicherzustellen.',
    coworkers: 'Mitarbeiter',
    addCoworker: 'Mitarbeiter hinzufügen',
    coworkerRemoved: 'Der Mitarbeiter wurde erfolgreich vom Projekt entfernt.',
  },

  supplierDetailView: {
    coworkers: 'Mitarbeiter',
    contentTypes: 'Inhaltstypen',
    checkBot: 'Checkbot',
    assignedConfiguration: 'Zugewiesene Konfiguration',
    configurationEditor: 'Konfigurationseditor',
    assignConfiguration: 'Konfiguration zuweisen',
    noConfigurationAssigned: 'Keine Konfiguration wurde zugewiesen',
  },

  pageTop: {
    languages: {
      en: 'Englisch',
      de: 'Deutsch',
    },
    logout: 'Ausloggen',
    currentUser: 'Aktueller Nutzer',
    chooseLang: 'Sprache auswählen',
  },

  sidebar: {
    header: 'DL Doku Portal',
    dashboard: 'Dashboard',
    suppliers: 'Lieferantenverwaltung',
    addSuplier: 'Dienstleister Firma anlegen',
    suppliersList: 'Lieferantenliste',
    projects: 'Projekte',
    projectsList: 'Projektliste',
    adminPanel: 'Administration',
  },

  errors: {
    error: 'Ein Fehler ist aufgetreten',
  },

  suppliersForm: {
    addSupplier: 'Dienstleister Firma anlegen',
    name: 'Name',
    supplierId: 'SAP Lieferantennummer',
    address: 'Adresse',
    street: 'Straße',
    houseNumber: 'Hausnummer',
    ZIPCode: 'PLZ',
    city: 'Ort',
    validationErrorEmpty: 'Dieses Feld kann nicht leer bleiben',
    validationErrorToLong: 'Die maximale Zeichenzahl ist 256',
    validationErrorZIPCode: 'Die Postleitzahl muss aus 5 Ziffern bestehen',
    submit: 'Senden',
    supplierCreated: 'Dienstleister Firma angelegt',
    submittingSupplier: 'Dienstleister Firma wird angelegt',
    submitError:
      'Bei der Übermittlung deiner Anfrage ist leider ein Fehler aufgetreten',
    submittingChanges: 'Lieferant Daten werden aktualisiert',
    changesSubmitted: 'Lieferant Daten wurden aktualisiert',
  },

  supplierDetails: {
    tabs: {
      informations: 'Informationen',
      projects: 'Projekte',
      coworkers: 'Mitarbeiter',
      contentTypes: 'Inhaltstypen',
      deliveries: 'Anlieferungen',
      documents: 'Dokumente',
    },

    informations: {
      informations: '@:supplierDetails.tabs.informations',
      street: 'Straße',
      houseNumber: 'Hausnummer',
      ZIPCode: 'PLZ',
      city: 'Ort',
      SAPNumber: 'SAP-Nr.',

      errors: {
        couldNotGetSupplierDetails:
          'Beim Laden des Lieferanten ist ein Fehler ist aufgetreten',
      },
    },

    coworkers: {
      coworkers: '@:generic.coworkers',
      firstName: '@:generic.firstName',
      lastName: '@:generic.lastName',
      email: '@:generic.email',
      description:
        'Willkommen in unserer Mitarbeiterübersicht und -verwaltung! ' +
        'Hier können Sie alle Informationen über Mitarbeiter des Lieferanten ' +
        'zentral verwalten und organisieren, um reibungslose Projektabläufe ' +
        'sicherzustellen.',
      addCoworker: 'Mitarbeiter hinzufügen',
      errors: {
        couldNotAddCoworker:
          'Beim hinzufügen des Mitarbeiters ist ein Fehler ist aufgetreten',
        coworkerAlreadyAddedLong:
          'Die Mitarbeiterzuweisung ist nicht möglich, da der Mitarbeiter bereits zum Lieferanten {supplierName} zugewiesen wurde.',
        coworkerAlreadyAddedShort: 'Mitarbeiterzuweisung nicht möglich',
      },
    },

    // old
    supplierDetails: 'Lieferantendetails',
    editSupplier: 'Lieferant bearbeiten',
    chooseCoworker: 'Mitarbeiter auswählen',
    updateSupplier: 'Speichern',
    cancelUpdateSupplier: 'Abbrechen',
    coworkerAlreadyAdded: 'Dieser Mitarbeiter wurde schon hinzugefügt.',
    coworkersList: 'Mitarbeiterliste',
    noCoworkerSelected: 'Kein Mitarbeiter wurde ausgewählt',
    coworkerAdded: 'Mitarbeiter wurde hinzugefügt',
    addCoworkerToSupplier: 'Mitarbeiter zu Lieferant hinzufügen',
    actions: 'Aktionen',
    deleteCoworker: 'Mitarbeiter entfernen',
    coworkerDeleted: 'Mitarbeiter wurde erfolgreich entfernt',
  },

  projectSettings: {
    settings: 'Einstellungen',
  },

  projectForm: {
    title: 'Titel',
    number: 'Nummer',
    location: 'Standort',
    status: 'Status',
    duration: 'Laufzeit',
    inspectionDueTime: 'Prüffrist (in Tagen)',
    description: 'Beschreibung',
    projectStatus: {
      active: 'aktiv',
      inactive: 'inaktiv',
    },
    changesSaved: 'Änderungen wurden gespeichert',
    saveChanges: 'Änderungen speichern',
    cancel: 'Abbrechen',
    createDigitalizationProject: 'Internes Projekt anlegen',
    errors: {
      wrongProjectNumber: 'Falsche Projektnummer',
      projectNumberNotUniqueForSite:
        'Ein Projekt mit dieser Nummer existiert bereits für diesen Standort.',
    },

    projectTitle: 'Projekttitel',
    projectId: 'Projektnummer',
    projectLocation: 'Projekt Standort',
    projectDescription: 'Beschreibung',
    assignedSuppliers: 'Zugewiesene Dienstleister',
    assignedCoworkers: 'Zugewiesene Mitarbeiter',
    KKS: 'KKS Kennzeichen',
    projectDuration: 'Projektlaufzeit',
    durationStart: 'Anfang',
    durationEnd: 'Ende',
    submit: 'Projekt anlegen',
    updateProject: 'Speichern',
    cancelUpdateProject: 'Abbrechen',
    submitError:
      'Bei der Übermittlung deiner Anfrage ist leider ein Fehler aufgetreten',
    submittingChanges: 'Projektdaten werden aktualisiert',
    changesSubmitted: 'Projektdaten wurden aktualisiert',
    projectCreated: 'Projekt wurde erfolgreich angelegt',
    submittingProject: 'Projekt wird angelegt',
    durationStartLaterThanEnd:
      'Das Anfangsdatum kann nicht später sein als das Enddatum',
    mandatoryFieldsInfo: 'Die Pflichtangaben sind mit * gekennzeichnet ',
    projectNumberWrongSignes:
      'Die Projektnummer muss alphanumerisch sein (- und _ sind erlaubt).',
    projectTitleWrongSignes:
      'Das Projekttitel muss alphanumerisch sein (- und _ sind erlaubt).',
  },

  projectsDocuManagers: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    docuManager: 'Dokumenten-Manager | Dokumenten-Manager',
    addDocuManager: 'Dokumenten-Manager hinzufügen',
    errors: {
      couldNotGetDocuManagers:
        'Beim Laden der Dokumentenmanagers im Projekt ist ein Fehler aufgetreten',
      docuManagerAlreadyAdded:
        'Dieser Dokumentenmanager wurde schon zu diesem Projekt zugewiesen.',
      cannotRemoveLast:
        'Der Letzte Dokumentenmanager im Projekt kann nicht enfernt werden.',
      errorRemovingDocuManager:
        'Beim Entfernen des Dokumentenmanagers ist ein Fehler aufgetreten.',
      cannotRemoveSelf:
        'Ein Dokumentenmanager kann sich nicht selbst aus dem Projekt entfernen.',
    },
  },

  inspectors: {
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    inspectors: 'Prüfer',
    addInspector: 'Prüfer hinzufügen',
    specialization: 'Spezialisierung',
    inspectorAdded: 'Prüfer wurde erfolgreich zum Projekt zugewiesen',
    inspectorAlreadyAdded:
      'Dieser Prüfer wurde zu diesem Projekt schon zugewiesen',
    couldNotGetInspectors: 'Beim Laden der Prüfer ist ein Fehler aufgetreten',
    couldNotAddInspector:
      'Beim zuweisen des Prüfers zu diesem Projekt ist ein Fehler aufgetreten',
    errors: {
      errorRemovingInspector:
        'Beim Entfernen des Prüfers ist ein Fehler aufgetreten.',
      cannotRemoveLast:
        'Der Letzte Prüfer im Projekt kann nicht enfernt werden.',
    },
  },

  createProject: {
    errors: {
      noDocuManagersAdded:
        'Wenigstens ein Dokumententenmanager muss zugewiesen werden',
      couldNotCreateProject:
        'Beim erstellen des Projekts ist ein Fehler aufgetreten',
    },
  },

  projectConfiguration: {
    inspectionTime: 'Prüffrist Einstellung',
    days: 'Tage',
    inspectionTimeSet: 'Prüffrist wurde eingestellt',
  },

  coworkerDetailView: {
    coworkerDetails: 'Mitarbeiterdetails',
    projects: 'Projekte',
    project: 'Projekt',
    supplier: 'Lieferant',
    projectId: 'Projektnummer',
    supplierId: 'Lieferantennummer',
  },

  addSupplierToProject: {
    addSupplierToProject: 'Lieferant zu Projekt hinzufügen',
    add: 'Hinzufügen',
    actions: 'Aktionen',
    remove: 'Entfernen',
    supplierAdded: 'Lieferant hinzugefügt',
    availableSuppliers: 'Verfügbare Lieferanten',
    assignedSuppliers: 'Zugewiesene Lieferanten',
  },

  addCoworkerToProject: {
    addCoworkerToProject: 'Mitarbeiter zu Projekt hinzufügen',
    coworkerAdded: 'Mitarbeiter hinzugefügt',
    errors: {
      couldNotGetSuppliersCoworkers:
        'Beim Laden der Mitarbeiter des Lieferanten ist ein Fehler aufgetreten',
      couldNotGetProjectsCoworkers:
        'Beim Laden der zum Projekt zugewiesenen Mitarbeitern des Lieferanten ist ein Fehler aufgetreten',
      couldNotAddCoworker:
        'Beim zuweisen des Mitarbeiters zu diesem Projekt ist ein Fehler aufgetreten',
      couldNotAddCoworkerToSupplier:
        'Beim zuweisen des Mitarbeiters zum Lieferant ist ein Fehler aufgetreten',
      coworkerAlreadyAddedLong:
        'Die Mitarbeiterzuweisung ist nicht möglich, da der Mitarbeiter bereits zum Lieferanten {supplierName} zugewiesen wurde.',
      coworkerAlreadyAddedShort: 'Mitarbeiterzuweisung nicht möglich',
    },
  },

  addDocuManagerToProject: {
    addDocuManagerToProject: 'Dokumentenmanager zum Projekt zuweisen',
    docuManagerAdded: 'Dokumentenmanager zum Projek zugewiesen',
  },

  docuManagersTable: {
    addDocuManager: 'Dokumentenmanager zuweisen',
    deleteDocuManager: 'Dokumentenmanager entfernen',
    errors: {
      couldNotGetDocuManagers:
        'Beim Laden der Dokumentenmanagers ist ein Fehler aufgetreten',
    },
  },

  docuManagers: {
    docuManager: 'Dokumentenmanager | Dokumentenmanagers',
  },

  docuManagerDetailView: {
    docuManagerDetails: 'Dokumentenmanager details',
    exchangeDocuManager: 'Dokumentenmanager in allen Projekten austauschen',
    selectNewDocuManager: 'Wählen Sie einen neuen Dokumentenmanager aus',
    docuManagerExchanged: 'Dokumentenmanager wurde erfolgreich ausgetauscht',
    noDocuManagerSelected: 'Kein Dokumentenmanager wurde ausgewählt',
  },

  contentTypeConfiguration: {
    contentTypeConfiguration: 'Import Konfiguration',
    uploadCsv: 'CSV Vorlage hochladen',
    identifiedColumns: 'Erkannte Spalten',
    csvColumns: 'CSV Spalten',
    contentTypeField: 'Content-typ Feld',
    dataType: 'Datentyp',
    importField: 'Import-Wert',
    importFieldType: 'Import-Wert-Typ',
    csvMapping: 'Spaltenzuordnung',
    selectForAllFields: 'Wählen Sie ein Import Feld für jedes Pflichtfeld aus',
    mappingSaved: 'Import Konfiguration wurde gespeichert',
    revision: 'Revision',
    relativePath: 'Relativer Pfad',
    checkBotField: 'Checkbot-Feld',
    checkBotFieldsValidationError:
      'Für die folgenden Check Bot Pflichtfelder wurde kein Contenttyp Feld ausgewählt: {missingFields}',
    duplicateCheckBotFieldsAssignments:
      'Jedes Check Bot Feld darf nur zu einem Contenttyp Feld zugewiesen werden',
    checkBotFieldOnlyWithCSVField: 'Import-Wert fehlt',
    constantValue: 'Konstanter Wert',
    currentDate: 'Aktuelles Datum',
    csvField: 'CSV Feld',
    presetValue: 'Vordefinierter Wert',
    errors: {
      couldNotGetPossibleTaxonomyValues:
        'Beim Laden der Taxonomy-Werte ist ein Fehler aufgetreten',
      errorGettingMapping:
        'Beim Laden der Import Konfiguration ist ein Fehler aufgetreten.',
      errorSavingMapping:
        'Beim speichern der Import Konfiguration ist ein Fehler aufgetreten.',
    },
  },

  documentUpload: {
    zipToolHint:
      'Wir empfehlen, ZIP-Dateien größer als 1,5 GB mit dem 7-Zip-Tool unter Verwendung des Deflate-Algorithmen zu komprimieren.',
    fileName: 'Dateiname',
    importDataSets: 'Metadaten importieren',
    uploadFiles: 'Dateien anliefern',
    uploadFile: 'Datei anliefern',
    uploadFileSecondary: 'Datei Upload',
    dragFileHere: 'Datei hierhin ziehen',
    upToSize: 'bis zu {size}',
    csvImport: 'CSV Import',
    zipUpload: 'Zip Upload',
    massUpload: 'Massen-Upload',
    singleUpload: 'Einzel-Upload',
    newUpload: 'Neue Anlieferung',
    documentUpload: 'Dokument-Anlieferung',
    fileUpload: 'Dateianlieferung',
    manualEntry: 'Manueller Eintrag',
    dataSets: 'Datensätze',
    fileSetsAndFileUpload: 'Datensätze und Datei',
    documentId: 'Dokument-ID',
    upload: 'Anlieferung',
    source: 'Quelle',
    documentStatus: 'Dokumentstatus',
    filepath: 'Ordner-Pfad',
    file: 'Datei',
    actions: 'Aktionen',
    header:
      'Dokument-Anlieferung {uploadId} für {projectName} vom Content-Typ {contentTypeName}',
    version: 'Version',
    id: 'ID',
    singleUploadInfo: 'Eine Datei manuel hochladen',
    massUploadInfo: 'Mahrere Dateien von einem Zip-Archiv manuel hochladen',
    fileFound: 'Datei gefunden',
    finalizeUpload: 'Anlieferung abschließen',
    metadataFor: 'Metadaten für {fileName}',
    validationErrorEmpty: 'Dieses Feld kann nicht leer bleiben',
    fileMissing: 'Datei fehlt',
    filesUploaded: 'Die Dateien wurden erfolgreich zum Sharepoint hochgeladen',
    noMappingAssigned:
      'Für den Lieferanten wurde in diesem Projekt noch kein Inhalstypen Mapping angelegt. Kontakten Sie bitte den Dokumenten-Manager des Projekts.',
    referenceNumberError:
      'Eine Anlieferung mit dieser Referenznummer wurde bereits in diesem Projekt durchgeführt',
    errorReadingCsv: 'Beim lesen der CSV Datei ist ein Fehler aufgetreten',
    field: 'Feld',
    value: 'Wert',
    newValue: 'Neuer Wert',
    oldValue: 'Alter Wert',
    [UploadType.NEW_DOCUMENT]: 'Neues Dokument',
    [UploadType.UPDATED_DOCUMENT]: 'Aktualisiertes Dokument',
    [UploadType.UPDATED_METADATA]: 'Aktualisierte Metadaten',
    singleUploadDescription:
      'Eine Datei entsprechend des CSV Schemas hochladen.',
    errors: {
      missingFile: 'Datei fehlt.',
      missingRequiredFields: 'Alle Pflichtfelder müssen ausgefüllt werden.',
      fileToBigShort: 'Die Datei ist zu groß.',
      fileToBig:
        'Die hochgeladene Datei ist zu groß. Die maximale größe ist {maxSize}.',
      uploadError:
        'Beim der Dokumenten Anlieferung ist ein Fehler aufgetreten.',
      noUploadRecords: 'Die Anlieferung enthält keine Dateien',
      uploadFailed: 'Beim hochgeladen der Dateien ist ein Fehler aufgetreten.',
      wrongDateFormat:
        'Das Datum sollte im folgenden Format sein: "TT.MM.JJJJ".',
      invalidDocumentId:
        'Im ID Eingabefeld befinden sich Sonderzeichen. Überprüfe deine Eingabe und versuch es erneut. (- und _ sind erlaubt).',
      zipCorrupted: 'Die hochgeladene ZIP-Datei ist beschädigt.',
      forbiddenCharacters:
        'In dem Dateinamen befinden sich unzulässige Zeichen',
      validationError:
        'Bei der Validation der Anlieferung ist ein Fehler aufgetreten.',
      cannotGetMetadataChanges:
        'Beim prüfen der Metadatenänderungen ist ein Fehler aufgetreten.',
      cannotCheckPreviousVersions:
        'Beim prüfen der älteren Versionen der Dokumente ist ein Fehler aufgetreten.',
    },
    warnings: {
      noFilesFound:
        'In dem ZIP Archiv wurden keine passenden Dateien gefunden.',
      fileIsEmpty: 'Die hochgeladene Datei is leer.',
    },
    uploadSuccessfull:
      'Die Datei wurde erfolgreich hochgeladen.|Die Dateien wurde erfolgreich hochgeladen.',
    uploadSuccessfullShort: 'Datei hochgeladen|Dateien hochgeladen',
    uploadZipFile: 'ZIP Datei anliefern',
    uploadDirectory: 'Ordner anliefern',
    uploadMultipleFiles: 'Dateien anliefern',
    uploadPackage: 'Paket anliefern',
    disabledCheckBotInspection: 'Checkbot-Prüfung ausgeschaltet',
    noMetadataUpload: 'Metadatenlose Anlieferung',
    columnsWithMissingContent:
      'In den folgenden Spalten der angelieferten CSV fehlen Werte für Pflichtfelder:',
  },

  ADModal: {
    noUserSelected: 'Kein User wurde ausgewählt',
    pressEnterToSelect: 'Enter - auswählen',
    pressEnterToRemove: 'Enter - Auswahl löschen',
    enterAtLeast3Characters: 'Geben Sie mindestens 3 Buchstaben ein',
    noData: 'Keine Daten',
  },

  spGroupModal: {
    noUserSelected: 'Kein User wurde ausgewählt',
    pressEnterToSelect: 'Enter - auswählen',
    pressEnterToRemove: 'Enter - Auswahl löschen',
    noData: 'Keine Daten',
    couldNotGetUsers:
      'Beim Laden der Active Directory Nutzer is ein Fehler aufgetreten',
    noResults: 'Keine Ergebnisse',
    selectUser: 'Benutzer auswählen',
  },

  filesOverview: {
    newUpload: 'Neue Anlieferung',
    uploadId: 'Referenznr.',
    selectContentType: 'Inhaltstyp',
    provideUploadId: 'Die Referenznummer muss angegeben werden',
    provideContentType: 'Inhaltstyp muss ausgewählt werden',
    provideSupplier: 'Lieferant muss ausgewählt werden',
    id: 'ID',
    uploadedIn: 'Angeliefert in',
    contentType: 'Content Type',
    inspectionDueDate: 'Prüffrist',
    fileStatus: 'Formale Prüfung',
    uploadDate: 'Anlieferungsdatum',
    inspectorStatus: 'Prüfer Status',
    supplier: 'Lieferant',
    coworker: 'Mitarbeiter',
    actions: 'Aktionen',
    all: 'Alle',
    select: 'Auswählen',
    acceptSelected: 'Ausgewählte annehmen',
    rejectSelected: 'Ausgewählte ablehnen',
    confirmStatusChanges: 'Statusänderungen bestätigen',
    followingDocumentsWillBeAccepted: 'Folgende Dokumente werden angenommen:',
    followingDocumentsWillBeRejected: 'Folgende Dokumente werden abgelehnt:',
    showAfterCheckbot: 'Nur durch Checkbot geprüfte Dateien',
    plusOthers: '+ weitere',
    changeInspectionDueDate: 'Prüffrist ändern',
    newInspectionDueDate: 'Neue Prüffrist',
    inspectorStatusChangeSuccessfullFiles:
      'Der Status der Formalen Prüfung wurde für {successfullCount} Dokument erfolgreich geändert. | Der Status der Formalen Prüfung wurde für {successfullCount} Dokumente erfolgreich geändert.',
    inspectorStatusChangeFailedFiles:
      'Beim Ändern des Statuses der Formalen Prüfung von {failedCount} Dokument ist ein Fehler aufgetreten. | Beim Ändern des Statuses der Formalen Prüfung von {failedCount} Dokumenten ist ein Fehler aufgetreten.',
    errors: {
      inspectionDueDateChangeError:
        'Beim ändern der Prüffrist ist ein Fehler aufgetreten',
    },
  },

  missingFilesModal: {
    title: 'Dateien fehlen',
    missingFiles: 'Manche dateien fehlen und werden nicht hochgeladen',
    cancel: 'Abbrechen',
    submit: 'Hochladen',
    missingFilesWithChangedMetadata:
      'Für diese IDs werden nur die Metadaten aktualisiert, denn Dateien wurden für sie nicht zugewiesen',
    emptyFiles: 'Für diese IDs wurden leere (0 Bytes) Dateien hochgeladen',
  },

  documentDetailView: {
    document: '@:generic.document',
    remarksPlaceHolder: 'Auflagen unter der das Dokument angenommen wird.',
    documentDetails: 'Dokument Details',
    metadata: 'Metadaten',
    checkBotStatus: 'Formale Prüfung',
    inspectorStatus: 'Prüfer Status',
    openFile: 'Datei öffnen',
    downloadDocument: 'Dokument herunterladen',
    accept: 'Annehmen',
    acceptWithRemarks: 'Annehmen m. Auf.',
    reject: 'Ablehnen',
    remarks: 'Auflagen',
    inspectorStatusChanged: 'Prüfer Status wurde geändert',
    checkBotDetails: 'Checkbot Details',
    rerunFormalControl: 'Formale Prüfung erneut durchführen',
    successfullyRerunFormalControl: {
      short: 'Formale Prüfung durchgeführt',
      long: 'Die Formale Prüfung wurde erneut durchgeführt.',
    },
    checkBotEstablishedKksNumbers: 'KKS Vorschläge',
    copyCheckBotKks: 'Alle übernehmen',
    copyValidatedKks: 'Bestätigte übernehmen',
    checkBotEstablishedKksCopied: {
      short: 'KKS übernommen',
      long: 'Die Checkbot ermittelte KKS wurde erfolgreich übernommen. | Die Checkbot ermittelten KKS wurdern erfolgreich übernommen.',
    },
    metadataCheckAccepted: 'Die Metadatenprüfung wurde erflogreich angenommen.',
    tabs: {
      document: 'Dokument',
      history: 'Ereignisprotokoll',
    },
    errors: {
      cannotDownloadFile: {
        long: 'Beim herunterladen der Datei ist ein Fehler aufgetreten.',
      },
      cannotRemoveLastValue: {
        short: 'Wert kann nicht entfernt werden',
        long: 'Der letzte Wert eines Pflichtfeldes kann nicht entfernt werden.',
      },
      cannotGetPossibleValues: {
        short: '@:errors.error',
        long: 'Beim Laden der möglichen Metadaten Werte ist ein Fehler aufgetreten.',
      },
      errorRerunningMetadataCheck: {
        long: 'Bei der Durchführung der Metadatenprüfung ist ein Fehler aufgetreten.',
      },
      errorCopyingEstablishedKks: {
        long: 'Beim kopieren der ermittelten KKS ist ein Fehler aufgetreten.',
      },
      errorValidatingMetadata:
        'Die Validation der Metadaten ist fehlgeschlagen.',
      errorAcceptingMetadataCheck:
        'Beim annehmen der Metadatenprüfung ist ein Fehler aufgetreten.',
    },
    history: {
      errors: {
        errorGettingHistory:
          'Beim Laden des Ereignisprotokolls ist ein Fehler aufgetreten',
      },
      event: 'Ereignis',
      date: 'Datum',
      actor: 'Handelnder',
      statusChange: 'Statusänderung',
      note: 'Notiz',
      additionalInfo: 'Zusätzliche Informationen',
      events: {
        [DocumentEvent.UPLOAD]: 'Upload Durchgeführt',
        [DocumentEvent.FAILED_UPLOAD]: 'Fehlgeschlagener Upload',
        [DocumentEvent.INSPECTION]: 'Inhaltliche Prüfung',
        [DocumentEvent.QUALITY_CHECK]: 'Qualitätsprüfung abgeschlossen',
        [DocumentEvent.CHECKBOT_RESULT]:
          'Formale Prüfung auf inhaltliche Korrektheit abgeschlossen',
        [DocumentEvent.COPIED_TO_RECORD_LIBRARY]: 'Übertragung ins Daisy',
        [DocumentEvent.CHECKBOT_INPUT_LIST_ENTRY]:
          'Formale Prüfung auf Inhalt angefragt',
        [DocumentEvent.CHECKBOT_INPUT_FAILED]:
          'Eintrag auf die Checkbot Input Liste fehlgeschlagen',
        [DocumentEvent.METADATA_CHECK_RESULT]:
          'Formale Prüfung auf taxonomische Korrektheit abgeschlossen',
        [DocumentEvent.REVERTED_TO_METADATA_CHECK]:
          'Dokument wurde aufgrund einer Änderung der Metadaten in den Zustand Metadatenprüfung zurückversetzt.',
        [DocumentEvent.FAILED_CHECK_SUPPLIER_NOTIFICATION]:
          'Benachrichtigung des Lieferanten über fehlgeschlagene formale Prüfung',
        [DocumentEvent.NEW_RESULTS_INSPECTOR_NOTIFICATION]:
          'Benachrichtigung der Prüfer über Resultat der formalen Prüfung',
        [DocumentEvent.INSPECTION_DUE_INSPECTORS_NOTIFICATION]:
          'Benachrichtigung der Prüfer über ablaufende Prüffrist',
        [DocumentEvent.FAILED_INSPECTION_SUPPLIER_NOTIFICATION]:
          'Benachrichtigung des Lieferanten über fehlgeschlagene inhaltliche Prüfung',
        [DocumentEvent.METADATA_INFERENCE_CHECKBOT_INPUT_LIST_ENTRY]:
          'KI Vorschläge angefragt',
        [DocumentEvent.METADATA_INFERENCE_CHECKBOT_RESULT]:
          'KI Vorschläge erhalten',
      },
      [ACTOR_APP]: 'Anwendung',
      failedCheckBotInputInfo:
        'Der Eintrag auf die Checkbot Input Liste ist fehlgeschlagen. Das Dokument kann nicht weiter bearbeitet werden. Bitte kontaktieren Sie Ihren Dokumenten-Manager für das Projekt.',
    },
    inspectionDueDate: 'Prüffrist',
    changeInspectionDueDate: 'Prüffrist ändern',
    inspectionDueDateChanged: 'Prüffrist wurde erfolgreich geändert',
    continueProcess: 'Prozess weiterführen',
    showKksProposals: 'KKS Vorschläge zeigen',
    hideKksProposals: 'KKS Vorschläge ausblenden',
    showAllMetadata: 'Alle Metadaten zeigen',
    showLessMetadata: 'Weniger Metadaten zeigen',
    openInNewTab: 'Dokument im neuen Tab öffnen',
  },

  remarksModal: {
    title: {
      [RemarksModalMode.ACCEPT_WITH_REMARKS]: 'Dokument mit Auflagen annehmen',
      [RemarksModalMode.REJECT]: 'Dokument ablehnen',
    },
    remarksPlaceHolder: {
      [RemarksModalMode.ACCEPT_WITH_REMARKS]:
        'Auflagen unter der das Dokument angenommen wird.',
      [RemarksModalMode.REJECT]: 'Ablehnungsgründe',
    },
    errors: {
      enterRemarks: {
        [RemarksModalMode.ACCEPT_WITH_REMARKS]: 'Geben Sie die Auflagen an.',
        [RemarksModalMode.REJECT]: 'Geben Sie die Ablehnungsgründe an.',
      },
    },
  },

  checkBotConfigurator: {
    checkBotConfiguration: 'Checkbot Konfiguration',
    checkBotConfigurationSavedShort: 'Checkbot Konfiguration gespeichert',
    checkBotConfigurationSaved:
      'Die Checkbot Konfiguration wurde erfolgreich gespeichert.',
    errors: {
      couldNotGetCheckBotConfig:
        'Beim Laden der Checkbot Konfiguration ist ein Fehler aufgetreten.',
      couldNotSaveCheckBotConfig:
        'Beim speichern der Checkbot Konfiguration ist ein Fehler aufgetreten.',
    },
    warnings: {
      checkWasAlreadyAdded: 'Diese Prüfung wurde bereits hinzugefügt',
      checkWasAlreadyAddedShort: 'Prüfung bereits hinzugefügt',
    },
  },

  addCheckModal: {
    addCheck: 'Prüfung hinzufügen',
    errors: {
      errorLoadingChecks:
        'Beim Laden der Checkbot Prüfungen ist ein Fehler aufgetreten.',
    },
  },

  appConfig: {
    appConfig: 'App Konfiguration',
    checkBotTimeout: 'Checkbot Timeout',
    save: 'Speichern',
    days: 'Tage',
    checkBotTimeoutSaved: 'Checkbot timeout wurde gespeichert!',
  },

  checkBotResultsTable: {
    check: 'Checknamen',
    state: 'Status',
    details: 'Details',
    result: 'Ergebnis',
    detailsCopied: 'Details kopiert',
  },

  metadataCheckResultsTable: {
    metadataCheck: 'Metadatenprüfung',
    field: 'Feld',
    value: 'Wert',
    status: 'Status',
    error: 'Fehler',
    statuses: {
      true: 'Angenommen',
      false: 'Abgelehnt',
    },
  },

  progressStepsIndicator: {
    documentUploadFailed:
      'Beim Hochladen des Dokuments ist ein Fehler aufgetreten. Bitte kontaktieren Sie Ihren Dokumenten-Manager für das Projekt.',
    project: {
      [DocuProcessingStep.UPLOAD]: 'Dokument hochgeladen',
      [DocuProcessingStep.FORMAL_INSPECTION]: 'Formale Prüfung',
      [DocuProcessingStep.CONTENT_INSPECTION]: 'Inhaltliche Prüfung',
      [DocuProcessingStep.RECORD_LIBRARY_TRANSFER]: 'Übertragung ins Daisy',
    },
    digitalizationProject: {
      [DigiDocuProcessingStep.UPLOAD_DONE]: 'Dokument hochgeladen',
      [DigiDocuProcessingStep.METADATA_INSPECTION]: 'Metadaten Prüfung',
      [DigiDocuProcessingStep.FORMAL_CONTROL]: 'Formale Prüfung',
      [DigiDocuProcessingStep.QUALITY_CHECK]: '@:generic.qualityCheck',
      [DigiDocuProcessingStep.RECORD_LIBRARY_TRANSFER]: 'Übertragung ins Daisy',
    },
  },

  footer: {
    privacy: 'Datenschutz',
    legalNotice: 'Impressum',
  },

  styledTagsInput: {
    addTag: 'Wert hinzufügen',
  },

  forbiddenView: {
    heading: 'Sie haben leider keinen Zugang',
    subheading:
      'Sie haben leider keinen Zugang. Kontaktieren Sie bitte Ihren Dokumenten Manager für das Projekt.',
  },

  csvParsingErrorsModal: {
    csvErrors: 'Beim lesen der CSV Datei sind folgende Fehler aufgetreten:',
    error: 'Fehler',
    row: 'Reihe',
    InvalidQuotes: 'Schließendes Zitat im zitierten Feld ist fehlerhaft',
    MissingQuotes: 'Zitiertes Feld nicht beendet',
    TooFewFields:
      'Zu wenige Felder: {expectedFieldsNumber} Felder erwartet, aber {parsedFieldsNumber} Felder gefunden',
    TooManyFields:
      'Zu viele Felder: {expectedFieldsNumber} Felder erwartet, aber {parsedFieldsNumber} Felder gefunden',
    UndetectableDelimiter:
      'Trennzeichen konnte nicht automatisch erkannt werden',
  },

  fileNameErrorsModal: {
    forbiddenCharactersPresent:
      'In den Dateinamen befinden sich unzulässige Zeichen.',
    documentId: 'Dokument ID',
    forbiddenCharacters: 'Unzulässige Zeichen',
  },

  digitalizationProjectsList: {
    addDigitalizationProject: 'Internes Projekt hinzufügen',
    description:
      'Willkommen in unserer Übersicht und Verwaltung für interne Projekte! ' +
      'Hier können Sie alle Informationen und Fortschritte Ihrer ' +
      'internen Projekte zentral verwalten und organisieren, um eine effiziente ' +
      'und erfolgreiche Projektumsetzung sicherzustellen.',
    digitalizationProjects: 'Interne Projekte',
    number: 'Nummer',
    errors: {
      errorGettingDigitalizationProjects: {
        long: 'Beim Laden der Internen Projekte ist ein Fehler aufgetreten.',
      },
    },
    status: 'Status',
    statuses: {
      [DigitalizationProjectStatus.ACTIVE]: 'aktiv',
    },
    title: 'Titel',
  },

  createDigitalizationProject: {
    addDigitalizationProject: 'Internes Projekt hinzufügen',
    errors: {
      digitalizationProjectNumberNotUnique: {
        long: 'Ein Internes Projekt mit dieser Projektnummer existiert bereits.',
      },
      errorCreatingDigitalizationProject: {
        long: 'Beim Anlegen des Projektes ist ein Fehler aufgetreten.',
      },
      noDocuResponsibleAdded: {
        long: 'Wenigstens ein Doku-Verantwortlicher muss zugewiesen werden.',
      },
    },
    requiredFieldsMarkedWith: 'Die Pflichtangaben sind mit * gekennzeichnet.',
  },

  docuResponsiblesTable: {
    docuResponsibles: 'Doku-Verantwortlicher | Doku-Verantwortliche',
    email: '@:generic.email',
    errors: {
      couldNotGetDocuResponsibles: {
        long: 'Beim Laden der Dokument-Verantwortlichen ist ein Fehler aufgetreten.',
      },
    },
    firstName: '@:generic.firstName',
    lastName: '@:generic.lastName',
  },

  digitalizationProjectDetails: {
    errors: {
      errorLoadingProjectData: {
        long: 'Beim Laden der Projektdaten ist ein Fehler aufgetreten.',
      },
    },
    goToProjectRoom: 'Zum Projektraum abspringen',
    tabs: {
      documents: 'Dokumente',
      informations: 'Informationen',
      settings: 'Einstellungen',
      suppliers: 'Lieferanten',
      uploads: 'Anlieferungen',
    },
  },

  digitalizationProjectContentTypes: {
    addContentType: 'Inhaltstyp hinzufügen',
    contentTypes: 'Inhaltstypen',
    errors: {
      errorGettingContentTypes: {
        long: 'Beim Laden der Inhaltstypen ist ein Fehler aufgetreten.',
      },
    },
    tableFields: {
      contentTypeName: 'Name',
      uploadsCount: 'Anlieferungen',
    },
  },

  digitalizationUploadsOverview: {
    contentTypeName: '@:generic.contentType',
    documentsCount: 'Dok.',
    documentsStatuses: 'Dokumentenstatus',
    downloadUploadConfirmation: 'Anlieferungsbestätigung herunterladen',
    errors: {
      errorGettingUploads: {
        long: 'Beim Laden der Anlieferungen ist ein Fehler aufgetreten',
      },
    },
    hideProcessed: 'Abgeschlossene Anlieferungen ausblenden',
    newAndUpdatedDocumentsCount: 'Inhalt',
    newUpload: 'Neue Anlieferung',
    referenceNumber: 'Referenznr.',
    uploadDate: 'Upload-Datum',
    uploads: 'Anlieferungen',
  },

  qualityCheckPanel: {
    accept: 'Annehmen',
    errors: {
      errorSettingQualityCheckStatus: {
        long: 'Beim ändern des Qualitätschek Statuses ist ein Fehler aufgetreten.',
      },
    },
    qualityCheck: '@:generic.qualityCheck',
    qualityCheckStatusSet:
      'Der Qualitätschek Status wurde erfolgreich geändert.',
    reject: 'Ablehnen',
  },

  digitalizationDocumentsOverview: {
    checkBotInferenceStatus: '@:generic.metadataInference',
    contentTypeName: '@:generic.contentType',
    continueProcess: 'Prozess weiterführen',
    coworker: '@:generic.coworker',
    deselectAll: 'Alle Dokumente abwählen',
    documentId: '@:generic.documentIdAbbreviated',
    documents: 'Dokumente',
    documentStatus: 'Prozessstatus',
    errors: {
      errorGettingDocuments: {
        long: 'Beim Laden der Dokumente ist ein Fehler aufgetreten.',
      },
    },
    formalControlStatus: '@:generic.inspection',
    plusOthers: '+ weitere',
    recordLibraryTransferStatus: '@:generic.recordLibraryTransfer',
    referenceNumber: 'Referenznr.',
    revision: 'Rev.',
    selectAll: 'Alle Dokumente auswählen',
    title: 'Titel',
    qualityCheckStatus: '@:generic.qualityCheck',
    uploadDate: '@:generic.uploadDate',
  },

  digitalizationProjectInformations: {
    informations: 'Informationen',
  },

  digitalizationProjectDocuResponsibles: {
    addDocuResponsible: 'Dokumentationsverantwortlichen hinzufügen',
    docuResponsible: '@:roles.docuResponsible',
    docuResponsibleAdded:
      'Der Doku-Verantortliche wurde erfolgreich hinzugefügt.',
    email: '@:generic.email',
    errors: {
      docuResponsibleAlreadyAdded: {
        long: 'Dieser Doku-Verantortliche wurde bereits zu diesem Projekt hinzugefügt.',
      },
      errorAddingDocuResponsible: {
        long: 'Beim hinzufügen des Doku-Verantwortlichen ist ein Fehler aufgetreten.',
      },
      errorGettingDocuResponsbiles: {
        long: 'Beim Laden der Dokument-Verantwortlichen ist ein Fehler aufgetreten.',
      },
    },
    firstName: '@:generic.firstName',
    lastName: '@:generic.lastName',
  },

  metadataGridEditor: {
    changedHint: '✘ - Dokument wird in die Formale Prüfung zurück versetzt',
    checkBotEstablishedKksNumbers: 'KKS Vorschläge',
    copyCheckBotKks: 'Alle KKS übernehmen',
    copyValidatedKks: 'Bestätigte KKS übernehmen',
    cancel: '@:generic.cancel',
    docAfterInferenceHint:
      'Dokumente mit lila makierten ID wurden durch den Checkbot bearbeitet.',
    inferredValueHint:
      'Zellen, die braun markiert sind, enthalten Werte, die durch den Checkbot ermittelt worden.',
    errors: {
      errorGettingMetadata:
        'Beim Laden der Metadaten ist ein Fehler aufgetreten.',
      errorSavingMetadataChanges:
        'Beim speichern der Metadaten ist ein Fehler aufgetreten.',
      errorUpdatingQualityCheck:
        'Beim Ändern der Statuse der Qualitätsprüfung ist ein Fehler aufgetreten.',
      errorValidatingMetadata:
        'Die Validation der Metadaten ist fehlgeschlagen.',
      errorGettingInferenceStatuses:
        'Beim Laden der Checkbot Metadatenermittlung Statusen ist ein Fehler aufgetreten.',
    },
    hint: 'Hinweis',
    metadataEditor: 'Metadaten-Editor',
    metadataSaved: 'Die Metadaten wurden erfolgreich gespeichert.',
    notChangedHint: '✔ - Dokument ist bereit für die Übertragung an Daisy',
    saveChanges: '@:generic.saveChanges',
    saveChangesAndContinue: 'Prozess weiterführen',
    undoAcceptingKks: 'KKS zuweisung ruckgangig machen',
  },

  newDigitalizationUploadModal: {
    disableCheckBotInspection: 'Checkbot-Prüfung überspringen',
    noMetadataUpload: 'Metadatenlose Anlieferung',
    noMappingAssigned:
      'Für den Lieferanten wurde in diesem Projekt noch kein Inhalstypen Mapping angelegt. Kontakten Sie bitte den Dokumenten-Verantwortlichen des Projekts.',
  },

  kksValidator: {
    errors: {
      errorValidatingKks:
        'Beim Validieren der KKS Werte ist ein Fehler aufgetreten.',
    },
  },

  contentTypeFields: {
    CreateDate: 'Erstellungsdatum',
    CreatedBy: 'Erstellt von',
    CreatorDocumentNumber: 'Dokumentennummer',
    DocLanguage: 'Sprache',
    DocStatus: 'Status',
    DocumentClass: 'Master DCC',
    DocumentClassContent: 'Content DCC',
    DocumentCreator: 'Hersteller',
    documentId: 'Dokument-ID',
    FolderNumber: 'Ordnernummer',
    KKS: 'KKS',
    ObjectID: 'Objekt-ID',
    OriginalFilename: 'Originaldateiname',
    /** Typo in content type field on Queportdev SharePoint */
    OriginalFIlename: 'Originaldateiname',
    Revision: 'Revision',
    Title: 'Titel',
    Project: 'Projekt',
    ReleaseDate: 'Freigabedatum',
    ResponsibleArea: 'Verantwortungsbereich',
    StationNumber: 'Standort',
  },

  kksPicker: {
    allowWildcardSelector: 'Unterknoten mit einbeziehen',
    copyToClipboard: 'In die Zwischenablage kopieren',
    noSelectOptions: 'Keine Einträge vorhanden',
    searchPrompt: 'Bitte geben Sie einen Suchbegriff ein...',
    selectStates: 'Bitte mindestens einen Status auswählen',
  },
};
