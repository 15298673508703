import { IInspectorStatusAssignmentResponseDto } from 'types';
import Vue from 'vue';
import StyledModal from '../../components/StyledModal/StyledModal.vue';

export default Vue.extend({
  name: 'inspector-confirmation-modal',
  components: { StyledModal },
  props: {
    isChangingInspectorStatus: {
      type: Boolean,
      required: true,
    },
    results: {
      type: Object as () => IInspectorStatusAssignmentResponseDto | undefined,
      required: false,
    },
    statusChanges: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },

  methods: {
    handleModalOk() {
      if (this.results) {
        this.$bvModal.hide('inspector-confirmation-modal');
      } else {
        this.$emit('inspector-confirmation');
      }
    },
  },

  mounted() {},
});
