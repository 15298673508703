revogr-filter-panel {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  z-index: 100;

  opacity: 1;
  transform: none;
  background-color: #fff;
  transform-origin: 62px 0px;
  box-shadow: 0 5px 18px -2px rgba(black, 20%);
  padding: 10px;
  border-radius: 4px;

  min-width: 220px;
  text-align: left;

  .filter-holder > div {
    display: flex;
    flex-direction: column;
  }

  label {
    color: gray;
    font-size: 13px;
    font-weight: 600;
    display: block;
    padding: 8px 0;
  }

  select {
    width: 100%;
  }

  input[type="text"] {
    border: 0;
    min-height: 34px;
    margin: 5px 0;
    background: #f3f3f3;
    border-radius: 5px;
    padding: 0 10px;
    box-sizing: border-box;
    width: 100%;
  }

  button {
    margin-top: 10px;
    margin-right: 5px;
  }


  .filter-actions {
    text-align: right;
    margin-right: -5px;
  }
}

.rgHeaderCell {
  &:hover .rv-filter {
    transition: opacity 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 178ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  &:hover .rv-filter,
  .rv-filter.active {
    opacity: 1;
  }

  .rv-filter {
    $btn-size: 24px;
    height: $btn-size;
    width: $btn-size;
    background: none;
    border: 0;
    opacity: 0;
    visibility: visible;
    cursor: pointer;
    border-radius: 4px;

    &.active {
      color: #10224a;
    }

    .filter-img {
      $img-size: 11px;
      color: gray;
      width: $img-size;
    }
  }
}

.select-css {
  $gradient: "data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E";
  $linearGradient: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  $borderColor: #f1f1f1;

  display: block;
  font-family: sans-serif;
  font-weight: 600;
  color: #444;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  width: 100%;
  max-width: 100%; /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid $borderColor;
  box-shadow: transparent;
  border-radius: 0.5em;
  appearance: none;
  background-color: #fff;
  background-image: url($gradient), $linearGradient;
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;

  /* Hide arrow icon in IE browsers */
  &::-ms-expand {
    display: none;
  }
  /* Hover style */
  &:hover {
    border-color: rgb(197, 197, 197);
  }
  /* Focus style */
  &:focus {
    border-color: $borderColor;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;
    color: #222;
    outline: none;
  }

  /* Set options to normal weight */
  option {
    font-weight: normal;
  }

  /* Disabled styles */
  &:disabled,
  &[aria-disabled="true"] {
    color: gray;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),
      linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  }

  &:disabled:hover,
  &[aria-disabled="true"] {
    border-color: $borderColor;
  }
}

.multi-filter-list {
  margin-top: 5px;
  margin-bottom: 5px;

  div {
    white-space: nowrap;
  }

  .multi-filter-list-action {
    display: flex;

    justify-content: space-between;
    align-items: center;
  }

  .and-or-button {
    margin: 0 0 0 10px;
    min-width: 58px;
    cursor: pointer;
  }
  .trash-button {
    $btn-remove-size: 22px;
    margin: 0 0 -2px 6px;
    cursor: pointer;

    width: $btn-remove-size;
    height: $btn-remove-size;
    color: gray;
    font-size: 18px;


    .trash-img {
      width: 1em;
    }
  }
}

.add-filter-divider {
  display: block;
  margin: 0 -10px 10px -10px;

  border-bottom: 1px solid #d9d9d9;
  height: 10px;
  box-shadow: 0 4px 5px rgb(0 0 0 / 5%);
}

.select-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}