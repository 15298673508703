"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QualityCheckStatus = exports.MetadataInspectionStatus = exports.DocumentStatus = exports.DigitalizationFormalControlStatus = exports.DigitalizationCheckBotStatus = exports.CheckBotInferenceStatus = void 0;
var check_bot_inference_status_enum_1 = require("./check-bot-inference-status.enum");
Object.defineProperty(exports, "CheckBotInferenceStatus", { enumerable: true, get: function () { return check_bot_inference_status_enum_1.CheckBotInferenceStatus; } });
__exportStar(require("./constants"), exports);
var digitalization_check_bot_status_enum_1 = require("./digitalization-check-bot-status.enum");
Object.defineProperty(exports, "DigitalizationCheckBotStatus", { enumerable: true, get: function () { return digitalization_check_bot_status_enum_1.DigitalizationCheckBotStatus; } });
var digitalization_formal_control_status_enum_1 = require("./digitalization-formal-control-status.enum");
Object.defineProperty(exports, "DigitalizationFormalControlStatus", { enumerable: true, get: function () { return digitalization_formal_control_status_enum_1.DigitalizationFormalControlStatus; } });
var document_status_type_1 = require("./document-status.type");
Object.defineProperty(exports, "DocumentStatus", { enumerable: true, get: function () { return document_status_type_1.DocumentStatus; } });
var metadata_inspection_status_enum_1 = require("./metadata-inspection-status.enum");
Object.defineProperty(exports, "MetadataInspectionStatus", { enumerable: true, get: function () { return metadata_inspection_status_enum_1.MetadataInspectionStatus; } });
var quality_check_status_enum_1 = require("./quality-check-status.enum");
Object.defineProperty(exports, "QualityCheckStatus", { enumerable: true, get: function () { return quality_check_status_enum_1.QualityCheckStatus; } });
