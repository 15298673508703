/**
 * Creates a debounced function that delays invoking the provided function
 * until after `wait` milliseconds have elapsed since the last time the
 * debounced function was invoked. The debounced function comes with a `this`
 * context that can be used to maintain the context from where it is called.
 *
 * @param fn - The function to debounce. It can be any function that needs to
 * be throttled based on the `wait` time.
 * @param wait - The number of milliseconds to delay; this is the debounce
 * period during which time if the debounced function is invoked again, the
 * timer resets.
 * @returns Returns a new debounced function that can be called any number of
 * times, or until the debounce time has not been interrupted by another call
 * to it.
 *
 * @example
 * // Example usage in a Vue component method
 * methods: {
 *   inputHandler: debounce(function(this: Vue, event) {
 *     // Assume we have a method named 'search' to call on input change
 *     this.search(event.target.value);
 *   }, 300)
 * }
 *
 * // Using the debounced function in an event listener
 * const debouncedLog = debounce((message) => console.log(message), 500);
 * window.addEventListener('resize', () => debouncedLog('Window resized'));
 */
export function debounce(fn: Function, wait: number) {
  let timer: NodeJS.Timeout;
  return function (this: any, ...args: unknown[]) {
    if (timer) {
      /** clear any pre-existing timer */
      clearTimeout(timer);
    }
    /** get the current context */
    const context = this;
    timer = setTimeout(() => {
      /** call the function if time expires */
      fn.apply(context, args);
    }, wait);
  };
}
