import { render, staticRenderFns } from "./ConfirmationModal.vue?vue&type=template&id=521a7606&scoped=true"
import script from "./ConfirmationModal.script.ts?vue&type=script&lang=ts&external"
export * from "./ConfirmationModal.script.ts?vue&type=script&lang=ts&external"
import style0 from "./ConfirmationModal.style.scss?vue&type=style&index=0&id=521a7606&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521a7606",
  null
  
)

export default component.exports