import { render, staticRenderFns } from "./ProjectDetails.vue?vue&type=template&id=07ca00d8&scoped=true"
import script from "./ProjectDetails.script.ts?vue&type=script&lang=js&external"
export * from "./ProjectDetails.script.ts?vue&type=script&lang=js&external"
import style0 from "./ProjectDetails.styles.scss?vue&type=style&index=0&id=07ca00d8&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07ca00d8",
  null
  
)

export default component.exports