import endpointsList from '@/helpers/endpointsList';
import { router } from '@/vue';
import axios from 'axios';
import GoBack from '../../components/GoBack/GoBack.vue';

export default {
  name: 'coworker-details',
  props: ['coworkerEmail', 'id', 'goBackTo'],
  components: { GoBack },
  data() {
    return {
      coworkerData: false,

      supplier: false,
      suppliersTableFields: [
        {
          key: 'name',
          label: this.$i18n.t('coworkerDetailView.supplier'),
          sortable: true,
        },
        {
          key: 'id',
          label: this.$i18n.t('suppliersList.supplierId'),
          sortable: true,
        },
      ],

      coworkersProjects: false,
      projectsTableFields: [
        {
          key: 'project.projectTitle',
          label: this.$i18n.t('coworkerDetailView.project'),
          sortable: true,
        },
        {
          key: 'project.projectNumber',
          label: this.$i18n.t('coworkerDetailView.projectId'),
          sortable: true,
        },
      ],
    };
  },
  methods: {
    getCoworkersData() {
      axios
        .get(
          endpointsList.projects.getProjectAndSuppliersForCoworker(
            this.coworkerEmail,
          ),
        )
        .then((response) => (this.coworkersProjects = response.data));

      axios
        .get(
          endpointsList.suppliers.getSupplierByCoworkerByEmail(
            this.coworkerEmail,
          ),
        )
        .then((response) => (this.supplier = response.data));

      axios
        .get(endpointsList.suppliers.getCoworkerByEmail(this.coworkerEmail))
        .then((response) => (this.coworkerData = response.data));
    },
  },
  mounted() {
    if (this.coworkerEmail === undefined) {
      router.push({ name: 'projectsList' });
    }

    this.getCoworkersData();
  },
};
