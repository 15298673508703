import { render, staticRenderFns } from "./CoworkerDetailView.vue?vue&type=template&id=a6567fac&scoped=true"
import script from "./CoworkerDetailView.script.js?vue&type=script&lang=js&external"
export * from "./CoworkerDetailView.script.js?vue&type=script&lang=js&external"
import style0 from "./CoworkerDetailView.styles.scss?vue&type=style&index=0&id=a6567fac&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6567fac",
  null
  
)

export default component.exports