"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Order = exports.DocumentDetailViewMode = void 0;
__exportStar(require("./common-metadata-fields"), exports);
__exportStar(require("./constants"), exports);
var document_detail_view_mode_enum_1 = require("./document-detail-view-mode.enum");
Object.defineProperty(exports, "DocumentDetailViewMode", { enumerable: true, get: function () { return document_detail_view_mode_enum_1.DocumentDetailViewMode; } });
var order_enum_1 = require("./order.enum");
Object.defineProperty(exports, "Order", { enumerable: true, get: function () { return order_enum_1.Order; } });
