"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeEmptyStrings = removeEmptyStrings;
/**
 * Removes all empty strings from an array of strings.
 *
 * @param {string[]} arr - The array of strings to be filtered.
 * @returns {string[]} A new array containing only non-empty strings.
 *
 * @example
 * const result = removeEmptyStrings(["hello", "", "world", ""]);
 * console.log(result); // Output: ["hello", "world"]
 */
function removeEmptyStrings(arr) {
    return arr.filter((str) => str !== '');
}
