import { render, staticRenderFns } from "./CheckBotEstablishedKks.vue?vue&type=template&id=2112f2a2&scoped=true"
import script from "./CheckBotEstablishedKks.script.ts?vue&type=script&lang=ts&external"
export * from "./CheckBotEstablishedKks.script.ts?vue&type=script&lang=ts&external"
import style0 from "./CheckBotEstablishedKks.style.scss?vue&type=style&index=0&id=2112f2a2&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2112f2a2",
  null
  
)

export default component.exports