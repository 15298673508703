<template>
  <styled-multiselect
    v-model="selectedSupplier"
    :isError="isError"
    :label="label"
    :loading="isFetchingSuppliers"
    :options="suppliers"
    :placeholder="$t('projectSuppliersSelect.selectSupplier')"
    :trackBy="trackBy"
  ></styled-multiselect>
</template>

<script lang="ts">
  import Vue from 'vue';
  import StyledMultiselect from '../StyledMultiselect/StyledMultiselect.vue';
  import { SuppliersService } from '@/services';
  import { GlobalUtils } from '@/mixins/globalUtils';

  export type SupplierOption = {
    name: string;
    _id: string;
    SAPNumber: string;
  };

  export default Vue.extend({
    name: 'project-suppliers-select',
    components: { StyledMultiselect },
    props: {
      isError: {
        type: Boolean,
        default: false,
      },
      projectId: {
        type: String,
        required: true,
      },
      value: {
        type: Object as () => SupplierOption | null,
        default: null,
      },
    },
    data(): {
      isFetchingSuppliers: boolean;
      selectedSupplier: SupplierOption | null;
      suppliers: SupplierOption[];
    } {
      return {
        isFetchingSuppliers: false,
        selectedSupplier: null,
        suppliers: [],
      };
    },
    computed: {
      label(): keyof SupplierOption {
        return 'name';
      },
      trackBy(): keyof SupplierOption {
        return 'name';
      },
    },
    methods: {
      async fetchSuppliers() {
        this.isFetchingSuppliers = true;
        try {
          this.suppliers = await SuppliersService.getSuppliersForProject(
            this.projectId,
            ['_id', 'name', 'SAPNumber'],
          );
        } catch {
          (this as unknown as GlobalUtils).createErrorToast(
            'projectSuppliersSelect.errors.errorGettingSuppliers',
          );
        }

        this.isFetchingSuppliers = false;
      },
    },
    watch: {
      value(newValue) {
        this.selectedSupplier = newValue;
      },
      selectedSupplier(newValue) {
        this.$emit('input', newValue);
      },
    },
    mounted() {
      this.fetchSuppliers();
    },
  });
</script>
