"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Role = void 0;
var Role;
(function (Role) {
    Role["Coworker"] = "coworker";
    Role["DigitalizationCoworker"] = "digitalization-coworker";
    Role["Inspector"] = "inspector";
    Role["DocuManager"] = "docuManager";
    Role["DocuResponsible"] = "docuResponsible";
    Role["Admin"] = "admin";
})(Role || (exports.Role = Role = {}));
