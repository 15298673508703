import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import SelectModal from '@/components/SelectModal/SelectModal.vue';
import axios, { AxiosResponse } from 'axios';
import {
  IAssignCheckBotConfigTemplateDto,
  IAssignCheckBotTemplateRequest,
  ITestKeysByConfigs,
  Serialized,
} from 'types';
import Vue from 'vue';
import endpointsList from '../../helpers/endpointsList';
import { ToastMixin } from '../../mixins/globalUtils';

export enum CheckBotTemplateModalMode {
  PROJECT = 'project',
  DIGITALIZATION_PROJECT = 'digitalizationProject',
}

interface ICheckBotTemplateModal {
  selectedTemplate?: string;
  checkBotTemplatesEndpoint: string;
  isReassignmentConfirmed: boolean;
  testKeysForTemplates: { [testKey: string]: string };
  createToast?: ToastMixin;
}

export default Vue.extend({
  name: 'check-bot-template-modal',

  components: { SelectModal, ConfirmationModal },

  props: {
    projectId: {
      type: String,
      required: true,
    },
    supplierId: {
      type: String,
      required: false,
    },
    isCustomTemplate: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String as () => CheckBotTemplateModalMode,
      required: true,
    },
  },

  data(): ICheckBotTemplateModal {
    return {
      selectedTemplate: undefined,
      checkBotTemplatesEndpoint:
        endpointsList.checkBot.getCheckBotConfigTemplates,
      isReassignmentConfirmed: false,
      testKeysForTemplates: {},
    };
  },

  methods: {
    async getTestKeysForTemplates() {
      try {
        const response: AxiosResponse<ITestKeysByConfigs> = await axios.get(
          endpointsList.checkBot.getTestKeysForTemplates,
        );
        const testKeys = response.data;

        const testKeysAsStrings: { [testKey: string]: string } = {};

        for (const testKey in testKeys) {
          testKeysAsStrings[testKey] =
            testKeys[testKey as keyof typeof testKeys].join(', ');
        }

        this.testKeysForTemplates = testKeysAsStrings;
      } catch (err) {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('projectsSuppliers.errors.couldNotLoadTestKeys'),
          'danger',
        );
      }
    },

    selectTemplate(template: string) {
      this.selectedTemplate = template;
    },

    confirmReassignment() {
      this.isReassignmentConfirmed = true;
      this.assignTemplate();
      this.$bvModal.hide('confirmation-modal');
    },

    async assignTemplate() {
      if (!this.selectedTemplate) {
        return;
      }
      if (this.isCustomTemplate && !this.isReassignmentConfirmed) {
        this.$bvModal.hide('check-bot-template-modal');
        this.$bvModal.show('confirmation-modal');
        return;
      }

      try {
        if (this.mode === CheckBotTemplateModalMode.PROJECT) {
          const assignment: IAssignCheckBotTemplateRequest = {
            projectId: this.projectId,
            supplierId: this.supplierId,
            templateName: this.selectedTemplate,
          };

          await axios.patch(
            endpointsList.projects.assignCheckBotConfigTemplateToSupplier,
            assignment,
          );
        } else if (
          this.mode === CheckBotTemplateModalMode.DIGITALIZATION_PROJECT
        ) {
          const assignment: Serialized<IAssignCheckBotConfigTemplateDto> = {
            digitalizationProjectId: this.projectId,
            checkBotConfigTemplate: this.selectedTemplate,
          };

          await axios.post(
            endpointsList.digitalizationProjects.setCheckBotConfiguration,
            assignment,
          );
        }

        this.$emit('template-updated');

        this.createToast!(
          this.$t('projectsSuppliers.checkBotConfigTemplateSetShort'),
          this.$t('projectsSuppliers.checkBotConfigTemplateSet'),
          'success',
        );
      } catch (err: unknown) {
        this.createToast!(
          this.$t('errors.error'),
          this.$t('projectsSuppliers.errors.couldNotUpdateConfigTemplate'),
          'danger',
        );
      }
    },
  },

  created() {
    this.getTestKeysForTemplates();
  },
});
