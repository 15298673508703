import Vue from 'vue';
import Multiselect from 'vue-multiselect';

export default Vue.extend({
  name: 'styled-multiselect',
  components: { Multiselect },

  props: {
    value: {
      type: [Array, String, Object, Number, Boolean],
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    label: {
      type: [String, Function],
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    trackBy: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    tooltips: {
      type: Object as () => { [key: string]: string },
      required: false,
    },
    isError: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    updateValue(newValue: any) {
      this.$emit('input', newValue);
    },

    emitSearchChange(search: string) {
      this.$emit('search-change', search);
    },

    emitSelect(selection: string) {
      this.$emit('select', selection);
    },
  },
});
