/**
 * Formatting date to DD.MM.YYYY format (always to day digits)
 */
export function formatDateForDisplay(date: Date): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  return date.toLocaleDateString('de-DE', options);
}
