import fileCheckIcon from '@/assets/icons/file-check.svg';
import FilterableTable from '@/components/FilterableTable/FilterableTable.vue';
import NewDigitalizationUploadModal from '@/components/NewDigitalizationUploadModal/NewDigitalizationUploadModal.vue';
import TitlePanel from '@/components/TitlePanel/TitlePanel.vue';
import VfButton from '@/components/VfButton/VfButton.vue';
import { ITableField } from '@/frontendInterfaces';
import endpointsList from '@/helpers/endpointsList';
import { GlobalUtils, ToastMixin } from '@/mixins/globalUtils';
import digitalizationProjectTabNumbersMixin, {
  DigitalizationProjectDetailViewTab,
  DigitalizationProjectTabNumbersMixin,
} from '@/views/DigitalizationProjectDetails/digitalizationProjectTabNumbersMixin';
import { router } from '@/vue';
import axios, { AxiosResponse } from 'axios';
import { debounce } from 'lodash';
import {
  DocumentStatus,
  IDigitalizationProjectSettings,
  IDigitalizationUploadSummaryDto,
  IPageDto,
  Order,
  Role,
} from 'types';
import Vue from 'vue';
import { DigitalizationDocumentsOverviewTableConfig } from '../DigitalizationDocumentsOverview/DigitalizationDocumentsOverview.script';
import {
  FilterType,
  IFilterConfiguration,
  Mode,
  SelectOptions,
} from '../FilterableTable/FilterableTable.script';
import filterableTableHelpersMixin from '../FilterableTable/filterableTableHelpersMixin';
import { IDateRange } from '../StyledDateRangePicker/StyledDateRangePicker.script';
import { ButtonSize, ButtonVariant } from '../VfButton/VfButton.script';

interface IDigitalizationUploadsOverview {
  uploads?: IDigitalizationUploadSummaryDto[];
  fileCheckIcon: string;
  filtering?: Record<string, string | Date | IDateRange>;
  isLoadingUploads: boolean;
  newUploadButton: {
    size: ButtonSize.LARGE;
    variant: ButtonVariant.SECONDARY;
  };
  page: number;
  perPage: number;
  selectOptions?: SelectOptions<IDigitalizationUploadSummaryDto>;
  sortBy: keyof IDigitalizationUploadSummaryDto;
  sortDesc: boolean;
  tableFilterConfiguration: IFilterConfiguration;
  tableFilterGridConfiguration: string[];
  tableMode: Mode.OUTSOURCED;
  tableRows: number;
  documentStatus: typeof DocumentStatus;
}

export default Vue.extend({
  name: 'digitalization-uploads-overview',
  components: {
    FilterableTable,
    NewDigitalizationUploadModal,
    TitlePanel,
    VfButton,
  },
  mixins: [digitalizationProjectTabNumbersMixin, filterableTableHelpersMixin],
  props: {
    digitalizationProjectSettings: {
      type: Object as () => IDigitalizationProjectSettings,
      required: true,
    },
  },
  data(): IDigitalizationUploadsOverview {
    return {
      uploads: undefined,
      fileCheckIcon,
      filtering: undefined,
      isLoadingUploads: false,
      newUploadButton: {
        size: ButtonSize.LARGE,
        variant: ButtonVariant.SECONDARY,
      },
      page: 1,
      perPage: 10,
      selectOptions: undefined,
      sortBy: 'uploadDate',
      sortDesc: true,
      tableFilterConfiguration: {
        referenceNumber: {
          type: FilterType.INPUT,
          width: '180px',
          column: 1,
          simpleLabel: true,
        },
        documentsCount: {
          type: FilterType.INPUT,
          width: '170px',
          column: 2,
          simpleLabel: true,
        },
        contentTypeName: {
          type: FilterType.INPUT,
          width: '180px',
          column: 3,
          translateOptions: true,
          simpleLabel: true,
        },
        excludeProcessed: {
          type: FilterType.CHECK_BOX,
          width: '150px',
          column: 5,
          customLabel: this.$i18n.t(
            'digitalizationUploadsOverview.hideProcessed',
          ),
        },
        uploadDate: {
          type: FilterType.DATE_RANGE_PICKER,
          width: '170px',
          column: 6,
          simpleLabel: true,
        },
      },
      tableFilterGridConfiguration: [
        '20%',
        '15%',
        '15%',
        '15%',
        '18%',
        '12%',
        '5%',
      ],
      tableMode: Mode.OUTSOURCED,
      tableRows: 0,
      documentStatus: DocumentStatus,
    };
  },
  computed: {
    tableFields(): ITableField<IDigitalizationUploadSummaryDto>[] {
      return [
        {
          key: 'referenceNumber',
          label: this.$t('digitalizationUploadsOverview.referenceNumber'),
          sortable: true,
          tdClass: ['w-20'],
          thClass: ['w-20', 'align-middle', 'break-all-words'],
        },
        {
          key: 'documentsCount',
          label: this.$t('digitalizationUploadsOverview.documentsCount'),
          sortable: true,
          tdClass: ['w-15'],
          thClass: ['w-15', 'align-middle', 'break-all-words'],
        },
        {
          key: 'contentTypeName',
          label: this.$t('digitalizationUploadsOverview.contentTypeName'),
          sortable: true,
          tdClass: ['w-15'],
          thClass: ['w-15', 'align-middle', 'break-all-words'],
        },
        {
          key: 'newAndUpdatedDocumentsCount',
          label: this.$t(
            'digitalizationUploadsOverview.newAndUpdatedDocumentsCount',
          ),
          sortable: false,
          tdClass: ['w-15'],
          thClass: ['w-15', 'align-middle', 'break-all-words'],
        },
        {
          key: 'documentsStatuses',
          label: this.$t('digitalizationUploadsOverview.documentsStatuses'),
          sortable: false,
          tdClass: ['w-18'],
          thClass: ['w-18', 'align-middle', 'break-all-words'],
        },
        {
          key: 'uploadDate',
          label: this.$t('digitalizationUploadsOverview.uploadDate'),
          sortable: true,
          tdClass: ['w-12'],
          thClass: ['w-12', 'align-middle', 'break-all-words'],
        },
        {
          key: 'action',
          label: '',
          sortable: false,
          tdClass: ['w-5'],
          thClass: ['w-5', 'align-middle', 'break-all-words'],
        },
      ];
    },
  },
  methods: {
    async getUploads(): Promise<void> {
      try {
        this.isLoadingUploads = true;

        const filtering = Object.fromEntries(
          Object.entries(this.filtering ?? {}).filter(
            ([_key, value]) => Boolean(value) && _key !== 'uploadDate',
          ),
        );

        const response: AxiosResponse<
          IPageDto<IDigitalizationUploadSummaryDto>
        > = await axios.get(
          endpointsList.digitalizationUploads.getPaginatedDigitalizationUploadsSummary(
            {
              digitalizationProjectId: this.digitalizationProjectSettings._id,
              order: this.sortDesc ? Order.DESC : Order.ASC,
              page: this.page,
              sortBy: this.sortBy,
              take: this.perPage,
              ...filtering,
            },
          ),
        );

        const pageDto = response.data;

        this.uploads = pageDto.data;
        this.tableRows = pageDto.meta.itemCount;
      } catch {
        (this.createToast as ToastMixin)!(
          this.$t('errors.error'),
          this.$t(
            'digitalizationUploadsOverview.errors.errorGettingUploads.long',
          ),
          'danger',
        );
      } finally {
        this.isLoadingUploads = false;
      }
    },

    debouncedGetUploads: debounce(function (this: any) {
      this.getUploads();
    }, 200),

    handlePerPageChange(perPage: number): void {
      this.perPage = perPage;
      this.getUploads();
    },

    handlePageNumberChange(pageNumber: number): void {
      this.page = pageNumber;
      this.getUploads();
    },

    handleFilteringChange(filtering: Record<string, string>): void {
      this.filtering = filtering;
      if (
        (filtering.uploadDate as IDateRange).startDate &&
        (filtering.uploadDate as IDateRange).endDate
      ) {
        this.filtering.uploadDateFrom = (
          filtering.uploadDate as IDateRange
        ).startDate!;
        this.filtering.uploadDateTo = (
          filtering.uploadDate as IDateRange
        ).endDate!;
      } else {
        this.filtering.uploadDateFrom = '';
        this.filtering.uploadDateTo = '';
      }
      this.debouncedGetUploads();
    },

    handleSortDescChanged(sortDesc: boolean): void {
      this.sortDesc = sortDesc;
      this.getUploads();
    },

    handleSortByChanged(sortBy: keyof IDigitalizationUploadSummaryDto): void {
      if (!sortBy) return;
      this.sortBy = sortBy;
      this.getUploads();
    },

    formatUploadContentsForDisplay(
      document: IDigitalizationUploadSummaryDto,
    ): string {
      return `${document.newAndUpdatedDocumentsCount.new} | ${document.newAndUpdatedDocumentsCount.updated}`;
    },

    handleNewUploadButtonClick() {
      this.$bvModal.show('new-digitalization-upload-modal');
    },

    goToDocumentsOverview(row: { item: IDigitalizationUploadSummaryDto }) {
      const tableConfig: DigitalizationDocumentsOverviewTableConfig = {
        filtering: {
          referenceNumber: row.item.referenceNumber,
        },
      };
      this.$store.commit(
        'setDigitalizationDocumentsOverviewTableConfig',
        tableConfig,
      );

      const tabNumber = (
        this as unknown as DigitalizationProjectTabNumbersMixin
      ).getDigitalizationProjectDetailViewTabNumber(
        DigitalizationProjectDetailViewTab.DOCUMENTS,
      );

      router.push({
        name: 'digitalizationProjectDetails',
        params: {
          id: this.digitalizationProjectSettings._id.toString(),
          tabNumber,
        },
      });
    },

    isUploadConfirmationReady(
      upload: IDigitalizationUploadSummaryDto,
    ): boolean {
      const relevantUpload = this.uploads?.find(
        (u) => u.referenceNumber === upload.referenceNumber,
      );
      return !!relevantUpload?.isUploadConfirmationReady;
    },

    getUploadConfirmationFileLink(
      upload: IDigitalizationUploadSummaryDto,
    ): string {
      const fileDownloadEndpoint =
        endpointsList.digitalizationUploads.downloadUploadConfirmationFile(
          this.digitalizationProjectSettings._id.toString(),
          upload.referenceNumber,
        );

      return `${window.location.origin}/api/${fileDownloadEndpoint}`;
    },

    getRowClass(upload: IDigitalizationUploadSummaryDto | undefined): string {
      if (upload) {
        if (upload.isCheckBotInspectionDisabled) {
          return 'selected-row';
        }
      }

      return 'row-class-clickable';
    },

    isNewUploadAllowed(): boolean {
      return (this as unknown as GlobalUtils).isUserRole([
        Role.Admin,
        Role.DigitalizationCoworker,
      ]);
    },
  },
});
