import { endpointsList } from '@/helpers';
import axios from 'axios';
import {
  DEFAULT_METADATA_VALUES_SEPARATOR,
  IUpdateMetadataOfMultipleDocumentsDto,
  ProjectType,
} from 'types';

export class MetadataUpdateService {
  constructor(private readonly mode: ProjectType) {}

  public async updateMultiValueMetadataProperty(
    uploadId: string | undefined,
    documentId: string | undefined,
    documentOid: string | undefined,
    digitalizationProjectId: string | undefined,
    metadataField: string,
    values: string[],
  ): Promise<void> {
    const joinedString = values.join(DEFAULT_METADATA_VALUES_SEPARATOR);

    if (this.mode === ProjectType.SUPPLIER_PROJECT) {
      await axios.post(
        endpointsList.documentsUploads.updateValuesOfEditableMetadataFields(
          uploadId!,
          documentId!,
        ),
        {
          [metadataField]: joinedString,
        },
      );
    } else if (this.mode === ProjectType.DIGITALIZATION_PROJECT) {
      const dto: IUpdateMetadataOfMultipleDocumentsDto = {
        [documentOid!]: {
          [metadataField]: joinedString,
        },
      };

      await axios.patch(
        endpointsList.digitalizationUploads.updateMetadataOfMultipleDocuments(
          digitalizationProjectId!,
          false,
        ),
        dto,
      );
    }
  }
}
