import { endpointsList } from '@/helpers';
import axios from 'axios';
import { IVgbkListElementDto, IVgbkListResponseDto, Serialized } from 'types';

export class VgbkService {
  public static async getVgbkListForProject(
    projectId: string,
  ): Promise<IVgbkListElementDto[]> {
    const response = await axios.get<Serialized<IVgbkListResponseDto>>(
      endpointsList.ibl.getVgbkListForProject(projectId),
    );

    return response.data.vgbk;
  }
}
