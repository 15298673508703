import { render, staticRenderFns } from "./DateEditor.vue?vue&type=template&id=31fd6a7e&scoped=true"
import script from "./DateEditor.script.ts?vue&type=script&lang=ts&external"
export * from "./DateEditor.script.ts?vue&type=script&lang=ts&external"
import style0 from "./DateEditor.style.scss?vue&type=style&index=0&id=31fd6a7e&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31fd6a7e",
  null
  
)

export default component.exports