"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroup = void 0;
var UserGroup;
(function (UserGroup) {
    UserGroup["admins"] = "Waerme-Berlin-Anlagen-DDP-Administrator";
    UserGroup["docuManagers"] = "Waerme-Berlin-Anlagen-DDP-DokuManager";
    UserGroup["docuResponsibles"] = "Waerme-Berlin-Anlagen-DDP-DokuVerantwortliche";
    UserGroup["inspectors"] = "Waerme-Berlin-Anlagen-DDP-Pruefer";
    UserGroup["coworkers"] = "Waerme-Berlin-Anlagen-DDP-Lieferanten";
    UserGroup["digitalizationCoworkers"] = "Waerme-Berlin-Anlagen-DDP-Digitalisierungs-Lieferanten";
})(UserGroup || (exports.UserGroup = UserGroup = {}));
