import StyledDatePicker from '@/components/StyledDatePicker/StyledDatePicker.vue';
import { endpointsList } from '@/helpers';
import { IUpdateMetadataOfMultipleDocumentsDto } from 'types';
import axios from 'axios';
import Vue from 'vue';

interface IDocumentMetadataCalendarInput {
  currentValue?: Date;
  isBusy: boolean;
  isInputValid: boolean;
}

export default Vue.extend({
  name: 'document-metadata-calendar-input',

  components: { StyledDatePicker },

  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
    digitalizationDocumentObjectId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialValue: {
      type: String,
      required: true,
    },
    getMetadataFunction: {
      type: Function,
      required: false,
    },
    metadataField: {
      type: String,
      required: true,
    },
    validationFunction: {
      type: Function,
      required: true,
    },
  },

  data(): IDocumentMetadataCalendarInput {
    return {
      currentValue: undefined,
      isBusy: false,
      isInputValid: true,
    };
  },

  methods: {
    setCurrentValue() {
      if (this.initialValue) {
        this.currentValue = new Date(this.initialValue);
      }
    },

    async handleDateUpdated(date: Date) {
      this.isBusy = true;

      try {
        const transformedDate = date.toLocaleDateString('en-US');

        const dto: IUpdateMetadataOfMultipleDocumentsDto = {
          [this.digitalizationDocumentObjectId]: {
            [this.metadataField]: transformedDate,
          },
        };
        await axios.patch(
          endpointsList.digitalizationUploads.updateMetadataOfMultipleDocuments(
            this.digitalizationProjectId,
            false,
          ),
          dto,
        );

        await this.getMetadataFunction();

        this.currentValue = date;
      } catch (err) {
        console.log(err);
        (this as any).createToast(
          this.$t('errors.error'),
          this.$t('errors.error'),
          'danger',
        );
      } finally {
        this.validateInput();
        this.isBusy = false;
        this.$emit('document-data-changed');
      }
    },

    validateInput(): void {
      this.isInputValid = this.validationFunction(this.currentValue);
    },
  },

  mounted() {
    this.setCurrentValue();
    this.validateInput();
  },
});
