<template>
  <div>
    <div class="hide-empty-switch-container">
      <b-form-checkbox v-model="isHideEmpty" switch>
        {{ $tt('kksVgbmForm.hideEmpty') }}
      </b-form-checkbox>
    </div>
    <main-table
      :busy="!localVgbmAssignments.length"
      :customFilterFunction="tableFilterFunction"
      :fields="tableFields"
      :initialSortBy="tableSortBy"
      :items="localVgbmAssignments"
      :noActionIcon="true"
    >
      <template v-for="field in textInputFields" #[`cell(${field})`]="data">
        <b-form-input
          :disabled="isInputDisabled(data.item, field)"
          :key="field"
          :state="getInputState(data.item, field)"
          :value="data.item[field]"
          @blur="handleFormInput(data.item, field, $event)"
          @input="handleFormInput(data.item, field, $event, true)"
        ></b-form-input>
      </template>
      <template #cell(quality)="data">
        <styled-multiselect
          v-if="data"
          :allowEmpty="false"
          :disabled="isInputDisabled(data.item, 'quality')"
          :isError="!getInputState(data.item, 'quality')"
          :options="qualityOptions"
          :value="data.item.quality"
          @select="handleFormInput(data.item, 'quality', $event)"
        >
          <template #singleLabel>
            <div :class="getSelectLabelClass(data.item.quality)">
              {{ getSelectLabel(data.item.quality) }}
            </div>
          </template>
          <template #option="option">
            <div :class="getSelectLabelClass(option.option.option)">
              {{ getSelectLabel(option.option.option) }}
            </div>
          </template>
        </styled-multiselect>
      </template>
    </main-table>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import MainTable from '@/components/MainTable/MainTable.vue';
  import {
    IGetVgbmAssignmentResponseDto,
    QualityCheckStatus,
    VgbmAssignmentQuality,
  } from 'types';
  import { ITableField, TableFilterFunction } from '@/frontendInterfaces';
  import { TableClass, TableFieldsBuilder } from '@/helpers';
  import { PropType } from 'vue';
  import StyledMultiselect from '../StyledMultiselect/StyledMultiselect.vue';
  import { cloneDeep, debounce } from 'lodash';

  const KKS_VGBM_CLASS = 'VGBM006';

  export type FilledVgbmAssignment = IGetVgbmAssignmentResponseDto & {
    quality: VgbmAssignmentQuality;
  };
  export type AssignmentUpdateEvent =
    | {
        assignment: FilledVgbmAssignment;
        isValid: true;
      }
    | {
        assignment: IGetVgbmAssignmentResponseDto;
        isValid: false;
      };
  type EventOrValue =
    | {
        target: {
          value: string;
        };
      }
    | string;

  export default Vue.extend({
    name: 'kks-vgbm-form',
    components: {
      MainTable,
      StyledMultiselect,
    },
    props: {
      pendingVgbmClasses: {
        type: Array as PropType<Array<string>>,
        default: () => [],
        required: false,
      },
      vgbmAssignments: {
        type: Array as PropType<IGetVgbmAssignmentResponseDto[]>,
        required: true,
      },
    },
    data(): {
      localVgbmAssignments: IGetVgbmAssignmentResponseDto[];
      isHideEmpty: boolean;
    } {
      return {
        localVgbmAssignments: [],
        isHideEmpty: false,
      };
    },
    computed: {
      tableFields(): ITableField[] {
        return new TableFieldsBuilder<IGetVgbmAssignmentResponseDto>({
          translationKeyPrefix: 'kksVgbmForm',
          defaults: {
            tdClassFunction: this.getTableRowClass,
            tdClass: [TableClass.ALIGN_MIDDLE],
            sortable: true,
          },
        })
          .addField('description')
          .addField('class')
          .addField('value')
          .addField('unit', {
            width: ' 10%',
          })
          .addField('quality')
          .build();
      },
      tableSortBy(): keyof IGetVgbmAssignmentResponseDto {
        return 'class';
      },
      tableFilterFunction(): TableFilterFunction<IGetVgbmAssignmentResponseDto> {
        if (this.isHideEmpty) {
          return (item) => !!(item.value || item.unit || item.quality);
        } else return () => true;
      },
      textInputFields(): (keyof IGetVgbmAssignmentResponseDto)[] {
        return ['value', 'unit'];
      },
      qualityOptions(): VgbmAssignmentQuality[] {
        return Object.values(VgbmAssignmentQuality).filter(
          (value) => typeof value === 'number',
        );
      },
    },
    methods: {
      getInputState(
        assignment: IGetVgbmAssignmentResponseDto,
        key: keyof IGetVgbmAssignmentResponseDto,
      ): boolean | null {
        if (key === 'unit') return null;
        return !!assignment[key];
      },
      isAssignmentValid(assignment: IGetVgbmAssignmentResponseDto): boolean {
        return !!assignment.value && !!assignment.quality;
      },
      getSelectLabel(option: VgbmAssignmentQuality): string {
        return `${option} - ${this.$tt(`kksVgbmForm.qualityOptions.${option}`).toString()}`;
      },
      getSelectLabelClass(quality: QualityCheckStatus): string {
        return `quality-${quality}`;
      },
      syncForm() {
        this.localVgbmAssignments = cloneDeep(this.vgbmAssignments);
      },
      isInputDisabled(
        assignment: IGetVgbmAssignmentResponseDto,
        key: keyof IGetVgbmAssignmentResponseDto,
      ): boolean {
        return (
          /**
           * The KKS value cannot be edited because it's being treated as the
           * primary key.
           */
          (key === 'value' && assignment.class === KKS_VGBM_CLASS) ||
          this.pendingVgbmClasses.includes(assignment.class)
        );
      },
      getValueFromEvent(eventOrValue: EventOrValue): string {
        return typeof eventOrValue === 'object'
          ? eventOrValue.target.value
          : eventOrValue;
      },
      handleFormInput(
        assignment: IGetVgbmAssignmentResponseDto,
        key: keyof IGetVgbmAssignmentResponseDto,
        eventOrValue: EventOrValue,
        isDebounced = false,
      ) {
        if (isDebounced) {
          this.debouncedApplyFormInputChange(assignment, key, eventOrValue);
        } else this.applyFormInputChange(assignment, key, eventOrValue);
      },
      applyFormInputChange(
        assignment: IGetVgbmAssignmentResponseDto,
        key: keyof IGetVgbmAssignmentResponseDto,
        eventOrValue: EventOrValue,
      ) {
        const value = this.getValueFromEvent(eventOrValue);
        if (!this.didAssignmentChange(assignment, key, value)) return;

        this.$set(assignment, key, value);

        const assignmentUpdateEvent: AssignmentUpdateEvent = {
          assignment: cloneDeep(assignment),
          isValid: this.isAssignmentValid(assignment),
        } as AssignmentUpdateEvent;
        this.$emit('assignmentChanged', assignmentUpdateEvent);
      },
      debouncedApplyFormInputChange: debounce(function (
        this: any,
        assignment: IGetVgbmAssignmentResponseDto,
        key: keyof IGetVgbmAssignmentResponseDto,
        eventOrValue: EventOrValue,
      ) {
        this.handleFormInput(assignment, key, eventOrValue);
      }, 800),
      didAssignmentChange(
        assignment: IGetVgbmAssignmentResponseDto,
        key: keyof IGetVgbmAssignmentResponseDto,
        newValue: string,
      ) {
        return assignment[key] !== newValue;
      },
      getTableRowClass(
        _value: IGetVgbmAssignmentResponseDto[keyof IGetVgbmAssignmentResponseDto],
        key: keyof IGetVgbmAssignmentResponseDto,
        item: IGetVgbmAssignmentResponseDto,
      ): string[] {
        if (
          (key === 'value' || key === 'unit' || key === 'quality') &&
          this.pendingVgbmClasses.includes(item.class)
        ) {
          return ['busy'];
        } else return [];
      },
    },
    watch: {
      vgbmAssignments: {
        immediate: true,
        deep: true,
        handler() {
          this.syncForm();
        },
      },
    },
  });
</script>

<style lang="scss" scoped>
  @include no-valid-input-icon;

  .hide-empty-switch-container {
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-end;
  }

  @mixin colors-by-quality {
    font-weight: 500;
    &:has(.quality-10) {
      color: $brick-red-dark-1;
    }
    &:has(.quality-20) {
      color: $brick-red-light-2;
    }
    &:has(.quality-40) {
      color: $dynamic-orange-light-2;
    }
    &:has(.quality-60) {
      color: $electric-yellow-light-2;
    }
    &:has(.quality-80) {
      color: $markish-green-dark-1;
    }
  }

  ::v-deep .multiselect {
    &__single {
      @include colors-by-quality;
    }
    &__element {
      @include colors-by-quality;
    }
    &__option {
      @include colors-by-quality;
    }
  }
</style>
