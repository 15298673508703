import Vue from 'vue';
import CheckBotEstablishedKks from '@/components/CheckBotEstablishedKks/CheckBotEstablishedKks.vue';
import { IDocumentMetadata, IKksValidationListDto } from 'types';
import { MetadataGridEditorProvider } from '../../MetadataGridEditor.script';

const inject: (keyof MetadataGridEditorProvider)[] = ['kksValidation'];

export default Vue.extend({
  components: { CheckBotEstablishedKks },

  inject,

  props: {
    calculateRowHeightFunction: {
      type: Function,
      required: true,
    },
    data: {
      type: Array as () => IDocumentMetadata[],
      required: true,
    },
    documentObjectIdsByDocumentIds: {
      type: Object as () => Record<string, string>,
      required: true,
    },
    isValidatingKks: {
      type: Boolean,
      default: false,
    },
    kksValidationList: {
      type: Object as () => IKksValidationListDto,
      default: () => {},
    },
    model: {
      type: Object as () => IDocumentMetadata,
      required: true,
    },
    prop: {
      type: String,
      required: true,
    },
    rowIndex: {
      type: Number,
      required: true,
    },
    updateMetadataFunction: {
      type: Function,
      required: true,
    },
  },

  methods: {
    getKks(): string[] {
      return this.model[this.prop];
    },

    getDocumentObjectId(): string {
      const documentId = this.model.documentId;
      return this.documentObjectIdsByDocumentIds[documentId];
    },

    handleKksClicked(kks: string): void {
      this.updateMetadataFunction(kks, this.model.documentId);
    },
  },

  mounted() {
    this.calculateRowHeightFunction();
  },
});
