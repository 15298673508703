"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IblImportWarningType = exports.IblImportErrorType = void 0;
var IblImportErrorType;
(function (IblImportErrorType) {
    IblImportErrorType["CONFLICT"] = "conflict";
    IblImportErrorType["INTERNAL_ERROR"] = "internal_error";
    IblImportErrorType["MISSING_RESOURCE"] = "missing_resource";
})(IblImportErrorType || (exports.IblImportErrorType = IblImportErrorType = {}));
var IblImportWarningType;
(function (IblImportWarningType) {
    IblImportWarningType["INVALID_KKS"] = "invalid_kks";
    IblImportWarningType["INVALID_VALUE"] = "invalid_value";
    IblImportWarningType["INVALID_UNIT"] = "invalid_unit";
    IblImportWarningType["UNKNOWN_WARNING"] = "unknown_warning";
})(IblImportWarningType || (exports.IblImportWarningType = IblImportWarningType = {}));
