"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortReturnValues = void 0;
/**
 * Enum representing possible return values for a sorting function.
 */
var SortReturnValues;
(function (SortReturnValues) {
    /**
     * Indicates that the first item should come before the second item.
     */
    SortReturnValues[SortReturnValues["BEFORE"] = -1] = "BEFORE";
    /**
     * Indicates that the two items are equal in sorting order.
     */
    SortReturnValues[SortReturnValues["EQUAL"] = 0] = "EQUAL";
    /**
     * Indicates that the first item should come after the second item.
     */
    SortReturnValues[SortReturnValues["AFTER"] = 1] = "AFTER";
})(SortReturnValues || (exports.SortReturnValues = SortReturnValues = {}));
