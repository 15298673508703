import CsvParsingErrorsModal from '@/components/CsvParsingErrorsModal/CsvParsingErrorsModal.vue';
import { IUploadRecord } from '@/views/DocumentUpload/DocumentUpload.script';
import iconv from 'iconv-lite';
import JSZip from 'jszip';
import Papa, { ParseError } from 'papaparse';
import { isDefined, isSystemPath } from 'utils';
import Vue from 'vue';
import { ToastMixin } from '../../mixins/globalUtils';
import { UploadBoxMode } from '../UploadBox/UploadBox.script';
import UploadBox from '../UploadBox/UploadBox.vue';
import { UploadType } from '../UploadTable/UploadTable.script';

interface IUploadFrames {
  createToast?: ToastMixin;
  csvParsingErrors?: ParseError[];
  UploadBoxMode: typeof UploadBoxMode;
}

interface IFileAndFilepath {
  file: File;
  path: string;
}

interface ICsvDataSet {
  [key: string]: string | number;
}

export default Vue.extend({
  name: 'upload-frames',
  data(): IUploadFrames {
    return {
      csvParsingErrors: undefined,
      UploadBoxMode,
    };
  },

  components: { CsvParsingErrorsModal, UploadBox },

  props: {
    isNoMetadataUploadMode: {
      type: Boolean,
      required: true,
    },
    uploadProgress: {
      type: Number,
      required: false,
    },
  },

  methods: {
    handleCsvUpload(file: File) {
      this.parseCsv(file);
    },

    parseCsv(file: File) {
      Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        encoding: 'iso-8859-1',
        complete: (results) => {
          if (results.errors.length) {
            this.csvParsingErrors = results.errors;
            this.$bvModal.show('csv-parsing-modal');

            this.createToast!(
              this.$t('errors.error'),
              this.$t('documentUpload.errorReadingCsv'),
              'danger',
            );

            return;
          }

          if (!results?.data) {
            return;
          }

          const data = results.data as ICsvDataSet[];
          const records = this.transformCsvDataToUploadRecords(data);

          this.$emit('mass-upload-records', records);
        },
      });
    },

    transformCsvDataToUploadRecords(csvData: ICsvDataSet[]): IUploadRecord[] {
      const records: IUploadRecord[] = csvData.map((dataSet) => {
        const dataSetWithStringifiedValues =
          this.transformDataSetValuesToStrings(dataSet);

        const record: IUploadRecord = {
          uploadType: UploadType.NEW_DOCUMENT,
          isNotFoundError: true,
          isEmptyFile: false,
          rawMetadata: dataSetWithStringifiedValues,
        };

        return record;
      });

      return records;
    },

    buildMockUploadRecords(files: IFileAndFilepath[]): IUploadRecord[] {
      return files.map((file) => ({
        file: file.file,
        uploadType: UploadType.NEW_DOCUMENT,
        rawMetadata: {},
        isNotFoundError: false,
        isEmptyFile: false,
      }));
    },

    transformDataSetValuesToStrings(
      dataSet: ICsvDataSet,
    ): Record<string, string> {
      const entriesWithStringValues = Object.entries(dataSet).map((data) => [
        data[0],
        data[1]?.toString() ?? '',
      ]);

      return Object.fromEntries(entriesWithStringValues);
    },

    async readZip(file: File) {
      this.$emit('reading-zip-file');

      try {
        const zip = await JSZip.loadAsync(file, {
          decodeFileName: (bytes) => iconv.decode(bytes as any, 'cp850'),
        });
        const filePromises = Object.keys(zip.files).map(async (fileName) => {
          const entry = zip.files[fileName];
          if (!entry.dir) {
            const blob = await entry.async('blob');
            const file = new File(
              [blob],
              fileName.split('/')[fileName.split('/').length - 1],
            );
            return { file, path: fileName };
          }
        });

        const files: IFileAndFilepath[] = (await Promise.all(filePromises))
          .filter(isDefined)
          .filter((file) => !isSystemPath(file.path));

        if (this.isNoMetadataUploadMode) {
          const mockedUploadRecords = this.buildMockUploadRecords(files);
          this.$emit('mass-upload-records', mockedUploadRecords);
        }

        this.$emit('mass-upload-files', files);
      } catch {
        this.$emit('zip-reading-error');

        this.createToast!(
          this.$t('errors.error'),
          this.$t('documentUpload.errors.zipCorrupted'),
          'danger',
        );
      }
    },

    handleFileUpload(file: File) {
      if (isSystemPath(file.name)) return;

      const mockedUploadRecords = this.buildMockUploadRecords([
        {
          file: file,
          path: file.name,
        },
      ]);

      this.$emit('mass-upload-records', mockedUploadRecords);
    },

    preventDragDefaults(e: any) {
      e.preventDefault();
      e.stopPropagation();
    },
  },

  mounted() {
    window.addEventListener('dragover', this.preventDragDefaults);
    window.addEventListener('drop', this.preventDragDefaults);
  },

  beforeDestroy() {
    window.removeEventListener('dragover', this.preventDragDefaults);
    window.removeEventListener('drop', this.preventDragDefaults);
  },
});
