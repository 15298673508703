import {
  DigitalizationDocumentWithStatus,
  DocumentDetailViewMode,
  DocumentStatus,
  IFilesListFile,
  SharePointUploadStatus,
} from 'types';
import Vue from 'vue';

export default Vue.extend({
  name: 'document-status-panel',

  props: {
    mode: {
      type: String as () => DocumentDetailViewMode,
      required: true,
    },
    file: {
      type: Object as () => IFilesListFile,
      required: false,
    },
    digitalizationDocument: {
      type: Object as () => DigitalizationDocumentWithStatus,
      required: false,
    },
  },

  data() {
    return {
      modes: {
        project: DocumentDetailViewMode.PROJECT,
        digitalizationProject: DocumentDetailViewMode.DIGITALIZATION_PROJECT,
      },
    };
  },

  methods: {
    isSupplierProject(): boolean {
      return this.mode === DocumentDetailViewMode.PROJECT;
    },

    isDigitalizationProject(): boolean {
      return this.mode === DocumentDetailViewMode.DIGITALIZATION_PROJECT;
    },

    isMetadataInspection(): boolean {
      return (
        this.digitalizationDocument?.documentStatus ===
        DocumentStatus.METADATA_INSPECTION
      );
    },

    getMetadataInspectionStatus(): string {
      return this.isSharePointUploadFailed()
        ? '-'
        : this.$t(
            `metadataInspectionStatus.${this.digitalizationDocument?.metadataInspectionStatus}`,
          ).toString();
    },

    isFormalControl(): boolean {
      return (
        this.digitalizationDocument?.documentStatus ===
        DocumentStatus.FORMAL_CONTROL
      );
    },

    getFormalControlStatus(): string {
      return this.isSharePointUploadFailed()
        ? '-'
        : this.mode === DocumentDetailViewMode.PROJECT
          ? this.$t(
              `formalControlStatus.${
                (this.file ?? this.digitalizationDocument).formalControlStatus
              }`,
            ).toString()
          : this.$t(
              `digitalizationFormalControlStatus.${
                (this.file ?? this.digitalizationDocument).formalControlStatus
              }`,
            ).toString();
    },

    isQualityCheck(): boolean {
      return (
        this.digitalizationDocument?.documentStatus ===
        DocumentStatus.QUALITY_CHECK
      );
    },

    getQualityCheckStatus(): string {
      return this.isSharePointUploadFailed()
        ? '-'
        : this.$t(
            `qualityCheckStatus.${this.digitalizationDocument?.qualityCheckStatus}`,
          ).toString();
    },

    getInspectorStatus(): string {
      return this.isSharePointUploadFailed()
        ? '-'
        : this.$t(`inspectorStatus.${this.file.inspectorStatus}`).toString();
    },

    isSharePointUploadFailed(): boolean {
      return (
        (this.file ?? this.digitalizationDocument).sharePointUploadStatus ===
        SharePointUploadStatus.FAILED
      );
    },
  },
});
