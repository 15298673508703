"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFileNameFromContentDisposition = getFileNameFromContentDisposition;
/**
 * Extracts the file name from the Content-Disposition header.
 * @param contentDisposition The Content-Disposition header value.
 * @returns The extracted file name, or null if no file name is found.
 */
function getFileNameFromContentDisposition(contentDisposition) {
    if (!contentDisposition) {
        return;
    }
    const fileNameMatch = contentDisposition.match(/filename\*?=(?:(?:UTF-8'')?([^;\n]+)|"([^"]+)")/i);
    if (fileNameMatch) {
        return decodeURIComponent(fileNameMatch[1] || fileNameMatch[2]);
    }
    return;
}
