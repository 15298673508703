import { ICoworker, ISupplier, UserGroup } from 'types';
import Vue from 'vue';
import {
  FilterType,
  IFilterConfiguration,
} from '../FilterableTable/FilterableTable.script';
import { ToastMixin } from '../../mixins/globalUtils';
import FilterableTable from '../FilterableTable/FilterableTable.vue';
import SpGroupModal from '../SpGroupModal/SpGroupModal.vue';
import redCrossIcon from '../../assets/icons/remove-cross.svg';
import { TranslateResult } from 'vue-i18n';
import axios from 'axios';
import endpointsList from '../../helpers/endpointsList';

interface ISuppliersCoworkers {
  sortBy: keyof ICoworker;
  sortDesc: boolean;
  filterConfiguration: IFilterConfiguration;
  filterGridConfiguration: string[];
  createToast?: ToastMixin;
  customActionIcon: string;
  spGroup: UserGroup;
}

export default Vue.extend({
  name: 'suppliers-coworkers',

  props: {
    supplierData: {
      type: Object as () => ISupplier | null,
      required: false,
    },
  },

  components: { FilterableTable, SpGroupModal },

  data(): ISuppliersCoworkers {
    return {
      sortBy: 'lastName',
      sortDesc: true,
      filterConfiguration: {
        firstName: { type: FilterType.INPUT, column: 1, width: '208px' },
        lastName: { type: FilterType.INPUT, column: 2, width: '208px' },
        email: { type: FilterType.INPUT, column: 3, width: '208px' },
      },
      filterGridConfiguration: ['36%', '28%', '34%'],
      customActionIcon: redCrossIcon,
      spGroup: UserGroup.coworkers,
    };
  },

  computed: {
    tableFields(): Array<{
      key: string;
      label: TranslateResult;
      sortable: boolean;
      tdClass: string[];
    }> {
      return [
        {
          key: 'firstName',
          label: this.$i18n.t('supplierDetails.coworkers.firstName'),
          sortable: true,
          tdClass: ['w-36'],
        },
        {
          key: 'lastName',
          label: this.$i18n.t('supplierDetails.coworkers.lastName'),
          sortable: true,
          tdClass: ['w-28'],
        },
        {
          key: 'email',
          label: this.$i18n.t('supplierDetails.coworkers.email'),
          sortable: true,
          tdClass: ['w-34'],
        },
      ];
    },
  },

  methods: {
    async handleAddCoworker(coworker: ICoworker) {
      if (!this.supplierData) {
        return;
      }

      try {
        const response = await axios.patch(
          endpointsList.suppliers.addCoworkerToSupplier(
            this.supplierData._id.toString(),
          ),
          coworker,
        );

        if (response.status === 200) {
          this.$emit('load-supplier-data');

          this.createToast!(
            this.$t('supplierDetails.coworkerAdded'),
            this.$t('supplierDetails.coworkerAdded'),
            'success',
          );
        }
      } catch (err: any) {
        console.log(err);

        if (err.response.status === 409) {
          this.createToast!(
            this.$t(
              'supplierDetails.coworkers.errors.coworkerAlreadyAddedShort',
            ),
            this.$t(
              'supplierDetails.coworkers.errors.coworkerAlreadyAddedLong',
              {
                supplierName:
                  err.response.data.message.nameOfSupplierWithCoworker,
              },
            ),
            'danger',
          );

          return;
        }

        this.createToast!(
          this.$t('errors.error'),
          this.$t('supplierDetails.coworkers.errors.couldNotAddCoworker'),
          'danger',
        );
      }
    },

    // handleCoworkerClick(coworker: ICoworker) {
    //   if (!this.supplierData) {
    //     return;
    //   }
    //
    //   router.push({
    //     name: 'coworkerDetails',
    //     params: {
    //       coworkerEmail: coworker.email,
    //       ID: this.supplierData?._id.toString(),
    //       goBackTo: 'supplierDetails',
    //     },
    //   });
    // },

    async deleteCoworkerFromProject() {
      // removing docu manager from project

      this.createToast!(
        this.$t('generic.notImplemented'),
        this.$t('generic.notImplemented'),
        'warning',
      );
    },
  },
});
