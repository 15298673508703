import { DocumentObjectIdsAndUploadIds } from '@/components/DigitalizationDocumentsOverview/DigitalizationDocumentsOverview.script';
import endpointsList from '@/helpers/endpointsList';
import { METADATA_GRID_DOCUMENT_IDS_LOCAL_STORAGE_KEY } from '@/interfaces/constants';
import { GlobalUtils } from '@/mixins/globalUtils';
import kksValidator, { KksValidator } from '@/mixins/kksValidator';
import { ProjectDataService } from '@/services';
import digitalizationProjectTabNumbersMixin, {
  DigitalizationProjectDetailViewTab,
  DigitalizationProjectTabNumbersMixin,
} from '@/views/DigitalizationProjectDetails/digitalizationProjectTabNumbersMixin';
import { router } from '@/vue';
import SelectTypePlugin from '@revolist/revogrid-column-select';
import VGrid, {
  VGridVueEditor,
  VGridVueTemplate,
} from '@revolist/vue-datagrid';
import axios, { AxiosError, AxiosResponse, HttpStatusCode } from 'axios';
import { cloneDeep, debounce, isEqual, isNil } from 'lodash';
import {
  CheckBotInferenceStatus,
  CHECK_BOT_ESTABLISHED_KKS,
  ContentTypeFieldType,
  DEFAULT_METADATA_VALUES_SEPARATOR,
  DocumentDetailViewMode,
  DOCUMENT_ID,
  ICheckBotEstablishedKksForFiles,
  IDocumentMetadata,
  IDocumentMetadataWithFieldMetadataDto,
  IGetCheckBotEstablishedKksForDocumentsDto,
  IGetInferenceStatusForDocumentsDto,
  IInferenceSatusesForFiles,
  IKksValidationListDto,
  IMetadataAndFieldMetadata,
  IUpdateMetadataOfMultipleDocumentsDto,
  IUpdateMultipleQualityCheckStatusesDto,
  KKS,
  ORIGNINAL_FILE_NAME_METADATA_FIELD,
  ProjectType,
  QualityCheckStatus,
  Serialized,
  UniversalMappingField,
} from 'types';
import { deduplicateArray, divideArray, isDefined } from 'utils';
import Vue from 'vue';
import { IconForData } from './customCells/CellWithIcon/CellWithIcon.script';
import CellWithIcon from './customCells/CellWithIcon/CellWithIcon.vue';
import CheckBotKksCell from './customCells/CheckBotKksCell/CheckBotKksCell.vue';
import DateEditor from './customEditors/DateEditor/DateEditor.vue';
import TagEditor from './customEditors/TagEditor/TagEditor.vue';
import KksEditor from './customEditors/KksEditor/KksEditor.vue';

const datePicker = VGridVueEditor(Vue.component('dateEditor', DateEditor));
const tagEditor = VGridVueEditor(Vue.component('tagEditor', TagEditor));
const kksEditor = VGridVueEditor(Vue.component('kksEditor', KksEditor));

export enum MetadataGridEditorMode {
  METADATA_INSPECTION = 'metadataInspection',
  QUALITY_CHECK = 'qualityCheck',
}

interface IMetadataGridEditor {
  changedMetadata: Record<string, boolean>;
  changeIndicatorIcons: IconForData[];
  columns: Column[];
  documentObjectIds?: string[];
  documentObjectIdsAndUploadIds?: DocumentObjectIdsAndUploadIds[];
  documentsMetadataWithFieldInfo?: IDocumentMetadataWithFieldMetadataDto[];
  gridEditors: { datePicker: any; kksEditor: any; tagEditor: any };
  inferenceStatuses: IInferenceSatusesForFiles;
  initialMetadata: IDocumentMetadata[];
  isCopyingKks: boolean;
  isGettingMetadata: boolean;
  isSavingChanges: boolean;
  isSavingChangesAndContinuing: boolean;
  isTransformingMetadata: boolean;
  kksBeforeAcceptance?: KksBeforeAcceptance;
  metadata?: IDocumentMetadata[];
  plugin: { select: SelectTypePlugin };
  projectDataService: ProjectDataService;
  projectLocation?: string;
  rowDefinitions: RowDefinition[];
  rows: any;
}

type KksBeforeAcceptance = {
  [documentId: string]: {
    KKS?: string;
    checkBotEstablishedKKS?: string[];
  };
};

type RowDefinition = {
  type: 'rgRow';
  index: number;
  size: number;
};

type Column = {
  columnType?: ColumnType;
  editor?: any;
  prop: string;
  name: string;
  source?: string[];
  cellProperties?: ({ prop, model }: { prop: string; model: any }) => object;
  readonly: boolean;
  pin?: ColumnPin;
  cellTemplate?: (createElement: any, props: any, additionalData?: any) => any;
  size?: number;
};

enum ColumnType {
  DATE = 'date',
  NUMBER = 'number',
  SELECT = 'select',
  STRING = 'string',
}

enum ColumnPin {
  COL_PIN_START = 'colPinStart',
}

export type ColumnTypeAndEditor = Pick<
  Column,
  'columnType' | 'editor' | 'cellTemplate'
>;

export type MetadataGridEditorProvider = {
  fieldInfo: () => IDocumentMetadataWithFieldMetadataDto[] | undefined;
  kksValidation: () => IKksValidationListDto;
  projectLocation: () => string;
};

const getMarkedCellStyle = (
  borderColor: '#5c1413' | 'red' | '#36286d',
  pointerOnHover = false,
) => ({
  display: 'block',
  borderWidth: '.08rem',
  borderStyle: 'solid',
  borderColor,
  cursor: pointerOnHover ? 'pointer' : 'auto',
});
const cellStyles = {
  docId: { cursor: 'pointer' },
  idOfDocAfterInference: getMarkedCellStyle('#36286d', true),
  checkBotInferredValue: getMarkedCellStyle('#5c1413'),
  missingRequired: getMarkedCellStyle('red'),
};

export default Vue.extend({
  name: 'metadata-grid-editor',

  components: { VGrid },

  mixins: [digitalizationProjectTabNumbersMixin, kksValidator],

  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
    mode: {
      type: String as () => MetadataGridEditorMode,
      required: true,
    },
  },

  data(): IMetadataGridEditor {
    return {
      changeIndicatorIcons: [],
      changedMetadata: {},
      columns: [],
      documentObjectIds: undefined,
      documentObjectIdsAndUploadIds: undefined,
      documentsMetadataWithFieldInfo: undefined,
      gridEditors: { datePicker, kksEditor, tagEditor },
      initialMetadata: [],
      inferenceStatuses: {},
      isCopyingKks: false,
      isGettingMetadata: false,
      isSavingChanges: false,
      isSavingChangesAndContinuing: false,
      isTransformingMetadata: false,
      kksBeforeAcceptance: undefined,
      metadata: undefined,
      plugin: { select: new SelectTypePlugin() },
      projectDataService: new ProjectDataService(
        ProjectType.DIGITALIZATION_PROJECT,
      ),
      projectLocation: undefined,
      rowDefinitions: [],
      rows: [],
    };
  },

  methods: {
    getDocumentObjectIdsFromLocalStorage() {
      const stringifiedIds = localStorage.getItem(
        METADATA_GRID_DOCUMENT_IDS_LOCAL_STORAGE_KEY,
      );
      if (stringifiedIds === null) return;
      const ids: DocumentObjectIdsAndUploadIds[] = JSON.parse(stringifiedIds);

      this.documentObjectIds = ids.map((id) => id.documentObjectId);
      this.documentObjectIdsAndUploadIds = ids;
    },

    async getMetadata() {
      try {
        this.isGettingMetadata = true;

        const response = await axios.post<
          IDocumentMetadataWithFieldMetadataDto[]
        >(
          endpointsList.digitalizationUploads.getDocumentMetadataByIds(
            this.digitalizationProjectId,
            false,
            [ORIGNINAL_FILE_NAME_METADATA_FIELD as string],
          ),
          this.documentObjectIds,
        );

        this.documentsMetadataWithFieldInfo = response.data;
        const metadata = this.transformMetadaResponseToArrayOfObjects(
          response.data,
        );

        this.metadata = metadata;
        this.initialMetadata = cloneDeep(metadata);

        await this.appendCheckBotEstablishedKksToMetadata();

        this.transformMetadataToColumns();
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('metadataGridEditor.errors.errorGettingMetadata'),
          'danger',
        );
      } finally {
        this.isGettingMetadata = false;
      }
    },

    transformMetadaResponseToArrayOfObjects(
      response: IDocumentMetadataWithFieldMetadataDto[],
    ): IDocumentMetadata[] {
      return response.map((metadataAndId) =>
        Object.fromEntries(
          metadataAndId.metadata.map((metadata) => [
            metadata.fieldName,
            metadata.fieldValue,
          ]),
        ),
      ) as IDocumentMetadata[];
    },

    transformMetadataToColumns() {
      if (!this.documentsMetadataWithFieldInfo) return;

      const columns = Object.keys(this.metadata?.[0] ?? {})
        .filter((key) => this.getFieldInfoByFieldName(key))
        .map((key) => {
          const fieldInfo = this.getFieldInfoByFieldName(key);
          if (!fieldInfo) return;

          const columnSettings = this.getColumnTypeByDataType(
            fieldInfo.fieldType,
            key,
          );
          const source = fieldInfo.possibleValues;
          return {
            prop: key,
            name: this.$t(
              `contentTypeFields.${fieldInfo.fieldDisplayName}`,
            )?.toString(),
            source,
            cellProperties: ({ prop, model }: any) => {
              return {
                style: this.getCellStyle(model[prop], fieldInfo),
                onClick: () => {
                  this.handleCellClick(prop, model[prop]);
                },
              };
            },
            readonly: fieldInfo.readonly,
            pin:
              fieldInfo.fieldName === UniversalMappingField.DOCUMENT_ID
                ? ColumnPin.COL_PIN_START
                : undefined,
            ...columnSettings,
            size: fieldInfo.fieldName === KKS ? 400 : undefined,
          };
        })
        .filter(isDefined);

      this.columns = columns;
      this.appendCheckBotEstablishedKksColumn();
      this.sortColumns();
    },

    sortColumns() {
      const order: string[] = [
        'OriginalFilename',
        /**
         * Temporary fix to deal with typo in `OriginalFileName` field in
         * Queportdev SharePoint.
         */
        'OriginalFIlename',
        'CreatorDocumentNumber',
        'Title',
        KKS as string,
        CHECK_BOT_ESTABLISHED_KKS as string,
        'DocumentClass',
        'DocumentClassContent',
        'DocLanguage',
        'Revision',
        'DocumentCreator',
        'CreateDate',
        'ReleaseDate',
      ];
      this.columns = this.columns.sort((a, b) => {
        const indexA = order.indexOf(a.prop);
        const indexB = order.indexOf(b.prop);

        /**
         * If the key is not in the predefinedOrder array, place it after the
         * sorted ones.
         */
        if (indexA === -1 && indexB !== -1) return 1;
        if (indexA !== -1 && indexB === -1) return -1;

        return indexA - indexB;
      });
    },

    getColumnTypeByDataType(
      dataType: ContentTypeFieldType,
      fieldName: string,
    ): ColumnTypeAndEditor {
      const columnException = this.getColumnException(fieldName);
      if (columnException) return columnException;

      switch (dataType) {
        case ContentTypeFieldType.TEXT:
          return { columnType: ColumnType.STRING };
        case ContentTypeFieldType.NOTE:
          return { columnType: ColumnType.STRING };
        case ContentTypeFieldType.DATE_TIME:
          return {
            columnType: ColumnType.DATE,
            editor: this.gridEditors.datePicker,
          };
        case ContentTypeFieldType.TAXONOMY_FIELD_TYPE:
          return { columnType: ColumnType.SELECT };
        case ContentTypeFieldType.TAXONOMY_FIELD_TYPE_MULTI:
          return { editor: this.gridEditors.tagEditor };
        default:
          return { columnType: ColumnType.STRING };
      }
    },

    getColumnException(fieldName: string): ColumnTypeAndEditor | undefined {
      if (fieldName === UniversalMappingField.DOCUMENT_ID) {
        return {
          cellTemplate: VGridVueTemplate(CellWithIcon, {
            icons: ['🔗'],
            buildIconsForDataFunction:
              this.mode === MetadataGridEditorMode.QUALITY_CHECK
                ? this.buildChangeIndicatorIcons
                : undefined,
          }),
        };
      }
      if (fieldName === KKS) {
        return {
          editor: this.gridEditors.kksEditor,
        };
      }
    },

    getCellStyle(currentValue: string, fieldInfo: IMetadataAndFieldMetadata) {
      /** Empty required value */
      if (this.isValueEmpty(currentValue) && fieldInfo.required) {
        return cellStyles.missingRequired;
      } else if (
        /** Check bot inferred value */
        currentValue !== undefined &&
        fieldInfo.checkBotInferredValue !== undefined &&
        currentValue === fieldInfo.checkBotInferredValue
      ) {
        return cellStyles.checkBotInferredValue;
      } else if (
        currentValue !== undefined &&
        fieldInfo.fieldName === UniversalMappingField.DOCUMENT_ID
      ) {
        if (this.isMetadataInferedForDocumentId(currentValue)) {
          return cellStyles.idOfDocAfterInference;
        } else {
          return cellStyles.docId;
        }
      } else {
        return {};
      }
    },

    getFieldInfoByFieldName(
      fieldName: string,
    ): IMetadataAndFieldMetadata | undefined {
      return this.documentsMetadataWithFieldInfo?.[0].metadata.find(
        (field) => field.fieldName === fieldName,
      );
    },

    isValueEmpty(value: string | number): boolean {
      return isNil(value) || value === '';
    },

    goToDocumentsOverview() {
      router.push({
        name: 'digitalizationProjectDetails',
        params: {
          id: this.digitalizationProjectId.toString(),
          tabNumber: (
            this as unknown as DigitalizationProjectTabNumbersMixin
          ).getDigitalizationProjectDetailViewTabNumber(
            DigitalizationProjectDetailViewTab.DOCUMENTS,
          ),
        },
      });
    },

    buildMetadataUpdateDto(
      metadata: IDocumentMetadata[],
    ): IUpdateMetadataOfMultipleDocumentsDto {
      if (!this.documentsMetadataWithFieldInfo) return {};

      return Object.fromEntries(
        metadata.map((metadata) => {
          const _id = this.documentsMetadataWithFieldInfo!.find(
            (document) =>
              document.metadata.find(
                (field) => field.fieldName === 'documentId',
              )?.fieldValue === metadata.documentId,
          )?._id;

          return [_id, metadata];
        }),
      );
    },

    handleCancelButtonClick(): void {
      this.goToDocumentsOverview();
    },

    async handleMetadataSave(
      acceptMetadataInspection: boolean,
      metadata: IDocumentMetadata[],
    ): Promise<void> {
      const dto = this.buildMetadataUpdateDto(metadata);

      try {
        await axios.patch(
          endpointsList.digitalizationUploads.updateMetadataOfMultipleDocuments(
            this.digitalizationProjectId,
            acceptMetadataInspection,
          ),
          dto,
        );

        (this as unknown as GlobalUtils).createToast(
          '',
          this.$t('metadataGridEditor.metadataSaved'),
          'success',
        );
      } catch (err: unknown) {
        if (
          err instanceof AxiosError &&
          err.response?.status === HttpStatusCode.BadRequest
        ) {
          (this as unknown as GlobalUtils).createToast(
            this.$t('errors.error'),
            this.$t('metadataGridEditor.errors.errorValidatingMetadata'),
            'danger',
          );
        } else {
          (this as unknown as GlobalUtils).createToast(
            this.$t('errors.error'),
            this.$t('metadataGridEditor.errors.errorSavingMetadataChanges'),
            'danger',
          );
        }

        throw err;
      }
    },

    buildQualityCheckUpdateDto(
      documentObjectIds: string[],
      qualityCheckStatus: QualityCheckStatus,
    ): Serialized<IUpdateMultipleQualityCheckStatusesDto> {
      return {
        digitalizationProjectId: this.digitalizationProjectId,
        documentObjectIds,
        qualityCheckStatus,
      };
    },

    async handleAcceptQualityCheck(documentObjectIds: string[]): Promise<void> {
      try {
        await axios.patch(
          endpointsList.digitalizationUploads
            .setQualityCheckStatusesOfMultipleDocuments,
          this.buildQualityCheckUpdateDto(
            documentObjectIds,
            QualityCheckStatus.ACCEPTED,
          ),
        );
      } catch (err) {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('metadataGridEditor.errors.errorUpdatingQualityCheck'),
          'danger',
        );

        throw err;
      }
    },

    async handleSaveButton(acceptMetadataInspection: boolean): Promise<void> {
      try {
        if (!this.metadata) return;

        this.setSaveButtonBusyState(acceptMetadataInspection, true);

        if (this.isMetadataInspectionMode()) {
          await this.handleMetadataSave(
            acceptMetadataInspection,
            this.metadata,
          );
        } else {
          const [changed, notChanged] = divideArray(
            this.metadata,
            (doc) => this.changedMetadata[doc.documentId],
          );
          const docObjectIdsOfNotChanged = notChanged.map((doc) =>
            this.getDocumentObjectIdByDocumentId(doc.documentId),
          );

          const updatePromises: Promise<void>[] = [];
          if (changed.length) {
            updatePromises.push(this.handleMetadataSave(true, changed));
          }
          if (notChanged.length) {
            updatePromises.push(
              this.handleAcceptQualityCheck(docObjectIdsOfNotChanged),
            );
          }

          await Promise.all(updatePromises);
        }

        this.goToDocumentsOverview();
      } finally {
        this.setSaveButtonBusyState(acceptMetadataInspection, false);
      }
    },

    setSaveButtonBusyState(
      acceptMetadataInspection: boolean,
      isBusy: boolean,
    ): void {
      if (acceptMetadataInspection) {
        this.isSavingChangesAndContinuing = isBusy;
      } else {
        this.isSavingChanges = isBusy;
      }
    },

    async getCheckBotEstablishedKks() {
      if (!this.documentObjectIds) return;

      const body: Serialized<IGetCheckBotEstablishedKksForDocumentsDto> = {
        documentObjectIds: this.documentObjectIds,
      };

      const response: AxiosResponse<ICheckBotEstablishedKksForFiles> =
        await axios.post(
          endpointsList.digitalizationUploads
            .getCheckBotEstablishedKksForMultipleDocuments,
          body,
        );

      return response.data;
    },

    async appendCheckBotEstablishedKksToMetadata(): Promise<void> {
      const checkBotKks = await this.getCheckBotEstablishedKks();

      if (!this.metadata || !checkBotKks) return;

      this.metadata = this.metadata.map((doc) => {
        const docuObjectId = this.documentsMetadataWithFieldInfo?.find(
          (metadataForDoc) =>
            metadataForDoc.metadata.find(
              (field) => field.fieldName === DOCUMENT_ID,
            )?.fieldValue === doc.documentId,
        )?._id;
        if (!docuObjectId) return doc;

        const kks = checkBotKks[docuObjectId.toString()];
        const deduplicatedKks = deduplicateArray(kks);
        const filteredKks = deduplicatedKks.filter(
          (kks) =>
            !doc.KKS?.split(DEFAULT_METADATA_VALUES_SEPARATOR).includes(kks),
        );

        doc.checkBotEstablishedKKS = filteredKks;

        return doc;
      });
    },

    appendCheckBotEstablishedKksColumn(): void {
      this.columns.push({
        columnType: ColumnType.STRING,
        prop: CHECK_BOT_ESTABLISHED_KKS as string,
        name: this.$t(
          'metadataGridEditor.checkBotEstablishedKksNumbers',
        ).toString(),
        readonly: true,
        size: 300,
        cellTemplate: VGridVueTemplate(CheckBotKksCell, {
          calculateRowHeightFunction:
            this.calculateCheckBotEstablishedKksCellHeight,
          documentObjectIdsByDocumentIds:
            this.getDocumentObjectIdsForDocumentIds(),
          updateMetadataFunction: this.handleCheckBotKksAccepted,
        }),
      });
    },

    handleCheckBotKksAccepted(kks: string, documentId: string) {
      const metadata = this.metadata?.find(
        (doc) => doc.documentId === documentId,
      );
      if (!metadata) throw new Error();

      if (!metadata.KKS) {
        metadata.KKS = kks;
      } else {
        metadata.KKS = `${metadata.KKS}${DEFAULT_METADATA_VALUES_SEPARATOR}${kks}`;
      }

      metadata!.checkBotEstablishedKKS =
        metadata.checkBotEstablishedKKS!.filter(
          (checkBotKks) => checkBotKks !== kks,
        );

      this.metadata = cloneDeep(this.metadata);
    },

    calculateCheckBotEstablishedKksCellHeight() {
      const checkBotKksNodes = document.querySelectorAll('.check-bot-kks-cell');
      checkBotKksNodes.forEach((node, index) => {
        const size = (node as HTMLElement).offsetHeight;

        this.rowDefinitions = [
          ...this.rowDefinitions,
          {
            size,
            type: 'rgRow',
            index,
          },
        ];
      });
    },

    getDocumentObjectIdsForDocumentIds(): Record<string, string> {
      return (
        this.documentsMetadataWithFieldInfo?.reduce(
          (ids, doc) => {
            const documentId = doc.metadata.find(
              (field) => field.fieldName === DOCUMENT_ID,
            )?.fieldValue;
            if (!documentId) throw new Error();

            ids[documentId] = doc._id.toString();
            return ids;
          },
          {} as Record<string, string>,
        ) ?? {}
      );
    },

    saveKksBeforeAcceptance() {
      this.kksBeforeAcceptance =
        this.metadata?.reduce((kksState, doc) => {
          kksState[doc.documentId] = {
            KKS: doc.KKS,
            checkBotEstablishedKKS: doc.checkBotEstablishedKKS,
          };

          return kksState;
        }, {} as KksBeforeAcceptance) ?? {};
    },

    applyKksBeforeAcceptance() {
      if (!this.kksBeforeAcceptance) throw new Error();

      Object.entries(this.kksBeforeAcceptance).forEach(([docId, kksState]) => {
        const metadata = this.metadata?.find((doc) => doc.documentId === docId);
        if (!metadata) throw Error();

        metadata.KKS = kksState.KKS;
        metadata.checkBotEstablishedKKS = kksState.checkBotEstablishedKKS;
      });
    },

    async moveCheckBotKksToMainKksField(onlyValidated: boolean): Promise<void> {
      this.saveKksBeforeAcceptance();

      this.metadata?.forEach((doc) => {
        doc.checkBotEstablishedKKS?.forEach((kks) => {
          if (
            !onlyValidated ||
            (this as unknown as KksValidator).kksValidationList[kks]
          ) {
            this.handleCheckBotKksAccepted(kks, doc.documentId);
          }
        });
      });
    },

    getAllKks(): string[] {
      const checkBotKks =
        this.metadata
          ?.flatMap((doc) => doc.checkBotEstablishedKKS)
          .filter(isDefined) ?? [];
      const kks =
        this.metadata
          ?.flatMap((doc) => doc.KKS?.split(DEFAULT_METADATA_VALUES_SEPARATOR))
          .filter(isDefined) ?? [];

      return [...checkBotKks, ...kks];
    },

    async validateAllKks(): Promise<void> {
      if (!this.projectLocation) return;
      const kks = this.getAllKks();
      await (this as unknown as KksValidator).validateKks(
        kks,
        this.projectLocation,
      );
    },

    async getCheckBotInferenceStatuses(): Promise<void> {
      if (!this.documentObjectIds) return;

      const body: Serialized<IGetInferenceStatusForDocumentsDto> = {
        documentObjectIds: this.documentObjectIds,
      };
      try {
        const response = await axios.post(
          endpointsList.digitalizationUploads
            .getCheckBotInferenceStatusForMultipleDocuments,
          body,
        );

        this.inferenceStatuses = response.data;
      } catch {
        (this as unknown as GlobalUtils).createToast(
          this.$t('errors.error'),
          this.$t('metadataGridEditor.errors.errorGettingInferenceStatuses'),
          'danger',
        );
      }
    },

    getDocumentByDocumentId(
      documentId: string,
    ): IDocumentMetadataWithFieldMetadataDto | undefined {
      return this.documentsMetadataWithFieldInfo?.find((doc) =>
        doc.metadata.some(
          (metadata) =>
            metadata.fieldName === UniversalMappingField.DOCUMENT_ID &&
            metadata.fieldValue === documentId,
        ),
      );
    },

    getDocumentObjectIdByDocumentId(documentId: string): string {
      const id = this.getDocumentByDocumentId(documentId)?._id;
      if (!id) throw new Error();

      return id.toString();
    },

    isMetadataInferedForDocumentId(documentId: string): boolean {
      const docObjectId = this.getDocumentObjectIdByDocumentId(documentId);
      return (
        this.inferenceStatuses[docObjectId] ===
        CheckBotInferenceStatus.DATA_RECEIVED
      );
    },

    handleCellClick(field: string, value: string): void {
      if (field !== UniversalMappingField.DOCUMENT_ID) return;
      this.goToDocumentDetailView(value);
    },

    getUploadIdByDocumentId(documentId: string): string | undefined {
      const documentObjectId = this.getDocumentObjectIdByDocumentId(documentId);
      return this.documentObjectIdsAndUploadIds
        ?.find((id) => id.documentObjectId === documentObjectId)
        ?.toString();
    },

    goToDocumentDetailView(documentId: string) {
      const routeData = router.resolve({
        name: 'documentDetailView',
        params: {
          mode: DocumentDetailViewMode.DIGITALIZATION_PROJECT,
          documentId,
          projectId: this.digitalizationProjectId,
          uploadId: this.getDocumentObjectIdByDocumentId(documentId),
        },
      });

      window.open(routeData.href, '_blank');
    },

    getChangedMetadata(): void {
      this.metadata?.forEach((doc) => {
        const initialMetadataDoc = this.initialMetadata?.find(
          (initialDoc) => initialDoc.documentId === doc.documentId,
        );

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { checkBotEstablishedKKS, ...docWithoutCheckBotKks } = doc;

        this.changedMetadata[doc.documentId] = !isEqual(
          JSON.parse(JSON.stringify(docWithoutCheckBotKks)),
          JSON.parse(JSON.stringify(initialMetadataDoc)),
        );
      });
    },

    buildChangeIndicatorIcons(): IconForData[] {
      return (this.changeIndicatorIcons = Object.entries(
        this.changedMetadata,
      ).map(([docId, isChanged]) => ({
        data: docId,
        icon: isChanged ? '✘' : '✔',
      })));
    },

    isMetadataInspectionMode(): boolean {
      return this.mode === MetadataGridEditorMode.METADATA_INSPECTION;
    },

    isQualityCheckMode(): boolean {
      return this.mode === MetadataGridEditorMode.QUALITY_CHECK;
    },

    async getProjectLocation(): Promise<void> {
      this.projectLocation = await this.projectDataService.getProjectLocation(
        this.digitalizationProjectId,
      );
    },
  },

  provide(): MetadataGridEditorProvider {
    return {
      fieldInfo: () => this.documentsMetadataWithFieldInfo,
      kksValidation: () => (this as unknown as KksValidator).kksValidationList,
      projectLocation: () => this.projectLocation ?? '',
    };
  },

  watch: {
    metadata: {
      handler() {
        this.validateAllKks();
        this.getChangedMetadata();
        this.calculateCheckBotEstablishedKksCellHeight();
        this.getProjectLocation();
      },
      deep: true,
    },
  },

  created() {
    this.calculateCheckBotEstablishedKksCellHeight = debounce(
      this.calculateCheckBotEstablishedKksCellHeight.bind(this),
      300,
    );
  },

  mounted() {
    this.getDocumentObjectIdsFromLocalStorage();
    this.getMetadata();
    this.getCheckBotInferenceStatuses();
    this.validateAllKks();
  },
});
