<template>
  <p id="go-back" @click="handleGoBack">
    <font-awesome-icon icon="fa-solid fa-arrow-left-long" />
    {{ $t('generic.goBack') }}
  </p>
</template>

<script lang="ts">
  import { router } from '../../vue';
  import Vue from 'vue';

  export default Vue.extend({
    name: 'go-back',
    components: {},
    props: {
      target: {
        type: String,
      },
      id: {
        type: String,
        required: true,
      },
      tabIndex: {
        type: Number,
        required: false,
      },
    },
    methods: {
      handleGoBack() {
        router.push({
          name: this.target,
          params: { id: this.id, targetTabIndex: this.tabIndex as any },
        });
      },
    },
  });
</script>

<style scoped lang="scss">
  #go-back {
    cursor: pointer;
  }
</style>
