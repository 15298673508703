import { ISingleUploadRecord } from '@/components/SingleUploadForm/SingleUploadForm.script';
import endpointsList from '@/helpers/endpointsList';
import axios from 'axios';
import { DocumentsUploadRequest, FileRequest, IFileMetadata } from 'types';
import { IUploadRecord } from '../../DocumentUpload/DocumentUpload.script';
import DocumentUploadHelperAbstract from './DocumentUploadHelperAbstract';

export default class DocumentUploadHelper extends DocumentUploadHelperAbstract {
  constructor(
    projectName: string,
    projectId: string,
    projectNumber: string,
    readonly supplierName: string,
    readonly supplierId: string,
    readonly SAPNumber: string,
    referenceNumber: string,
    contentTypeId: string,
    uploadRecords: IUploadRecord[] | ISingleUploadRecord[],
  ) {
    super(
      projectName,
      projectId,
      projectNumber,
      referenceNumber,
      contentTypeId,
      uploadRecords,
    );
  }

  async createSharepointFolder() {
    await axios.post(
      endpointsList.sharePointIntegration.prepareUploadDirectories(
        this.projectNumber,
        this.SAPNumber,
      ),
    );
  }

  async saveFilesInSharepoint() {
    for (const record of this.uploadRecords) {
      // Skip records without files (just metadata changes)
      if (!record.file || record.isEmptyFile) {
        continue;
      }

      this.emit('uploadStatusChange', {
        id: record.metadata!.documentId,
        status: 'uploading',
      });

      const formData = new FormData();
      formData.append('file', record.file);
      formData.append('referenceNumber', this.referenceNumber);
      formData.append('documentId', record.metadata!.documentId);
      formData.append('originalFileName', record.file.name);
      formData.append('contentTypeId', this.contentTypeId);

      await axios.post(
        endpointsList.documentsUploads.uploadFile(
          this.projectId,
          this.supplierId,
        ),
        formData,
      );

      this.emit('uploadStatusChange', {
        id: record.metadata!.documentId,
        status: 'uploaded',
      });
    }
    this.isUploadToSPFinished = true;
  }

  async postDocumentsUploadsToDB() {
    if (!this.isUploadToSPFinished) {
      return;
    }

    const files: FileRequest[] = this.uploadRecords.map(
      (record): FileRequest => {
        const file: {
          fileMetadata: IFileMetadata;
          rawMetadata?: Record<string, string>;
          originalFileName?: string;
        } = {
          fileMetadata: record.metadata!,
        };

        if (record.file && !record.isEmptyFile) {
          file.originalFileName = record.file.name;
        }

        if ((record as IUploadRecord).rawMetadata) {
          file.rawMetadata = (record as IUploadRecord).rawMetadata;
        }

        return file;
      },
    );

    const documentsUpload: DocumentsUploadRequest = {
      referenceNumber: this.referenceNumber,
      contentTypeId: this.contentTypeId,
      projectId: this.projectId,
      supplierId: this.supplierId,
      files,
    };

    await axios.post(
      endpointsList.documentsUploads.addDocumentsUpload,
      documentsUpload,
    );

    this.isUploadToDBFinished = true;
  }
}
