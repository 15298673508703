var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('b-tooltip',{key:_vm.targetId,attrs:{"placement":"bottom","triggers":"click","target":_vm.targetId}},[_c('div',{staticClass:"progress-step-indicator-tooltip"},[_c('document-status-panel',{attrs:{"digitalizationDocument":_vm.digitalizationDocument,"file":_vm.file,"mode":_vm.mode}}),(_vm.isSupplierProject() && _vm.isFormalControlDone())?_c('inspector-panel',{attrs:{"file":_vm.file},on:{"status-updated":_vm.emitDocumentDataChangedEvent}}):_vm._e(),(
        _vm.isDigitalizationProject() &&
        _vm.isQualityCheck() &&
        _vm.isUserRole(_vm.qualityCheckPanelAllowedRoles) &&
        !_vm.isSharePointUploadFailed()
      )?_c('quality-check-panel',{attrs:{"digitalizationDocument":_vm.digitalizationDocument},on:{"document-data-changed":_vm.emitDocumentDataChangedEvent}}):_vm._e(),(
        _vm.isUserRole(_vm.metadataInspectionAllowedRoles) && _vm.isMetadataInspection()
      )?_c('accept-metadata-inspection-button',{attrs:{"digitalizationDocument":_vm.digitalizationDocument},on:{"document-data-changed":_vm.emitDocumentDataChangedEvent}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }