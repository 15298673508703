"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DigitalizationFormalControlStatus = void 0;
var DigitalizationFormalControlStatus;
(function (DigitalizationFormalControlStatus) {
    DigitalizationFormalControlStatus["ACCEPTED"] = "accepted";
    DigitalizationFormalControlStatus["REJECTED"] = "rejected";
    DigitalizationFormalControlStatus["ACCEPTED_WITH_REMARKS"] = "acceptedWithRemarks";
    DigitalizationFormalControlStatus["WAITING"] = "waiting";
    DigitalizationFormalControlStatus["WAITING_FOR_METADATA_CHECK"] = "waitingForMetadataCheck";
})(DigitalizationFormalControlStatus || (exports.DigitalizationFormalControlStatus = DigitalizationFormalControlStatus = {}));
