import ProjectForm from '@/components/ProjectForm/ProjectForm.vue';
import DigitalizationProjectDocuResponsibles from '@/components/DigitalizationProjectDocuResponsibles/DigitalizationProjectDocuResponsibles.vue';
import { IDigitalizationProject } from 'types';
import Vue from 'vue';
import { ProjectFormMode } from '../ProjectForm/ProjectForm.script';

interface DigitalizationProjectInformations {
  ProjectFormMode: typeof ProjectFormMode;
}

export default Vue.extend({
  name: 'digitalization-project-informations',
  components: { DigitalizationProjectDocuResponsibles, ProjectForm },
  props: {
    digitalizationProjectId: {
      type: String,
      required: true,
    },
    digitalizationProjectData: {
      type: Object as () => IDigitalizationProject,
      required: true,
    },
  },
  data(): DigitalizationProjectInformations {
    return {
      ProjectFormMode,
    };
  },
  methods: {
    handleUpdateProjectDataEvent() {
      this.$emit('update-project-data');
    },
  },
});
